import React, { useState, useEffect } from "react";
import './published-trip.css';
import 'react-dropdown/style.css';
import SideBar from "../../../common/sidebar/side";
import avatar from "../../../assets/images/Avatar.png";
import star from "../../../assets/images/star-blue.png";
import Header from "../../../common/header";
import { useNavigate } from "react-router-dom";
import { EDITPROFILE_API } from "../../../services/Api/editProfileApi";
import User from '../../../assets/Avatar2.png';
import toast from "react-hot-toast";
import ReactModal from 'react-modal';
import Footer from "../../../common/footer";

const PublishedTrips = (props) => {
    const navigate = useNavigate();
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(String(new Date().getMonth() + 1).padStart(2, '0'));
    const [userId, setUserId] = useState('');
    const [riderList, setRiderList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [pdId, setPdId] = useState('');
    const [selcetedCardPdId, setSelectedCardPdid] = useState('');
    const [bookedTripId, setBookedTripId] = useState('');
    const [hasFutureTrips, setHasFutureTrips] = useState(false);

    const handleOpenModal = (id, pdIddd) => {
        
        setBookedTripId(id);
        setSelectedCardPdid(pdIddd);
        setPdId(id);
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        updateMonthOptions();
    }, [selectedYear]);

    const handleDelete = async () => {
        let obj = {
            user_id: bookedTripId !== undefined ? bookedTripId : "null",
            pd_id: selcetedCardPdId
        };
        const response = await EDITPROFILE_API.deleteProfileTrip(obj);
        if (response?.status === 200) {
            toast.success('Route gelöscht', { autoClose: 2000 });
            setIsOpen(false);
            navigate("/PublishedTripCancelled");
        } else {
            toast.error('Fehler beim Aktualisieren des Profils. Bitte versuche es erneut.');
        }
    };

    const updateMonthOptions = () => {
        let monthOptions = [];
        for (let month = 1; month <= 12; month++) {
            const formattedMonth = String(month).padStart(2, '0');
            monthOptions.push(
                <option key={month} value={formattedMonth}>{getMonthName(month)}</option>
            );
        }
        return monthOptions;
    };

    const getMonthName = (monthNumber) => {
        const months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
        return months[parseInt(monthNumber) - 1];
    };

    const getPublishedTrip = (monthSelected, yearSelected, userId) => {
        EDITPROFILE_API.getPublishedTrip(monthSelected, yearSelected, userId)
            .then((response) => {
                if (response.status === 200) {
                    setRiderList(response.data);
                    // Check if any trip has a future date
                    const currentDate = new Date();
                    const hasFutureTrip = response.data.some(item => new Date(item.pickup_date) > currentDate);
                    setHasFutureTrips(hasFutureTrip);
                } else {
                    console.error('Error fetching trip data. Response:', response);
                }
            })
            .catch((error) => {
                console.error('Error fetching trip data:', error);
            });
    };

    useEffect(() => {
        let usr = localStorage.getItem('user');
        let user_info = JSON.parse(usr);
        let user_id = user_info.userInfo.user_id;
        setUserId(user_id);
        getPublishedTrip(selectedMonth, selectedYear, user_id);
    }, [selectedMonth, selectedYear]);

    return (
        <React.Fragment>
            <Header />
            <div className="pading-mobile">
                <div className="container pb-5">
                    <div className="row mt-lg-5 pt-3">
                        <ReactModal
                            isOpen={isOpen}
                            onRequestClose={handleCloseModal}
                            ariaHideApp={true}
                        >
                            <span className="close-btn p-3 py-2" onClick={handleCloseModal} style={{ float: 'right', backgroundColor: '#000', color: '#fff', borderRadius: 50 }}>&times;</span>
                            <div>
                                <div className="mt-5 pt-4">
                                    <h2 className="text-center" style={{ color: '#000', fontWeight: 600 }}>Wollen Sie ihre Fahrt wirklich stornieren?</h2>
                                    <p className="text-center" style={{ color: '#717d96' }}>Wenn Sie Ihre Fahrt stornieren, wird sie nicht mehr in der<br /> Suche angezeigt und niemand kann Sie bezüglich dieser Fahrt<br />kontaktieren.</p>
                                    <div className="text-center mt-3">
                                        <button className="btn btn-secondary me-3" style={{ backgroundColor: '#25A9E0', border: '#25A9E0' }} onClick={handleCloseModal}>Zurück</button>
                                        <button className="btn btn-danger" style={{ backgroundColor: '#fff', color: 'red', border: "1px solid #000000" }} onClick={handleDelete}>Stornieren</button>
                                    </div>
                                </div>
                            </div>
                        </ReactModal>

                        <div className="col-lg-3 hamburger-sec">
                            <nav className="navbar navbar-expand-lg navbar-light bg-white">
                                <div className="container-fluid justify-content-end px-lg-0">
                                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse mt-3 mt-lg-0" id="navbarSupportedContent">
                                        <SideBar title="publishedtrips" />
                                    </div>
                                </div>
                            </nav>
                        </div>
                        <div className="col-lg-9">
                            <div className="container">
                                <div style={{ fontSize: 24, fontWeight: 600 }}>Veröffentlichte Fahrten</div>
                                <div className="container mt-3">
                                    <div className="row">
                                        <div className="col-lg-6 col-12 px-0">
                                            <div className="dropdown">
                                                <button className="btn btn-secondary dropdown-toggle w-100 drp-bt" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                                    {selectedYear}
                                                </button>
                                                <ul className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton2">
                                                    {Array.from({ length: new Date().getFullYear() - 2023 }, (_, index) => (
                                                        <li key={2024 + index}>
                                                            <a className="dropdown-item" href="#" onClick={() => setSelectedYear(2024 + index)}>
                                                                {2024 + index}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12 px-0 px-lg-2 mt-3 mt-lg-0">
                                            <div className="dropdown">
                                                <button className="btn btn-secondary dropdown-toggle w-100 drp-bt" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    {getMonthName(selectedMonth)}
                                                </button>
                                                <ul className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                                                    {updateMonthOptions().map(monthOption => (
                                                        <li key={monthOption.props.value}>
                                                            <a className="dropdown-item" href="#" onClick={() => setSelectedMonth(monthOption.props.value)}>
                                                                {monthOption.props.children}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container mt-5 pb-5">
                                <div className="row">
                                    {riderList.length > 0 ? (
                                        riderList.map((item) => {
                                            // const currentDate = new Date();
                                            // const arrivalDateParts = item.arrival_date.split('.');
                                            // const arrivalDate = new Date(arrivalDateParts[2], arrivalDateParts[1] - 1, arrivalDateParts[0]);
                                            // const isExpired = arrivalDate < currentDate;
                                            const currentDate = new Date();
                                            const pickupDateParts = item.pickup_date.split('.');
                                            const pickupTimeParts = item.pickup_time.split(':');
                                            const pickupDateTime = new Date(
                                            pickupDateParts[2],
                                            pickupDateParts[1] - 1,
                                            pickupDateParts[0],
                                            pickupTimeParts[0],
                                            pickupTimeParts[1]
                                            );
                                     
                                            const isExpired = pickupDateTime < currentDate;
                                            

                                            return (
                                                <div className="col-lg-4 col-md-6 col-12 mt-3" key={item.pd_id}>
                                                    <div className="p-4" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", borderRadius: 24 }}>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="mt-1" style={{ color: "#000", fontSize: 16, fontWeight: 700 }}>
                                                                    Abfahrt
                                                                </div>
                                                                <div className="mt-1" style={{ color: "#4A5468", fontSize: 14, fontWeight: 700 }}>
                                                                    {item.pickup_date}
                                                                </div>
                                                                <div className="mt-1" style={{ color: "#4A5468", fontSize: 14, fontWeight: 700 }}>
                                                                    {item.pickup_time}
                                                                </div>
                                                                <div className="mt-1" style={{ color: "#4A5468", fontSize: 14 }}>
                                                                    {item.depart_to}
                                                                </div>
                                                            </div>
                                                            <div className="col-6" style={{ borderLeft: '0.1rem solid #A0ABC0' }}>
                                                                <div className="mt-1" style={{ color: "#000", fontSize: 16, fontWeight: 700 }}>
                                                                    Ankunft
                                                                </div>
                                                                <div className="mt-1" style={{ color: "#4A5468", fontSize: 14, fontWeight: 700 }}>
                                                                    {item.arrival_date}
                                                                </div>
                                                                <div className="mt-1" style={{ color: "#4A5468", fontSize: 14, fontWeight: 700 }}>
                                                                    {item.arrival_time}
                                                                </div>
                                                                <div className="mt-1" style={{ color: "#4A5468", fontSize: 14 }}>
                                                                    {item.depart_from}
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3">
                                                                <div className="col-lg-12">
                                                                    <div className="d-flex justify-content-between">
                                                                        <div className="d-flex">
                                                                            <div>
                                                                                <img
                                                                                    src={item.image_url || User}
                                                                                    style={{ width: 48, height: 48, borderRadius: 50 }}
                                                                                    alt="User Image"
                                                                                />
                                                                            </div>
                                                                            <div style={{ marginLeft: 15 }}>
                                                                                <div style={{ color: '#000', fontSize: 16, fontWeight: 700, textTransform: 'capitalize' }}>{item.name}</div>
                                                                                <div className="d-flex">
                                                                                    <img src={star} style={{ width: 24, height: 24 }} />
                                                                                    <div
                                                                                        className="my-1"
                                                                                        style={{
                                                                                            color: "#4A5468",
                                                                                            fontSize: 16,
                                                                                            marginLeft: 6,
                                                                                        }}
                                                                                    >
                                                                                        {item.avarge_rating != null && !isNaN(item.avarge_rating) ? Number(item.avarge_rating).toFixed(1) : '5.0'}/5.0
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                        <div className="d-flex justify-content-center">
                                        <div className="text-center" style={{backgroundColor:'#25a9e0',color:'#fff',borderRadius:50, width:40,height:40}}><span style={{position:'relative',top:9}}>
                                        {JSON.parse(item?.no_of_stop).length}</span>
                                        </div>
                                        </div>
                                        <div className="justify-content-center d-flex">
    {JSON.parse(item?.no_of_stop).length === 0|| JSON.parse(item?.no_of_stop).length >1 ? " Stopps" : JSON.parse(item?.no_of_stop).length == 1 ? " Stopp" : ""}
                                        </div>
                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {!isExpired && (
                                                        <div className="d-flex justify-content-center mt-3 mb-5">
                                                            <button onClick={() => handleOpenModal(item.user_id, item.pd_id)} style={{ fontSize: 16, color: "#FFFFFF", background: '#F5222D', borderRadius: 8, border: "1px solid #F5222D", paddingLeft: 25, paddingRight: 25, paddingTop: 8, paddingBottom: 8 }}>Fahrt stornieren</button>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="col-lg-12 text-center mt-3" style={{ justifyContent: "center", fontSize: "22px", color: "grey", padding: "20px" }}>
                                            <b> Keine Aufzeichnungen gefunden!</b>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
}

export default PublishedTrips;
