import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setInformation } from "../../redux/redux/actions/locationActions";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate hook

import "./information.css";
import Header from "../../../common/zuruckheader";

export default function InformationSeeker() {
  const [flag, setFlag] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate hook

  const storedUserString = localStorage.getItem("user");
  const storedUserObject = JSON.parse(storedUserString);
  const userInfo = storedUserObject.userInfo;

  const userEmail = userInfo.email;
  const userPhone = userInfo.phone_no;

  // State to track whether each checkbox is checked
  const [emailCheckboxChecked, setEmailCheckboxChecked] = useState(true); // Assuming email checkbox is initially checked
  const [phoneCheckboxChecked, setPhoneCheckboxChecked] = useState(true); // Assuming phone checkbox is initially checked

  // Function to handle checkbox change
  const handleCheckboxChange = (e) => {
    setFlag(false);
    const { name, checked } = e.target;
    if (name === "emailCheckbox") {
      setEmailCheckboxChecked(checked);
    } else if (name === "phoneCheckbox") {
      setPhoneCheckboxChecked(checked);
    }
  };

  const handleContinue = () => {
    if (phoneCheckboxChecked && emailCheckboxChecked) {
      dispatch(setInformation(userEmail, userPhone));
    } else if (phoneCheckboxChecked) {
      dispatch(setInformation(userPhone));
    } else {
      dispatch(setInformation(userEmail));
    }
    return true; // Return true if validation passes
  };

  // const handleButtonClick = () => {
  //   if (emailCheckboxChecked || phoneCheckboxChecked) {
  //     const continueClicked = handleContinue(); // Check validation before redirection
  //     if (continueClicked) {
  //       navigate("/ExtraInformation"); // Redirect only if validation passes
  //     }
  //   } else {
  //     setFlag(true);
  //     return false;
  //   }
  // };
  const handleButtonClick = () => {
    if (emailCheckboxChecked || phoneCheckboxChecked) {
      const continueClicked = handleContinue(); // Check validation before redirection
      if (continueClicked) {
        navigate("/ExtraInformation"); // Redirect only if validation passes
      }
    } else {
      setFlag(true);
      return false; // Show error if neither checkbox is checked
    }
  };  
  return (
    <>
      <Header />
      <div className="container information-seeker">
        <div className="row justify-content-center mt-lg-5 pt-5">
          <div className="col-lg-8 col-12">
            <div
              className="text-center mb-4 inform-text"
              style={{  fontWeight: 600 }}
            >
              Welche Informationen wollen Sie öffentlich sichtbar machen?
            </div>
            <div
              className="d-flex p-4 justify-content-between"
              style={{ background: "#EDF0F7", borderRadius: 24, height: 82 }}
              id={`${flag === true ? "error" : ""}`}
            >
              <input
                type="text"
                value={userEmail}
                style={{ fontSize: 18, fontWeight: 600, color: "#000" }}
                disabled
              />
              <div className="p-0">
                <input
                  className="check"
                  type="checkbox"
                  id="notification1"
                  name="emailCheckbox"
                  checked={emailCheckboxChecked}
                  onChange={handleCheckboxChange}
                  defaultChecked
                />
              </div>
            </div>
            <div
              className="d-flex mt-3 p-4 justify-content-between"
              style={{ background: "#EDF0F7", borderRadius: 24, height: 82 }}
              id={`${flag === true ? "error" : ""}`}
            >
              <input
                type="text"
                value={userPhone}
                style={{ fontSize: 18, fontWeight: 600, color: "#000" }}
                disabled
              />
              <div className="p-0">
                <input
                  className="check"
                  type="checkbox"
                  id="notification2"
                  name="phoneCheckbox"
                  checked={phoneCheckboxChecked}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <button
                type="button"
                onClick={handleButtonClick}
                className="btn btn-primary px-3 py-2 my-auto"
                style={{
                  backgroundColor: "#25A9E0",
                  border: "none",
                  fontSize: 16,
                  marginLeft: 20,
                }}
              >
                Weiter
              </button>
            </div>
            {flag && (
          <div className="text-danger">Das Feld „E-Mail“ oder „Telefonnummer“ ist ein Pflichtfeld.</div>
        )}
          </div>
        </div>
      </div>
    </>
  );
}
