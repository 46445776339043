import React, { useState, useEffect } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import RangeImg from '../../../assets/range.png'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import sms from "../../../assets/images/sms.png";
// import User from '../../../assets/Avatar2.png';
import ReportImg from '../../../assets/report-image.png'
import call from "../../../assets/images/call.png";
import { ToastContainer } from 'react-toastify';
import toast from "react-hot-toast";
import pending from "../../../assets/images/pending.png";
import star from "../../../assets/images/star-blue.png"
import starGray from "../../../assets/images/starg.png"
import avatar from "../../../assets/images/Avatar.png"
import right from "../../../assets/images/arrow-right.png"
import Back from '../../../assets/back.png'
import LocationGreen from '../../../assets/images/location-green.png'
import LocationYellow from '../../../assets/images/location-yellow.png'
import User from '../../../assets/Avatar2.png';
import { useDispatch, useSelector } from "react-redux";
import Header from '../../../common/header';
import { Modal } from 'react-responsive-modal';
import verify from "../../../assets/images/verify.png";
import 'react-responsive-modal/styles.css';
import Send from '../../../assets/images/send-blue.png'
import weigt from "../../../assets/images/weight.png";
import mycar from "../../../assets/images/car-b.png";
import infoicon from "../../../assets/images/info.png"
import ReportIcon from '../../../assets/images/Report.png'
import ProfileImg from '../../../assets/Avatar2.png'
import ForwardImg from '../../../assets/forward.png'
import StarImg from '../../../assets/images/star-blue.png'
import EmailImg from '../../../assets/images/sms.png'
import CallImg from '../../../assets/images/call.png'
import Crown from '../../../assets/images/crown.png'
import SecurityImg from '../../../assets/images/security.png'
import { setRoute } from "../../redux/redux/actions/locationActions";
import rat1 from "../../../assets/images/Rating1.png"
import rat2 from "../../../assets/images/Rating2.png"
import rat3 from "../../../assets/images/Rating3.png"
import rat4 from "../../../assets/images/Rating4.png"
import rat5 from "../../../assets/images/Rating5.png"
import './ListingDetailPage.css'
import { json, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { EDITPROFILE_API } from '../../../services/Api/editProfileApi'
import Footer from '../../../common/footer'
import ReactModal from 'react-modal';
import Carousel from "react-elastic-carousel"
import Rating from 'react-rating';
export default function ListingDetailPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pdId = queryParams.get("pdId");
    const [open, setOpen] = useState(false);
    const [userId, setUserId] = useState('');
    const [email, setEmail] = useState('')
    const [phoneNo, setPhoneNo] = useState('')
    const [aboutMe, setAboutMe] = useState('');
    const [departFrom, setDepartFrom] = useState('');
    const [departTo, setDepartTo] = useState('');
    const [driverName, setDriverName] = useState('');
    const [driverEmail, setDriverEmail] = useState('');
    const [driverPhone, setDriverPhone] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [pickupDate, setPickupDate] = useState('')
    const [arrivalDate, setArrivalDate] = useState('')
    const [pickupDate1, setPickupDate1] = useState('')
    const [arrivalDate1, setArrivalDate1] = useState('')
    const [pickupTime, setPickupTime] = useState('')
    const [arrivalTime, setArrivalTime] = useState('')
    const [weight, setWeight] = useState('')
    const [noOfCars, setNoOfCars] = useState('')
    const [information, setInformation] = useState('')
    const [profileUrl, setProfileUrl] = useState('')
    const [distance, setDistance] = useState("0");
    const [subscibed, setSubscribed] = useState('');
    const [distanceUnits, setDistanceUnits] = useState("0");
    const [time, setTime] = useState("");
    const [documentVerified, setStatus] = useState('')
    const [riderList, setRiderList] = useState([]);
    const [stops, setStops] = useState([]); // Array to store stops
    const [driver_id, set_driver_id] = useState(0)
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');
    const [showReportedModal, setShowReportedModal] = useState(false);
    const [ratingList, setRatingList] = useState([]);
    const [ratings, setRatings] = useState([]);
    const [ratCount, setRatCount] = useState({});
    const [opneRate, setIsOpenRate] = useState(false);
    const [desc, setDesc] = useState("");
    const [to, setSetTo] = useState("");
    const [from, setFrom] = useState("");
    const [ratNumber, setRatNumber] = useState(0);
    const [noOfStops, setNoOFStops] = useState([]);
    const [companyName, setCompanyName] = useState('');
    const [name, setName] = useState('');
    const [imagerating, setImageRating] = useState('');
    const [ratinggivenTo, setRatingGivenTo] = useState('');
    useEffect(()=>{
        
        const searchParams = new URLSearchParams(window.location.search);
        if(searchParams.get("given_to") && searchParams.get("geven_by")){
            setSetTo(searchParams.get("given_to"))
            setFrom(searchParams.get("geven_by"))
            setIsOpenRate(true);
            getProfileInfo(searchParams.get("given_to"));
        }else{
            setSetTo("")
            setFrom("")
            setIsOpenRate(false);
        }
    },[])
    const getProfileInfo = async (userId1) => {
        await EDITPROFILE_API.getProfileInfo(userId1)
            .then((response) => {
                if (response?.status === 200) {
                    setName(response?.data[0].name);
                    setCompanyName(response?.data[0].name);
                    setImageRating(response?.data[0].image_url)
                    setRatingGivenTo(response?.data[0].rating)
                }
            })
            .catch((error) => {
                // Handle error
            });
    };
    useEffect(() => {
        let usr = localStorage.getItem("user");
        let user = JSON.parse(usr)
        
        EDITPROFILE_API?.ratingList(driver_id).then(res => {
            if (res && res?.data?.length > 0) {
                setRatingList(res);

                let rat = res?.data?.map(va => va?.rating);
                let ratsort = rat.sort();
                let obj = {}
                for (var ar of ratsort) {
                    obj[ar] = obj[ar] ? obj[ar] + 1 : 1;
                }
                setRatCount(obj);
            }
        });
    }, [driver_id])



    const handleOpenModal = () => {
        setIsOpen(true);
    };
    const handleCloseModal = () => {
        setIsOpen(false);
    };
    const options = [
        'Betrug', 'Verdächtige Aktivität', 'Falsche Informationen', 'Unangemessener Inhalt', 'Sonstiges'
    ];
    const defaultOption = options[0];
    const handleNextClick = () => {

        navigate(`/ConfirmReservation?pdId=${pdId}`);
    }
    const onOpenModal = () => {
        setOpen(true)
        document.getElementById("navbarSupportedContent")?.classList.remove("show");
    };
    const onCloseModal = () => {
        setOpen(false);
        setShowReportedModal(false);
        setError('');
        setSelectedOption(null);
        setDescription('');
    }
    const handleContinue = async () => {
        if (!selectedOption || !description) {
            setError('Bitte füllen Sie alle Pflichtfelder aus.');
        } else {
            let obj = {
                driverId: driver_id,
                searcherId: userId,
                pdId: parseInt(pdId),
                description: description,
                reason: selectedOption
            }
            const response = await EDITPROFILE_API.reportDriver(obj);
            if (response?.status === 200 || response?.status === 201) {

                toast.success('Ihre Meldung wurde gesendet.', {
                    autoClose: 2000,
                });
                setOpen(false);
                setShowReportedModal(true);
            } else {
                
                toast.error('Fehler beim Aktualisieren des Profils. Bitte versuche es erneut.');
            }
        }
    };

    const getBokkedTripDetails = (pd_id) => {
        EDITPROFILE_API.getTripByPdiddd(pd_id)
            .then((response) => {
                if (response && response.status === 200 && response.data) {

                    const profiledata = response.data[0] || []
                    set_driver_id(profiledata.user_id)
                    setDepartFrom(profiledata.depart_from.split(',')[0])
                    setDepartTo(profiledata.depart_to.split(',')[0])
                    setDriverName(profiledata.name)
                    setDriverEmail(profiledata.visible_email)
                    setDriverPhone(profiledata.visible_phone_no)
                    setAboutMe(profiledata.about_me)
                    setImageUrl(profiledata.image_url)
                    setPickupDate(profiledata.pickup_date)
                    setArrivalDate(profiledata.arrival_date)
                    setPickupDate1(profiledata.pickup_date)
                    setArrivalDate1(profiledata.arrival_date)
                    setPickupTime(profiledata.pickup_time)
                    setArrivalTime(profiledata.arrival_time)
                    setWeight(profiledata.weight)
                    setNoOfCars(profiledata.no_of_cars)
                    setInformation(profiledata.description)
                    setProfileUrl(profiledata.img_url)
                    setNoOFStops(JSON.parse(profiledata.no_of_stop))
                    getAllPublishedTrip(profiledata.user_id)
                    setRatings(profiledata?.avarge_rating ?parseFloat(profiledata?.avarge_rating)?.toFixed(1):"5.0")
                } else {
                    console.error('Error fetching branch data. Response:', response)
                }
            })
            .catch((error) => {
                console.error('Error fetching branch data:', error)
            })
    }
    const getAllPublishedTrip = (userid) => {
        EDITPROFILE_API.getAllPublishedTrip(userid)
            .then((response) => {
                if (response && response.status === 200 && response.data) {
                    const cardData = response.data[0] || []
                    setRiderList(response.data);
                } else {
                    console.error('Error fetching branch data. Response:', response)
                }
            })
            .catch((error) => {
                console.error('Error fetching branch data:', error)
            })
    }


    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('.');
        const formattedDate = `${year}-${month}-${day}`;
        const date = new Date(Date.parse(formattedDate));
        if (isNaN(date.getTime())) {
            console.error('Invalid date:', dateString);
            return '';
        }
        const options = { weekday: 'long', month: 'short', day: '2-digit' };
        return new Intl.DateTimeFormat('de-DE', options).format(date);
    };
    const formatDate1 = (dateString) => {
        const [day, month, year] = dateString.split('.');
        const formattedDate = `${year}-${month}-${day}`;
        const date = new Date(Date.parse(formattedDate));
        if (isNaN(date.getTime())) {
            console.error('Invalid date:', dateString);
            return '';
        }
        const options = { month: 'short', day: '2-digit' };
        return new Intl.DateTimeFormat('de-DE', options).format(date);
    };
   
    const latitude2 = 52.5200143;
    const longitude2 = 13.4049447;
    const latitude1 = 48.1352031;
    const longitude1 = 11.5820972;


    useEffect(() => {
        const directionsService = new window.google.maps.DirectionsService();
        const directionsRenderer = new window.google.maps.DirectionsRenderer();

        const map = new window.google.maps.Map(document.getElementById("my-map"), {
            center: { lat: latitude1, lng: longitude1 },
            zoom: 14,
        });

        directionsRenderer.setMap(map);

        const origin = new window.google.maps.LatLng(latitude1, longitude1);
        const destination = new window.google.maps.LatLng(latitude2, longitude2);
        const waypoints =
        noOfStops && Array.isArray(noOfStops)
          ? noOfStops.map((noOfStops) => ({
              location: new window.google.maps.LatLng(noOfStops.lat, noOfStops.lon),
              stopover: true,
            }))
          : [];
        const request = {
            origin: departTo,
            destination: departFrom,
            waypoints: waypoints,
      optimizeWaypoints: true,
            travelMode: window.google.maps.TravelMode.DRIVING,
        };


        directionsService.route(request, (response, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
                directionsRenderer.setDirections(response);
                const route = response.routes[0];
                const routeDistance = route.legs.reduce((acc, curr) => acc + curr.distance.value, 0);
                const distanceInKm = routeDistance / 1000;
                setDistance(distanceInKm.toFixed(0));
                setDistanceUnits("Kilometer");
                const routeTime = route.legs.reduce((acc, curr) => acc + curr.duration.value, 0);
                const hours = Math.floor(routeTime / 3600);
                const minutes = Math.floor((routeTime % 3600) / 60);
                setTime(`${hours} Std. ${minutes} Min.`);
                const stops = extractStops(response);
                setStops(stops);
                dispatch(setRoute(`${hours} Std. ${minutes} Min.`, `${distanceInKm.toFixed(0)} Km`, stops));
            } else {
                console.error("Directions request failed due to " + status);
            }
        });
    }, [latitude1, longitude1, latitude2, longitude2, departFrom, departTo]);
    const extractStops = (response) => {
        const stops = [];
        response.routes[0].legs.forEach((leg) => {
            leg.steps.forEach((step) => {
                if (step.instructions) {
                    const instruction = step.instructions.replace(/<\/?[^>]+(>|$)/g, "");
                    if (instruction.toLowerCase().includes("onto")) {
                        const stopName = instruction.split("onto")[1].trim();
                        stops.push({ name: stopName });
                    }
                }
            });
        });
        return stops;
    };

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 3 },
        { width: 768, itemsToShow: 4 },
        { width: 1200, itemsToShow: 5 },
    ]
    useEffect(() => {
        let usr = localStorage.getItem('user')
        let user_info = JSON.parse(usr)
        let user_id = user_info.userInfo.user_id;
        setSubscribed(user_info.userInfo.is_subscribe)
        setUserId(user_id)
        let status = user_info.userInfo.status;
        setStatus(status);
        getBokkedTripDetails(pdId)
       
    }, [])

    const submitRating=async(e)=>{
        let obj={
            rating:ratNumber,
            given_to:parseInt(to),
            given_by:parseInt(from),
            description:desc
        }
        const response = await EDITPROFILE_API.feedBackSubmit(obj);
        if (response?.status === 200 || response?.status === 201) {
            toast.success('FeedBack erfolgreich gesendet', {
                autoClose: 1000,
            });
          await  navigate("/")
           
        } else {
          
        }
    }

    return (
        <>
      
            <Header />
        
            <div className='container'>
                <ReactModal
                    isOpen={isOpen}
                    onRequestClose={handleCloseModal}
                    ariaHideApp={true}
                    className="Listing-modal-tab"

                >
                    <span className="close-btn p-3 py-2" onClick={handleCloseModal} style={{ float: 'right', backgroundColor: '#000', color: '#fff', borderRadius: 50,cursor:'pointer' }}>&times;</span>
                    <div>

                        <div className="tab-sec-modal">
                            <Tabs>
                                <TabList>
                                    <Tab>Fahrer Informationen</Tab>
                                    <Tab>Fahrer Bewertung</Tab>
                                </TabList>

                                <TabPanel>
                                    {/* <div className='mt-3 mb-2' style={{ fontSize: 24, fontWeight: 600 }}>Profil</div> */}
                                    <div className="d-flex profile-container">
                                        <div style={{ height: '96px' }}>
                                            {imageUrl ? (
                                                <img src={imageUrl} className="img-fluid profile-image" width="80" style={{ borderRadius: 50, height: 80 }} alt="Profile" />
                                            ) : (
                                                <img src={User} className="img-fluid profile-image" width="80" style={{ borderRadius: 50, height: 80 }} alt="User" />
                                            )}
                                        </div>

                                        <div className="mx-4 mt-2">
                                            <div style={{ color: "#000000", fontSize: 26, fontWeight: 700, textTransform: 'capitalize' }}>{driverName}</div>
                                            {(subscibed === 1 || documentVerified === 1) ? (
                                                <div style={{ color: "#000000", fontSize: 16 }}>
                                                    <div className="d-flex">
                                                        <div>
                                                            <img src={verify} width={18} alt="Verified" />
                                                        </div>
                                                        <div className='mx-2'>Verifiziertes Profil</div>
                                                    </div>
                                                </div>

                                            ) : (
                                                <div style={{ color: "#000000", fontSize: 18 }}>

                                                    <div className="d-flex">
                                                        <div>
                                                            <img src={pending} width={22} alt="pending" />
                                                        </div>
                                                        <div className="mx-2">Verifizierung steht aus</div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                    <div className="row container ">
                                        {driverEmail &&
                                            <div className="col-lg-6">
                                                <div className="" style={{ color: "#000000", fontSize: 20, fontWeight: 700 }} >
                                                    E-Mail
                                                </div>
                                                <div className="d-flex mt-2"  >
                                                    <div>
                                                        <img src={sms} style={{ width: 26, height: 26 }} />
                                                    </div>
                                                    <div className='my-auto mx-2' style={{ color: "#000000", fontSize: 18 }}>{driverEmail}</div>
                                                </div>
                                            </div>
                                        }
                                        {driverPhone &&
                                            <div className="col-lg-4">
                                                <div className="" style={{ color: "#000000", fontSize: 20, fontWeight: 700 }} >
                                                    Telefonnummer
                                                </div>
                                                <div className="d-flex mt-2"  >
                                                    <div>
                                                        <img src={call} style={{ width: 26, height: 23 }} />
                                                    </div>
                                                    <div className='my-auto mx-2' style={{ color: "#000000", fontSize: 18 }}>{driverPhone}</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="row container mt-3">
                                        <div className="col-lg-12">
                                            <div className="" style={{ color: "#000000", fontSize: 20, fontWeight: 700 }}>
                                                Erzählen Sie uns ein wenig über sich
                                            </div>
                                            <div>
                                                {aboutMe ? (
                                                    <div style={{ color: "#4A5468", fontSize: 18 }}>{aboutMe}</div>
                                                ) : (
                                                    <div style={{ color: "#4A5468", fontSize: 18 }}>Ich liebe Autos &#128578;</div>
                                                )}
                                            </div>


                                        </div>
                                    </div>
                                    <hr className='' />
                                    <div className="row ">
                                        <div className='mb-3' style={{ fontSize: 24, fontWeight: 600 }}>Veröffentlichte Fahrten</div>
                                        <div className='col-12'>
                                            <Carousel breakPoints={breakPoints}>
                                                {riderList.length > 0 ? (
                                                    riderList.map((item) => (
                                                        <div className="col-lg-11" key={item.pd_id}>
                                                            <div className=" p-4" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", borderRadius: 24 }}>
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="mt-1" style={{ color: "#000", fontSize: 16, fontWeight: 700 }}>
                                                                            Abfahrt
                                                                        </div>
                                                                        <div className="mt-1" style={{ color: "#4A5468", fontSize: 14, fontWeight: 700 }}>
                                                                            {item.pickup_date}
                                                                        </div>
                                                                        <div className="mt-1" style={{ color: "#4A5468", fontSize: 14, fontWeight: 700 }}>
                                                                            {item.pickup_time}
                                                                        </div>
                                                                        <div className="mt-1" style={{ color: "#4A5468", fontSize: 14 }}>
                                                                            {item.depart_to}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6" style={{ borderLeft: '0.1rem solid #A0ABC0' }}>
                                                                        <div className="mt-1" style={{ color: "#000", fontSize: 16, fontWeight: 700 }}>
                                                                            Ankunft
                                                                        </div>
                                                                        <div className="mt-1" style={{ color: "#4A5468", fontSize: 14, fontWeight: 700 }}>

                                                                            {item.arrival_date}
                                                                        </div>
                                                                        <div className="mt-1" style={{ color: "#4A5468", fontSize: 14, fontWeight: 700 }}>

                                                                            {item.arrival_time}
                                                                        </div>
                                                                        <div className="mt-1" style={{ color: "#4A5468", fontSize: 14 }}>
                                                                            {item.depart_from}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-3">
                                                                        <div className="col-lg-12">
                                                                            <div className="d-flex">
                                                                                <div>
                                                                                    <img
                                                                                        src={item.image_url || User}
                                                                                        style={{ width: 48, height: 48, borderRadius: 50 }}
                                                                                        alt="User Image"
                                                                                    />
                                                                                </div>
                                                                                <div style={{ marginLeft: 15 }}>
                                                                                    <div style={{ color: '#000', fontSize: 16, fontWeight: 700 }}>{item.name}</div>
                                                                                    <div className="d-flex">
                                                                                        <img src={star} style={{ width: 24, height: 24 }} />
                                                                                        <div className="my-1" style={{ color: '#4A5468', fontSize: 16, marginLeft: 6 }}>{ratings}/5.0</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            {/* <div className="d-flex justify-content-center mt-3">
                                            
                                            
                                        <button onClick={() => handleOpenModal(item.trip_booked,item.pd_id)}style={{fontSize:16,color:"#FFFFFF",background:'#F5222D',borderRadius:8,border:"1px solid #F5222D",paddingLeft:25,paddingRight:25,paddingTop:8,paddingBottom:8}}>Fahrt stornieren</button>
                                        </div > */}
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="col-lg-12 text-center mt-3" style={{ justifyContent: "center", fontSize: "22px", color: "grey", padding: "20px" }}>
                                                        <b> Keine Aufzeichnungen gefunden!</b>
                                                    </div>
                                                )}
                                            </Carousel>
                                        </div>


                                    </div>

                                </TabPanel>
                                <TabPanel>
                                {ratingList?.count ? <div className="mt-4" style={{ fontSize: 22, color: "#717D96" }}>{ratingList?.av} aus {ratingList?.count} Bewertungen</div> : <div className="mt-4" style={{ fontSize: 22,padding: 22, color: "grey",textAlign:"center" }}> <b>Keine Bewertungen erhalten</b></div>}
                                    {/* <div style={{ fontSize: 24, fontWeight: 600 }}>Bewertungen</div>
                                    <div className="mt-4" style={{ fontSize: 24, fontWeight: 600 }}>{ratingList?.av ? ratingList?.av : '5.0'}/5.0</div> */}
                                    {ratingList?.av ==="NaN" &&
                                    <>
                                    <div className="mt-4" style={{ fontSize: 22, color: "#717D96" }}>aus {ratingList?.count ? ratingList?.count:0} Bewertungen</div>
                                   
                                    <div className="row mt-3">
                                        <div style={{
                                            backgroundColor: "#eee",
                                            border: '0 none',
                                            color: '#717D96',
                                            height: '0.1rem'
                                        }}></div>
                                        <div className="col-lg-10 mt-3">


                                            <div className="d-flex justify-content-between mt-3">
                                                <div className="d-flex">
                                                    <img src={star} style={{ height: 24, width: 24 }} />
                                                    <img src={star} style={{ height: 24, width: 24 }} />
                                                    <img src={star} style={{ height: 24, width: 24 }} />
                                                    <img src={star} style={{ height: 24, width: 24 }} />
                                                    <img src={star} style={{ height: 24, width: 24 }} />
                                                </div>
                                                <div style={{
                                                    backgroundColor: "#717D96",
                                                    border: '0 none',
                                                    color: '#717D96',
                                                    height: '0.1rem',
                                                    width: '100%',
                                                    marginTop: 10,
                                                    marginLeft: 10
                                                }}></div>
                                                <div style={{ marginLeft: 10, color: "#717D96", fontSize: 14, fontWeight: 600 }}>{ratCount['5'] ? ratCount['5'] : "0"}</div>
                                            </div>
                                            <div className="d-flex justify-content-between mt-3">
                                                <div className="d-flex">
                                                    <img src={star} style={{ height: 24, width: 24 }} />
                                                    <img src={star} style={{ height: 24, width: 24 }} />
                                                    <img src={star} style={{ height: 24, width: 24 }} />
                                                    <img src={star} style={{ height: 24, width: 24 }} />

                                                </div>
                                                <div style={{
                                                    backgroundColor: "#717D96",
                                                    border: '0 none',
                                                    color: '#717D96',
                                                    height: '0.1rem',
                                                    width: '100%',
                                                    marginTop: 10,
                                                    marginLeft: 10
                                                }}></div>
                                                <div style={{ marginLeft: 10, color: "#717D96", fontSize: 14, fontWeight: 600 }}>{ratCount['4'] ? ratCount['4'] : "0"}</div>
                                            </div>
                                            <div className="d-flex justify-content-between mt-3">
                                                <div className="d-flex">
                                                    <img src={star} style={{ height: 24, width: 24 }} />
                                                    <img src={star} style={{ height: 24, width: 24 }} />
                                                    <img src={star} style={{ height: 24, width: 24 }} />

                                                </div>
                                                <div style={{
                                                    backgroundColor: "#717D96",
                                                    border: '0 none',
                                                    color: '#717D96',
                                                    height: '0.1rem',
                                                    width: '100%',
                                                    marginTop: 10,
                                                    marginLeft: 10
                                                }}></div>
                                                <div style={{ marginLeft: 10, color: "#717D96", fontSize: 14, fontWeight: 600 }}>{ratCount['3'] ? ratCount['3'] : "0"}</div>
                                            </div>
                                            <div className="d-flex justify-content-between mt-3">
                                                <div className="d-flex">
                                                    <img src={star} style={{ height: 24, width: 24 }} />
                                                    <img src={star} style={{ height: 24, width: 24 }} />
                                                </div>
                                                <div style={{
                                                    backgroundColor: "#717D96",
                                                    border: '0 none',
                                                    color: '#717D96',
                                                    height: '0.1rem',
                                                    width: '100%',
                                                    marginTop: 10,
                                                    marginLeft: 10
                                                }}></div>
                                                <div style={{ marginLeft: 10, color: "#717D96", fontSize: 14, fontWeight: 600 }}>{ratCount['2'] ? ratCount['2'] : "0"}</div>
                                            </div>
                                            <div className="d-flex justify-content-between mt-3">
                                                <div className="d-flex">
                                                    <img src={star} style={{ height: 24, width: 24 }} />
                                                </div>
                                                <div style={{
                                                    backgroundColor: "#717D96",
                                                    borderBlock: '1px',
                                                    color: '#717D96',
                                                    height: '0.1rem',
                                                    width: '100%',
                                                    marginTop: 10,
                                                    marginLeft: 10
                                                }}></div>
                                                <div style={{ marginLeft: 10, color: "#717D96", fontSize: 14, fontWeight: 600 }}>{ratCount['1'] ? ratCount['1'] : "0"}</div>
                                            </div>
                                        </div>
                                        <div className="mt-4" style={{
                                            backgroundColor: "#eee",
                                            border: '0 none',
                                            color: '#717D96',
                                            height: '0.1rem'
                                        }}></div>
                                    </div><br />
                                    </>
                                    }

                                    {ratingList?.data?.map((val, ins) =>
                                        <div className="row mt-0" key={ins}>
                                            <div className="col-lg-4">
                                                <div className="d-flex ">
                                                    <div>
                                                        <img src={val?.image_url ? val?.image_url : avatar} style={{ width: 48, height: 48, borderRadius: 50 }} />
                                                    </div>
                                                    <div className="my-auto" style={{ color: '#000', fontSize: 16, fontWeight: 700, marginLeft: 10 }}>
                                                        {val?.company_name != "" ? <span style={{ textTransform: 'capitalize' }}>{val?.company_name}</span> : <><span style={{ textTransform: 'capitalize' }}>{val?.first_name}</span> <span style={{ textTransform: 'capitalize' }}>{val?.last_name}</span></>}
                                                    </div>
                                                    <div className="my-auto">
                                                        <img src={right} style={{ width: 30, height: 25 }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex mt-2">
                                                <img src={val?.rating == 1 ? rat1 : val?.rating == 2 ? rat2 : val?.rating == 3 ? rat3 : val?.rating == 4 ? rat4 : rat5} style={{ width: val?.rating == 3 ? '11%' : val?.rating == 5 ? "19%" : val?.rating == 4 ? "15%" : val?.rating == 2 ? "7%" : '3%' }} />
                                            </div>
                                            <div className="mt-3" style={{ fontSize: 22, color: "#717D96" }}>
                                                {val?.description}
                                            </div>
                                            <div><hr /></div>
                                        </div>
                                    )}
                                </TabPanel>
                            </Tabs>
                        </div>
                        {/* Add more content or functionality for your modal */}
                    </div>
                </ReactModal>
                <div className='row mt-5 pt-lg-4 pb-5' style={{ borderBottom: '5px solid #E2E7F0' }}>
                    <div className='col-12 mb-5 pb-lg-2'>
                        <div className='d-flex my-auto' onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
                            <img className='mx-2' src={Back} width={7} height={14} />
                            <div style={{ fontSize: 14, marginRight: 100 }}>Zurück</div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-12'>
                        <div className='' style={{ fontSize: 40, fontWeight: 600, color: '#10475E' }}> {departTo} nach {departFrom}</div>
                        <div className='d-flex justify-content-between mt-4'>
                            <div className='booktrip' style={{ fontWeight: 600, color: '#25A9E0' }}>{formatDate(pickupDate)}</div>
                            <div className='mx-3 booktrip' style={{ fontWeight: 600, color: '#A0ABC0' }}>&#8594;</div>
                            <div className='booktrip' style={{ fontWeight: 600, color: '#25A9E0' }}>{formatDate(arrivalDate)}</div>
                        </div>
                        <div className='mt-4'><img src={RangeImg} width={'100%'} /></div>
                        <div className='d-flex justify-content-between mt-4'>
                            <div>
                                {/* <div><img src={LocationGreen} width={18} /></div> */}
                                <div style={{ fontSize: 16, fontWeight: 600 }}>Abfahrt</div>
                                <div style={{ fontSize: 14, color: '#4A5468', fontWeight: 600 }}>{formatDate1(pickupDate1)} <br /> {pickupTime}</div>
                                <div style={{ fontSize: 14, color: '#A0ABC0' }}>{departTo}</div>
                            </div>
                            <div style={{ textAlign: 'end' }}>
                                {/* <div className='d-flex justify-content-end'><img src={LocationYellow} width={18} /></div> */}
                                <div style={{ fontSize: 16, fontWeight: 600 }}>Ankunft</div>
                                <div style={{ fontSize: 14, color: '#4A5468', fontWeight: 600 }}>{formatDate1(arrivalDate1)} <br />  {arrivalTime}</div>
                                <div style={{ fontSize: 14, color: '#A0ABC0' }}>{departFrom}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 offset-lg-1 px-lg-0 mt-4 mt-lg-0">
                        <div id="my-map" style={{ width: "100%", height: "300px", borderRadius: 24 }}></div>
                    </div>
                </div>
                <div className='row my-5'>
                    <div className='col-lg-6 col-12'>
                        <div style={{ fontSize: "24px", color: "#000", fontWeight: 600 }}>Fahrer Informationen</div>
                        <div className='back-card px-4 py-3' onClick={handleOpenModal} style={{ backgroundImage: "radial-gradient(#145D7B,#10475E)", borderRadius: 24,cursor:'pointer' }}>
                            <div className='row'>
                                <div className='col-lg-6 py-4 inform-sec'>
                                    <div className='d-flex mb-4'>
                                        {imageUrl ? (
                                            <img src={imageUrl} width={'48px'} style={{ borderRadius: "50%", height: 48 }} alt="User Image" />
                                        ) : (
                                            <img src={User} width={'48px'} style={{ borderRadius: "50%", height: 48 }} alt="Default User Image" />
                                        )}
                                        <div className='mx-3 my-auto'>
                                            <div className='text-white' style={{ fontSize: 16, fontWeight: 600, textTransform: 'capitalize' }}>{driverName}</div>
                                            <div className='d-flex'>
                                                <img src={StarImg} width={20} height={20} />
                                               
                                                <div className='text-white mx-2'>{ratings}/5.0</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex mb-2'>
                                        {driverEmail && <img src={EmailImg} width={26} height={24} />}
                                        {driverEmail && <div className='text-white mx-3' style={{ fontSize: "18px", overflowWrap: 'anywhere' }}>{driverEmail}</div>}
                                    </div>
                                    <div className='d-flex'>
                                        {driverPhone && <img src={CallImg} width={24} height={24} />}
                                        {driverPhone && <div className='text-white mx-3' style={{fontSize:18}}>{driverPhone}</div>}
                                    </div>
                                </div>
                                <div className='col-lg-6 my-auto pt-4 pt-lg-0'>
                                    <div>
                                        <div className='d-flex'>
                                            <div>
                                                <img src={Crown} width={23} style={{ height: 20 }} />
                                            </div>
                                            <div className='text-white  mx-2' style={{ fontSize: 18, fontWeight: 'regular' }}>Storniert nie Fahrten</div>
                                        </div>
                                        <div className='d-flex' style={{ marginRight: 20 }}>
                                            <div>
                                                <img src={SecurityImg} width={23} height={20} />
                                            </div>
                                            <div className='text-white  mx-2' style={{ fontSize: 18, fontWeight: 'regular' }}>Verifizierter Fahrer</div>
                                        </div>
                                        <div className='d-flex' style={{ marginRight: 20 }}>
                                            <div>
                                                <img src={StarImg} width={23} height={20} />
                                            </div>
                                            <div className='text-white mx-2' style={{ fontSize: 18, fontWeight: 'regular' }}>
                                                {(ratings >= 1 && ratings <= 3) ? "Mittelmäßig Bewertet" : (ratings > 3 && ratings <= 4) ? "Gut Bewertet" : ratings > 4 ? "Sehr gut bewertet" : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-12 offset-lg-1 mt-4 mt-lg-0" >
                        <div style={{ fontSize: "24px", color: "#000", fontWeight: 600 }}>Fahrzeug Informationen</div>
                        <div className="card px-3 " style={{ border: "0.1rem solid #E2E7F0", borderRadius: 20, height: 210 }}>
                            <div className="d-flex mt-3 ">
                                <div className="tooltip-container" data-tooltip="Zuladungsgewicht"><img src={weigt} width={"30"} /></div>
                                <div className="mx-2 my-auto" style={{ color: "#000", fontSize: 20 }}>{weight}</div>
                            </div>
                            <div className="d-flex mt-3">
                                <div className="tooltip-container" data-tooltip="Verfügbare Stellplätze"><img src={mycar} width={"30"} style={{ height: 26 }} /></div>
                                <div className="mx-2 my-auto" style={{ color: "#000", fontSize: 20 }}>
                                    {noOfCars > 1 ? `${noOfCars} Stellplätze` : `${noOfCars} Stellplatz`}
                                </div>
                            </div>
                            <div className="d-flex mt-3">
                                <div className="tooltip-container" data-tooltip="Zusatzinformation"><img src={infoicon} width={"30"} /></div>
                                <div className="mx-2 my-auto" style={{ color: "#000", fontSize: 20 }}>{information}</div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-end mt-3' style={{ color: "red" }}><img src={ReportIcon} width={20} height={20} /><div onClick={onOpenModal} style={{ cursor: 'pointer' }}>Fahrt melden</div></div>
                    </div>
                </div>
            </div>
            
            <div className=''>
            <div className='container-fluid'>
                <div className='row py-3 ' style={{ background: "#25A9E0" }} >
                    <div className='col-12 d-flex justify-content-center '>
                        <div>
                            <button
                                type="button"
                                class="btn btn-primary px-3 py-2  d-flex justify-content-center"
                                style={{
                                    backgroundColor: "#fff",
                                    color: '#25A9E0',
                                    border: "none",
                                    fontSize: 16,
                                }}
                            >
                                <img src={Send} width={"20px"} />
                                <span style={{ marginLeft: 5 }} onClick={handleNextClick}>
                                    Fahrt anfragen
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            {/* <Footer /> */}
            <Modal open={open} onClose={onCloseModal} center style={{ background: '#f2f2f2' }}>
                <div className="container-modal">
                    <div className="text-center contact">Fahrt melden</div>
                    <form>
                        <label className="label-text mt-3 mb-1" htmlFor="reason">
                            Welchen Grund hat Ihre Meldung? <sup style={{ color: 'red' }}>*</sup>
                        </label>
                        <Dropdown
                            options={options}
                            value={selectedOption}
                            onChange={(e) => setSelectedOption(e.value)}
                            placeholder="Wahlen Sie einen Grund aus"
                        />
                        <label className="label-text mt-3" htmlFor="description">
                            Beschreiben Sie Ihre Meldung kurz<sup style={{ color: 'red' }}>*</sup>
                        </label>
                        <textarea
                            id="description"
                            name="description"
                            placeholder=""
                            style={{ height: '100px' }}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                        <div className="text-center">
                            <button
                                type="button"
                                className="btn btn-primary"
                                style={{ width: '100%', backgroundColor: 'rgb(37, 169, 224)', borderColor: 'rgb(37, 169, 224)' }}
                                onClick={handleContinue}
                            >
                                Weiter
                            </button>
                        </div>
                        <div style={{ height: 20, color: 'red', fontSize: 12, marginTop: 5 }}>{error}</div>
                    </form>
                </div>
            </Modal>
            <Modal open={showReportedModal} onClose={onCloseModal} center>
                <div className="container-modal">
                    <div className='d-flex justify-content-center'><img src={ReportImg} width={"200px"} /></div>
                    <div className="text-center contact my-3">Vielen Dank, wir haben Ihre Meldung erhalten.</div>
                    <div className='text-center' style={{ fontSize: 14, color: '#A0ABC0' }}>Ihre Meldung wurde versendet und wird umgehend von uns geprüft. Vielen Dank, dass Sie dazu beitragen, dass WarumLeer ein sicherer&nbsp;Ort&nbsp;bleibt.</div>
                </div>
            </Modal>
          
            <Modal open={opneRate} onClose={()=>{setIsOpenRate(false)}} center style={{ background: '#f2f2f2' }}>
                <div className="container-modal">
                    <div className="text-center contact">
                        Wie gefiel Ihnen<br />
                        <span style={{ textTransform: 'capitalize' }}>{name?name:companyName}</span>
                    </div>
                    <div style={{ height: '96px' }}>
                        {imagerating ? (
                            <img src={imagerating} className="img-fluid profile-image" width="80" style={{ borderRadius: 50, height: 80, margin: "auto" }} alt="Profile" />
                        ) : (
                            <img src={User} className="img-fluid profile-image" width="80" style={{ borderRadius: 50, height: 80, margin: "auto" }} alt="User" />
                        )}
                        <div>
                            <div style={{ fontSize: 18, fontWeight: 'bold', textTransform: 'capitalize', textAlign: 'center' }}>
                                {name?name:companyName}
                            </div>
                            <div className='d-flex justify-content-center'>
                                <div><img src={star} className="icon" style={{ margin: "auto", width: 20 }} /></div>&nbsp;
                                {/* <div style={{ color: "#4A5468", fontSize: 14, fontWeight: 700 }}>{ratinggivenTo?ratinggivenTo:"5.0"}/5.0</div> */}
                                <div style={{ color: "#4A5468", fontSize: 14, fontWeight: 700 }}>
  {ratinggivenTo ? parseFloat(ratinggivenTo).toFixed(1) : "5.0"}/5.0
</div>

                            </div>
                        </div>
                    </div>
                    <div id='rating' className='mt-4'>
                        <Rating className='mt-3'
                            stars={ratNumber}
                            onChange={(rate) => setRatNumber(rate)}
                            placeholderRating={ratNumber}
                            emptySymbol={<img src={starGray} className="icon" />}
                            placeholderSymbol={<img src={star} className="icon" />}
                            fullSymbol={<img src={star} className="icon" />}
                        />
                    </div>
                    <div>
                        <div>
                            <label className="label-feedback mt-3" for="subject">Geben Sie ein Feedback zu {name?name:companyName}
                            </label>
                            <textarea onChange={(e) => {
                                setDesc(e.target.value);
                            }} id="subject" name="subject" placeholder="" style={{ height: '100px', }}></textarea>
                            <div className="text-center">
                                <button type="button" className="btn btn-primary" onClick={submitRating} style={{ width: '100%', backgroundColor: 'rgb(37, 169, 224)', borderColor: 'rgb(37, 169, 224)' }}>Weiter</button>
                            </div>
                            <div style={{ height: 20, color: "red", fontSize: 12, marginTop: 5 }}></div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}