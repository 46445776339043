import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./views/redux/redux/store"; // Update the import path

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
// index.js
const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// Replace placeholder with actual API key
document.querySelector('script[src^="https://maps.googleapis.com/maps/api/js"]')
  .setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
