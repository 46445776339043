import React, { useState } from 'react'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import axios from "axios";
import toast from "react-hot-toast";
import { ToastContainer } from 'react-toastify';
import Location from "../../../assets/location.png";
import Route from "../../../assets/routing.png";
import Calendar from "../../../assets/calendar-2.png";
import ListingImg from "../../../assets/No Results.png";
import "../HomePage/HomePage.css";
import Header from "../../../common/header";
import Footer from "../../../common/footer";
import { Link } from "react-router-dom";
import { useLocation,useNavigate } from "react-router-dom";
export default function ListingPageNoResults() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [errr, setErrr] = useState("")
  const [showMenu, setShowMenu] = useState(true)
  const [phoneErr, setPhoneErr] = useState(false)
  const [contactBack, setContactBack] = useState(0);
  const location = useLocation();
  const { location: loc, arrivalLocation, date } = location.state || {};
  const [departureLocation, setDepartureLocation] = useState(loc || "");
  const [arrivalLocationValue, setArrivalLocationValue] = useState(arrivalLocation || "");
  const [dateValue, setDateValue] = useState(date || "");
  const onOpenModal = () => {
    setOpen(true)
    // document.getElementById("navbarSupportedContent")?.classList.remove("show");
  };
  const handleGetNotification= () =>{
   
    navigate("/GetNotifications", {
      state: {
        location: departureLocation,
        arrivalLocation: arrivalLocationValue,
        date: formatDate(dateValue),
      },
    });
  }
  const formatDate = (date) => {
    let dt = date;
    return date;
  };
  const onCloseModal = () => setOpen(false);

  const onSumbit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!name || !email || !phone || !message) {
          setErrr("Bitte füllen Sie alle markierten Felder aus");
          setTimeout(() => {
            setErrr("");
          }, 3000);
          return;
        }
    
        if (!emailRegex.test(email)) {
          setErrr("Bitte füllen Sie alle markierten Felder aus");
          setTimeout(() => {
            setErrr("");
          }, 3000);
          return;
        }
    
        if (phone.length < 8 || phone.length > 14) {
          setErrr("Telefonnummer muss zwischen 8 und 14 Zeichen lang sein");
          setTimeout(() => {
            setErrr("");
          }, 3000);
          return;
        }
    setOpen(false);

    axios.post("https://smtp.taction.in/api/api/v2/send-mail", {
      name: name,
      email: email,
      message: message
    }).then(res => {
      if (res && res?.status == 200) {

        toast.success('Ihre Anfrage wurde übermittelt ', {
          autoClose: 2000,
        }
        );
       
      }

    }).catch((err) => {
      
    })
  }

  const closeT = () => {
    document.getElementById("navbarSupportedContent")?.classList.remove("show");
  }

  return (
    <>
      <Header />

      <div className="container mt-5">
        <div className="row justify-content-center mt-4">
          <div className="col-12">
            <div
              className="text-center"
              style={{ fontSize: 40, fontWeight: 600 }}
            >
              Wir konnten leider keine Fahrt finden...
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="mt-2 px-lg-5">
              <div className="px-lg-5">
                <img className="px-5" src={ListingImg} width={"100%"} />
              </div>
            </div>
            <div
              className="text-center my-4"
              style={{ fontSize: 22, color: "#717D96" }}
            >
              Keine Sorge! Du kannst entweder die Benachrichtigungen zu dieser
              Route aktivieren oder eine alternative Route suchen, um die besten
              Fahrtmöglichkeiten zu entdecken.
            </div>
            <div className="d-flex justify-content-center">
              <div className="text-center text-lg-start mb-4 pb-5">
                <button
                  type="button"
                  class="btn btn-primary px-3 py-2 my-auto me-md-4"
                  style={{
                    backgroundColor: "#25A9E0",
                    border: "none",
                    fontSize: 16
                  }}
                  onClick={handleGetNotification}
                >
                  {/* <Link to="/GetNotifications" style={{ textDecoration: 'none', color: '#fff' }}> */}
                    Benachrichtigung erhalten
                  {/* </Link> */}
                </button><br className='d-md-none'/>
                <button
                  type="button"
                  class="btn btn-outline-secondary px-3 py-2 my-md-auto mt-3"
                  style={{ color: "#25A9E0", fontSize: 16 }}
                >
                  <Link to={'/SearchingRoute'} style={{ textDecoration: 'none', color: 'rgb(37, 169, 224)' }}>
                    Neue Fahrt suchen
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </>
  );
}