import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { Urls } from "../Urls/apiUrl";
import toast from "react-hot-toast";
export const LOCATION_API = {
  saveLocationData,
};

function saveLocationData(requestOptions) {
  return axios    .post(Urls + "warumleer_trip_detail_registration", requestOptions,{ headers: { Authorization: getToken() }})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          // window.location.href = "/login";
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message);
        }
    });
}

function getToken() {
  // debugger
  // Retrieve the user data from localStorage
  const storedUserString = localStorage.getItem("user");
  // Check if the user data is stored in localStorage
  if (storedUserString) {
    const storedUserObject = JSON.parse(storedUserString);
    // Retrieve the token from the user data
    const token = storedUserObject.token;

    if (token) {
      const decoded = jwtDecode(token);

      // Perform additional validation if needed
      if (decoded.email === storedUserObject.userInfo.email) {
        // If the token is valid, return it in the "Bearer" format
        return `Bearer ${token}`;
      } else {
        // Token is invalid
        return null;
      }
    } else {
      // Token not found in user data
      return null;
    }
  } else {
    window.location.href = "/";
    // setTimeout(() => {

    // })
  }
}
