import React from 'react'
import Header from '../../../common/header'
import Footer from '../../../common/footer'
import './impressum.css'
export default function Impressum() {
  return (
    <>
    <Header/>
    <div style={{ backgroundImage: 'radial-gradient(#145d7b, #10475e)' }}>
        <div className='container'>
            <div className='row'>
                <div className='col-12'>
                    <div className='text-center text-white py-4' style={{ fontSize: 40, fontWeight: 'bold' }}>Impressum</div>
                </div>
            </div>
        </div>
    </div>

    <div className='container'>
        <div className='row mt-5 pb-5'>
            <div className='col-12 pb-5'>
                <div style={{fontSize:24,fontWeight:'bold'}}>Impressum</div>

                <div className='mt-3 impressum-text'>Tolga Bugdaci</div>

                <div className='mt-1 impressum-text'>Am Stadthafen 19</div>

                <div className='mt-1 impressum-text'>45356 Essen</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Kontakt</div>

                <div className='mt-3 impressum-text'>Telefon: 0201 325865</div>

                <div className='mt-1 impressum-text'>E-Mail: tolga@warumleer.de</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Umsatzsteuer-ID</div>

                <div className='mt-3 impressum-text'>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:</div>

                <div className='mt-1 impressum-text'>DE346453976</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>EU-Streitschlichtung</div>

                <div className='mt-3 impressum-text'>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:<a className='link-url' href="https://ec.europa.eu/consumers/odr/" target='_blank'>https://ec.europa.eu/consumers/odr/.</a> Unsere E-Mail-Adresse finden Sie oben im Impressum.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Verbraucherstreitbeilegung/<br className='d-md-none'/>Universalschlichtungsstelle</div>

                <div className='mt-3 impressum-text'>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Zentrale Kontaktstelle nach dem Digital Services Act - DSA (Verordnung (EU) 2022/265)</div>

                <div className='mt-3 impressum-text'>Unsere zentrale Kontaktstelle für Nutzer und Behörden nach Art. 11, 12 DSA erreichen Sie wie folgt:</div>

                <div className='mt-1 impressum-text'>E-Mail: kontakt@warumleer.de</div>
            </div>
        </div>
     
    </div>
    {/* <Footer/> */}
    </>
  )
}
