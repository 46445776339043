import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import locationReducer from "./reducers/locationReducer"; // Import your location reducer here

// Combine reducers if you have multiple reducers
const rootReducer = combineReducers({
  location: locationReducer, // Assuming you have a reducer named locationReducer for managing location state
  // Add more reducers if necessary
});

// Create Redux store
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
