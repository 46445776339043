import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { Urls } from '../Urls/apiUrl'
import toast from 'react-hot-toast'
import { ToastProvider } from 'react-hot-toast';
import GetNotifications from './../../views/pages/GetNotifications/GetNotifications';



export const EDITPROFILE_API = {
  getProfileInfo,
  verifyEmail,
  updateProfile,
  verifyPhone,
  verifyEmailOtp,
  verifyPhoneOtp,
  userForgetPassword,
  userChangePassword,
  addVehicle,
  getCarDetail,
  getSingleCarDetail,
  updateVehicle,
  deleteCarDetail,
  DocumentUpload,
  uploadProfileImg,
  getDocumentsByUserId,
  getPublishedTrip,
  deleteProfileTrip,
  getBokkedTripDetails,
  bookTrip,
  getAllPublishedTrip,
  getTripByUser,
  reportDriver,
  tripAccept,
  cancel_tripbysearcher,
  getUserDetailsOnTrip,
  getArchivedTrips,
  request_otp_email,
  verify_otp_registration,
  GetNotifications_by_id,
  notification_status_chnage,
  ratingList,
  ratingGivenList,
  trip_list_by_date,
  getTripByPdiddd,
  feedBackSubmit
}

function getTripByPdiddd(pd_id) {
  return axios
    .get(Urls + `get_trip_bypdId/${pd_id}`, {
      headers: { Authorization: getToken() },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response?.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function trip_list_by_date(payload) {
  return axios
    .post(Urls + `trip_list_by_date`, payload)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error.response
    })
}
function feedBackSubmit(payload) {
  return axios
    .post(Urls + `feedback_post`, payload, {
      headers: { Authorization: getToken() },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response?.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function ratingGivenList(user_id) {
  return axios
    .get(Urls + `get_rating_given_by_id/${user_id}`, {
      headers: { Authorization: getToken() },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response?.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function ratingList(user_id) {
  return axios
    .get(Urls + `get_rating_by_id/${user_id}`, {
      headers: { Authorization: getToken() },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response?.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function notification_status_chnage(payload) {
  return axios
    .patch(Urls + `notification_status_chnage`, payload, {
      headers: { Authorization: getToken() },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response?.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function GetNotifications_by_id(user_id) {
  return axios
    .get(Urls + `get_notification_data_list/${user_id}`, {
      headers: { Authorization: getToken() },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response?.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function verify_otp_registration(payload) {
  return axios
    .post(Urls + `verify_otp_registration`, payload, {
      headers: { Authorization: getToken() },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message, {
        autoClose: 1000
      })
      return error?.response;
    })
}
function request_otp_email(payload) {
  return axios
    .post(Urls + `request_otp_email`, payload, {
      headers: { Authorization: getToken() },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message, {
        autoClose: 1000
      })
      return error?.response;
    })
}
function ShowMessage(response) {
  if (response.status == 200 || response.status == 201) {
    toast.success(response?.data?.message, {
      autoClose: 1000,
    })
  }
  else {
    toast.error(response?.response?.data?.message, {
      autoClose: 1000,
    })
  }
}
function getProfileInfo(user_id) {
  return axios
    .get(Urls + `user_list_warum_leer/${user_id}`, {
      headers: { Authorization: getToken() },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response?.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function updateProfile(requestOptions) {

  return axios
    .patch(Urls + 'Update_user_profile', requestOptions, { headers: { Authorization: getToken() } })
    .then((response) => {

      return response.data
    })
    .catch((error) => {


      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function verifyEmail(requestOptions) {
  return axios
    .post(Urls + 'Get_otp', requestOptions, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Ungültiger Code', { autoClose: 1000, })
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 404) {
          toast.error("Bitte geben Sie eine registrierte E-Mail-Adresse ein", {
            autoClose: 1000,

          })
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function verifyEmailOtp(requestOptions) {
  return axios
    .post(Urls + 'OTPVerificationemail', requestOptions, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Ungültiger Code', { autoClose: 1000, })
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function verifyPhone(requestOptions) {
  return axios
    .post(Urls + 'get_otp_on_mobile', requestOptions, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Ungültiger Code', { autoClose: 1000, })
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function verifyPhoneOtp(requestOptions) {
  return axios
    .post(Urls + 'OTPVerificationmobile', requestOptions, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Ungültiger Code', { autoClose: 1000, })
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function userForgetPassword(requestOptions) {
  return axios
    .post(Urls + 'user_forgot_password', requestOptions, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Ungültiger Code', { autoClose: 1000, })
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function userChangePassword(requestOptions) {

  return axios
    .post(Urls + 'user_change_password', requestOptions, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 404) {
          toast.error('Ungültiges altes Passwort', { autoClose: 1000, })
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function addVehicle(requestOptions) {

  return axios
    .post(Urls + 'car_details', requestOptions, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response?.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function getCarDetail(user_id) {
  return axios
    .get(Urls + `get_car_detail/${user_id}`, {
      headers: { Authorization: getToken() },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response?.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function getSingleCarDetail(car_id) {
  return axios
    .get(Urls + `get_car_detail_BYId/${car_id}`, {
      headers: { Authorization: getToken() },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response?.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function updateVehicle(requestOptions) {
  return axios
    .patch(Urls + 'car_detail_update', requestOptions, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function deleteCarDetail(requestOptions) {
  const { car_id, ...otherOptions } = requestOptions

  return axios
    .delete(`${Urls}Delete_car_detail/${car_id}`, {
      ...otherOptions,
      headers: { Authorization: getToken() },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function DocumentUpload(requestOptions) {
  return axios
    .post(Urls + 'upload_documents', requestOptions, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function uploadProfileImg(requestOptions) {
  return axios
    .post(Urls + 'image_upload', requestOptions, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function getDocumentsByUserId(user_id) {
  return axios
    .get(Urls + `documents_by_id/${user_id}`, {
      headers: { Authorization: getToken() },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response?.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function getPublishedTrip(is_publishmonth, is_publishyear, user_id) {
  return axios
    .get(Urls + `trip_details/${is_publishmonth}/${is_publishyear}/${user_id}`, {
      headers: { Authorization: getToken() },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return [];
      }
      if (error.response != undefined) {
        if (error.response?.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, { autoClose: 1000 });
        } else if (error.response?.status === 400) {
          toast.error(error?.response?.data?.message, { autoClose: 1000 });
        } else if (error.response?.status === 409) {
          toast.error(error?.response?.data?.message, { autoClose: 1000 });
        } else if (error.response?.status === 404) {
          toast.error(error?.response?.data?.message, { autoClose: 1000 });
        }
      }
    });
}
function getAllPublishedTrip(user_id) {
  return axios
    .get(Urls + `user_trip_details/${user_id}`, {
      headers: { Authorization: getToken() },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return [];
      }
      if (error.response != undefined) {
        if (error.response?.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, { autoClose: 1000 });
        } else if (error.response?.status === 400) {
          toast.error(error?.response?.data?.message, { autoClose: 1000 });
        } else if (error.response?.status === 409) {
          toast.error(error?.response?.data?.message, { autoClose: 1000 });
        } else if (error.response?.status === 404) {
          toast.error(error?.response?.data?.message, { autoClose: 1000 });
        }
      }
    });
}
function deleteProfileTrip(requestOptions) {

  return axios
    .post(Urls + 'cancelTrip', requestOptions, { headers: { Authorization: getToken() } })

    .then((response) => {

      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function getBokkedTripDetails(pd_id) {
  return axios
    .get(Urls + `get_trip_detailsbyID/${pd_id}`, {
      headers: { Authorization: getToken() },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response?.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response?.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function bookTrip(requestOptions) {
  return axios
    .post(Urls + 'bookTrip', requestOptions, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function reportDriver(requestOptions) {
  return axios
    .post(Urls + 'report_ride', requestOptions, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function tripAccept(requestOptions) {
  return axios
    .post(Urls + 'bookingconfirmation', requestOptions, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data
    })
    .catch((error) => {

      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function cancel_tripbysearcher(user_id, pd_id, book_trip_id) {
  return axios
    .delete(Urls + `cancel_tripbysearcher/${user_id}/${pd_id}/${book_trip_id}`, {
      headers: { Authorization: getToken() },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return [];
      }
      if (error.response != undefined) {
        if (error.response?.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, { autoClose: 1000 });
        } else if (error.response?.status === 400) {
          toast.error(error?.response?.data?.message, { autoClose: 1000 });
        } else if (error.response?.status === 409) {
          toast.error(error?.response?.data?.message, { autoClose: 1000 });
        } else if (error.response?.status === 404) {
          toast.error(error?.response?.data?.message, { autoClose: 1000 });
        }
      }
    });
}
function getUserDetailsOnTrip(pd_id, user_id) {
  return axios
    .get(Urls + `getuserdetails_on_trip/${pd_id}/${user_id}`, {
      headers: { Authorization: getToken() },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return [];
      }
      if (error.response != undefined) {
        if (error.response?.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, { autoClose: 1000 });
        } else if (error.response?.status === 400) {
          toast.error(error?.response?.data?.message, { autoClose: 1000 });
        } else if (error.response?.status === 409) {
          toast.error(error?.response?.data?.message, { autoClose: 1000 });
        } else if (error.response?.status === 404) {
          toast.error(error?.response?.data?.message, { autoClose: 1000 });
        }
      }
    });
}
function getArchivedTrips(requestOptions) {
  return axios
    .post(Urls + 'archive_trip_list', requestOptions, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data
    })
    .catch((error) => {

      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function getTripByUser(requestOptions) {
  return axios
    .post(Urls + 'booked_trip', requestOptions, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data
    })
    .catch((error) => {

      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,

          })
        }
    })
}
function getToken() {
  // debugger
  // Retrieve the user data from localStorage
  const storedUserString = localStorage.getItem('user')
  // Check if the user data is stored in localStorage
  if (storedUserString) {
    const storedUserObject = JSON.parse(storedUserString)
    // Retrieve the token from the user data
    const token = storedUserObject.token

    if (token) {
      const decoded = jwtDecode(token)

      // Perform additional validation if needed
      if (decoded.email === storedUserObject.userInfo.email) {
        // If the token is valid, return it in the "Bearer" format
        return `Bearer ${token}`
      } else {
        // Token is invalid
        return null
      }
    } else {
      // Token not found in user data
      return null
    }
  }
}