import React, { useEffect, useState } from 'react'
import Visa from '../../../assets/Visa.png'
import PayPal from '../../../assets/PayPal.png'
import Applepay from '../../../assets/ApplePay.png'
import MasterCard from '../../../assets/Mastercard.png'
import Maestro from '../../../assets/Maestro.png'
import Stripe from '../../../assets/Stripe.png'
import Shield from '../../../assets/security-safe.png'
import Header from '../../../common/zuruckheader'
import Footer from '../../../common/footer'
import { loadStripe } from '@stripe/stripe-js';
import abc from "../../../assets/Logo-for-verification.png"
import StripeCheckout from 'react-stripe-checkout';
import { AUTENTICATE_API } from '../../../services/Api/authenticationApi'
import "./Purchase.css"
import toast from 'react-hot-toast'
import spinImg from "../../../assets/images/loading.gif"
import axios from 'axios'

export default function CompletePuchase() {
  const pKey = 'pk_test_51P7AgfRuEtDIemokoG7IJdkO2b6oPxuYX1z8pQdQU1YNdQfo8gcYiGddwGr8YWwOGIUDbKAvTxeATrwtVOS5c6AH00BnvOyzeP';
  const [address, setAddress] = useState("");
  const [city, setcity] = useState("");
  const [postalCode, setpostalCode] = useState("");
  const [phone, setphone] = useState("");
  const [company, setcompany] = useState("");
  const [vatId, setvatId] = useState("");
  const [amount, setAmount] = useState(0);
  const [Subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [tax, setTax] = useState(19);
  const [makePayments, setMakePayment] = useState(false);
  const [user, setUser] = useState("");
  const [spin, setSpin] = useState(false);
  const [paymentOption, setPaymentOption] = useState("stripe");

  useEffect(() => {
    let price = localStorage.getItem("price");
    let pc = JSON.parse(price);
    let user = localStorage.getItem("user");
    let usr = JSON.parse(user);
    setUser(usr?.userInfo);
    if (pc?.duration == "Jährlich") {
      let pr = 12 * parseInt(pc?.price);
      setTotal(pr);
      let per = (pr * 19) / 100;
      setTax(per?.toFixed(2))
      let sub = pr - parseFloat(per?.toFixed(2));
      setSubtotal(sub);
      setAmount(pr);
    } else {
      let pr = parseInt(pc?.price);
      setTotal(pr);
      let per = (pr * 19) / 100;
      setTax(per?.toFixed(2))
      let sub = pr - parseFloat(per?.toFixed(2));
      setSubtotal(sub);
      setAmount(pr);
    }
  }, [])

  const paypalpay = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('http://localhost:8080/create-payment', {});
      if (res && res.data) {
        window.location.href = res.data.links[1].href;
      }

    } catch (error) {
      console.error('Error:', error);
    }

  }

  const vakidate = () => {
    if (address == "" || city == "" || phone == "" || postalCode == "" || company == "" || vatId == "") {
      setMakePayment(true)
      return false
    } else {
      setMakePayment(false)
    }
  }

  const makePayment = (token) => {
    let user = localStorage.getItem("user");
    let usr = JSON.parse(user);
    let pr = localStorage.getItem("price");
    let prdata = JSON.parse(pr);
    //  token.billing
    let product = {
      amount,
      total,
      name: usr?.userInfo?.name,
      email: usr?.userInfo?.email,
      phone_no: usr?.userInfo?.phone_no,
      user_id: usr?.userInfo?.user_id,
      currency: "EUR",
      duration: prdata?.duration,
      paymentType: paymentOption,
      address: address,
      city: city,
      postalCode, postalCode,
      phone: phone,
      company: company,
      vatId: vatId
    }

    let data = {
      token,
      product,
    }
    AUTENTICATE_API.makePayment(data).then(res => {
      setSpin(true)
      if (res?.status === 200) {
        toast.success('Zahlung erfolgreich abgeschlossen');
        let storeData = localStorage.getItem('user');
        let parseData = JSON.parse(storeData);
        parseData['userInfo']["payment_status"] = 'succeeded'
        parseData['userInfo']["is_subscribe"] = 1
        localStorage.setItem("user", JSON.stringify(parseData))
        setTimeout(() => {
          setSpin(false)
          window.location.href = '/PaymentMadeSuccessful';
        }, 1000)
      }
    });
  }


  return (
    <>
      <Header />
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-8 col-12'>
            <div className='text-center mt-5'>
              <div style={{ fontSize: 40, fontWeight: 600 }}>Zahlung abschließen</div>
              <div className='mt-4 mb-5' style={{ color: '#4A5468' }}>Fast geschafft! Wählen Sie jetzt Ihre bevorzugte Zahlungsmethode, tragen Sie die erforderlichen Informationen ein und starten Sie in die Reise mit WarumLeer – einfach, sicher und unkompliziert.</div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6 col-12 offset-lg-1'>
            <div className='p-4' style={{ border: '2px solid #A0ABC0', borderRadius: 24 }}>
              <div className='d-flex justify-content-between'>
                <div style={{ fontSize: 20, fontWeight: 600 }}>Kreditkarte</div>
                <div className='d-flex'>
                  <img src={Visa} width={34} height={24} />
                  <img className='mx-2' src={MasterCard} width={34} height={24} />
                  <img src={Maestro} width={34} height={24} />
                </div>
              </div>
              <input className='w-100 px-3 py-2 mt-4' placeholder='Kartennummer' type="text" id="fname" name="fname" style={{ border: '2px solid #A0ABC0', borderRadius: 8 }} />
              <div className='d-flex'>
                <input className='w-100 px-3 py-2 mt-4' placeholder='Verfallsdatum' type="text" id="fname" name="fname" style={{ border: '2px solid #A0ABC0', borderRadius: 8, marginRight: 10 }} />
                <input className='w-100 px-3 py-2 mt-4' placeholder='CVV' type="text" id="fname" name="fname" style={{ border: '2px solid #A0ABC0', borderRadius: 8 }} />
              </div>
              <input className='w-100 px-3 py-2 mt-4' placeholder='Name auf der Karte' type="text" id="fname" name="fname" style={{ border: '2px solid #A0ABC0', borderRadius: 8 }} />
            </div>
            <div onClick={(e) => { setPaymentOption("paypal") }} id={`${paymentOption == "paypal" ? "ActivePay" : ''}`} className='d-flex justify-content-between px-4 py-3 mt-4' style={{ border: '2px solid #A0ABC0', borderRadius: 16, cursor: "pointer" }}>
              <div className='my-auto' style={{ fontSize: 20, fontWeight: 600 }}>PayPal</div>
              <div className='my-auto'>
                <img src={PayPal} width={34} height={24} />
              </div>
            </div>
            {/* <div onClick={(e)=>{setPaymentOption("applepay")}} className='d-flex justify-content-between px-4 py-3 mt-4' id={`${paymentOption=="applepay"?"ActivePay":''}`} style={{ border: '2px solid #A0ABC0', borderRadius: 16,cursor:"pointer" }}>
              <div className='my-auto' style={{ fontSize: 20, fontWeight: 600 }}>Apple Pay</div>
              <div className='my-auto'>
                <img src={Applepay} width={34} height={24} />
              </div>
            </div> */}
            <div onClick={(e) => { setPaymentOption("stripe") }} className='d-flex justify-content-between px-4 py-3 mt-4' id={`${paymentOption == "stripe" ? "ActivePay" : ''}`} style={{ border: '2px solid #A0ABC0', borderRadius: 16, cursor: "pointer" }}>
              <div className='my-auto' style={{ fontSize: 20, fontWeight: 600 }}>Stripe</div>
              <div className='my-auto'>
                <img src={Stripe} width={34} height={24} />
              </div>
            </div>
            <div className='p-4 mt-4 mb-5' style={{ border: '2px solid #A0ABC0', borderRadius: 24 }}>
              <div style={{ fontSize: 20, fontWeight: 600 }}>Rechnung</div>
              <input className='w-100 px-3 py-2 mt-4' onChange={(e) => { setAddress(e.target.value) }} placeholder='Adresse' type="text" id={`${(makePayments == true && address == "") ? 'nameErr1' : ""}`} name="fname" style={{ border: '2px solid #A0ABC0', borderRadius: 8 }} />
              <div className='d-flex'>
                <input className='w-100 px-3 py-2 mt-4' onChange={(e) => { setcity(e.target.value) }} placeholder='Stadt' type="text" id={`${(makePayments == true && city == "") ? 'nameErr1' : ""}`} name="fname" style={{ border: '2px solid #A0ABC0', borderRadius: 8, marginRight: 10 }} />
                <input className='w-100 px-3 py-2 mt-4' onChange={(e) => { setpostalCode(e.target.value) }} placeholder='Postleitzahl' type="text" id={`${(makePayments == true && postalCode == "") ? 'nameErr1' : ""}`} name="fname" style={{ border: '2px solid #A0ABC0', borderRadius: 8 }} />
              </div>
              <div className='d-flex'>
                <input className='w-100 px-3 py-2 mt-4' onChange={(e) => { setphone(e.target.value) }} placeholder='Telefonnummer' type="text" id={`${(makePayments == true && phone == "") ? 'nameErr1' : ""}`} name="fname" style={{ border: '2px solid #A0ABC0', borderRadius: 8, marginRight: 10 }} />
                <input className='w-100 px-3 py-2 mt-4' onChange={(e) => { setcompany(e.target.value) }} placeholder='Unternehmen' type="text" id={`${(makePayments == true && company == "") ? 'nameErr1' : ""}`} name="fname" style={{ border: '2px solid #A0ABC0', borderRadius: 8 }} />
              </div>
              <input className='w-100 px-3 py-2 mt-4' onChange={(e) => { setvatId(e.target.value) }} placeholder='USt-ID-Nummer' type="text" id={`${(makePayments == true && vatId == "") ? 'nameErr1' : ""}`} name="fname" style={{ border: '2px solid #A0ABC0', borderRadius: 8 }} />
            </div>
          </div>
          <div className='col-lg-4 col-12'>
            <div className='p-4' style={{ border: '2px solid #A0ABC0', borderRadius: 24 }}>
              <div className='mt-2 pb-4' style={{ fontSize: 20, fontWeight: 600, borderBottom: '1px solid #A0ABC0' }}>Zusammenfassung</div>
              <div className='d-flex justify-content-between mt-4'>
                <div style={{ fontSize: 20, fontWeight: 600 }}>Zwischensumme</div>
                <div style={{ fontSize: 20 }}>€{Subtotal}</div>
              </div>
              <div className='d-flex justify-content-between mt-4 pb-4' style={{ borderBottom: '1px solid #A0ABC0' }}>
                <div style={{ fontSize: 20, fontWeight: 600 }}>MwSt. (19%)</div>
                <div style={{ fontSize: 20 }}>€{tax}</div>
              </div>
              <div className='d-flex justify-content-between mt-4 pb-4' style={{ borderBottom: '1px solid #A0ABC0' }}>
                <div style={{ fontSize: 20, fontWeight: 600 }}>Gesamtsumme</div>
                <div style={{ fontSize: 20 }}>€{parseFloat(total)}</div>
              </div>
              <div className='mt-4'>
                {
                  spin === true ?
                    <button disabled={spin} className="btn btn-primary btn-block" style={{
                      background: '#25A9E0', color: "#fff", border: "1px solid #25A9E0",
                      paddingLeft: 25, paddingRight: 25,
                      borderRadius: 8, fontSize: 16, paddingTop: 8, paddingBottom: 8
                    }}>
                      <div className='d-flex'>
                        <img className='my-auto' src={spinImg} width={20} height={20} />&nbsp;&nbsp;Jetzt zahlen
                      </div>
                    </button>
                    : <>
                      {(address == "" || city == "" || phone == "" || postalCode == "" || company == "" || vatId == "") ? <button onClick={vakidate} className="btn btn-primary btn-block" style={{
                        background: '#25A9E0', color: "#fff", border: "1px solid #25A9E0",
                        paddingLeft: 25, paddingRight: 25,
                        borderRadius: 8, fontSize: 16, paddingTop: 8, paddingBottom: 8
                      }}>
                        Jetzt zahlen</button> : <StripeCheckout stripeKey={pKey}
                          token={makePayment}
                          name="Sichere Zahlung"
                          amount={total * 100}
                          currency="EUR"
                          email={user?.email}
                          price={total}
                          // image={abc}
                          panelLabel="Jetzt"
                        >
                        <button className="btn btn-primary btn-block" style={{
                          background: '#25A9E0', color: "#fff", border: "1px solid #25A9E0",
                          paddingLeft: 25, paddingRight: 25,
                          borderRadius: 8, fontSize: 16, paddingTop: 8, paddingBottom: 8
                        }}>
                          Jetzt zahlen</button>
                      </StripeCheckout>}
                    </>
                }
                {/* <button onClick={paypalpay}>Pay</button> */}
                {/* </button> */}
              </div>
              <div className='d-flex mt-3'>
                <img src={Shield} width={16} height={16} />
                <div className='mx-2' style={{ fontSize: 12, fontWeight: 600 }}>Sichere Zahlung</div>
              </div>
            </div>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-lg-10 col-12' style={{ borderTop: '1px solid #A0ABC0' }}>
            <div className='row mt-5'>
              <div className='col-lg-8 col-12'>
                <div style={{ fontSize: 20, fontWeight: 600 }}>Akzeptierte Zahlungsmethoden</div>
                <div className='d-flex mt-2'>
                  <img src={Visa} width={34} />
                  <img className='mx-2' src={MasterCard} width={34} />
                  <img src={Maestro} width={34} />
                  <img className='mx-2' src={Stripe} width={34} />
                  <img src={PayPal} width={34} />
                  <img className='mx-2' src={Applepay} width={34} />
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className='d-flex'>
                  <img src={Shield} width={24} height={24} />
                  <div className='mx-3' style={{ fontSize: 20, fontWeight: 600 }}>Sichere Zahlung</div>
                </div>
                <div className='mt-2' style={{ fontSize: 16, color: '#4A5468' }}>Dank SSL-Verschlüsselung bieten wir Ihnen höchste Sicherheit bei jeder Transaktion. Vertrauensvoll buchen auf WarumLeer.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className="col-lg-10 col-12 my-5 accordian-sec">
            <div
              className="text-center mb-4"
              style={{ fontSize: 40, fontWeight: 600 }}
            >
              Häufig gestellte Fragen
            </div>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Wie funktioniert WarumLeer genau?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    WarumLeer ermöglicht es Fahrern aller Art, seien es Spediteure oder
                    Abschleppfahrer, Leerfahrten zu minimieren, indem sie ihre Routen mit anderen
                    teilen. Suchende können passende Fahrten finden und Kontakt mit den Fahrern
                    aufnehmen. Eine genauere Erklärung finden Sie auch auf unserer <a href="/HowItWorks" style={{ color: "safed" == "safed" ? "white" : '' }}>Wie es
                      funktioniert</a> Seite.
                  </div>
                </div>
              </div>
              <br />
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed  shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Ist meine Mitgliedschaft verpflichtend, um Fahrten zu
                    suchen oder anzubieten?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Die Mitgliedschaft ist optional, aber sie bietet entscheidende Vorteile. Ohne
                    Mitgliedschaft können Sie Fahrten suchen, aber um die Kontaktdaten des Fahrers zu sehen und
                    eine Fahrt zu buchen, ist die Mitgliedschaft notwendig.Das Fahrten Anbieten ist auf unserer Website kostenlos.
                  </div>
                </div>
              </div>
              <br />
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Wie sicher sind die Zahlungen auf WarumLeer?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Wir verwenden ein SSL-Secure-Payment System, um sichere Zahlungen zu
                    gewährleisten. Ihre Daten sind verschlüsselt, und wir setzen alles daran, einen
                    sicheren Zahlungsvorgang zu ermöglichen.
                  </div>
                </div>
              </div>
              <br />
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Kann ich meine Fahrt stornieren oder ändern?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Ja, Sie können Ihre Fahrt stornieren oder ändern. Gehen Sie dazu einfach als
                    Suchender in Ihrem Profil auf "Gebuchte Fahrten" und als Fahrer auf "Veröffentlichte
                    Fahrten" und stornieren Sie Ihre gewünschte Fahrt. Der Buchende oder der Fahrer
                    wird daraufhin per E-Mail über die Stornierung benachrichtigt.
                  </div>
                </div>
              </div>
              <br />
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button collapsed shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Wie kann ich mein Profil bearbeiten?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Gehen Sie zu Ihrem Profil, dort finden Sie Optionen zum Bearbeiten von
                    Informationen wie Profilbild, Kontaktangaben und Präferenzen. Klicken Sie auf
                    "Speichern", um die Änderungen zu bestätigen.
                  </div>
                </div>
              </div>
              <br />
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingNine">
                  <button
                    class="accordion-button collapsed shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseNine"
                    aria-expanded="false"
                    aria-controls="collapseNine"
                  >
                    Welche Vorteile bietet mir WarumLeer?
                  </button>
                </h2>
                <div
                  id="collapseNine"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingNine"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    WarumLeer ermöglicht die effiziente Nutzung von Fahrzeugkapazitäten, trägt zur
                    Umweltentlastung bei, bietet Flexibilität bei Transportaufträgen und erleichtert die
                    Kommunikation. Durch dieses Win-Win-Prinzip profitieren sowohl Fahrer als auch
                    Suchende gleichermaßen von der Plattform.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  )
}