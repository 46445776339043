import React from 'react'
import Timeline from '../../../assets/1st color shape.png'
import TimelineMobile from '../../../assets/1st color mobile.png'
import { Link,useNavigate } from "react-router-dom";
import Header from '../../../common/zuruckheader';

export default function ProvideFrontPicture() {
  const Navigate = useNavigate();
  return (
    <>
    <Header/>
    <div className='container'>
        <div className='row justify-content-center mt-md-5 pt-5'>
            <div className='col-md-10 col-12'>
                <div className='d-none d-md-block'>
                    <img src={Timeline} width={'100%'}/>
                </div>
                <div className='d-md-none'>
                    <img src={TimelineMobile} width={'100%'}/>
                </div>
                <div className='mt-5 pt-5 text-center' style={{fontSize:32,fontWeight:600}}>Bitte laden Sie die Vorderseite Ihres Ausweises hoch</div>
                <div className='d-flex justify-content-center mt-5'>
                  {/* <button type="button" onClick={()=>{Navigate("/CaptureFrontId")}} class="btn btn-primary px-3 py-2 my-auto d-none d-lg-block" style={{backgroundColor:'#25A9E0',border:'none',fontSize:16}}><Link to={'/CaptureFrontId'} style={{textDecoration:'none',color:'#fff'}}>Foto machen</Link></button> */}
                  <button type="button" onClick={()=>{Navigate("/UploadPicture")}} class="btn btn-outline-secondary mx-3 px-3 py-2 my-auto" style={{color:'#25A9E0',fontSize:16}}>Foto hochladen</button>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
