import React, { useState } from 'react'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import SubmissionImg from '../../../assets/Submission sent notification.png'
import Header from '../../../common/header'
import Footer from '../../../common/footer'
import { Link } from 'react-router-dom'

export default function SuccesfullNotificationSubmission() {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")
    const [errr, setErrr] = useState("")
    const [showMenu, setShowMenu] = useState(true)
      const onOpenModal = () => {
          setOpen(true)
          // document.getElementById("navbarSupportedContent")?.classList.remove("show");
        };
        const onCloseModal = () => setOpen(false);
      
        const onSumbit = () => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!name || !email || !phone || !message) {
              setErrr("Bitte füllen Sie alle markierten Felder aus");
              setTimeout(() => {
                  setErrr("");
              }, 3000);
              return;
          }
  
          if (!emailRegex.test(email)) {
              setErrr("Bitte füllen Sie alle markierten Felder aus");
              setTimeout(() => {
                  setErrr("");
              }, 3000);
              return;
          }
  
          if (phone.length < 8 || phone.length > 14) {
              setErrr("Telefonnummer muss zwischen 8 und 14 Zeichen lang sein");
              setTimeout(() => {
                  setErrr("");
              }, 3000);
              return;
          }
          setOpen(false);
  
          axios
              .post("https://smtp.taction.in/api/api/v2/send-mail", {
                  name: name,
                  email: email,
                  message: message,
              })
              .then((res) => {
                  if (res && res?.status == 200) {
                      toast.success("Ihre Anfrage wurde übermittelt ", {
                          autoClose: 2000,
                      });
  
                  }
              })
              .catch((err) => {
              });
      };
      
        const closeT=()=>{
          document.getElementById("navbarSupportedContent")?.classList.remove("show");
        }
  return (
    <>
    <Header/>
    <div className='pt-3 pb-5'>
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-12 mt-lg-3'>
                    <div className='text-center' style={{fontSize:40,fontWeight:600}}>Anfrage erhalten!</div>
                </div>
                <div className='col-md-6 col-12 px-lg-5'>
                    <div className='mt-3 px-lg-5'>
                        <img className='px-5' src={SubmissionImg} width={"100%"}/>
                    </div>
                </div>
                <div className='col-lg-7 col-12 mb-5 pb-5 pb-lg-0 mb-lg-0'>
                  <div className='text-center my-4' style={{fontSize:22,color:'#717D96'}}>Wir haben Ihre gewünschte Route gespeichert. Sobald ein<br className='d-none d-md-block'/> Fahrer diese Route inseriert, werden Sie umgehend über Ihre<br className='d-none d-md-block'/> angegebene Kontaktmöglichkeit benachrichtigt.</div>
                    <div className='d-flex justify-content-center'>
                        <div className='text-center text-lg-start mb-4 pb-5'>
                            <button type="button" class="btn btn-outline-secondary px-3 py-2 my-md-auto mt-3" style={{color:'#25A9E0',fontSize:16}}><Link to="/SearchingRoute" style={{textDecoration:'none',color:'#25A9E0'}}>Neue Fahrt suchen</Link></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <Footer/> */}
    </>
  )
}
