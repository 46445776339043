import React, { useEffect, useState } from 'react'
import Successful from '../../../assets/Payment-Decline.png'
import { Link, useNavigate } from "react-router-dom";
import Header from '../../../common/zuruckheader';

export default function PaymentDecline() {
    const navigate=useNavigate();
    

  return (
    <>
    <Header/>
    <div className='container'>
        <div className='row justify-content-center pt-5'>
            <div className='col-xl-7 col-lg-8 col-md-10 col-12'>
                <div className='text-center' style={{fontSize:40,fontWeight:600}}>Ihre Zahlung wurde abgelehnt</div>
                <div className='d-flex justify-content-center mt-4 px-lg-5 mx-lg-5'>
                    <div className='px-lg-5'>
                        <img className='px-lg-5' src={Successful} width={'100%'}/>
                    </div>
                </div>
                <div className='text-center px-3 mt-4' style={{fontSize:22,color:'#717D96'}}>
                Leider konnte Ihre Gold-Mitgliedschaft nicht aktiviert werden.<br className='d-md-block'/> Bitte überprüfen Sie Ihre Zahlungsinformationen und versuchen<br className='d-md-block'/> Sie es erneut. <br className='d-md-block'/><br className='d-md-block'/>
                Bei Fragen stehen wir Ihnen gerne zur Verfügung.
                </div>
                <div className='d-flex justify-content-center mt-4'>
                    <div className='text-center text-lg-start mb-5'>
                        <button type="button" class="btn btn-primary px-3 py-2 my-auto" style={{backgroundColor:'#25A9E0',border:'none',fontSize:16,marginRight:20}}
                        onClick={()=>{
                            navigate('/')
                        }}
                        >Zurück zur Zahlungsübersicht</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
