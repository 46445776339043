import React, { useEffect, useState } from "react";
import eye from "../../../assets/eye.png";
import sms from "../../../assets/sms.png";
import call from "../../../assets/call.png";
import emailred from "../../../assets/email-red.png";
import key from "../../../assets/key.png";
import keyred from '../../../assets/key-red.png';
import Confirm from "../../../assets/confirm-password.png";
import "./NewPassword.css";
import { AUTENTICATE_API } from "../../../services/Api/authenticationApi";
import toast from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import { EDITPROFILE_API } from "../../../services/Api/editProfileApi";
import { useLocation } from "react-router-dom";
import Header from "../../../common/zuruckheader";

const NewPassword = (props) => {
    const [email1, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordErrFlag, setPasswordErrFlag] = useState(false);
    const [confirmPasswordErrFlag, setConfirmPasswordErrFlag] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
        const alphanumericRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,15}$/;
        // const alphanumericRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,15}$/;
        const isValidPassword = alphanumericRegex.test(value);
        if (!isValidPassword) {
            setErrorMessage("Das Passwort muss 8-15 Zeichen lang sein und Großbuchstaben, Kleinbuchstaben und Zahlen enthalten.");
            setPasswordErrFlag(true);
        } else {
            setErrorMessage("");
            setPasswordErrFlag(false);
        }
    };

    const handleConfirmPasswordChange = (e) => {
        const value = e.target.value;
        setConfirmPassword(value);
        if (password !== value) {
            setErrorMessage('Passwort muss übereinstimmen');
            setConfirmPasswordErrFlag(true);
        } else {
            setErrorMessage('');
            setConfirmPasswordErrFlag(false);
        }
    };

    const togglePasswordVisibility = () => {
        const passwordField = document.getElementById('password');
        passwordField.type = passwordField.type === 'password' ? 'text' : 'password';
    };

    const toggleConfirmPasswordVisibility = () => {
        const confirmPasswordField = document.getElementById('confirmPassword');
        confirmPasswordField.type = confirmPasswordField.type === 'password' ? 'text' : 'password';
    };

    const onLogin = async () => {
        const isEmailInteger = /^\d+$/.test(email1);
        if (password === "" && confirmPassword === "") {
            setPasswordErrFlag(true);
            setConfirmPasswordErrFlag(true);
            return false;
        }
        if (password === "") {
            setPasswordErrFlag(true);
            return false;
        }
        if (confirmPassword === "") {
            setConfirmPasswordErrFlag(true);
            return false;
        }
        if (password !== confirmPassword) {
            setErrorMessage('Bestätigen Passwort sollte dasselbe sein wie Neu Passwort');
            setConfirmPasswordErrFlag(true);
            return false;
        }

        let abc = {
            phone_no: isEmailInteger ? parseInt(email1) : '',
            email: isEmailInteger ? '' : email1,
            password: password
        };

        const response = await EDITPROFILE_API.userForgetPassword(abc);
        if (response && (response.status === 201 || response.status === 200)) {
            toast.success('Passwort erfolgreich aktualisiert', {
                autoClose: 1000,
            });
            navigate(isEmailInteger ? '/Login' : '/ForgotPasswordSuccessful');
        } else {
            
        }
        localStorage.removeItem('myKey');
    };

    useEffect(() => {
        let mail = localStorage.getItem('myKey');
        let usr_mail = JSON.parse(mail);
        setEmail(usr_mail?.UserEmail);
    }, []);

    return (
        <React.Fragment>
            <Header />
            <div className="container login-page">
                <div className="row mt-lg-5 pt-lg-5">
                    <div className="col-lg-5 col-12 order-2 order-lg-1 mt-5 mt-xl-4 mt-lg-0">
                        <div className="text-center text-lg-start title">
                            Passwort zurücksetzen
                        </div>
                        <div className="info">
                            Bitte legen Sie ein neues Passwort fest, das aus<br /> mindestens 8 Zeichen besteht und eine Kombination <br />aus Buchstaben und Zahlen enthält.
                        </div>
                        <div className="form-group mt-4">
                            <div className="form-wrap d-flex">
                                <img src={passwordErrFlag ? keyred : key} className="eye" alt="Password Icon" />
                                <input
                                    value={password}
                                    onChange={handlePasswordChange}
                                    onCopy={(e)=>{
                                        e.preventDefault()
                                        return false;
                                      }} 
                                    className="form-input form-control shadow-none con-passwords"
                                    id="password"
                                    type="password"
                                    name="password"
                                    placeholder="Neues Passwort"
                                />
                                <img
                                    onClick={togglePasswordVisibility}
                                    src={eye}
                                    className="eye1"
                                    alt="Show Password Icon"
                                />
                            </div>
                            {passwordErrFlag && <div className="error-message">{errorMessage}</div>}
                        </div>
                        <div className="form-group mt-4">
                            <div className="form-wrap d-flex">
                                <img src={confirmPasswordErrFlag ? keyred : key} className="eye" alt="Confirm Password Icon" />
                                <input
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    className={`form-input form-control shadow-none con-passwords ${confirmPasswordErrFlag && confirmPassword === '' ? 'error-border' : ''}`}
                                    id="confirmPassword"
                                    type="password"
                                    name="confirmPassword"
                                    placeholder="Neues Passwort bestätigen"
                                />
                                <img
                                    onClick={toggleConfirmPasswordVisibility}
                                    src={eye}
                                    className="eye1"
                                    alt="Show Confirm Password Icon"
                                />
                            </div>
                            {confirmPasswordErrFlag && <div className="error-message">{errorMessage}</div>}
                        </div>
                        <div className="col-12 mt-4">
                            <button onClick={onLogin} style={{
                                background: 'rgb(37, 169, 224)',
                                border: '1px solid rgb(37, 169, 224)'
                            }} className="btn btn-primary btn-block">Bestätigen</button>
                        </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2 my-auto offset-lg-1 px-5">
                        <img src={Confirm} className="w-100 px-5" alt="Confirm Password Image" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default NewPassword;