import React, { useEffect, useState } from 'react'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import axios from "axios";
import sms from "../../../assets/sms.png"
import emailred from "../../../assets/email-red.png"
import { ToastContainer } from 'react-toastify';
import toast from "react-hot-toast";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './GetNotifications.css'
import Email from '../../../assets/Email.png'
import SendImg from '../../../assets/send.png'
import Header from '../../../common/header';
import Footer from '../../../common/footer';
import Call from '../../../assets/call.png'
import CallRed from "../../../assets/call-red.png"
import { AUTENTICATE_API } from '../../../services/Api/authenticationApi';
import { useNavigate,useLocation } from 'react-router';

export default function GetNotifications() {
  const [open, setOpen] = useState(false);
const navi=useNavigate();

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [emailErrFlag, setemailErrFlag] = useState(false);
  const [phoneerrorFlag, setPhoneErrFlag] = useState(false);
  const [bothrrorEmailFlag, setBothEmailErrFlag] = useState(false);
  const [bothrrorPhoneFlag, setBothPhoneErrFlag] = useState(false);
  const [emailboth, setEmailBoth] = useState('');
  const [phoneboth, setPhoneBoth] = useState('');
  const [name, setName] = useState("")
  const [message, setMessage] = useState("")
  const [errr, setErrr] = useState("")
  const [showMenu, setShowMenu] = useState(true)
  const [tabss, setTabss] = useState("email")
  const [contactBack, setContactBack] = useState(0);
  const [phone1, setPhone1] = useState('');
  const [email1, setEmail1] = useState('');
  const [phoneErr, setPhoneErr] = useState(false);
  const location = useLocation();
  const { location: loc, arrivalLocation, date } = location.state || {};
  const [departureLocation, setDepartureLocation] = useState(loc || "");
  const [arrivalLocationValue, setArrivalLocationValue] = useState(arrivalLocation || "");
  const [dateValue, setDateValue] = useState(date || "");
  const onOpenModal = () => {
    setOpen(true)
    // document.getElementById("navbarSupportedContent")?.classList.remove("show");
  };
  const onCloseModal = () => setOpen(false);

  const onSumbit=()=>{
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!name || !email1 || !phone1 || !message) {
          setErrr("Bitte füllen Sie alle markierten Felder aus");
          setTimeout(() => {
            setErrr("");
          }, 3000);
          return;
        }
    
        if (!emailRegex.test(email1)) {
          setErrr("Bitte füllen Sie alle markierten Felder aus");
          setTimeout(() => {
            setErrr("");
          }, 3000);
          return;
        }
    
        if (phone1.length < 8 || phone1.length > 14) {
          setErrr("Telefonnummer muss zwischen 8 und 14 Zeichen lang sein");
          setTimeout(() => {
            setErrr("");
          }, 3000);
          return;
        }    setOpen(false);

     
    https: axios
    .post("https://api.warumleer.de/api/api/v1/contect_us", {
      Name: name,
      email_id: email1,
      is_checked: contactBack,
      mobile_no: phone1,
      description: message,
    })
    .then((res) => {
      if ( res?.status == 201) {
        toast.success("Ihre Anfrage wurde übermittelt", {
          autoClose: 2000,
        });

        setTimeout(() => {
          setEmail("");
          setName("");
          setMessage("");
          setPhone("");
          setContactBack("");
        }, 2500);
      }
    })
    .catch((err) => {
    
    });
};


  const closeT = () => {
    document.getElementById("navbarSupportedContent")?.classList.remove("show");
  }

  useEffect(()=>{
    //debugger
    let user=localStorage.getItem("user");
    let usr=JSON.parse(user);
    setEmail(usr?.userInfo?.email);
    setPhone(usr?.userInfo?.phone_no);
    setEmailBoth(usr?.userInfo?.email);
    setPhoneBoth(usr?.userInfo?.phone_no);
  },[tabss])
 

  const onSubmit = async () => {
    if (phone === "" && tabss=="sms") {
      setPhoneErrFlag(true)
      return false
    }
    if (email === "" && tabss=="email") {
      setemailErrFlag(true)
      return false
    }
    const re =/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if(tabss=="email" && !re.test(email)){
      setemailErrFlag(true)
      return false
    }
    if (emailboth === "" && phoneboth==="" && tabss=="both") {
      setBothEmailErrFlag(true);
      setBothPhoneErrFlag(true);
      return false
    }
    if (emailboth === "" && tabss=="both") {
      setBothEmailErrFlag(true);
      return false
    }

    if(tabss=="both" && !re.test(emailboth)){
      setBothEmailErrFlag(true)
      return false
    }

    if ( phoneboth==="" && tabss=="both") {
      setBothPhoneErrFlag(true);
      return false
    }

    let user=localStorage.getItem("user");
    let usr=JSON.parse(user);


    let obj={};
    if(tabss==="email"){
      obj={
        email:email,
        form:arrivalLocationValue,
        to: departureLocation,
        // form:localStorage.getItem("from"),
        // to:localStorage.getItem("to"),
        user_id:usr?.userInfo?.user_id,
        date:dateValue,
        name:usr?.userInfo?.name
        // date:localStorage.getItem("date")
      }
    }
    if(tabss=="sms"){
      obj={
        phone:phone,
        form:localStorage.getItem("from"),
        to:localStorage.getItem("to"),
        user_id:usr?.userInfo?.user_id,
        date:localStorage.getItem("date")
      }
    }
    if(tabss==="both"){
      obj={
        email:emailboth,
        phone:phoneboth,
        form:localStorage.getItem("from"),
        to:localStorage.getItem("to"),
        user_id:usr?.userInfo?.user_id,
        date:localStorage.getItem("date")
      }
    }
    AUTENTICATE_API?.notificationSave(obj).then(res=>{
      if(res && res?.status===200){
        toast.success("Informationen erfolgreich gespeichert",{autoClose:1500});
        setTimeout(() => {
          setEmail("");
          setPhone("");
          setEmailBoth("");
          setPhoneBoth("");
          navi('/SuccesfullNotificationSubmission');
        }, 2500);
      }
    });
    
  }
  return (
    <>
      <Header />
      <ToastContainer style={{zIndex:999}} />
      <div className=' back-images get-notifications'>
        <div className='container'>
          <div className='row justify-content-center pt-5'>
            <div className='col-lg-8 col-12 notification-sec' style={{ marginTop: 100 }}>
              <div className='text-center' style={{ fontSize: 32, fontWeight: 600 }}>Wie wollen Sie benachrichtigt werden?</div>
              <div>
                <Tabs className="text-center">
                  <TabList>
                    <Tab onClick={()=>{
                      setPhone("");
                      setEmailBoth("");
                      setTabss("email");
                      // setemailErrFlag(false);
                      // setBothEmailErrFlag(false);
                      // setBothPhoneErrFlag(false);
                      }}>Via Email</Tab>
                    {/* <Tab onClick={()=>{
                      setEmail("");
                      setPhoneBoth("");
                      setTabss("sms");
                      // setemailErrFlag(false);
                      // setBothEmailErrFlag(false);
                      // setBothPhoneErrFlag(false);
                      }}>Via SMS</Tab>
                    <Tab onClick={()=>{
                      setEmail("");
                      setPhone("");
                      setTabss("both");
                      // setemailErrFlag(false);
                      // setBothEmailErrFlag(false);
                      // setBothPhoneErrFlag(false);
                      }}>Beides</Tab> */}
                  </TabList>
                  <TabPanel>
                    <div className=''>
                      <div className='text-center' style={{ fontSize: 16, fontWeight: 600 }}>Email</div>
                      <div className="form-wrap d-flex">
                        <img src={(emailErrFlag === true && tabss=="email") ? emailred : sms} className="eye" style={{ marginTop: 17, marginLeft: 15 }} />
                        <input value={email} readOnly onChange={(e) => {
                          setemailErrFlag(false)
                          setEmail(e.target.value);
                        }} className="form-input form-control shadow-none con-passwords text-center" id={`${(emailErrFlag === true && tabss=="email") ? "nameErr" : "password"}`} type="text" name="E-Mail" placeholder='meine@mail.de' style={{ borderRadius: 10 }} />
                      </div>
                      {emailErrFlag === true && (
                        <div className="text-danger">Keine korrekte Angabe, bitte noch einmal prüfen</div>
                      )}
                      <div className='d-flex justify-content-center'>
                        <button type="button" class="btn btn-primary px-3 py-2 mt-4 mb-5 d-flex justify-content-center" style={{ backgroundColor: '#25A9E0', border: 'none', fontSize: 16 }} onClick={onSubmit}>
                          <img src={SendImg} width={"20px"} />
                          <span style={{ marginLeft: 5 }}>Bestätigen</span>
                        </button>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className=''>
                      <div className='text-center' style={{ fontSize: 16, fontWeight: 600 }}>Telefon</div>
                      <div className="form-wrap d-flex">
                        <img src={phoneerrorFlag === true ? CallRed : Call} className="eye" style={{ marginTop: 17, marginLeft: 15 }} />
                        <input value={phone} readOnly onChange={(e) => {
                          setPhoneErrFlag(false)
                          setPhone(e.target.value);
                        }} className="form-input form-control shadow-none con-passwords text-center" id={`${phoneerrorFlag === true ? "nameErr" : "password"}`} type="text" name="E-Mail" placeholder='+49 123 45678' style={{ borderRadius: 10 }} />
                      </div>
                      {phoneerrorFlag === true && (
                        <div className="text-danger">Keine korrekte Angabe, bitte noch einmal prüfen</div>
                      )}
                      <div className='d-flex justify-content-center mb-5 pb-5'>
                        <button type="button" class="btn btn-primary px-3 py-2 mt-4 mb-5 d-flex justify-content-center" style={{ backgroundColor: '#25A9E0', border: 'none', fontSize: 16 }} onClick={onSubmit}>
                          <img src={SendImg} width={"20px"} />
                          <span style={{ marginLeft: 5 }}>Bestätigen</span>
                        </button>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className='row'>
                      <div className='col-md-6 col-12'>
                        <div className=''>
                          <div className='text-center' style={{ fontSize: 16, fontWeight: 600 }}>Email</div>
                          <div className="form-wrap d-flex">
                            <img src={(bothrrorEmailFlag === true && tabss==="both") ? emailred : sms} className="eye" style={{ marginTop: 17, marginLeft: 15 }} />
                            <input value={emailboth} readOnly onChange={(e) => {
                              setBothEmailErrFlag(false)
                              setEmailBoth(e.target.value);
                            }} className="form-input form-control shadow-none con-passwords text-center" id={`${bothrrorEmailFlag === true ? "nameErr" : "password"}`} type="text" name="E-Mail" placeholder='meine@mail.de' style={{ borderRadius: 10 }} />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6 col-12' style={{ fontSize: 16, fontWeight: 600 }}>
                        <div className=''>
                          <div className='text-center'>Telefon</div>
                          <div className="form-wrap d-flex">
                            <img src={(bothrrorPhoneFlag === true && tabss=="both") ? CallRed : Call} className="eye" style={{ marginTop: 17, marginLeft: 15 }} />
                            <input value={phoneboth} readOnly onChange={(e) => {
                              setBothPhoneErrFlag(false)
                              setPhoneBoth(e.target.value);
                            }} className="form-input form-control shadow-none con-passwords text-center" id={`${bothrrorPhoneFlag === true ? "nameErr" : "password"}`} type="text" name="E-Mail" placeholder='+49 123 45678' style={{ borderRadius: 10 }} />
                          </div>
                        </div>
                      </div>
                      {((bothrrorEmailFlag === true && emailboth=="") && (bothrrorPhoneFlag===true && phoneboth=="")) && (
                        <div className="text-danger">Keine korrekte Angabe, bitte noch einmal prüfen</div>
                      )}
                      {((bothrrorEmailFlag === true && phoneboth!=="") ) && (
                        <div className="text-danger">Keine korrekte Angabe, bitte noch einmal prüfen</div>
                      )}
                      <div className='col-12'>
                        <div className='d-flex justify-content-center mb-5 pb-5'>
                          <button type="button" class="btn btn-primary px-3 mt-4 py-2 mb-5 d-flex justify-content-center" style={{ backgroundColor: '#25A9E0', border: 'none', fontSize: 16 }} onClick={onSubmit}>
                            <img src={SendImg} width={"20px"} />
                            <span style={{ marginLeft: 5 }}>Bestätigen</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </>
  )
}