import React, { useState, useEffect } from "react";
import './info.css'
import SideBar from "../../../common/sidebar/side";
import eye from "../../../assets/eye.png"
import openEyes from "../../../assets/open-eye.png"
import { EDITPROFILE_API } from "../../../services/Api/editProfileApi";
import toast from "react-hot-toast";
import Header from '../../../common/header'
import Footer from "../../../common/footer";

const Info = (props) => {
    const storedUserString = localStorage.getItem('user')
    const storedUserObject = JSON.parse(storedUserString)
    const email = storedUserObject.userInfo.email;
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordErr, setNewPasswordErr] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [confirmError, setConfirmError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setNewPassword(value);
        setErrorMessage("")
        const alphanumericRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,15}$/;
        // const alphanumericRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,15}$/;
        const isValidPassword = alphanumericRegex.test(value);
        if (!isValidPassword) {
            setErrorMessage("Das Passwort muss 8-15 Zeichen lang sein und Großbuchstaben,Kleinbuchstaben und Zahlen enthalten.");
            setNewPasswordErr(true);
        } else {
            setErrorMessage("");
            setNewPasswordErr(false);
        }
    };
    const togglePasswordVisibility = (field) => {
        if (field === 'oldPassword') setShowPassword(!showPassword);
        else if (field === 'newPassword') setShowPassword1(!showPassword1);
        else setShowPassword2(!showPassword2);
    };

    const getPasswordType = (field) => {
        if (field === 'oldPassword') return showPassword ? 'text' : 'password';
        else if (field === 'newPassword') return showPassword1 ? 'text' : 'password';
        else return showPassword2 ? 'text' : 'password';
    };

    const handleChangePassword = async () => {
        if (newPassword !== confirmPassword || newPasswordErr === true) {
            setConfirmError(true);
            return;
        }

        const obj = {
            email: email,
            oldpassword: oldPassword,
            newpassword: newPassword,
        }

        const response = await EDITPROFILE_API.userChangePassword(obj);
        if (response != undefined && response.status === 200) {
            toast.success('Passwort aktualisiert ', {
                autoClose: 1000,
            })
        }
        
        if (newPassword === confirmPassword) {
            setConfirmError(false);
        }
    }

    return (
        <React.Fragment>
            <Header />
            <div className="container pading-mobile">
                <div className="row mt-lg-5 pt-3">
                    <div className="col-lg-3 hamburger-sec">
                        {/* <SideBar title="profile" /> */}
                        <nav className="navbar navbar-expand-lg navbar-light bg-white">
                            <div className="container-fluid justify-content-end px-lg-0">
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse mt-3 mt-lg-0" id="navbarSupportedContent">
                                    <SideBar title="Kontoinformationen" />
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div className="col-lg-9">
                        <div className="container">
                            <div style={{ fontSize: 24, fontWeight: 600 }}>Konto E-Mail</div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 mt-3">
                                    <label className="pro-label">E-Mail</label>
                                    <div className="form-wrap d-flex">
                                        <input
                                            type="text" value={email}
                                            disabled
                                            className="form-control shadow-none profile-imput" placeholder="E-Mail" />
                                        <div style={{ height: '24px', width: '24px', position: 'relative', top: 7, left: '-30px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div style={{ fontSize: 24, fontWeight: 600, marginTop: 15 }}>Passwort</div>
                        </div>
                        <div className="container mb-lg-5 pb-lg-5">
                            <div className="row">
                                <div className="col-12 mt-3">
                                    <label className="pro-label">Aktuelles Passwort</label>
                                    <div className="form-wrap d-flex mt-1">
                                        <input
                                            type={getPasswordType('oldPassword')}
                                            onChange={(e) => setOldPassword(e.target.value)}
                                            className="form-control shadow-none profile-imput"
                                            placeholder="Aktuelles Passwort"
                                        />
                                        <img
                                            src={showPassword ? openEyes : eye}
                                            alt="show password"
                                            onClick={() => togglePasswordVisibility('oldPassword')}
                                            style={{ height: '24px', width: '24px', position: 'relative', top: 7, left: '-30px' }}
                                        />
                                        {/* <input type={showPassword ? 'text' : 'password'}
                                            onChange={(e) => setOldPassword(e.target.value)}
                                            className="form-control shadow-none profile-imput" placeholder="Aktuelles Passwort" />
                                        <img
                                            src={eye} alt="show password"
                                            onClick={() => togglePasswordVisibility('oldPassword')}
                                            style={{ height: '24px', width: '24px', position: 'relative', top: 7, left: '-30px' }} /> */}
                                    </div>
                                </div>
                                <div className="col-12 mt-4">
                                    <label className="pro-label">Neues Passwort</label>
                                    <div className="form-wrap d-flex mt-1">
                                        <input
                                            type={getPasswordType('newPassword')}
                                            onChange={handlePasswordChange}
                                            onCopy={(e)=>{
                                                e.preventDefault()
                                                return false;
                                              }} 
                                            className="form-control shadow-none profile-imput"
                                            placeholder="Neues Passwort"
                                            maxLength={15}
                                        />
                                        <img
                                            src={showPassword1 ? openEyes : eye}
                                            alt="show password"
                                            onClick={() => togglePasswordVisibility('newPassword')}
                                            style={{ height: '24px', width: '24px', position: 'relative', top: 7, left: '-30px' }}
                                        />

                                        {/* <input type={showPassword1 ? 'text' : 'password'}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            className="form-control shadow-none profile-imput" placeholder="Neues Passwort" />
                                        <img
                                            src={eye} alt="show password"
                                            onClick={() => togglePasswordVisibility('newPassword')}
                                            style={{ height: '24px', width: '24px', position: 'relative', top: 7, left: '-30px' }} /> */}
                                    </div>
                                    {newPassword && <div className="error-message">{errorMessage}</div>}
                                </div>
                                <div className="col-12 mt-4">
                                    <label className="pro-label">Neues Passwort bestätigen</label>
                                    <div className={`form-wrap d-flex mt-1 `}>
                                        <input
                                            type={getPasswordType('confirmPassword')}
                                            onChange={(e) => {
                                                setConfirmPassword(e.target.value)
                                                if (newPassword !== e.target.value) {
                                                    setConfirmError(true);
                                                } else {
                                                    setConfirmError(false);
                                                }
                                            }}
                                            className={`form-control shadow-none ${confirmError ? 'error' : 'profile-imput'}`}
                                            placeholder="Neues Passwort bestätigen"
                                            maxLength={15}
                                        />
                                        <img
                                            src={showPassword2 ? openEyes : eye}
                                            alt="show password"
                                            onClick={() => togglePasswordVisibility('confirmPassword')}
                                            style={{ height: '24px', width: '24px', position: 'relative', top: 7, left: '-30px' }}
                                        />

                                        {/* <input type={showPassword2 ? 'text' : 'password'}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            className={`form-control shadow-none ${confirmError ? 'error' : 'profile-imput'}` }placeholder="Neues Passwort bestätigen" />
                                        <img
                                            src={eye} alt="show password"
                                            onClick={() => togglePasswordVisibility('confirmPassword')}
                                            style={{ height: '24px', width: '24px', position: 'relative', top: 7, left: '-30px' }} /> */}
                                    </div>
                                    {confirmError && (
                                        <div className="error-msg">Passwort muss übereinstimmen</div>
                                    )}
                                </div>
                                <div className="col-12 mt-4 mb-5 pb-5">
                                    <button style={{
                                        background: '#25A9E0', color: "#fff", border: "1px solid #25A9E0",
                                        paddingLeft: 25, paddingRight: 25, borderRadius: 8, fontSize: 16, paddingTop: 8, paddingBottom: 8
                                    }}
                                        onClick={handleChangePassword}>Änderungen speichern</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </React.Fragment>
    );
}

export default Info;