import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import Visa from '../../../assets/Visa.png'
import PayPal from '../../../assets/PayPal.png'
import Applepay from '../../../assets/ApplePay.png'
import MasterCard from '../../../assets/Mastercard.png'
import Maestro from '../../../assets/Maestro.png'
import Stripe from '../../../assets/Stripe.png'
import Shield from '../../../assets/security-safe.png'
import Header from '../../../common/zuruckheader';
import Footer from '../../../common/footer';
import StripeCheckout from 'react-stripe-checkout';
import './billingCycle.css'
import axios from 'axios';
import { PAYMENT_API } from '../../../services/Api/payment';
import { loadStripe } from '@stripe/stripe-js';
import toast from "react-hot-toast";
const stripePromise = loadStripe('pk_test_51P7AgfRuEtDIemokoG7IJdkO2b6oPxuYX1z8pQdQU1YNdQfo8gcYiGddwGr8YWwOGIUDbKAvTxeATrwtVOS5c6AH00BnvOyzeP');


export default function BillingCycle() {
    const [checkboxChecked, setCheckboxChecked] = useState(0);
    const [phoneNumber, setPhoneNumber] = useState("")
    const [url, setUrl] = useState("")
    const [userid, setUserId] = useState("")
    const [emailll, setUserEmail] = useState("")
    const [price, setPrice] = useState()
    const [substype, setSubsType] = useState()
    const [err,setErrr] = useState(false)
    const[isSubscribed,setIsSubscribed]=useState("")
    useEffect(() => {
        let usr = localStorage.getItem('user')
        let user_info = JSON.parse(usr)
        const subscription_type = localStorage.getItem('checkoutUrl');
        if (subscription_type) {
            setType(subscription_type);
        }
       setUserId(user_info.userInfo.user_id);
       setIsSubscribed(user_info.userInfo.is_subscribe)
       setUserEmail(user_info.userInfo.email)
    }, [])
    const handleCheckOut = async () => {
        if(checkboxChecked===0){
           
            setErrr(true);
            toast.error("Bitte bestätigen Sie die Checkbox")
            return;
        }
        
        if(isSubscribed===1){
          toast.error("Gold-Mitgliedschaft bereits vorhanden")
        
    }
        else{
        let price1 =0;
        if(type==="Monatlich"){
            price1 = 36
        }
        else{
          price1 = 264
        }
        let obj={
                    user_id:userid,
                    plan:type,
            
                 }
      
        PAYMENT_API.getStripeCheckout(obj)
            .then((response) => {
              
                // setUrl(response.url)
                setUrl(response?.session?.url);
               
                localStorage.setItem('checkoutUrl', type);
                window.open(response?.session?.url, "_self")
                
                
            })
            .catch((error) => {
                console.error('Error fetching car data:', error);
            });
        }
    }
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    const [type, setType] = useState("");
    useEffect(() => {
        let url = window.location.href;
        if (url) {
            let types = url?.split("?")[1];
            if (types == "true") {
                setType("Jährlich");
            }
            if (types == "false") {
                setType("Monatlich");
            }
        }
    }, [])

    return (
        <>
            <Header />
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-lg-8 col-12'>
                        <div className='text-center mt-5'>
                            <div style={{ fontSize: 34, fontWeight: 600 }}>Abrechnungszeitraum wählen</div>
                            <div className='mt-4 mb-5' style={{ color: '#4A5468' }}>Entscheiden Sie sich für Ihren bevorzugten Rechnungszyklus. Wählen Sie zwischen monatlicher Flexibilität oder jährlicher Abrechnung für maximale Ersparnisse. Wählen Sie klug und optimieren Sie Ihre WarumLeer-Mitgliedschaft.</div>
                        </div>
                        <div className='px-md-5'>
            <div className='d-flex justify-content-between p-3 bg-white' style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 3.95px 3.95px 8.6px', borderRadius: 16 }}>
                <div className='my-auto d-flex'>
                    <input type="radio" id="Jährlich" onChange={() => { setType("Jährlich") }} name="fav_language" checked={type === "Jährlich"} />
                    <div className='my-auto'>
                        <label className='mx-2 Jährlich' htmlFor="Jährlich" style={{ fontWeight: 600, color: '#1A202C' }}>Jährlich</label>
                    </div>
                </div>
                <div>
                    <button className='Jährlich-btn' style={{ color: "#FFFFFF", background: '#25A9E0', borderRadius: 8, border: "1px solid #25A9E0"}}>192€ sparen</button>
                </div>
                <div className='my-auto Jährlich' style={{ fontWeight: 500 }}>22€ / pro Monat</div>
                
            </div>
        </div>


                        <div className='px-md-5 mt-3'>
                            <div className='d-flex justify-content-between p-3 bg-white' style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 3.95px 3.95px 8.6px', borderRadius: 16 }}>
                                <div className='my-auto d-flex'>
                                    <input type="radio" id="Jährlich" checked={type == "Monatlich"} onChange={() => { setType("Monatlich") }}  name="fav_language" />
                                    <div className='my-auto'>
                                        <label className='mx-2 Jährlich' for="Monatlich" style={{ fontWeight: 600, color: '#1A202C' }}>Monatlich</label>
                                    </div>
                                </div>
                                <div>
                                    <button className='Jährlich-btn' style={{ color: "#FFFFFF", background: '#fff', borderRadius: 8, border: "1px solid #ffffff" }}>192€ sparen</button>
                                </div>
                                <div className='my-auto Jährlich' style={{ fontWeight: 500 }}>36€ / pro Monat</div>
                                
                            </div>
                        </div>
               <div className='px-md-5 mt-4'>
               Es gelten unsere <a href='/AllgemeineGeschäftsbedingungen' target='_blank'>AGB</a> und unsere <a href='/Datenschutz' target='_blank'>Datenschutzerklärung</a>. Unsere Widerrufsbelehrung samt Widerrufsformular finden Sie <a href='/Widerrufsbelehrung' target='_blank'>hier</a>.
               </div>
       <div className='px-md-5 mt-3 d-flex'>
  <div className='my-0'>
    <input 
      type="checkbox"
      id="checkbox1"
      name="checkbox1"
      value="1"
      onChange={(e) => {
        const isChecked = e.target.checked ? 1 : 0; 
        setCheckboxChecked(isChecked);
        setErrr(false); // Reset error when checkbox state changes
      }}
      style={{ outline : err ? '2px solid red' : '',outlineStyle : err ? 'auto' : '' }}
    />
  </div>
  <div>
    <label
      className={`label-text ${err ? 'text-danger' : 'text-black'}`} // Change class based on err state
      htmlFor="checkbox1"
      style={{ marginLeft: "10px" }}
    >
      Ich stimme ausdrücklich zu, dass der Anbieter mit der Ausführung des Vertrags vor Ablauf der Widerrufsfrist beginnt. Ich habe zur Kenntnis genommen, dass ich durch meine Zustimmung mit Beginn der Vertragsausführung mein Widerrufsrecht verliere.
    </label>
  </div>
</div>







              
                        <div className='mx-md-5 d-flex justify-content-end mt-4 pb-5' style={{ borderBottom: '1px solid #A0ABC0' }}>
                     

                            <button
                                onClick={() => handleCheckOut()}
                                style={{
                                    fontSize: 18,
                                    color: "#FFFFFF",
                                    background: '#25A9E0',
                                    borderRadius: 8,
                                    border: "1px solid #25A9E0",
                                    paddingLeft: 25,
                                    paddingRight: 25,
                                    paddingTop: 8,
                                    paddingBottom: 8
                                }}
                            >
                                Weiter zur Kasse
                            </button>
                        </div>
                        <div className='row px-5 mt-5'>
                            <div className='col-lg-6 col-12'>
                                <div style={{ fontSize: 20, fontWeight: 600 }}>Akzeptierte Zahlungsmethoden</div>
                                <div className='d-flex mt-2'>
                                    <img src={Visa} width={34} />
                                    <img className='mx-2' src={MasterCard} width={34} />
                                    <img src={Maestro} width={34} />
                                    <img className='mx-2' src={Stripe} width={34} />
                                    <img src={PayPal} width={34} />
                                    {/* <img className='mx-2' src={Applepay} width={34} /> */}
                                </div>
                            </div>
                            <div className='col-lg-6 col-12 mt-3 mt-lg-0'>
                                <div className='d-flex'>
                                    <img src={Shield} width={24} height={24} />
                                    <div className='mx-3' style={{ fontSize: 20, fontWeight: 600 }}>Sichere Zahlung</div>
                                </div>
                                <div className='mt-2' style={{ fontSize: 16, color: '#4A5468' }}>Dank SSL-Verschlüsselung bieten wir Ihnen höchste Sicherheit bei jeder Transaktion. Vertrauensvoll buchen auf WarumLeer.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-lg-10 col-12 my-5 accordian-sec'>
                        <div className='text-center mb-4' style={{ fontSize: 40, fontWeight: 600 }}>Häufig gestellte Fragen</div>
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Wie funktioniert WarumLeer genau?
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        WarumLeer ermöglicht es Fahrern aller Art, seien es Spediteure oder
                                        Abschleppfahrer, Leerfahrten zu minimieren, indem sie ihre Routen mit anderen
                                        teilen. Suchende können passende Fahrten finden und Kontakt mit den Fahrern
                                        aufnehmen. Eine genauere Erklärung finden Sie auch auf unserer <a href="/HowItWorks" style={{ color: "safed" == "safed" ? "white" : '' }}>Wie es
                                            funktioniert</a> Seite.
                                    </div>
                                </div>
                            </div><br />
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed  shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Ist meine Mitgliedschaft verpflichtend, um Fahrten zu suchen oder anzubieten?
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Die Mitgliedschaft ist optional, aber sie bietet entscheidende Vorteile. Ohne
                                        Mitgliedschaft können Sie Fahrten suchen, aber um die Kontaktdaten des Fahrers zu sehen und
                                        eine Fahrt zu buchen, ist die Mitgliedschaft notwendig.Das Fahrten Anbieten ist auf unserer Website kostenlos.
                                    </div>
                                </div>
                            </div><br />
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Wie sicher sind die Zahlungen auf WarumLeer?
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Wir verwenden ein SSL-Secure-Payment System, um sichere Zahlungen zu
                                        gewährleisten. Ihre Daten sind verschlüsselt, und wir setzen alles daran, einen
                                        sicheren Zahlungsvorgang zu ermöglichen.
                                    </div>
                                </div>
                            </div><br />
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFour">
                                    <button class="accordion-button collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        Kann ich meine Fahrt stornieren oder ändern?
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Ja, Sie können Ihre Fahrt stornieren oder ändern. Gehen Sie dazu einfach als
                                        Suchender in Ihrem Profil auf "Gebuchte Fahrten" und als Fahrer auf "Veröffentlichte
                                        Fahrten" und stornieren Sie Ihre gewünschte Fahrt. Der Buchende oder der Fahrer
                                        wird daraufhin per E-Mail über die Stornierung benachrichtigt.
                                    </div>
                                </div>
                            </div><br />
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFive">
                                    <button class="accordion-button collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        Wie kann ich mein Profil bearbeiten?
                                    </button>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Gehen Sie zu Ihrem Profil, dort finden Sie Optionen zum Bearbeiten von
                                        Informationen wie Profilbild, Kontaktangaben und Präferenzen. Klicken Sie auf
                                        "Speichern", um die Änderungen zu bestätigen.
                                    </div>
                                </div>
                            </div><br />
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingSix">
                                    <button class="accordion-button collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                        Welche Vorteile bietet mir WarumLeer?
                                    </button>
                                </h2>
                                <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        WarumLeer ermöglicht die effiziente Nutzung von Fahrzeugkapazitäten, trägt zur
                                        Umweltentlastung bei, bietet Flexibilität bei Transportaufträgen und erleichtert die
                                        Kommunikation. Durch dieses Win-Win-Prinzip profitieren sowohl Fahrer als auch
                                        Suchende gleichermaßen von der Plattform.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </>
    )
}