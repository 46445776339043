import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDescription,addStop } from "../../redux/redux/actions/locationActions"; 
import { LOCATION_API } from "./../../../../src/services/Api/locationApi";
import './extrainformation.css'
import { Link, useNavigate } from "react-router-dom"; 
import Header from "../../../common/zuruckheader";
import { EDITPROFILE_API } from "../../../services/Api/editProfileApi";
export default function ExtraInformation() {
  const [info, setInfo] = useState("");
  const [userId, setUserId] = useState("");
  const [userType, setUserType] = useState("");
  let usr = localStorage.getItem("user");
  let user_info = JSON.parse(usr);
  const dispatch = useDispatch(); // Initializing dispatch
  const departToLocation = useSelector(
    (state) => state.location.departToLocation
  );
  const departFromLocation = useSelector(
    (state) => state.location.departFromLocation
  );
  const route = useSelector((state) => state.location.route);
  const stopLocation = useSelector((state) => state.location.stopLocation);
  const noOfFields = useSelector((state) => state.location.stops);
  const pickup_date = useSelector((state) => state.location.pickup_date);
  const pickup_time = useSelector((state) => state.location.pickup_time);
  const no_of_cars = useSelector((state) => state.location.no_of_cars);
  const weight = useSelector((state) => state.location.weight);
  const visibleInfo = useSelector((state) => state.location.information);
  const [documentDataExist, setDocumentDataExist] = useState("");
  const storedUserString = localStorage.getItem("user");
  const storedUserObject = JSON.parse(storedUserString);
  const userInfo = storedUserObject.userInfo;

  const id = userInfo.user_id;
  

  const navigate = useNavigate(); 

  const handleChange = (e) => {
 
    const info = e.target.value;
    setInfo(info);
   
   
  };

  const handleContinue = async () => {
    try {
      let descriptionText = info !== undefined && info.trim() !== '' ? info : "Der Fahrer hat keine Information zu seiner Fahrt angegeben.";
      await LOCATION_API.saveLocationData({
        depart_to: departToLocation.departTo,
        depart_from: departFromLocation.departFrom,
        stopLocation: stopLocation.location,
        route: route.route,
        user_id: id,
        no_of_stop: JSON.stringify(noOfFields),
        pickup_date: pickup_date,
        pickup_time: pickup_time,
        weight: weight,
        no_of_cars: no_of_cars,
        description: descriptionText,
        distance: route.distance,
        location_time: route.time,
        visible_email: visibleInfo.email,
        visible_phone_no: visibleInfo.phone_no,
      });
      dispatch(setDescription(info)); 
      return true;
    } catch (error) {
      console.error("Error saving location:", error);
    }
  };

  const handleButtonClick = async () => {
    if (user_info.userInfo.status == 0) {
      if(user_info.userInfo.document_status == 0)
        {
          
          const continueClicked = await handleContinue();
          // dispatch(addStop({}));
          if (continueClicked && user_info.userInfo.user_type==1) {
            navigate("/VerificationSuccessful");
            return; 
          }
          else if(continueClicked && user_info.userInfo.user_type==0){
            navigate("/CompanyVerificationSuccessful");
            return; 
          }
        }
      if( user_info.userInfo.user_type==1){
        navigate("/IdVerificationStart");
      }
      else{

        navigate('/IdVerificationCompany')
      }
    } else {

      const continueClicked = await handleContinue();
      if (continueClicked) {
        navigate("/TripSuccessfullyPublished"); 
      }
    }
  };
  const getDocumentsByUserId = (userId) => {
    EDITPROFILE_API.getDocumentsByUserId(userId)
      .then((response) => {
        if ( response?.status === 200 || response?.data===201) {
          const documentData = response.data[0] || [];
          setDocumentDataExist(documentData);
        } else {
          console.error("Error fetching branch data. Response:", response);
        }
      })
      .catch((error) => {
        console.error("Error fetching branch data:", error);
      });
  };
  useEffect(() => {
  
    let user_id = user_info.userInfo.user_id;
    let user_type =user_info.userInfo.user_type;
    setUserType(user_type)
    setUserId(user_id);
    getDocumentsByUserId(user_id);
  }, []);
  return (
    <>
      <Header />
      <div className="container">
        <div className="row justify-content-center mt-lg-5 pt-5">
          <div className="col-lg-8 col-12">
            <div
              className="text-center"
              style={{ fontSize: 40, fontWeight: 600 }}
            >
              Haben Sie noch weitere Infos zur Fahrt?
            </div>
            <div className="mt-5 px-4 ">
              <textarea
                className="form-control"
                placeholder="Hallo! Da eine Winde vorhanden ist, muss das Auto nur noch rollbereit sein!"
                id="exampleFormControlTextarea1"
                rows="4"
                style={{ border: "2px solid #A0ABC0", borderRadius: 16 ,fontSize:25}}
                value={info}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="d-flex justify-content-center mt-5">
              <button
                type="button"
                class="btn btn-primary px-3 py-2 my-auto"
                onClick={handleButtonClick}
                style={{
                  backgroundColor: "#25A9E0",
                  border: "none",
                  fontSize: 16,
                  marginLeft: 20,
                }}
              >
                Weiter
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}