import {
  SET_DEPART_TO_LOCATION,
  SET_DEPART_FROM_LOCATION,
  SET_STOP_LOCATION,
  SET_ROUTE,
  SET_NO_OF_STOP,
  SET_PICKUP_DATE,
  SET_PICKUP_TIME,
  SET_WEIGHT,
  SET_NO_OF_CARS,
  SET_DESCRIPTION,
  SET_FRONT_ID,
  SET_BACK_ID,
  ADD_STOP,
  REMOVE_STOP,
  REMOVE_ALL_STOP
} from "../actions/locationActions";
// import { ADD_STOP } from "../actions/locationActions";

// Initial state
const initialState = {
  departToLocation: {
    location: "",
    lat: null,
    lon: null,
  },
  departFromLocation: {
    location: "",
    lat: null,
    lon: null,
  },
  stopLocation: {
    location: "",
    lat: null,
    lon: null,
  },
  route: {
    distance: "",
    time: "",
    stops: "",
  },

  noOfFields: "",
  pickup_date: "",
  pickup_time: "",
  weight: "",
  no_of_cars: "",
  description: "",
  front_id: "",
  back_id: "",
  stops: [],
  stops: "",
};

// Reducer function
const locationReducer = (state = initialState, action) => {

  switch (action.type) {
    case "SET_DEPART_TO_LOCATION":
      return {
        ...state,
        departToLocation: {
          departTo: action.payload.location,
          lat: action.payload.lat,
          lon: action.payload.lon,
        },
      };
    case "SET_DEPART_FROM_LOCATION":
      return {
        ...state,
        departFromLocation: {
          departFrom: action.payload.location,
          lat: action.payload.lat,
          lon: action.payload.lon,
        },
      };

    case "SET_STOP_LOCATION":
      return {
        ...state,
        stopLocation: {
          stop: action.payload.location,
          lat: action.payload.lat,
          lon: action.payload.lon,
        },
      };
    case "SET_ROUTE":
      return {
        ...state,
        route: {
          time: action.payload.time,
          distance: action.payload.distance,
          stops: action.payload.stops,
        },
      };
    case "SET_INFORMATION":
      return {
        ...state,
        information: {
          email: action.payload.email,
          phone_no: action.payload.phone_no,
        },
      };

    case SET_NO_OF_STOP:
      return {
        ...state,
        noOfFields: action.payload,
      };
    case SET_PICKUP_DATE:
      return {
        ...state,
        pickup_date: action.payload,
      };
    case SET_PICKUP_TIME:
      return {
        ...state,
        pickup_time: action.payload,
      };

    case SET_WEIGHT:
      return {
        ...state,
        weight: action.payload,
      };
    case SET_NO_OF_CARS:
      return {
        ...state,
        no_of_cars: action.payload,
      };
    case SET_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };
    case SET_FRONT_ID:
      return {
        ...state,
        front_id: action.payload,
      };
    case SET_BACK_ID:
      return {
        ...state,
        back_id: action.payload,
      };

    case ADD_STOP:
      return {
        ...state,
        stops: [...state.stops, action.payload],
      };

      case REMOVE_ALL_STOP:
       

        return {
          ...state,
          stops: [],
        };  
    case "REMOVE_STOP":
      return {
        ...state,
        stops: state.stops.filter((_, index) => index !== action.payload),
      };

    default:
      return state;
  }
};

export default locationReducer;