import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setWeight } from "../../redux/redux/actions/locationActions";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../../common/zuruckheader";
import "./weight.css";
import toast from "react-hot-toast";

export default function WeightCarry() {
  const navigate = useNavigate();
  const [weightInteger, setWeightInteger] = useState("0");
  const [weightDecimal, setWeightDecimal] = useState("0");
  const dispatch = useDispatch();
  const [locationFlag, setLocationFlag] = useState(false);

  const [value, setValue] = useState("");
  const handleChange = (event) => {
    let inputValue = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2); 
    }

    // If the input is a two-digit number, convert it to decimal
    if (inputValue.length === 2) {
      const number = parseFloat(inputValue) / 10;
      inputValue = number.toFixed(1);
    }

    setValue(prevState => inputValue);
    
  };


  const handleContinue = () => {
    const weight = `${weightInteger}.${weightDecimal}`;
    
    if (value == "0.0" || value == "") {
      toast.error("Geben Sie ihr maximales Zuladungsgewicht ein")
      return false
    } else {
      dispatch(setWeight(`${value} t`));
      navigate("/TakeCars");
      return true;
    }

  };

  const handleButtonClick = () => {
    const continueClicked = handleContinue();
    if (continueClicked) {
      navigate("/TakeCars");
    }
  };

  return (
    <>
      <Header />
      <div className="container weight-carry">
        <div className="row justify-content-center mt-lg-5 pt-5">
          <div className="col-lg-8 col-12">
            <div
              className="text-center"
              style={{ fontSize: 40, fontWeight: 600 }}
            >
              Maximales Zuladungsgewicht wählen
            </div>
            {/* <div className="mt-4">
              <input
                className="text-center place"
                type="text"
                style={{
                  width: "100%",
                  height: 80,
                  border: "2px solid #a0abc0",
                  borderRadius: 16,
                  fontSize: 48,
                  color: "#229acc",
                  fontWeight: 600,
                }}
                id="twoDigitInput"
                value={value}
                onChange={handleChange}
                placeholder="0.0"
              />
              <div
                className="weight-t"
              >
                t
              </div>
            </div> */}
            <div
              className="inputWithIcon mt-4"
              style={{ width: "100%" }}
            >
              <input
                className="text-center place "
                type="text"
                style={{
                  width: "100%",
                  height: 70,
                  border: "2px solid #a0abc0 ",
                  borderRadius: 16,
                  fontSize: 48,
                  color: "rgb(37, 169, 224)",
                  fontWeight: 600,
                }}
                id="twoDigitInput"
                value={value}
                onChange={handleChange}
                placeholder="0.0"
                autoComplete="off"
              />



              <div
                className="text-center"
                style={{ marginLeft: 104, marginTop: '-70px', fontSize: 46, fontWeight: 600, color: 'rgb(37, 169, 224)' }}
              >
                t
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <button
                type="button"
                className="btn btn-primary px-3 py-2 my-auto"
                style={{
                  backgroundColor: "#25A9E0",
                  border: "none",
                  fontSize: 16,
                }}
                onClick={handleButtonClick}
              >
                Weiter
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}