import React, { useState, useEffect } from "react";
import Back from '../../../assets/back.png'
import { Link, useNavigate } from "react-router-dom";
import Header from '../../../common/header';
import './ArchiveTrips.css'
import { EDITPROFILE_API } from '../../../services/Api/editProfileApi';
import star from "../../../assets/images/star-blue.png"
import User from '../../../assets/Avatar2.png';

import Footer from "../../../common/footer";
export default function ArchiveTrips() {
    const navigate = useNavigate();
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(String(new Date().getMonth() + 1).padStart(2, '0'));
    const [userId, setUserId] = useState("");
    const [archivedList, setArchievedList] = useState([]);
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
      };
    const getArchivedTrips = (userId, selectedMonthhh, selectedYearrr) => {
        let obj_date = new Date();
        let obj={
            user_id:userId,
            is_publishmonth:selectedMonthhh,
            is_publishyear:selectedYearrr,
            currentTime: (obj_date.getHours() < 10 ? "0" + obj_date.getHours() : obj_date.getHours()) + ":" + (obj_date.getMinutes() < 10 ? "0" + obj_date.getMinutes() : obj_date.getMinutes()),
            pickup_date: formatDate(obj_date)
        }
        EDITPROFILE_API.getArchivedTrips(obj)
            .then((response) => {
                if (response.status === 200) {
                    setArchievedList(response.data);
                } else {
                    console.error("Error fetching trip data. Response:", response);
                }
            })
            .catch((error) => {
                console.error("Error fetching trip data:", error);
            });
    };
    useEffect(() => {
        let usr = localStorage.getItem("user");
        let user_info = JSON.parse(usr);
        let user_id = user_info.userInfo.user_id;
        setUserId(user_id);
        getArchivedTrips(user_id, selectedMonth, selectedYear);
    }, [userId, selectedMonth, selectedYear]);
    const updateMonthOptions = () => {
        let monthOptions = [];
        for (let month = 1; month <= 12; month++) {
            const formattedMonth = String(month).padStart(2, '0');
            monthOptions.push(
                <option key={month} value={formattedMonth}>{getMonthName(month)}</option>
            );
        }
        return monthOptions;
    };
    const getMonthName = (monthNumber) => {
        const months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
        return months[monthNumber - 1];
    };
    return (
        <>
            <Header />
            <div className="pading-mobile pb-lg-5">
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='d-flex my-auto' style={{ cursor: 'pointer' }}>
                                <div className='mt-1'>
                                    <img src={Back} width={'7px'} />
                                </div>
                                <div className='text-start mx-3 ' onClick={() => navigate(-1)}>Zurück</div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-6'>
                            <div style={{ fontSize: 24, fontWeight: 700 }}>Archiv</div>
                        </div>
                        <div className="col-lg-3 col-12  mt-2 mt-lg-0">
                            <div className="">
                                {/* Dropdown menu for years */}
                                <div className="dropdown">
                                    <button className="btn btn-secondary dropdown-toggle w-100 drp-bt" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                        {selectedYear} {/* Display selected year */}
                                    </button>
                                    <ul className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton2">
                                        {Array.from({ length: new Date().getFullYear() - 2023 }, (_, index) => (
                                            <li key={2024 + index}>
                                                <a className="dropdown-item" href="#" onClick={() => setSelectedYear(2024 + index)}>
                                                    {2024 + index}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-12 mt-3 mt-lg-0">
                            <div className="dropdown">
                                <button className="btn btn-secondary dropdown-toggle w-100 drp-bt" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    {getMonthName(selectedMonth)} {/* Display selected month */}
                                </button>
                                <ul className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                                    {updateMonthOptions().map(monthOption => (
                                        <li key={monthOption.props.value}>
                                            <a className="dropdown-item" href="#" onClick={() => setSelectedMonth(parseInt(monthOption.props.value))}>
                                                {monthOption.props.children}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* <select
                                        className="select-month"
                                        value={selectedMonth}
                                        onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                                    >
                                        {updateMonthOptions()}
                                    </select> */}
                        </div>

                    </div>
                    <div className="container mt-5 pb-5">
                        <div className="row">
                            {archivedList.length > 0 ? (
                                archivedList.map((item) => (
                                    <div className="col-lg-3 col-md-6 col-12 mt-3">
                                        <div
                                            key={item.pd_id}
                                            className=" p-3"
                                            style={{
                                                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                                borderRadius: 24,
                                                height: 245,
                                            }}
                                        >
                                            <div className="row">
                                                <div className="col-6">
                                                    <div
                                                        className="mt-1"
                                                        style={{
                                                            color: "#000",
                                                            fontSize: 16,
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        Abfahrt
                                                    </div>
                                                    <div
                                                        className="mt-1"
                                                        style={{
                                                            color: "#4A5468",
                                                            fontSize: 14,
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        {item.pickup_date}
                                                    </div>
                                                    <div
                                                        className="mt-1"
                                                        style={{
                                                            color: "#4A5468",
                                                            fontSize: 14,
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        {item.pickup_time}
                                                    </div>
                                                    <div
                                                        className="mt-1"
                                                        style={{ color: "#4A5468", fontSize: 14 }}
                                                    >
                                                        {item.depart_to}
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-6"
                                                    style={{ borderLeft: "0.1rem solid #A0ABC0" }}
                                                >
                                                    <div
                                                        className="mt-1"
                                                        style={{
                                                            color: "#000",
                                                            fontSize: 16,
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        Ankunft
                                                    </div>
                                                    <div
                                                        className="mt-1"
                                                        style={{
                                                            color: "#4A5468",
                                                            fontSize: 14,
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        {item.arrival_date}
                                                    </div>
                                                    <div
                                                        className="mt-1"
                                                        style={{
                                                            color: "#4A5468",
                                                            fontSize: 14,
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        {item.arrival_time}
                                                    </div>
                                                    <div
                                                        className="mt-1"
                                                        style={{ color: "#4A5468", fontSize: 14 }}
                                                    >
                                                        {item.depart_from}
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-lg-12">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="d-flex">
                                                                <div>
                                                                    <img
                                                                        src={item.image_url || User}
                                                                        style={{
                                                                            width: 48,
                                                                            height: 48,
                                                                            borderRadius: 50,
                                                                        }}
                                                                        alt="User Image"
                                                                    />
                                                                </div>
                                                                <div style={{ marginLeft: 15 }}>
                                                                    <div
                                                                        style={{
                                                                            color: "#000",
                                                                            fontSize: 16,
                                                                            fontWeight: 700,
                                                                        }}
                                                                    >
                                                                        {item.name}
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <img
                                                                            src={star}
                                                                            style={{ width: 24, height: 24 }}
                                                                        />
                                                                        <div
                                                                            className="my-1"
                                                                            style={{
                                                                                color: "#4A5468",
                                                                                fontSize: 16,
                                                                                marginLeft: 6,
                                                                            }}
                                                                        >
                                                                            {item.avarge_rating != null && !isNaN(item.avarge_rating) ? Number(item.avarge_rating).toFixed(2) : '5.0'}/5.0
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="d-flex justify-content-center">
                                                                    <div className="text-center" style={{ backgroundColor: '#25a9e0', color: '#fff', borderRadius: 50, width: 40, height: 40 }}><span style={{ position: 'relative', top: 9 }}>
                                                                        {JSON.parse(item?.no_of_stop).length}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="justify-content-center d-flex">
                                                                    {JSON.parse(item?.no_of_stop).length === 0 || JSON.parse(item?.no_of_stop).length > 1 ? " Stopps" : JSON.parse(item?.no_of_stop).length == 1 ? " Stopp" : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ))
                            ) : (
                                <div
                                    className="col-lg-12 text-center mt-3"
                                    style={{
                                        justifyContent: "center",
                                        fontSize: "22px",
                                        color: "grey",
                                        padding: "20px",
                                    }}
                                >
                                    <b> Keine Aufzeichnungen gefunden!</b>
                                </div>
                            )}
                        </div>



                    </div>

                    
                </div>
            </div>
            <Footer/>
        </>
    )
}