import React, { useEffect, useState } from "react";
import "./OTP.css";
import OTPIMG from "../../../../assets/otp.png";
import { useLocation, useNavigate } from "react-router-dom";
import { EDITPROFILE_API } from "../../../../services/Api/editProfileApi";
import toast from "react-hot-toast";
import OTPInput from "otp-input-react";
import Header from "../../../../common/zuruckheader";

const OTP = (props) => {
  
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [emptyFields, setEmptyFields] = useState(false);
const[email1,setEmail] = useState('');

  useEffect(() => {
    const otpInputs = document.querySelectorAll(".otp__digit");

    const handleNextInput = (event) => {
      const current = event.target;
      const index = parseInt(current.classList[1].split("__")[2], 10);

      if (event.keyCode === 8 && index > 1) {
        current.previousElementSibling.focus();
      }

      if (index < 6 && event.key >= "0" && event.key <= "9") {
        current.value = event.key;
        const next = current.nextElementSibling;
        next && next.focus();
      }

      const otpValues = Array.from(otpInputs).map((input) => input.value);
      const finalKey = otpValues.join("");

      setOtp(finalKey);

      if (finalKey.length === 6) {
        setEmptyFields(false);
        setInvalidOtp(false);
      } else {
        setEmptyFields(true);
        setInvalidOtp(false);
      }
    };
    let mail = localStorage.getItem('myKey')
    let usr_mail = JSON.parse(mail)
    
    setEmail(usr_mail.UserEmail)
    
    
    otpInputs.forEach((input) => {
      input.addEventListener("keyup", handleNextInput);
    });

    return () => {
      otpInputs.forEach((input) => {
        input.removeEventListener("keyup", handleNextInput);
      });
    };
   
  }, []);
  
  const onLogin = async () => {
    if (otp.length !== 6) {
      setEmptyFields(true);
      setInvalidOtp(false);
      setErrorMessage("Bitte geben Sie ein gültiges 6-stelliges OTP ein.");
      
      return;
     
    }

    let apiPayload;
    if (/^\d+$/.test(email1)) {
      apiPayload = {
        phone_no: email1,
        verfication_code: otp,
      };
    } else {
      apiPayload = {
        email: email1,
        verfication_code: otp,
      };
    }

    const response =
      /^\d+$/.test(email1)
        ? await EDITPROFILE_API.verifyPhoneOtp(apiPayload)
        : await EDITPROFILE_API.verifyEmailOtp(apiPayload);

    if (response && (response.status === 201 || response.status === 200)) {
      toast.success("Erfolgreich bestätigt", {
        autoClose: 1000,
      });
      navigate(`/NewPassword`);
    } else {
      setErrorMessage("Ungültiges OTP.");
      setInvalidOtp(true);
    }
    // localStorage.removeItem('myKey');
  };
  // useEffect(() => {
  //   let obj={
  //       UserEmail:email1
  //   }
  //   localStorage.setItem('myKey', JSON.stringify(obj));
  // }, [email1]);
  return (
    <React.Fragment>
      <Header />
      <div className="container login-page">
        <div className="row mt-lg-5 pt-lg-5">
          <div className="col-lg-5 col-12 my-auto order-2 order-lg-1 " style={{ background: "" }}>
            <div className="text-center text-lg-start" style={{ color: "#000000", fontSize: 45, fontWeight: 800 }}>
              Identität bestätigen
            </div>
            <div className="mt-3 mb-4" style={{ fontSize: 22, color: "#717D96" }}>
            Wir haben Ihnen einen Bestätigungscode per Mail schicken. Tragen Sie diesen bitte ein. <br/> 
              <span style={{ color: "#25A9E0" }}>Falsche E-Mail?</span>
            </div>
            <div className="col-12 mt-4">
              <OTPInput
                className={`input-classname ${emptyFields ? "input-error" : ""}`}
                value={otp}
                onChange={setOtp}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
              />
              {/* {emptyFields && <div className="error-message">Please enter OTP.</div>} */}
              {/* {invalidOtp && <div className="error-message">Invalid OTP.</div>} */}
            </div>
            <div className="col-12 mt-4">
              <button
                onClick={onLogin}
                style={{ color: "#FFFFFF", fontSize: 14, background: "#25A9E0", border: "1px solid #25A9E0", borderRadius: 8, width: "100%", padding: 8 }}
              >
                Weiter
              </button>
            </div>
            {/* <div className="col-12 mt-5">
              <div style={{ marginLeft: 5 }}>
                <span style={{ color: "#000000", fontSize: 14 }}>
                  <a href="" style={{ textDecoration: "none", color: "#25A9E0" }}>
                    Verwenden Sie einen anderen Weg
                  </a>
                </span>
              </div>
            </div> */}
          </div>
          <div className="col-lg-6 px-5 order-1 order-lg-2 my-auto offset-lg-1">
            <img className="px-4" src={OTPIMG} style={{ width: "100%" }} alt="OTP Image" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OTP;