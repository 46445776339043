import React from 'react'
import Header from '../../../common/header'
import Footer from '../../../common/footer'

export default function AGP() {
  return (
    <>
    <Header/>
    <div style={{ backgroundImage: 'radial-gradient(#145d7b, #10475e)' }}>
        <div className='container'>
            <div className='row'>
                <div className='col-12'>
                    <div className='text-center text-white py-4' style={{ fontSize: 33, fontWeight: 'bold' }}>Allgemeine Geschäftsbedingungen</div>
                </div>
            </div>
        </div>
    </div>

    <div className='container'>
        <div className='row mt-5 pb-5'>
            <div className='col-12'>
                <div style={{fontSize:22,fontWeight:'bold'}}>Allgemeine Nutzungsbedingungen von Tolga Bugdaci, Am Stadthafen 19, 45356 Essen (nachfolgend Plattformbetreiber) für die Plattform WarumLeer unter der URL warumleer.de</div>

                <div className='text-center mt-4' style={{fontSize:24,fontWeight:'bold'}}>Teil 1 - Allgemeine Bestimmungen</div>

                <div className='mt-4' style={{fontSize:24,fontWeight:'bold'}}>Geltungsbereich</div>

                <div className='mt-3 impressum-text'>Die vorliegenden Nutzungsbedingungen gelten für die Nutzung der Plattform unter der o. g. URL. Diese Nutzungsbedingungen gelten ausschließlich. Geschäftsbedingungen, die hiervon abweichen, erkennt der Plattformbetreiber vorbehaltlich einer ausdrücklichen Zustimmung nicht an.</div>

                <div className='mt-3 impressum-text'>Im Geltungsbereich dieser AGB gelten folgende Begriffsbestimmungen: </div>

                <div className='mt-2' style={{fontSize:24}}>
                    <ul>
                        <li className='impressum-text'><strong>„Plattformbetreiber“</strong> ist das o. g. Unternehmen.</li>
                        <li className='mt-2 impressum-text'><strong>„Plattform“</strong> ist die unter der in der Überschrift genannten URL abrufbare Plattform. </li>
                        <li className='mt-2 impressum-text'><strong>„Fahrer“</strong> sind alle natürlichen oder juristischen Personen, die Transportleistungen (Leerfahrten) auf der Plattform anbieten. Ein Fahrer kann gleichzeitig auch als Suchender nach Leerfahren suchen; in diesem Fall gelten für ihn die Regelungen für Suchende.</li>
                        <li className='mt-2 impressum-text'><strong>„Suchende“</strong> sind alle natürlichen oder juristischen Personen, die Transportleistungen (Leerfahrten) auf der Plattform suchen. Ein Suchender kann gleichzeitig auch als Fahrer Leerfahren anbieten; in diesem Fall gelten für ihn die Regelungen für Fahrer. </li>
                        <li className='mt-2 impressum-text'><strong>„Nutzer“</strong> meint alle Nutzer der Plattform unabhängig davon, ob sie als Suchende oder als Fahrer auftreten.  </li>
                    </ul>
                </div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Leistungsumfang</div>

                <div className='mt-3 impressum-text'>Die Plattform ermöglicht es, Leerfahrten von Kraftfahrzeugen zu minimieren, indem diese für Transporte genutzt werden können, die über die Plattform ausgewiesen werden. Die Plattform stellt hierbei den Kontakt zwischen dem Fahrer, der eine Leerfahrt anbietet und dem Suchenden her. Der Plattformbetreiber wird zu keinem Zeitpunkt Vertragspartner der zwischen Suchenden und Fahrern geschlossenen Verträge. Diese Verträge kommen ausschließlich zwischen dem Fahrer und dem Suchenden zustande. </div>

                <div className='mt-3 impressum-text'>Die Verfügbarkeit der Plattform liegt bei 96,5 % im Jahresmittel. Hiervon ausgenommen sind notwendige reguläre Wartungsarbeiten sowie diejenigen Zeiträume, in denen die Verfügbarkeit aufgrund von Ereignissen eingeschränkt wird, die der Plattformbetreiber nicht zu vertreten hat (z.B. höhere Gewalt, Handlungen Dritter, technische Probleme oder Änderungen der Rechtslage). Der Plattformbetreiber wird sich bemühen, sämtliche planbaren Wartungsarbeiten zu möglichst besuchsarmen Zeiten durchzuführen und diese möglichst kurz zu halten.  </div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Registrierung </div>

                <div className='mt-3 impressum-text'>Ein Nutzer kann zunächst einen kostenlosen Account auf der Plattform erstellen. Durch die anschließende Freischaltung des Accounts kommt ein Nutzungsverhältnis zwischen dem Nutzer und dem Plattformbetreiber zustande. Um Fahrer auf der Plattform suchen und kontaktieren zu können, müssen Nutzer eine kostenpflichtige Mitgliedschaft abschließen. </div>

                <div className='mt-3 impressum-text'>Leistungsgegenstand des Nutzungsverhältnisses ist ausschließlich die Zurverfügungstellung und die Pflege der Plattforminhalte und –funktionen. Verträge über die Durchführung einzelner entgeltlicher Vertragsleistungen kommen ausschließlich zwischen dem Suchenden und dem Fahrer zustande.</div>

                <div className='mt-3 impressum-text'>Vor Abgabe der Registrierung können die Suchende die Angaben mittels Maus- und Tastaturfunktion jederzeit korrigieren. Für den Vertragsschluss steht die deutsche Sprache zur Verfügung. Es gilt das Mängelgewährleistungsrecht des BGB. </div>

                <div className='text-center mt-5' style={{fontSize:24,fontWeight:'bold'}}>Teil 2 - Vertragsbeziehung zwischen Plattformbetreiber und den Fahrern</div>

                <div className='mt-4' style={{fontSize:24,fontWeight:'bold'}}>Leistungen ggü. Fahrern</div>

                <div className='mt-3 impressum-text'>Der Plattformbetreiber stellt auf seiner Plattform Fahrern die Möglichkeit zur Bewerbung und zum Verkauf von Leerfahrten zur Verfügung. Der Fahrer handelt selbstständig und unterliegt bei der Durchführung der von ihm angebotenen Leistungen – vorbehaltlich abweichender Vereinbarungen in diesen Nutzungsbedingungen – nicht den Weisungen des Plattformbetreibers. Vorbehaltlich abweichender Vereinbarungen oder zwingender gesetzlicher Regelungen, ist der Fahrer in der Ausgestaltung seiner Tätigkeit und der Vertragsinhalte zwischen ihm und dem Suchenden völlig frei; dies gilt insbesondere für Dauer, Zeitpunkt und Art der Leistungen, sowie die Einhaltung gesetzlicher Vorschriften. Der Fahrer hat innerhalb der Plattform nicht die Möglichkeit, seine Allgemeine Geschäftsbedingungen und sonstige ggf. notwendige Rechtstexte einzubinden, die im Verhältnis mit Suchenden gelten sollen; es obliegt damit dem Fahrer, ggf. erforderliche Rechtstexte seinen Kunden anderweitig in geeigneter Form zur Verfügung zu stellen. </div>

                <div className='mt-3 impressum-text'>Der Plattformbetreiber wird zu keinem Zeitpunkt Vertragspartner eines zwischen dem Suchenden und dem Fahrer geschlossenen Vertrags, weshalb ihn keinerlei Belehrungspflichten aus diesen Rechtsverhältnissen (z. B. im Hinblick auf Verbraucherrechte) treffen. Ein Vertrag kommt ausschließlich zwischen dem Fahrer und dem Suchenden zustande.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Registrierung auf der Plattform</div> 

                <div className='mt-3 impressum-text'>Fahrer können einen Account auf der Plattform anlegen. Durch die Registrierung kommt ein Nutzungsvertrag zwischen dem Fahrer und dem Plattformbetreiber zustande. Die Registrierung als Fahrer ist ausschließlich volljährigen natürlichen oder juristischen Personen vorbehalten. Im Rahmen der Registrierung kann der Plattformbetreiber bestimmte Pflichtinformationen abfragen, um den Fahrer zu verifizieren. Vorbehaltlich abweichender Vereinbarungen ist die Nutzung der Plattform für Fahrer kostenlos. Der Plattformbetreiber behält sich das Recht vor, die Angebote für Fahrer künftig kostenpflichtig anzubieten. In diesem Fall wird der Plattformbetreiber alle Fahrer spätestens einen Monat im Voraus informieren, damit diese widersprechen können. Sollten die Fahrer widersprechen, ist der Plattformbetreiber berechtigt, die entsprechenden Verträge außerordentlich zum Zeitpunkt des Inkrafttretens der Kostenpflicht zu kündigen.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Rechte und Pflichten des Fahrers</div>

                <div className='mt-3 impressum-text'>Der Fahrer hat dafür zu sorgen, dass alle von ihm eingestellten Inhalte und Dienstleistungen mit dem geltenden Recht (insb. Wettbewerbsrecht und Verbraucherschutz) vereinbar sind und keine entgegenstehenden Drittrechte bestehen. Der Fahrer muss insbesondere vollumfänglich zur Veröffentlichung der von ihm zur Verfügung gestellten Inhalte (z.B. Bilder und Texte) sowie sonstigen Daten und Unterlagen berechtigt sein. Darüber hinaus müssen auch die angebotenen Leistungen selbst mit dem geltenden Recht vereinbar sein. Insbesondere muss der Fahrer zur Erbringung der jeweiligen Leistung qualifiziert sein und die in seinem Land geltenden rechtlichen Bedingungen (v.a. behördliche Genehmigungen) zur Erbringung der jeweiligen Dienstleistung erfüllen. </div>

                <div className='mt-3 impressum-text'>Inhalte und Angebote, die gegen geltendes Recht verstoßen, sind unzulässig. Der Plattformbetreiber ist berechtigt, derartige Inhalte ohne Vorankündigung zu sperren oder löschen.</div>

                <div className='mt-3 impressum-text'>Im Falle eines Verstoßes gegen die Pflichten dieser Ziffer ist der Plattformbetreiber zur fristlosen Kündigung des Vertrags (außerordentliche Kündigung) oder zur Sperrung des Accounts oder zur Löschung einzelner Inhalte berechtigt. Die jeweilige Sanktionsmaßnahme steht im freien Ermessen des Plattformbetreibers, wobei dieser jedoch Art und Schwere des Verstoßes berücksichtigt.</div>

                <div className='text-center mt-5' style={{fontSize:24,fontWeight:'bold'}}>Teil 3 - Rechtsverhältnis zwischen Plattformbetreiber und den Suchenden</div>

                <div className='mt-4' style={{fontSize:24,fontWeight:'bold'}}>Pflichten des Suchenden </div>

                <div className='mt-3 impressum-text'>Der Suchende ist verpflichtet, die bei seiner Anmeldung angegebenen Daten stets aktuell zu halten und Verstöße gegen diese Nutzungsbedingungen und gegen geltendes Recht zu unterlassen. Der Suchende hat ferner dafür Sorge zu tragen, dass sein Account nur von ihm oder von ihm autorisierten Dritten aus dessen Unternehmen selbst benutzt wird. Er hat seine Zugangsdaten und die von ihm hinterlegten Daten vertraulich zu behandeln und sicherzustellen, dass unbefugte Dritte keinen Zugriff auf seine Daten haben. Verletzt der Suchende diese Pflicht schuldhaft, ist er selbst für hieraus entstehende Schäden verantwortlich. </div>

                <div className='mt-3 impressum-text'>Der Suchende ist ferner verpflichtet, die Plattform nur zu ihrem vorgesehenen Zweck zu verwenden und bei der Nutzung der Plattform sämtliche vertraglichen und gesetzlichen Vorschriften zu beachten. Jegliche über den Zweck des Nutzungsverhältnisses hinausgehende Nutzung ist untersagt. Insbesondere ist es dem Suchende untersagt: </div>

                <div className='mt-3 impressum-text'>-	sich mehrfach unter verschiedenen Identitäten auf der Plattform zu registrieren;</div>

                <div className='mt-3 impressum-text'>-	falsche oder irreführende Behauptungen innerhalb der Plattform zu verbreiten;</div>

                <div className='mt-3 impressum-text'>-	die Plattform zu Werbezwecken oder sonstigen kommerziellen Zwecken zu nutzen;</div>

                <div className='mt-3 impressum-text'>-	andere Suchende zu bedrohen, zu beleidigen, zu belästigen oder deren Rechte in sonstiger Weise zu verletzen; </div>

                <div className='mt-3 impressum-text'>-	bei der Nutzung der Plattform gegen diese AGB oder geltendes Recht (z.B. Urheber- und Markenrecht) zu verstoßen;</div>

                <div className='mt-3 impressum-text'>-	Kettenbriefe oder Spam-Nachrichten zu versenden; </div>

                <div className='mt-3 impressum-text'>Im Falle eines Verstoßes gegen die Bestimmungen dieser Ziffer ist der Plattformbetreiber zur vorübergehenden Sperrung des Suchende-Accounts bzw. des Versands elektronischer Nachrichten oder zur außerordentlichen Kündigung des Nutzungsverhältnisses berechtigt. Die Sanktionsmaßnahme richtet sich nach Art, Schwere und Dauer bzw. Anzahl des Verstoßes / der Verstöße und wird vom Plattformbetreiber nach eigenem Ermessen ausgewählt. Die Laufzeit des Nutzungsverhältnisses bleibt im Falle einer Sperrung des Suchende-Accounts oder des Versands elektronischer Nachrichten unberührt. </div>

                <div className='text-center mt-5' style={{fontSize:24,fontWeight:'bold'}}>Teil 4 - Haftung und Haftungsfreistellung</div>

                <div className='mt-4' style={{fontSize:24,fontWeight:'bold'}}>Haftung und Freistellung </div>

                <div className='mt-3 impressum-text'>Der Plattformbetreiber haftet aus jedem Rechtsgrund uneingeschränkt bei Vorsatz oder grober Fahrlässigkeit, bei vorsätzlicher oder fahrlässiger Verletzung des Lebens, des Körpers oder der Gesundheit, aufgrund eines Garantieversprechens, soweit diesbezüglich nichts Anderes geregelt ist oder aufgrund zwingender Haftung. </div>

                <div className='mt-3 impressum-text'>Verletzt der Plattformbetreiber fahrlässig eine wesentliche Vertragspflicht, ist die Haftung auf den vertragstypischen, vorhersehbaren Schaden begrenzt, sofern nicht gemäß vorstehender Ziffer unbeschränkt gehaftet wird. Wesentliche Vertragspflichten sind Pflichten, die der Vertrag dem Plattformbetreiber nach seinem Inhalt zur Erreichung des Vertragszwecks auferlegt, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf. </div>

                <div className='mt-3 impressum-text'>Im Übrigen ist eine Haftung des Plattformbetreiber ausgeschlossen. </div>

                <div className='mt-3 impressum-text'>Vorstehende Haftungsregelungen gelten auch im Hinblick auf die Haftung des Plattformbetreibers für Erfüllungsgehilfen und gesetzliche Vertreter. </div>

                <div className='mt-3 impressum-text'>Der Fahrer / der Suchende stellt Plattformbetreiber von jeglichen Ansprüchen Dritter – einschließlich der Kosten für die Rechtsverteidigung in ihrer gesetzlichen Höhe – frei, die gegen den Plattformbetreiber aufgrund von deren rechts- oder vertragswidrigen Handlungen geltend gemacht werden.</div>

                <div className='text-center mt-5' style={{fontSize:24,fontWeight:'bold'}}>Teil 5 - Datenschutz und Schlussbestimmungen</div>

                <div className='mt-4' style={{fontSize:24,fontWeight:'bold'}}>Vertragslaufzeit und Kündigung</div>

                <div className='mt-3 impressum-text'>Die Vertragslaufzeit richtet sich nach dem gebuchten Paket. Sofern eine Mindervertragslaufzeit von 12 Monaten vereinbart wurde, kann der Vertrag mit einer Frist von 1 Monat zum Ablauf der Mindestlaufzeit ordentlich gekündigt werden. Bei einer Vertragslaufzeit von einem Monat kann der Vertrag mit einer Frist von einem Tag zum Ablauf der Mindestlaufzeit gekündigt werden. Wird der Vertrag nicht fristgerecht gekündigt verlängert er sich auf unbestimmte Zeit und kann mit einer Frist von einem Monat ordentlich gekündigt werden. </div>

                <div className='mt-3 impressum-text'>Das Recht zur außerordentlichen fristlosen Kündigung aus wichtigem Grund bleibt unberührt. </div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Datenschutz</div>

                <div className='mt-3 impressum-text'>Der Plattformbetreiber behandelt die personenbezogenen Daten der Suchende und Fahrer vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften. Eine Weitergabe dieser Daten ohne die ausdrückliche Einwilligung des Betroffenen erfolgt nicht bzw. nur im Rahmen der notwendigen Abwicklung des Vertrages. Näheres ist der Datenschutzerklärung des Plattformbetreibers zu entnehmen.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Unzulässige Inhalte </div>

                <div className='mt-3 impressum-text'>Auf der Plattform dürfen keine Inhalte gespeichert werden, die beleidigend, extremistisch, gewaltverherrlichend oder -verharmlosend, volksverhetzend, rechtsextremistisch, diskriminierend, verfassungsfeindlich, jugendgefährdend oder pornografisch sind, die gegen die Rechte Dritter (z.B. Marken- und Urheberrecht) oder sonstiges geltendes Recht oder die guten Sitten (insbesondere Strafrecht und Ordnungswidrigkeitenrecht) verstoßen oder Schadcode bzw. Schadsoftware enthalten. Sofern der Plattformbetreiber Kenntnis darüber erlangt, dass im Rahmen der Plattform unzulässige Inhalte im Sinne dieses Absatzes auf dem bereitgestellten Speicherplatz hinterlegt sein könnten, wird er wie folgt vorgehen:</div>

                <div className='mt-3 impressum-text'>
                    <ul>
                        <li>Der Plattformbetreiber wird die betreffenden Inhalte unverzüglich kursorisch prüfen. Sollte die kursorische Prüfung ergeben, dass ein unzulässiger Inhalt nicht ausgeschlossen werden kann, kann der Plattformbetreiber diesen nach eigenem Ermessen vorläufig sperren oder andere, der Gefährdungslage angemessene Maßnahmen bis hin zur Löschung des Inhalts treffen. Der Fahrer wird den Urheber des Inhalts zur Stellungnahme auffordern und ihm hierfür eine angemessene Frist einräumen.</li>
                        <li className='mt-3'>Sobald die Stellungnahme des Urheber des Inhalts vorliegt oder wenn der Urheber des Inhalts innerhalb der eingeräumten Frist keine Stellungnahme abgegeben hat, wird der Plattformbetreiber eine endgültige Entscheidung darüber treffen, wie mit dem betroffenen Inhalt umzugehen ist. Hierbei kommen insbesondere folgende Maßnahmen in Betracht: Verwarnung; unbefristete Sperrung oder endgültige Löschung des Inhalts; vorübergehende Sperrung des Urheber des Inhalts (alternativ kann auch eine teilweise Sperrung erfolgen); ordentliche oder außerordentliche Kündigung des Vertrags; Strafanzeige oder Anzeige beim Ordnungsamt (sofern eine Straftat im Raum steht, die eine Gefahr für Leib, Leben oder Sicherheit einer Person darstellen kann, ist der Plattformbetreiber gesetzlich verpflichtet, diese zu melden).</li>
                        <li className='mt-3'>Der Plattformbetreiber wird die jeweilige Maßnahme erst nach einer gründlichen und objektiven Abwägung vornehmen und hierbei insbesondere die Schwere des Verstoßes, die Anzahl der Gesamtverstöße, potenzielle Auswirkungen auf die vom Plattformbetreiber bereitgestellten Dienste, dessen Kunden und sonstige Dritte, das Gesamtverhalten (z.B. Einsichtsfähigkeit hinsichtlich des Verstoßes), das Verschulden (Vorsatz, Fahrlässigkeit), die Motive des Verstoßes (soweit erkennbar) und die Einlassung des Urheber des Inhalts (sofern vorhanden) berücksichtigen.</li>
                        <li className='mt-3'>Der Plattformbetreiber wird den Urheber des Inhalts über die Bewertung, deren Ergebnis und die beschlossenen Maßnahmen informieren, soweit dem keine rechtlichen Gründe entgegenstehen.</li>
                        <li className='mt-3'>Der Plattformbetreiber wird die gespeicherten Inhalte nicht proaktiv prüfen und – vorbehaltlich abweichender Angaben – auch keine automatisierten Prüfungen der abgelegten Inhalte vornehmen. Er wird jedoch tätig, sobald er selbst derartige Inhalte erkennt oder von Dritten über solche Inhalte in Kenntnis gesetzt wird. Sofern ein Nutzer der Plattform Kenntnis von derartigen Inhalten erlangt, kann er sich selbstverständlich jederzeit an den Plattformbetreiber wenden; hierzu kann er die Kontaktdaten im Impressum verwenden.</li>
                    </ul>
                </div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Schlussbestimmungen </div>

                <div className='mt-3 impressum-text'>Auf die Verträge zwischen dem Plattformbetreiber und dem Fahrer / dem Suchenden findet das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts Anwendung, soweit diese Rechtswahl nicht dazu führt, dass ein Verbraucher hierdurch zwingenden verbraucherschützenden Normen entzogen wird. </div>

                <div className='mt-3 impressum-text'>Sofern es sich beim Vertragspartner um einen Kaufmann, eine juristische Person des öffentlichen Rechts oder um ein öffentlich-rechtliches Sondervermögen handelt, ist der Gerichtsstand für alle Streitigkeiten aus Vertragsverhältnissen zwischen dem Plattformbetreiber und dessen Vertragspartner am o. g. Sitz des Plattformbetreibers.</div>

                <div className='mt-3 impressum-text'>Der Plattformbetreiber ist berechtigt, diese Nutzungsbedingungen aus sachlich gerechtfertigten Gründen (z.B. Änderungen in der Rechtsprechung, Gesetzeslage, Marktgegebenheiten) und unter Einhaltung einer angemessenen Frist zu ändern. Bestandskunden werden hierüber spätestens zwei Wochen vor Inkrafttreten der Änderung per E-Mail benachrichtigt. Sofern der Bestandskunde nicht innerhalb der in der Änderungsmitteilung gesetzten Frist widerspricht, gilt seine Zustimmung zur Änderung als erteilt. Widerspricht er, treten die Änderungen nicht in Kraft; der Fahrer ist in diesem Fall berechtigt, den Vertrag zum Zeitpunkt des Inkrafttretens der Änderung außerordentlich zu kündigen. Die Benachrichtigung über die beabsichtigte Änderung dieser Nutzungsbedingungen wird auf die Frist und die Folgen des Widerspruchs oder seines Ausbleibens hinweisen.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Gesetzliche Pflichtinformationen zur Online-Streitbeilegung für Verbraucher</div>

                <div className='mt-3 impressum-text'>Die EU-Kommission stellt im Internet unter folgendem Link eine Plattform zur Online-Streitbeilegung bereit: <a href="https://ec.europa.eu/consumers/odr" target='_blank'>https://ec.europa.eu/consumers/odr</a> </div>

                <div className='mt-3 impressum-text'>Diese Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten aus Online-Kauf- oder Dienstleistungsverträgen, an denen ein Verbraucher beteiligt ist. Der Plattformbetreiber ist weder bereit noch verpflichtet, an einem Verbraucherstreitschlichtungsverfahren nach dem VSBG teilzunehmen.</div>

                <div className='mt-3 pb-5' style={{fontSize:24}}>Die E-Mail-Adresse des Plattformbetreibers ist der Überschrift dieser Nutzungsbedingungen zu entnehmen. </div>
            </div>
        </div>
    </div>
    {/* <Footer/> */}
    </>
  )
}
