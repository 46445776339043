import React, { useEffect, useState } from 'react'
import Successful from '../../../assets/payment-made.png'
import { Link, useNavigate } from "react-router-dom";
import Header from '../../../common/zuruckheader';
import { PAYMENT_API } from '../../../services/Api/payment';

export default function PaymentMadeSuccessful() {
    const [useridd,setUserId] = useState()
    const [sessionId,setSessionId] = useState()
    const [customerId,setCustomerId] = useState()
    const navigate=useNavigate();
    

    
    const updatePaymentStatus = async () => {
        let usr = localStorage.getItem('user')
        let url = localStorage.getItem('checkoutUrl')
        
        let user_info = JSON.parse(usr)
        // return;
        if(url){
        let obj={
            user_id:user_info.userInfo.user_id,
            paymentstatus:"success"
        }
        // debugger
        await PAYMENT_API.updatePaymentStatus(obj)
           .then((response) => {
             if (response?.status === 200) {
        
           localStorage.removeItem('checkoutUrl');
             }
           })
           .catch((error) => {
            
           })
        }
    }

       const CompletePayment = (sessionIdd,userIdd) => {
        
        if(sessionIdd != undefined && userIdd != undefined ){
        
        PAYMENT_API.CompletePayment(sessionIdd, userIdd)
            .then((response) => {
                if (response?.status === 201) {                
                    let localStorageData= localStorage.getItem('user');
                    let object = JSON.parse(localStorageData)
                    object.userInfo.customer_id=response.customer_id;
                    object.userInfo.is_subscribe=1;

                    localStorage.setItem('user',JSON.stringify(object))
                    updatePaymentStatus()

                    
                 
                } else {
                  
                    console.error('Error fetching trip data. Response:', response);
                }
            })
            .catch((error) => {
                console.error('Error fetching trip data:', error);
            });
        }
       };
    // const ChangePayment= async (e)=>{
    //     let info=localStorage.getItem("user");
    //     let userInfo=JSON.parse(info);
    //     e.preventDefault();
    //     PAYMENT_API.changePaymentMode({customerId: userInfo?.userInfo?.customer_id}).then(res=>{
    //         if(res?.session?.url){
    //             window.location.href =  res?.session?.url; 
    //         }
    //     });
    // }

    
       useEffect(() => {
        let url1 =document.URL;
        
        var url = new URL(url1);
        var subId = url.search;
        let abs=subId?.split("?sub_id=")[1];

        setSessionId(abs);
        let usr= localStorage.getItem("user");
        let user_info = JSON.parse(usr);
        setUserId(user_info.userInfo.user_id);
        CompletePayment(sessionId,useridd);
        
    // }, [])
        }, [sessionId])

  return (
    <>
    {/* <Header/> */}
    <div className='container'>
        <div className='row justify-content-center pt-5'>
            <div className='col-xl-7 col-lg-8 col-md-10 col-12'>
                <div className='text-center' style={{fontSize:40,fontWeight:600}}>Zahlung erfolgreich abgeschlossen</div>
                <div className='d-flex justify-content-center mt-4 px-5 mx-5'>
                    <div className='px-lg-5'>
                        <img className='px-lg-5' src={Successful} width={'100%'}/>
                    </div>
                </div>
                <div className='text-center px-3 mt-4' style={{fontSize:22,color:'#717D96'}}>
                Herzlichen Glückwunsch! <br/><br/> Ihre Gold-Mitgliedschaft ist jetzt aktiv. Ab sofort können Sie alle <br/>exklusiven Vorteile genießen. <br/> Wir wünschen Ihnen viel Erfolg und stehen bei Fragen immer <br/>zur Verfügung.
                </div>
                <div className='d-lg-flex justify-content-center mt-4'>
                    <div className='text-center text-lg-start mb-5'>
                        <button type="button" class="btn btn-primary px-3 py-2 my-auto" style={{backgroundColor:'#25A9E0',border:'none',fontSize:16,marginRight:20}}
                        onClick={()=>{
                            navigate('/')
                        }}
                        >Jetzt loslegen!</button>
                        <button type="button"
                        // onClick={ChangePayment}
                         onClick={()=>{
                            navigate('/Mitgliedschaft')
                        }}
                        class="btn btn-outline-secondary px-3 py-2 my-md-auto " style={{color:'#25A9E0',fontSize:16}}>Mitgliedschaftsdetails</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}