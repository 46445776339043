import React, { useState } from "react";
import Timeline from "../../../assets/3 color shape.png";
import TimelineMobile from "../../../assets/3 color mobile.png";
import "../CaptureFrontId/capture.css";
import Webcam from "react-webcam";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate hook
import { setSelfieId } from "../../redux/redux/actions/locationActions";
import { useDispatch, useSelector } from "react-redux"; // Import useSelector
import Header from "../../../common/zuruckheader";
import AWS from "aws-sdk";
import { EDITPROFILE_API } from "../../../services/Api/editProfileApi";

export default function CaptureSelfie() {
  const [selfieData, setSelfieData] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const front_id = useSelector((state) => state.location.front_id);
  const back_id = useSelector((state) => state.location.back_id);

  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);

  const storedUserString = localStorage.getItem("user");
  const storedUserObject = JSON.parse(storedUserString);
  const userInfo = storedUserObject.userInfo;
  const userId = userInfo.user_id;

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);

  const handleUpload = async (imageData) => {
    // Initialize AWS S3
    const s3 = new AWS.S3({
      region: "us-east-2",
      credentials: {
        accessKeyId: "AKIA6GBMFLZRFNFL64ZA",
        secretAccessKey: "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",
      },
    });
    // Convert base64 image to buffer
    const blob = await fetch(imgSrc).then((res) => res.blob());
    // Configure upload parameters
    const params = {
      Bucket: "warumleer-bucket-i-0e9a1494985796c7d",
      Key: `warumleer/images/${Date.now()}_image.jpg`,
      Body: blob,
      ACL: "public-read", // Set appropriate ACL as needed
      ContentType: "image/jpeg", // Set appropriate content type
    };
    try {
      // Upload image to S3
      const data = await s3.upload(params).promise();
      return data.Location;
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleButtonClick = async () => {
    try {
      const uploadedImageUrl = await handleUpload(imgSrc);
      dispatch(setSelfieId(uploadedImageUrl));

      let abc = {
        front_id_url: front_id,
        back_id_url: back_id,
        image_url: uploadedImageUrl,
        user_id: userId,
      };

      const response = await EDITPROFILE_API.DocumentUpload(abc);
      if (response && (response.status === 201 || response.status === 200)) {
      } 
      let localStorageData= localStorage.getItem('user');
      let object = JSON.parse(localStorageData)
      object.userInfo.document_status=0;

      localStorage.setItem('user',JSON.stringify(object))
      navigate("/VerificationSuccessful");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Header />
      <div className="container pb-5">
        <div className="row justify-content-center">
          <div className="col-md-10 col-12 mt-5">
            <div className="d-none d-md-block">
              <img src={Timeline} width={"100%"} />
            </div>
            <div className="d-md-none">
              <img src={TimelineMobile} width={"100%"} />
            </div>

            {imgSrc && (
              <div
                className="mt-4 pt-4 text-center"
                style={{ fontSize: 32, fontWeight: 600 }}
              >
                Foto bestätigen
              </div>
            )}

            {!imgSrc && (
              <div
                className="mt-4 pt-4 text-center"
                style={{ fontSize: 32, fontWeight: 600 }}
              >
                Machen Sie ein Selfie von sich im gekennzeichneten Bereich und
                klicken Sie auf ''Foto machen'
              </div>
            )}

            <>
              {!imgSrc ? (
                <div className="web-sec mt-4">
                  <div>
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                    />
                    <div
                      className="d-flex justify-content-center mt-3"
                      style={{ fontSize: 20, fontWeight: 600 }}
                    >
                      Hier platzieren
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <button
                        onClick={capture}
                        type="button"
                        class="btn btn-primary px-3 py-2 my-auto"
                        style={{
                          backgroundColor: "#25A9E0",
                          border: "none",
                          fontSize: 16,
                          borderRadius: 8,
                        }}
                      >
                        Foto machen
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {imgSrc && (
                <div className="web-img d-flex justify-content-center mt-4">
                  <img src={imgSrc} />
                </div>
              )}

              {imgSrc && (
                <div className="d-flex justify-content-center mt-5">
                  <button
                    type="button"
                    class="btn btn-outline-secondary px-3 py-2 my-auto"
                    style={{ color: "#25A9E0", fontSize: 16 }}
                  >
                    Neues Foto machen
                  </button>
                  <button
                    type="button"
                    // onClick={() => {
                    //   Navigate("/VerificationSuccessful");
                    // }}
                    onClick={handleButtonClick}
                    class="btn btn-primary px-3 py-2 my-auto"
                    style={{
                      backgroundColor: "#25A9E0",
                      border: "none",
                      fontSize: 16,
                      marginLeft: 20,
                    }}
                  >
                    Bestätigen und weiter
                  </button>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
}
