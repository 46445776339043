import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNoOfStop } from "../../redux/redux/actions/locationActions";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../../common/zuruckheader";

export default function DepartTo() {
  const navigate = useNavigate();
  const [location, setLocation] = useState("");
  const dispatch = useDispatch();
  const [checkedItems, setCheckedItems] = useState({});

  const stops = useSelector((state) => state.location.route.stops);
 

  const handleChange = (event) => {
    const id = event.currentTarget.id;
    const checked = event.currentTarget.checked;

    let updatedCheckedItems = { ...checkedItems };

    // If the checkbox is checked, add the item to checkedItems
    if (checked) {
      updatedCheckedItems = { ...updatedCheckedItems, [id]: id };
    } else {
      delete updatedCheckedItems[id];
    }
    setCheckedItems(updatedCheckedItems);

    
  };

  const checkedValuesArray = Object.values(checkedItems);

  const handleContinue = () => {
    
    dispatch(setNoOfStop(checkedValuesArray));

    
  };

  const handleAddCityClick = () => {
    navigate("/AddStop");
  };

  return (
    <>
      <Header />
      <div className="container mt-lg-5">
        <div className="row justify-content-center mt-lg-5 pt-5">
          <div className="col-lg-8 col-12">
            <div
              className="text-center"
              style={{ fontSize: 40, fontWeight: 600 }}
            >
              (Optional) Zwischenstopps hinzufügen
            </div>

            {stops &&
              stops.length > 0 &&
              stops.slice(0, 3).map((stop, index) => (
                <div
                  key={index}
                  className="d-flex justify-content-between p-3 mt-3"
                  style={{ backgroundColor: "#EDF0F7", borderRadius: 24 }}
                >
                  <div
                    className="my-auto"
                    style={{ fontSize: 18, fontWeight: 600 }}
                  >
                    {stop.name}
                  </div>
                  <div className="mt-2">
                    <input
                      type="checkbox"
                      id={stop.name} // Set id to stop name
                      value={stop.name} // Set value to stop name
                      onChange={handleChange}
                      style={{ height: 32, width: 32 }}
                    />
                  </div>
                </div>
              ))}

            <div
              className="mx-3 mt-4"
              style={{ fontSize: 16, color: "#25A9E0", cursor: "pointer" }}
              onClick={handleAddCityClick}
            >
              Stadt hinzufügen
            </div>
            <div className="d-flex justify-content-center mt-5">
              <button
                type="button"
                onClick={handleContinue}
                className="btn btn-primary px-3 py-2 my-auto"
                style={{
                  backgroundColor: "#25A9E0",
                  border: "none",
                  fontSize: 16,
                }}
              >
                <Link
                  to={"/SelectDate"}
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  Weiter
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
