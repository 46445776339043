import React, { useEffect, useState } from 'react'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import axios from "axios";
import toast from "react-hot-toast";
import 'react-tabs/style/react-tabs.css';
import { AUTENTICATE_API } from '../../../services/Api/authenticationApi';
import { useNavigate } from 'react-router';
import './reviews.css'
import SideBar from "../../../common/sidebar/side";
import star from "../../../assets/images/star-blue.png"
import avatar from "../../../assets/images/Avatar.png"
import right from "../../../assets/images/arrow-right.png"

import rat1 from "../../../assets/images/Rating1.png"
import rat2 from "../../../assets/images/Rating2.png"
import rat3 from "../../../assets/images/Rating3.png"
import rat4 from "../../../assets/images/Rating4.png"
import rat5 from "../../../assets/images/Rating5.png"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import Header from '../../../common/header'
import Footer from "../../../common/footer";
import { EDITPROFILE_API } from "../../../services/Api/editProfileApi";
const Reviews = (props) => {
  const [ratingList, setRatingList] = useState([]);
  const [ratCount, setRatCount] = useState({});
  const [ratingGivenList, setRatingGivenList] = useState([]);

  const [open, setOpen] = useState(false);
  const navi = useNavigate();

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [emailErrFlag, setemailErrFlag] = useState(false);
  const [phoneerrorFlag, setPhoneErrFlag] = useState(false);
  const [bothrrorEmailFlag, setBothEmailErrFlag] = useState(false);
  const [bothrrorPhoneFlag, setBothPhoneErrFlag] = useState(false);
  const [emailboth, setEmailBoth] = useState('');
  const [phoneboth, setPhoneBoth] = useState('');
  const [name, setName] = useState("")
  const [message, setMessage] = useState("")
  const [errr, setErrr] = useState("")
  const [showMenu, setShowMenu] = useState(true)
  const [tabss, setTabss] = useState("email")
  const [contactBack, setContactBack] = useState(0);
  const [phone1, setPhone1] = useState('');
  const [email1, setEmail1] = useState('');
  const [phoneErr, setPhoneErr] = useState(false)
  const onOpenModal = () => {
    setOpen(true)
    // document.getElementById("navbarSupportedContent")?.classList.remove("show");
  };
  const onCloseModal = () => setOpen(false);

  const onSumbit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!name || !email1 || !phone1 || !message) {
      setErrr("Bitte füllen Sie alle markierten Felder aus");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return;
    }

    if (!emailRegex.test(email1)) {
      setErrr("Bitte füllen Sie alle markierten Felder aus");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return;
    }

    if (phone1.length < 8 || phone1.length > 14) {
      setErrr("Telefonnummer muss zwischen 8 und 14 Zeichen lang sein");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return;
    }
    setOpen(false);


    https: axios
      .post("https://api.warumleer.de/api/api/v1/contect_us", {
        Name: name,
        email_id: email,
        is_checked: contactBack,
        mobile_no: phone,
        description: message,
      })
      .then((res) => {
        if (res?.status == 201) {
          toast.success("Ihre Anfrage wurde übermittelt", {
            autoClose: 2000,
          });

          setTimeout(() => {
            setEmail("");
            setName("");
            setMessage("");
            setPhone("");
            setContactBack("");
          }, 2500);
        }
      })
      .catch((err) => {
        
      });
  };



  useEffect(() => {
    let user = localStorage.getItem("user");
    let usr = JSON.parse(user);
    setEmail(usr?.userInfo?.email);
    setPhone(usr?.userInfo?.phone_no);
    setEmailBoth(usr?.userInfo?.email);
    setPhoneBoth(usr?.userInfo?.phone_no);
  }, [tabss])


  const onSubmit = async () => {
    if (phone === "" && tabss == "sms") {
      setPhoneErrFlag(true)
      return false
    }
    if (email === "" && tabss == "email") {
      setemailErrFlag(true)
      return false
    }
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (tabss == "email" && !re.test(email)) {
      setemailErrFlag(true)
      return false
    }
    if (emailboth === "" && phoneboth === "" && tabss == "both") {
      setBothEmailErrFlag(true);
      setBothPhoneErrFlag(true);
      return false
    }
    if (emailboth === "" && tabss == "both") {
      setBothEmailErrFlag(true);
      return false
    }

    if (tabss == "both" && !re.test(emailboth)) {
      setBothEmailErrFlag(true)
      return false
    }

    if (phoneboth === "" && tabss == "both") {
      setBothPhoneErrFlag(true);
      return false
    }

    let user = localStorage.getItem("user");
    let usr = JSON.parse(user);


    let obj = {};
    if (tabss === "email") {
      obj = {
        email: email,
        form: localStorage.getItem("from"),
        to: localStorage.getItem("to"),
        user_id: usr?.userInfo?.user_id,
        date: localStorage.getItem("date")
      }
    }
    if (tabss == "sms") {
      obj = {
        phone: phone,
        form: localStorage.getItem("from"),
        to: localStorage.getItem("to"),
        user_id: usr?.userInfo?.user_id,
        date: localStorage.getItem("date")
      }
    }
    if (tabss === "both") {
      obj = {
        email: emailboth,
        phone: phoneboth,
        form: localStorage.getItem("from"),
        to: localStorage.getItem("to"),
        user_id: usr?.userInfo?.user_id,
        date: localStorage.getItem("date")
      }
    }
    AUTENTICATE_API?.notificationSave(obj).then(res => {
      if (res && res?.status === 200) {
        toast.success("Informationen erfolgreich gespeichert", { autoClose: 1500 });
        setTimeout(() => {
          setEmail("");
          setPhone("");
          setEmailBoth("");
          setPhoneBoth("");
          navi('/SuccesfullNotificationSubmission');
        }, 2500);
      }
    });

  }

  useEffect(() => {
    let usr = localStorage.getItem("user");
    let user = JSON.parse(usr)
    EDITPROFILE_API?.ratingList(user?.userInfo?.user_id).then(res => {
      if (res && res?.data?.length > 0) {
        setRatingList(res);
        let rat = res?.data?.map(va => va?.rating);
        let ratsort = rat.sort();
        let obj = {}
        for (var ar of ratsort) {
          obj[ar] = obj[ar] ? obj[ar] + 1 : 1;
        }
        setRatCount(obj);
      }
    });

    EDITPROFILE_API?.ratingGivenList(user?.userInfo?.user_id).then(res => {
      if (res && res?.data?.length > 0) {
        setRatingGivenList(res);
      }
    });
  }, [])

  return <React.Fragment>
    <Header />
    <div className="container pading-mobile">
      <div className="row mt-lg-5 pt-3">
        <div className="col-lg-3 hamburger-sec">
          {/* <SideBar title="profile" /> */}
          <nav class="navbar navbar-expand-lg navbar-light bg-white">
            <div class="container-fluid justify-content-end px-lg-0">
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse mt-3 mt-lg-0" id="navbarSupportedContent">
                <SideBar title="Reviews" />
              </div>
            </div>
          </nav>
        </div>
        <div className="col-lg-9">
          <div className="container mb-5 pb-5">
            <Tabs>
              <div className="row" >
                <div className="col-lg-6">
                  <div style={{ fontSize: 24, fontWeight: 600 }}>Bewertungen</div>
                </div>
                <div className="col-lg-6 mt-4 mt-lg-0">
                  <TabList className='d-lg-flex px-0'>
                    <Tab className='m-0 px-3 py-2 tab-sec' style={{ textDecoration: 'none', listStyle: 'none', cursor: 'pointer', outline: 'none' }}>Erhaltene Bewertungen</Tab>
                    <Tab className='m-0 px-3 py-2 tab-sec' style={{ textDecoration: 'none', listStyle: 'none', cursor: 'pointer', outline: 'none' }}>Gegebene Bewertungen</Tab>
                  </TabList>
                </div>
              </div>
              <TabPanel>
                {/* <div className="mt-4" style={{ fontSize: 24, fontWeight: 600 }}>{ratingList?.av ? ratingList?.av : "5.0"}/5.0</div> */}
                {ratingList?.count ? <div className="mt-4" style={{ fontSize: 22, color: "#717D96" }}>{ratingList?.av} aus {ratingList?.count} Bewertungen</div> : <div className="mt-4" style={{ fontSize: 22,padding: 22, color: "grey",textAlign:"center" }}> <b>Keine Bewertungen erhalten</b></div>}

                {/* <div className="mt-4" style={{ fontSize: 22, color: "#717D96" }}>aus {ratingList?.count?ratingList?.count:0} Bewertungen</div> */}
                {ratingList?.av ==="NaN" &&
                <div className="row mt-3">
                  <div style={{
                    backgroundColor: "#eee",
                    border: '0 none',
                    color: '#717D96',
                    height: '0.1rem'
                  }}></div>
                  <div className="col-lg-10 mt-3">
                    <div className="d-flex justify-content-between mt-3">
                      <div className="d-flex">
                        <img src={star} style={{ height: 24, width: 24 }} />
                        <img src={star} style={{ height: 24, width: 24 }} />
                        <img src={star} style={{ height: 24, width: 24 }} />
                        <img src={star} style={{ height: 24, width: 24 }} />
                        <img src={star} style={{ height: 24, width: 24 }} />
                      </div>
                      <div style={{
                        backgroundColor: "#717D96",
                        border: '0 none',
                        color: '#717D96',
                        height: '0.1rem',
                        width: '100%',
                        marginTop: 10,
                        marginLeft: 10
                      }}></div>
                      <div style={{ marginLeft: 10, color: "#717D96", fontSize: 14, fontWeight: 600 }}>{ratCount['5'] ? ratCount['5'] : "0"}</div>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <div className="d-flex">
                        <img src={star} style={{ height: 24, width: 24 }} />
                        <img src={star} style={{ height: 24, width: 24 }} />
                        <img src={star} style={{ height: 24, width: 24 }} />
                        <img src={star} style={{ height: 24, width: 24 }} />

                      </div>
                      <div style={{
                        backgroundColor: "#717D96",
                        border: '0 none',
                        color: '#717D96',
                        height: '0.1rem',
                        width: '100%',
                        marginTop: 10,
                        marginLeft: 10
                      }}></div>
                      <div style={{ marginLeft: 10, color: "#717D96", fontSize: 14, fontWeight: 600 }}>{ratCount['4'] ? ratCount['4'] : "0"}</div>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <div className="d-flex">
                        <img src={star} style={{ height: 24, width: 24 }} />
                        <img src={star} style={{ height: 24, width: 24 }} />
                        <img src={star} style={{ height: 24, width: 24 }} />
                      </div>
                      <div style={{
                        backgroundColor: "#717D96",
                        border: '0 none',
                        color: '#717D96',
                        height: '0.1rem',
                        width: '100%',
                        marginTop: 10,
                        marginLeft: 10
                      }}></div>
                      <div style={{ marginLeft: 10, color: "#717D96", fontSize: 14, fontWeight: 600 }}>{ratCount['3'] ? ratCount['3'] : "0"}</div>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <div className="d-flex">
                        <img src={star} style={{ height: 24, width: 24 }} />
                        <img src={star} style={{ height: 24, width: 24 }} />
                      </div>
                      <div style={{
                        backgroundColor: "#717D96",
                        border: '0 none',
                        color: '#717D96',
                        height: '0.1rem',
                        width: '100%',
                        marginTop: 10,
                        marginLeft: 10
                      }}></div>
                      <div style={{ marginLeft: 10, color: "#717D96", fontSize: 14, fontWeight: 600 }}>{ratCount['2'] ? ratCount['2'] : "0"}</div>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <div className="d-flex">
                        <img src={star} style={{ height: 24, width: 24 }} />
                      </div>
                      <div style={{
                        backgroundColor: "#717D96",
                        borderBlock: '1px',
                        color: '#717D96',
                        height: '0.1rem',
                        width: '100%',
                        marginTop: 10,
                        marginLeft: 10
                      }}></div>
                      <div style={{ marginLeft: 10, color: "#717D96", fontSize: 14, fontWeight: 600 }}>{ratCount['1'] ? ratCount['1'] : "0"}</div>
                    </div>
                  </div>
                  <div className="mt-4" style={{
                    backgroundColor: "#eee",
                    border: '0 none',
                    color: '#717D96',
                    height: '0.1rem'
                  }}></div>
                </div>
}
                {ratingList?.data?.map((val, ins) =>
                  <div className="row mt-4" key={ins}>
                    <div className="col-lg-4">
                      <div className="d-flex ">
                        <div>
                          <img src={val?.image_url ? val?.image_url : avatar} style={{ width: 48, height: 48, borderRadius: 50 }} />
                        </div>
                        <div className="my-auto" style={{ color: '#000', fontSize: 16, fontWeight: 700, marginLeft: 10 }}>
                          {val?.company_name != "" ? <span style={{ textTransform: 'capitalize' }}>{val?.company_name}</span> : <><span style={{ textTransform: 'capitalize' }}>{val?.first_name}</span> <span style={{ textTransform: 'capitalize' }}>{val?.last_name}</span></>}
                        </div>
                        <div className="my-auto">
                          <img src={right} style={{ width: 30, height: 25 }} />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      <img src={val?.rating == 1 ? rat1 : val?.rating == 2 ? rat2 : val?.rating == 3 ? rat3 : val?.rating == 4 ? rat4 : rat5} style={{ width: val?.rating == 3 ? '11%' : val?.rating == 5 ? "19%" : val?.rating == 4 ? "15%" : val?.rating == 2 ? "7%" : '3%' }} />
                    </div>
                    <div className="mt-3" style={{ fontSize: 22, color: "#717D96" }}>
                      {val?.description}
                    </div>
                    <div><hr /></div>
                  </div>
                )}
              </TabPanel>
              <TabPanel className="mb-5 pb-5">
                <div>
                  {ratingGivenList?.count ? <>
                    {ratingGivenList?.data?.map((val, ins) =>
                      <div className="row mt-2" key={ins}>
                        <div className="col-lg-4">
                          <div className="d-flex ">
                            <div>
                              <img src={val?.image_url ? val?.image_url : avatar} style={{ width: 48, height: 48, borderRadius: 50 }} />
                            </div>
                            <div className="my-auto" style={{ color: '#000', fontSize: 16, fontWeight: 700, marginLeft: 10 }}>
                              {val?.company_name != "" ? <span style={{ textTransform: 'capitalize' }}>{val?.company_name}</span> : <><span style={{ textTransform: 'capitalize' }}>{val?.first_name}</span> <span style={{ textTransform: 'capitalize' }}>{val?.last_name}</span></>}
                            </div>
                            <div className="my-auto">
                              <img src={right} style={{ width: 30, height: 25 }} />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-2">
                          <img src={val?.rating == 1 ? rat1 : val?.rating == 2 ? rat2 : val?.rating == 3 ? rat3 : val?.rating == 4 ? rat4 : rat5} style={{ width: val?.rating == 3 ? '11%' : val?.rating == 5 ? "19%" : val?.rating == 4 ? "15%" : val?.rating == 2 ? "7%" : '3%' }} />
                        </div>
                        <div className="mt-3" style={{ fontSize: 22, color: "#717D96" }}>
                          {val?.description}
                        </div>
                        <div><hr /></div>
                      </div>
                    )}
                  </> : <div className='mt-3' style={{ fontSize: 22, color: "grey", padding: 22, textAlign: 'center' }}>
                    <b>Keine Bewertungen abgegeben</b>
                  </div>}
                  {/* {ratingGivenList?.count?<div style={{ fontSize: 22, color: "#717D96" }}>Keine Bewerbung abgegeben</div>:<>
                                
                                </>} */}
                </div>

              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </React.Fragment>
}
export default Reviews;