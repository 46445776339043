// import {auth} from "./auth";
// import { useNavigate } from "react-router-dom";
export default function RequireAuth({ children }) {

  var isAuthenticated
  if (localStorage.getItem("user")) {
    isAuthenticated = true;
  } else {
    isAuthenticated = false;
  }
//   const navigate = useNavigate();

if(isAuthenticated === true){
  return children

}
else
{
  window.location.href="/login"

  return null
}
 
}