import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { setPickupTime } from "../../redux/redux/actions/locationActions";
import { useNavigate } from "react-router-dom";
import { TimePicker } from "antd";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import Header from "../../../common/zuruckheader";
import "./cars.css";

const format = "HH:mm";

export default function PickUpCars() {
  const [time, setTime] = useState("12:00");
  const [isError, setIsError] = useState(false);
  const inputRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleContinue = () => {
    if (time === "") {
      setIsError(true);
      toast.error("Wählen Sie eine Uhrzeit aus");
      return false;
    } else {
      dispatch(setPickupTime(time));
      return true;
    }
  };

  const handleTimeChange = (time) => {
    setTime("")
    if(time!=undefined){
      const timeVal = `${time.$H < 10 ? "0" + time.$H : time.$H}:${time.$m < 30 ? "0" + time.$m : time.$m}`;
      setTime(timeVal);
      setIsError(false); 
    }
  };

  const handleButtonClick = () => {
    const continueClicked = handleContinue();
    if (continueClicked) {
      navigate("/WeightCarry");
    }
  };

  return (
    <>
      <Header />
    
      <div className="container">
        <div className="row justify-content-center mt-lg-5 pt-5">
          <div className="col-lg-8 col-12">
            <div className="text-center" style={{ fontSize: 40, fontWeight: 600 }}>
              Um welche Uhrzeit?
            </div>
            <div className="mt-4" id="pickup-car" style={{ textAlign: "center" }}>
              <TimePicker
                ref={inputRef}
                minuteStep={30}
                className={` ${time!="" ? "time-pick" : "time-pick1"} ${isError ? "error-border" : ""}`}
                defaultValue={dayjs("12:00", format)}
                format={format}
                onSelect={handleTimeChange}
                showNow={false}
                placeholder={"Wählen Sie eine Uhrzeit aus"}
                inputReadOnly={true}
              />
            </div>
            <div className="d-flex justify-content-center mt-4">
              <button
                type="button"
                className="btn btn-primary px-3 py-2 my-auto"
                onClick={handleButtonClick}
                style={{ backgroundColor: "#25A9E0", border: "none", fontSize: 16 }}
              >
                Weiter
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}