import React, { useEffect, useState } from 'react';
import Logo from '../assets/Logo-for-verification.png';
import Back from '../assets/back.png';
import { Link, useNavigate } from 'react-router-dom';
import './header.css';
import Search from '../assets/search-blue.png';
import Truck from '../assets/truck-blue.png';
import User from '../assets/Avatar2.png';

export default function Header() {
  const [userInfo, setUserInfo] = useState('');

  useEffect(() => {
    let user = localStorage.getItem('user');
    if (user) {
      let usr = JSON.parse(user);
      setUserInfo(usr);
    } else {
      setUserInfo('');
    }
  }, []);

  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    const currentUrl = window.location.href;

    if (currentUrl === 'https://web-warumleer.taction.in/billingCycle') {
      navigate('/');
    } else {
      window.history.back();
    }
  };

  const isZuruckVisible = window.location.href.includes('notify=uploaddocs');

  return (
    <div className="container my-3">
      <div className="row">
        <div className="col-12 my-auto">
          <div className="d-flex justify-content-between">
            <div className="d-flex my-auto" style={{ cursor: 'pointer' }}>
            {!isZuruckVisible && (
              <>
              <div className="mt-1">
                <img src={Back} width={'7px'} alt="Back" />
              </div>
            
              
                <div className="text-start mx-3" onClick={handleBackButtonClick}>
                  Zurück
                </div>
                </>
              )}
            </div>
            <a href="/">
              <img src={Logo} width={'100px'} alt="Logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
