import { useState, useEffect } from "react";
import Calendar from "react-calendar";
import { useDispatch } from "react-redux";
import "react-calendar/dist/Calendar.css";
import "./SelectDate.css";
import Header from "../../../common/zuruckheader";
import { setPickupDate } from "../../redux/redux/actions/locationActions";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate hook

function SelectDate() {
  const [date, setDate] = useState(new Date()); // Initialize with today's date
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate hook

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const handleContinue = () => {
    
    dispatch(setPickupDate(formatDate(date)));
    return true; // Return true if validation passes
  };

  const handleButtonClick = () => {
    const continueClicked = handleContinue(); // Check validation before redirection
    if (continueClicked) {
      navigate("/PickUpCars"); // Redirect only if validation passes
    }
  };

  return (
    <>
      {" "}
      <Header />
      <div className="app selct">
        <div
          className="text-center mt-5 pt-5 mb-4"
          style={{ fontSize: 40, fontWeight: 600 }}
        >
          Wann fahren Sie?
        </div>
        <div className="calendar-container container">
          <div className="row">
            <div className="col-12 d-flex justify-content-center date-sec">
              <Calendar onChange={setDate} value={date} minDate={new Date()}locale="de-DE" />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button
              type="button"
              onClick={handleButtonClick}
              className="btn btn-primary px-3 py-2 mt-4"
              style={{
                backgroundColor: "#25A9E0",
                border: "none",
                fontSize: 16,
                color:'#fff'
              }}
            >
              Weiter
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectDate;
