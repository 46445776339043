import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import axios from "axios";
import toast from "react-hot-toast";
import "./header.css";
import { Link } from "react-router-dom";

export default function Footer() {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errr, setErrr] = useState("");
  const [showMenu, setShowMenu] = useState(true);
  const [contactBack, setContactBack] = useState(0);
  const onOpenModal = () => {
    setOpen(true);
    // document.getElementById("navbarSupportedContent")?.classList.remove("show");
  };
  const onCloseModal = () => setOpen(false);

  const onSumbit = () => {
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!name || !email || !phone || !message) {
      setErrr("Bitte füllen Sie alle markierten Felder aus");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return;
    }

    if (!emailRegex.test(email)) {
      setErrr("Bitte füllen Sie alle markierten Felder aus");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return;
    }

    if (phone.length < 8 || phone.length > 14) {
      setErrr("Telefonnummer muss zwischen 8 und 14 Zeichen lang sein");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return;
    }
    setOpen(false);

    https: axios
      .post("https://api.warumleer.de/api/api/v1/contect_us", {
        Name: name,
        email_id: email,
        is_checked: contactBack,
        mobile_no: phone,
        description: message,
      })
      .then((res) => {
        if (res && (res?.status == 200 || res?.status == 201)) {
          toast.success("Ihre Anfrage wurde übermittelt", {
            autoClose: 2000,
          });

          setTimeout(() => {
            setEmail("");
            setName("");
            setMessage("");
            setPhone("");
            setContactBack("");
          }, 2500);
          
        }
      })
      .catch((err) => {
       
      });

  };

  const closeT = () => {
    document.getElementById("navbarSupportedContent")?.classList.remove("show");
  };

  return (
    <>
      <footer className="mt-5" style={{ backgroundColor: "#1A202C",position:'absolute',bottom:0,width:'100%' }}>
        <div className="container">
          <div className="row py-3">
            <div className="col-lg-3 col-12 order-2 order-lg-1">
              <div
                className="text-white text-center text-lg-start"
                style={{ fontSize: 14 }}
              >
                &#169; WarumLeer.
              </div>
            </div>
            <div className="col-lg-9 col-12 order-1 order-lg-2">
              <div
                className="d-lg-flex justify-content-between text-white text-center text-lg-start"
                style={{ fontSize: 14 }}
              >
                <div
                  className="mx-3"
                  style={{ cursor: "pointer" }}
                  onClick={onOpenModal}
                >
                  Kontakt
                </div>
                <div className="mx-3" onClick={()=>{window.location.href="/Impressum"}}style={{ cursor: "pointer" }}>Impressum</div>
                <div onClick={()=>{window.location.href="/Datenschutz"}}style={{ cursor: "pointer" }}>Datenschutz</div>
                <div onClick={()=>{window.location.href="/AllgemeineGeschäftsbedingungen"}} className="mx-3" style={{ cursor: "pointer" }}>Allgemeine Geschäftsbedingungen</div>
                <div className="me-3" style={{ cursor: "pointer" }} onClick={()=>{window.location.href="/Widerrufsbelehrung"}}>Widerrufsbelehrung</div>
                {/* <a onClick={()=>CCM.openWidget() }>Cookie-Einstellungen</a> */}
                {/* <div style={{ cursor: "pointer" }}onClick= {()=>{window.location.href="#CCM.openWidget"}}>Cookie-Einstellungen</div>  */}
                <a href="#CCM.openWidget" style={{color:"white",textDecoration:"none"}}>Cookie-Einstellungen</a>
                {/* <a href="#" onclick="CCM.openWidget();return false;">Konfigurationsbox öffnen</a> */}
               
              </div>
            </div>
          </div>
        </div>
      </footer>

      
      
      <Modal
      id="contact-modal"
        open={open}
        onClose={onCloseModal}
        center
        style={{ background: "#f2f2f2" }}
      >
        <div className="container-modal">
          <div className="text-center contact">Kontaktieren Sie uns!</div>
          <form action="/action_page.php">
            <label className="label-text" for="fname">
              Name <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              type="text"
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              id="fname"
              name="firstname"
              placeholder=""
            />
            <br />

            <label className="label-text" for="lname">
              E-Mail<sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              type="text"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
              id="Fname"
              name="lastname"
              placeholder=""
            />
          <label className="label-text" htmlFor="phone">
  Telefonnummer<sup style={{ color: "red" }}>*</sup>
</label>
<input
  type="text"
  onChange={(e) => {
    setPhone(e.target.value);
  }}
  value={phone}
  id="phone"
  name="phone"
  maxLength={14}
  placeholder=""
  onKeyPress={(e) => {
    // Check if the entered key is not a number
    if (isNaN(parseInt(e.key, 10))) {
      e.preventDefault();
    }
  }}
/>

            <label className="label-text" for="subject">
              Ihre Nachricht<sup style={{ color: "red" }}>*</sup>
            </label>
            <textarea
              onChange={(e) => {
                if (e.target.value?.length > 5000) {
                  setErrr("Messahe should be contain 500 charectors !!");
                  setTimeout(() => {
                    setErrr("");
                  }, 3000);
                  return false;
                } else {
                  setMessage(e.target.value);
                }
              }}
              className="mb-0"
              id="subject"
              value={message}
              name="subject"
              placeholder=""
              style={{ height: "100px" }}
            ></textarea>
{/* <div style={{  fontSize: '12px', color: '#6c757d' }}>
        {message.length}/200 characters
      </div> */}
            <div className="d-flex">
              <div className="my-auto d-flex justify-content-center">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    const isChecked = e.target.checked ? 1 : 0; // Convert checked state to 1 or 0

                    setContactBack(isChecked);
                  }}
                  checked={contactBack === 1} // Check if contactBack is equal to 1 to determine checked state
                  id="contactBack"
                  name="contactBack"
                  value="1" // This value will be submitted with the form data if the checkbox is checked
                />
              </div>
              <div className="my-auto">
                <label
                  className="label-text "
                  for="Fname"
                  style={{ marginLeft: "10px" }}
                >
                  Bitte um Rückruf
                </label>
              </div>
            </div>

            {/* <input type="submit" value="Submit"/> */}
            <div className="text-center mt-3">
              <button
                onClick={onSumbit}
                type="button"
                className="btn btn-primary"
                style={{
                  width: "100%",
                  backgroundColor: "rgb(37, 169, 224)",
                  borderColor: "rgb(37, 169, 224)",
                }}
              >
                Senden
              </button>
            </div>
            <div
              style={{ height: 20, color: "red", fontSize: 12, marginTop: 5 }}
            >
              {errr ? errr : ""}
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
