import React, { useEffect, useState } from "react";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import axios from "axios";
import toast from "react-hot-toast";
import './money.css'
import { useNavigate } from "react-router-dom";
import SideBar from "../../../common/sidebar/side";
import Header from '../../../common/header'
import { PAYMENT_API } from "../../../services/Api/payment";
import Footer from "../../../common/footer";

const Money = (props) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [errr, setErrr] = useState("")
    const [phoneErr, setPhoneErr] = useState(false)
    const [contactBack, setContactBack] = useState(0);
    const [phone, setPhone] = useState('');
    const [duration, setDuration] = useState('');
    const [expiry, setExpiry] = useState('');
    const onOpenModal = () => {
        setOpen(true)
    };
    const onCloseModal = () => setOpen(false);

    const ChangePayment= async (e)=>{
        let info=localStorage.getItem("user");
        let userInfo=JSON.parse(info);
        e.preventDefault();
        PAYMENT_API.changePaymentMode({customerId: userInfo?.userInfo?.customer_id}).then(res=>{
            if(res?.session?.url){
                window.location.href =  res?.session?.url; 
            }
        });
    }

    const onSumbit = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!name || !email || !phone || !message) {
            setErrr("Bitte füllen Sie alle markierten Felder aus");
            setTimeout(() => {
                setErrr("");
            }, 3000);
            return;
        }

        if (!emailRegex.test(email)) {
            setErrr("Bitte füllen Sie alle markierten Felder aus");
            setTimeout(() => {
                setErrr("");
            }, 3000);
            return;
        }

        if (phone.length < 8 || phone.length > 14) {
            setErrr("Telefonnummer muss zwischen 8 und 14 Zeichen lang sein");
            setTimeout(() => {
                setErrr("");
            }, 3000);
            return;
        }
        setOpen(false);


        https: axios
            .post("https://api.warumleer.de/api/api/v1/contect_us", {
                Name: name,
                email_id: email,
                is_checked: contactBack,
                mobile_no: phone,
                description: message,
            })
            .then((res) => {
                if (res?.status == 201) {
                    toast.success("Ihre Anfrage wurde übermittelt", {
                        autoClose: 2000,
                    });

                    setTimeout(() => {
                        setEmail("");
                        setName("");
                        setMessage("");
                        setPhone("");
                        setContactBack("");
                    }, 2500);
                }
            })
            .catch((err) => {
                // console.log(err);
            });
    };
    const PaymentDetailbyId = (userid) => {
        PAYMENT_API.PaymentDetailbyId(userid)
            .then((response) => {
                if (response?.status == 200 && response.data.length > 0) {
                    const paymentdata = response.data[0]; 
                    setDuration(paymentdata.duration);                   
                    let expiryDate = paymentdata.expires_at; 
                    let parts = expiryDate.split('/');
                    let formattedDate = parts[2] + '.' + parts[1] + '.' + parts[0]; 
                    setExpiry(formattedDate);
                } else {
                    setDuration('No record found');
                    setExpiry('No record found');
                }
            })
            .catch((error) => {
                console.error('Error fetching car data:', error);
            });
    }
    useEffect(() => {
        let usr = localStorage.getItem('user')
        let user_info = JSON.parse(usr)
        PaymentDetailbyId(user_info.userInfo.user_id)
      
    }, [])
    const handleSubscriptionButton =()=>{
        navigate('/Subscription')
    }
    return <React.Fragment>
        <Header />
        <div className="pading-mobile">
            <div className="container">
                <div className="row mt-lg-5 pt-3">
                    <div className="col-lg-3 hamburger-sec">
                        <nav class="navbar navbar-expand-lg navbar-light bg-white">
                            <div class="container-fluid justify-content-end px-lg-0">
                                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                <div class="collapse navbar-collapse mt-3 mt-lg-0" id="navbarSupportedContent">
                                    <SideBar title="Mitgliedschaft" />
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div className="col-lg-9">
                        <div className="container">
                            <div style={{ fontSize: 24, fontWeight: 600 }} >Ihre Mitgliedschaft</div>
                        </div>
                        <div className="container mt-5  mb-lg-5 pb-lg-5">
                            {duration !== 'No record found' ? (
                                <>
                                <div className="card p-4  mb-3 pb-3" style={{ border: "0.1rem solid #E2E7F0", borderRadius: 20 }}>
                                    <div className="row justify-content-between">
                                        <div className="col-lg-4 d-flex justify-content-lg-center border-none border-lg-block" style={{ borderRight: "0.1rem solid #E2E7F0" }}>
                                            <div>
                                            <div style={{ color: "#000", fontSize: 18, fontWeight: 700 }}>Abonnementdetails</div>
                                            <div className="mt-1" style={{ color: "#4A5468", fontSize: 16, }}>Zyklus: {duration}</div>
                                            <div className="mt-1" style={{ color: "#4A5468", fontSize: 16, }}>Status: Gold-Mitglied</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 d-flex justify-content-lg-center  mt-3 mt-lg-0" >
                                            <div>
                                            <div style={{ color: "#000", fontSize: 18, fontWeight: 700 }}>Nächste Zahlung fällig</div>
                                            <div className="mt-1" style={{ color: "#4A5468", fontSize: 16, }}>am {expiry}</div>
                                            </div>
                                        
                                        </div>
                                        <div className="col-lg-4 d-flex justify-content-lg-center mt-3 mt-lg-0" style={{ borderLeft: "0.1rem solid #E2E7F0" }}>
                                            <div>
                                            <div style={{ color: "#000", fontSize: 18, fontWeight: 700 }}>Zahlungsdetails</div>
                                            <div className="mt-1" style={{ color: "#25A9E0", fontSize: 16,cursor:"pointer" }} onClick={ChangePayment}>Abonnement bearbeiten</div>
                                            <div className="mt-1" style={{ color: "#25A9E0", fontSize: 16,cursor:"pointer" }} onClick={ChangePayment}>Rechnungen anzeigen</div>

                                            </div>
                                        
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="mt-2 d-flex justify-content-end" onClick={ChangePayment} style={{ fontSize: 15, color: "#F5222D",cursor:"pointer" }}>Abonnement kündigen</div>
                                </>
                                
                            ) : (
                                <>
                                <div className="mt-4 d-flex justify-content-center mb-3 pb-3"style={{ fontSize: 22, color: "grey",textAlign:"center"}}><b>Sie haben zurzeit keine Mitgliedschaft abgeschlossen</b></div>

                                <div className="mt-2 d-flex justify-content-center ">
                            <button
                            type="button"
                            class="btn btn-primary px-3 py-2 mt-2"
                            style={{
                                backgroundColor: "#25A9E0",
                                border: "none",
                                fontSize: 16,
                            }}
                            onClick={handleSubscriptionButton}
                            >
                            Jetzt Mitglied werden!
                            </button>
                        </div>
                                </>
                                
                            )}
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </React.Fragment>
}
export default Money;