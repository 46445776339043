import React from "react";
import "./side.css"
import { Link } from "react-router-dom";
import userw from "../../assets/images/user.png"
import userg from "../../assets/images/user-gray.png"
import settingw from "../../assets/images/setting-w.png"
import settingg from "../../assets/images/setting-g.png"
import moneyg from "../../assets/images/money-changeg.png"
import moneyw from "../../assets/images/money-changew.png"
import carg from "../../assets/images/car.png"
import carw from "../../assets/images/carw.png"
import truckg from "../../assets/images/truckg.png"
import truck from "../../assets/images/truck.png"
import notig from "../../assets/images/notification-g.png"
import notiw from "../../assets/images/notification-bing.png"
import starg from "../../assets/images/starg.png"
import starw from "../../assets/images/star.png"

const SideBar = (props) => {

const route_go=(url_)=>{
    window.location.href=url_
}


    return <React.Fragment>
          <div className={`d-flex nav-div ${props?.title=="profile"?"active-link":"no-active-link"}`}
          style={{cursor:"pointer"}}
          onClick={()=>{
            route_go("/profile")
          }}
               
          >
            <div><img src={`${props?.title=="profile"?userw:userg}`} className="header-icons" /></div>
           <div className="my-1 " style={{marginLeft:10}}>
           <Link  to="/profile" className={`my-auto ${props?.title=="profile"?"active-link header-link":"no-header-link"}`}>Profil</Link>
           </div>
        </div>

        <div className={`mt-4 d-flex nav-div ${props?.title=="Kontoinformationen"?"active-link":"no-active-link"}`}
          style={{cursor:"pointer"}}
          onClick={()=>{
            route_go("/kontoinformationen")
          }}
          >
            <div><img src={`${props?.title=="Kontoinformationen"?settingw:settingg}`} className="header-icons" /></div>
           <div className="my-1 " style={{marginLeft:10}}>
           <Link  to="/kontoinformationen" className={`my-auto ${props?.title=="Kontoinformationen"?"active-link header-link":"no-header-link"}`}>Kontoinformationen</Link>
           </div>
        </div>
        <div className={`mt-4 d-flex nav-div ${props?.title=="Mitgliedschaft"?"active-link":"no-active-link"}`}
          style={{cursor:"pointer"}}
          onClick={()=>{
            route_go("/Mitgliedschaft")
          }}
          
          >
            <div><img src={`${props?.title=="Mitgliedschaft"?moneyw:moneyg}`} className="header-icons" /></div>
           <div className="my-1 " style={{marginLeft:10}}>
           <Link  to="/Mitgliedschaft" className={`my-auto ${props?.title=="Mitgliedschaft"?"active-link header-link":"no-header-link"}`}>Mitgliedschaft</Link>
           </div>
        </div>
        <div className={`mt-4 d-flex nav-div ${props?.title=="booktrip"?"active-link":"no-active-link"}`}
          style={{cursor:"pointer"}}
          onClick={()=>{
            route_go("/book-trip")
          }}
          >
            <div><img src={`${props?.title=="booktrip"?carw:carg}`} className="header-icons" /></div>
           <div className="my-1 " style={{marginLeft:10}}>
           <Link  to="/book-trip" className={`my-auto ${props?.title=="booktrip"?"active-link header-link":"no-header-link"}`}>Gebuchte Fahrten</Link>
           </div>
        </div>

        <div className={`mt-4 d-flex nav-div ${props?.title=="publishedtrips"?"active-link":"no-active-link"}`}
          style={{cursor:"pointer"}}
          onClick={()=>{
            route_go("/publishedtrips")
          }}
          >
            <div><img src={`${props?.title=="publishedtrips"?truck:truckg}`} className="header-icons" /></div>
           <div className="my-1 " style={{marginLeft:10}}>
           <Link  to="/publishedtrips" className={`my-auto ${props?.title=="publishedtrips"?"active-link header-link":"no-header-link"}`}>Veröffentlichte Fahrten</Link>
           </div>
        </div>
        {/* <div className={`mt-4 d-flex nav-div ${props?.title=="notification"?"active-link":"no-active-link"}`}
          style={{cursor:"pointer"}}
          onClick={()=>{
            route_go("/notification")
          }}
          >
            <div><img src={`${props?.title=="notification"?notiw:notig}`} className="header-icons" /></div>
           <div className="my-1 " style={{marginLeft:10}}>
           <Link  to="/notification" className={`my-auto ${props?.title=="notification"?"active-link header-link":"no-header-link"}`}>Benachrichtigungen</Link>
           </div>
        </div> */}

        <div className={`mt-4 d-flex nav-div ${props?.title=="Reviews"?"active-link":"no-active-link"}`}
          style={{cursor:"pointer"}}
          onClick={()=>{
            route_go("/reviews")
          }}
          >
            <div><img src={`${props?.title=="Reviews"?starw:starg}`} className="header-icons" /></div>
           <div className="my-1 " style={{marginLeft:10}}>
           <Link  to="/reviews" className={`my-auto ${props?.title=="Reviews"?"active-link header-link":"no-header-link"}`}>Bewertungen</Link>
           </div>
        </div>
    </React.Fragment>
}
export default SideBar;