import React, { useEffect, useState } from 'react'
import './Invoice.css'
import Header from '../../../common/zuruckheader'
import { AUTENTICATE_API } from '../../../services/Api/authenticationApi';
import moment from 'moment';
import html2pdf from "html2pdf-fix-jspdf";
import Logo from '../../../assets/Logo-for-verification.png'

export default function Invoice() {
    const [paymentDetails,setPaynetDetails]=useState("");

    useEffect(()=>{
        let user = localStorage.getItem("user");
        let usr = JSON.parse(user);
        if(usr){
            AUTENTICATE_API?.getPaymentByUserId(usr?.userInfo?.user_id).then(res=>{
                if(res && res?.data){
                    setPaynetDetails(res?.data[res?.data?.length-1]);
                }
            })
        }
    },[])

    const download=()=> {  
        document.getElementById("btn-download").style.display="none"  
        document.getElementById("inv-text").style.display="block" 
        document.getElementById("inv-img").style.display="block"   
        setTimeout(() => {
            let dt = moment(new Date()).format('DD-MMM-YYYY');
            let dtArr = dt.split('-');
            let finalDate = dtArr[0] + dtArr[1].toLocaleUpperCase() + dtArr[2];
            let fname = `WarumLeer-${finalDate}`
            let element = document.getElementById('ddl');
            const opt = {
                jsPDF: {
                  unit: "mm",
                  format: "A4",
                  orientation: "portrait",
                  compress: true,
                },
                html2canvas: {
                  scale: 2,
                  useCORS: true,
                },
                image: { type: "png" },
                margin: 10,
                filename: fname,
                 pagebreak: { mode: ["avoid-all", "css"]},
              };
      
              html2pdf()
                .set(opt)
                .from(element)
                .save().then(res=>{
                    document.getElementById("btn-download").style.display="block" 
                    document.getElementById("inv-text").style.display="none" 
                    document.getElementById("inv-img").style.display="none"  
                })
        }, 1)
    }

   


  return (
    <>
    <Header/>
    <div className='container'>
        <div className='row justify-content-center' >
            <div className='col-12'>
            <div className='d-flex justify-content-center'><img src={Logo} width={"200px"}  /></div>
                <div className='text-center mt-3 mb-3'
                
                style={{fontSize:40,fontWeight:600}}>Rechnung</div>
            </div>
            <div className='col-lg-6' >
                <div className='p-3' id="ddl" style={{border:'1px solid #D7DAE0',borderRadius:12}}>
                <div className='text-center mt-3 mb-3' id="inv-text" style={{fontSize:30,fontWeight:600,display:"none"}}>Rechnung</div>
                <div className='d-flex justify-content-center' ><img src={Logo} id='inv-img' width={"150px"} style={{display:'none'}} /></div>
                    <div className='row mt-4'>
                        <div className='col-4'>
                            <div style={{fontSize:12,fontWeight:600,color:'#717D96'}}>Billed to</div>
                        </div>
                        <div className='col-4'>
                            <div className='text-center' style={{fontSize:12,fontWeight:600,color:'#717D96'}}>Invoice number</div>
                        </div>
                        <div className='col-4'>
                            <div className='text-end' style={{fontSize:12,fontWeight:600,color:'#717D96'}}>Invoice of (EUR)</div>
                        </div>
                    </div>
                    <div className='row mt-1'>
                        <div className='col-4'>
                            <div>
                                <div style={{fontSize:10,fontWeight:600}}>Gold Mitglied</div>
                                <div style={{fontSize:10,color:'#717D96'}}>Company address</div>
                                <div style={{fontSize:10,color:'#717D96'}}>{paymentDetails?.company_name}</div>
                                <div style={{fontSize:10,color:'#717D96'}}>{paymentDetails?.Address}</div>
                                <div style={{fontSize:10,color:'#717D96'}}>{paymentDetails?.city} - {paymentDetails?.postal_code}</div>
                                <div style={{fontSize:10,color:'#717D96'}}>{paymentDetails?.Phone_no}</div>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='text-center' style={{fontSize:10,fontWeight:600,textTransform:'uppercase'}}>#{paymentDetails?.invoice_id}</div>
                        </div>
                        <div className='col-4'>
                            <div className='text-end' style={{fontSize:20,fontWeight:600,color:'#25A9E0'}}>€ {paymentDetails?.amount}</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between mt-4'>
                        <div style={{fontSize:12,fontWeight:600,color:'#717D96'}}>Subject</div>
                        <div style={{fontSize:12,fontWeight:600,color:'#717D96'}}>Invoice date</div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div style={{fontSize:10,fontWeight:600}}>Subscription</div>
                        <div style={{fontSize:10,fontWeight:600}}>{moment(paymentDetails?.created_dt).format('DD-MMM-YYYY')}</div>
                    </div>
                    <table class="table mt-3">
                        <thead>
                            <tr style={{borderTop:'1px solid #D7DAE0'}}>
                                <th className='fst-sec px-0' scope="col" style={{fontSize:8,fontWeight:600,color:'#717D96'}}>ITEM DETAIL</th>
                                <th className='text-center' scope="col" style={{fontSize:8,fontWeight:600,color:'#717D96'}}>QTY</th>
                                <th className='text-center' scope="col" style={{fontSize:8,fontWeight:600,color:'#717D96'}}>RATE</th>
                                <th className='text-end px-0' scope="col" style={{fontSize:8,fontWeight:600,color:'#717D96'}}>AMOUNT</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* <tr style={{borderBottom:'1px solid #fff'}}>
                                <th className='fst-sec px-0' style={{fontSize:10,fontWeight:600}}>Item Name<br/><span style={{color:'#717D96',fontWeight:500}}>Subscription</span></th>
                                <td className='text-center py-3' style={{fontSize:10}}>1</td>
                                <td className='text-center py-3' style={{fontSize:10}}>€ 32</td>
                                <td className='text-end py-3 px-0' style={{fontSize:10}}>€ 32</td>
                            </tr> */}
                            <tr>
                                <th className='fst-sec px-0' style={{fontSize:10,fontWeight:600}}>Item Name<br/><span style={{color:'#717D96',fontWeight:500}}>Subscription</span></th>
                                <td className='text-center py-3' style={{fontSize:10}}>1</td>
                                <td className='text-center py-3' style={{fontSize:10}}>€ {paymentDetails?.amount}</td>
                                <td className='text-end py-3 px-0' style={{fontSize:10}}>€ {paymentDetails?.amount}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='row justify-content-end'>
                        <div className='col-6'>
                            <div className='d-flex justify-content-between'>
                                <div style={{fontSize:10}}>Subtotal</div>
                                <div style={{fontSize:10}}>€ {paymentDetails?.amount-paymentDetails?.amount*19/100}</div>
                            </div>
                            <div className='d-flex justify-content-between mt-2 pb-2' style={{borderBottom:'1px solid #D7DAE0'}}>
                                <div style={{fontSize:10}}>Tax (19%)</div>
                                <div style={{fontSize:10}}>€ {paymentDetails?.amount*19/100}</div>
                            </div>
                            <div className='d-flex justify-content-between mt-2'>
                                <div style={{fontSize:10}}>Total</div>
                                <div style={{fontSize:10}}>€ {paymentDetails?.amount}</div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 mt-4'>
                <div className='d-flex justify-content-center'>
                    <button onClick={download} type="button" id="btn-download" class="btn btn-primary" style={{backgroundColor:'#25A9E0',fontSize:14,borderColor:'#25A9E0'}}>Download</button>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
