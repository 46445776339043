import React, { useState } from 'react'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import Successful from '../../../assets/ID Verification.png'
import { Link } from "react-router-dom";
import Header from '../../../common/zuruckheader';
import Footer from '../../../common/footer';

export default function VerificationSuccessful() {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [errr, setErrr] = useState("")
  const [showMenu, setShowMenu] = useState(true)
  const onOpenModal = () => {
    setOpen(true)
    // document.getElementById("navbarSupportedContent")?.classList.remove("show");
  };
  const onCloseModal = () => setOpen(false);

  const onSumbit = () => {
    if (name == '' && email == "" && message == "") {
      setErrr("Alle Felder sind Pflichtfelder!!");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return false
    }
    const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!email.match(emailReg)) {
      setErrr("Bitte eine gültige E-Mail-Adresse eintragen!!");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return false
    }

    if (message?.length > 500) {
      setErrr("Messahe sollte 500 Zeichen enthalten!!");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return false
    }
    setOpen(false);

    axios.post("https://smtp.taction.in/api/api/v2/send-mail", {
      name: name,
      email: email,
      message: message
    }).then(res => {
      if (res && res?.status == 200) {

        toast.success('Ihre Anfrage wurde übermittelt', {
          autoClose: 2000,
        }
        );
        // setTimeout(() => {

        //   setEmail("");
        //   setName("");
        //   setMessage("")
        // }, 2500);
      }

    }).catch((err) => {
     
    })
   



  }

  const closeT = () => {
    document.getElementById("navbarSupportedContent")?.classList.remove("show");
  }
  return (
    <>
      {/* <Header /> */}
      <div className='container'>
        <div className='row justify-content-center mt-md-3 pt-4'>
          <div className='col-xl-6 col-lg-8 col-md-10 col-12'>
            <div className='text-center' style={{ fontSize: 32, fontWeight: 600 }}>Wir haben Ihre Informationen erhalten</div>
            <div className='d-flex justify-content-center mt-4 px-lg-5 mx-lg-5'>
              <img src={Successful} width={'100%'} />
            </div>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-xl-6 col-lg-8 col-md-10 col-12 mb-5 mb-lg-0'>
          {/* <div className='text-center px-3 mt-4' style={{ fontSize: 22, color: '#717D96' }}>
              Vielen Dank für Ihre Einreichung!
            </div> */}
            <div className='text-center px-3 mt-4' style={{ fontSize: 22, color: '#717D96' }}>
            Ihre Dokumente werden nun schnellstmöglich geprüft.<br className='d-none d-lg-block'/> Sobald dieser Vorgang
            abgeschlossen ist, benachrichtigen wir Sie und Ihre Fahrt wird veröffentlicht.
            </div>
            {/* <div className='text-center px-3 mt-4' style={{ fontSize: 22, color: '#717D96' }}>
              Vielen Dank für Ihre Einreichung! Ihr Ausweis wird nun<br className='d-none d-md-block' /> schnellstmöglich geprüft. Sobald dieser Vorgang<br className='d-none d-md-block' /> abgeschlossen ist, benachrichtigen wir Sie und Ihre Fahrt wird veröffentlicht.
            </div> */}
            <div className='d-flex justify-content-center mt-4 mb-5 pb-5 mb-lg-0 pb-lg-0'>
              <button type="button" class="btn btn-primary px-3 py-2 my-auto mb-5" style={{ backgroundColor: '#25A9E0', border: 'none', fontSize: 16, marginLeft: 20 }}><Link to={'/'} style={{ textDecoration: 'none', color: '#fff' }}>Zurück zur Startseite</Link></button>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </>
  )
}