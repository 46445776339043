import Circle from '../../../assets/circle-shape.png'
import ImageBlue from '../../../assets/image-blue.png'
import AWS from 'aws-sdk';
import React, { useRef, useState,useEffect } from "react";
import Header from '../../../common/zuruckheader';
import { EDITPROFILE_API } from '../../../services/Api/editProfileApi';
import './upload.css'

export default function UploadPictureCompany() {
  const [image, setImage] = useState('');
  const [image1, setImage1] = useState('');
  const [image2, setImage2] = useState('');
  const [userId,setUserId] = useState('')
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploadComplete, setUploadComplete] = useState(false);
  const [UrlState1,setUrlState1] = useState('');
  const [UrlState2,setUrlState2] = useState('');
  const [UrlState3,setUrlState3] = useState('');

  const fileChange = (event) => {
    const selectedFile = event.target.files[0];
    let img=[...files];
    img[0]=event.target.files[0];
    setFiles(img)
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };
  const fileChange1 = (event) => {
    const selectedFile = event.target.files[0];
    let img=[...files];
    img[1]=event.target.files[0];
    setFiles(img)
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage1(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  useEffect(() => {
    let usr = localStorage.getItem('user')
    let user_info = JSON.parse(usr)
    let user_id=user_info.userInfo.user_id;
    setUserId(user_id)
    
  }, [])

  const fileChange2 = (event) => {
    const selectedFile = event.target.files[0];
    let img=[...files];
    img[2]=event.target.files[0];
    setFiles(img)
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage2(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const fileInputRef = useRef(null)
  const fileInputRef1 = useRef(null)
  const fileInputRef2 = useRef(null)
  const handleFileUpload = (event) => {
    fileInputRef.current.click() 
  }
  const handleFileUpload1 = (event) => {
    fileInputRef1.current.click() 
  }
  const handleFileUpload2 = (event) => {
    fileInputRef2.current.click() 
  }
  const handleUpload = async () => {
  
    
    if (files.length<3) {
      console.error('No files selected');
      return;
    }

    let count = 0
    const s3 = new AWS.S3({
        region: 'us-east-2',
        credentials: {
          accessKeyId: "AKIA6GBMFLZRFNFL64ZA",
          secretAccessKey: "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",
        },
    })

    const uploadPromises = files.map((file, index) => {
      const uniqueId = Date.now().toString()
      const params = {
        Bucket: 'warumleer-bucket-i-0e9a1494985796c7d',
        Key: `warumleer/identity/${uniqueId}-${file.name}`,
        Body: file,
        ACL: 'public-read',
        ContentType: file.type
      };
    
      return new Promise((resolve, reject) => {
        s3.upload(params, function(err, data) {
          if (err) {
            console.error(err);
            reject(err);
            return;
          }
         
          const location = data.Location;
         
          setUploadProgress(prevProgress => ({
            ...prevProgress,
            [index]: 100
          }));
          resolve(data.Location);
          // resolve(location);
        }).on('httpUploadProgress', function(progress) {
          const percentage = Math.round((progress.loaded / progress.total) * 100);
          setUploadProgress(prevProgress => ({
            ...prevProgress,
            [index]: percentage
          }));
        });
      });
    });
    try {
      const urls = await Promise.all(uploadPromises);
    
      const [url1, url2, url3] = urls;
      setUrlState1(url1);
      setUrlState2(url2);
      setUrlState3(url3);
      
    
      setUploadComplete(true);
      
      let abc={
        front_id_url:url1,
        back_id_url:url2,
        image_url:url3,
        user_id:userId
      }
      
      const response = await EDITPROFILE_API.DocumentUpload(abc)
      if (response && (response.status === 201 || response.status === 200)) {
          
      } 
      let localStorageData= localStorage.getItem('user');
      let object = JSON.parse(localStorageData)
      object.userInfo.document_status=0;

      localStorage.setItem('user',JSON.stringify(object))
     


       window.location.href = '/CompanyVerificationSuccessful';
    } catch (error) {
      console.error('Upload failed', error);
    }
  };

  return (
    <>
    <Header/>
      <div className='container mt-lg-5 pt-lg-5 pb-5'>
        <div className='row mt-lg-5 pt-5'>
          <div className='col-lg-4 col-12'>
            <div className='d-flex justify-content-center'>
              <img src={image?ImageBlue:Circle} width={'64px'}/>
            </div>
            <div className='text-center mt-3' style={{fontSize:26,fontWeight:600}}>Laden Sie die Vorderseite Ihres Gewerbenachweises hoch</div>
            <div className='img-sec'>
                <div className='d-flex justify-content-center'>
                  {image && (
                    <img src={image} width="240" height="160" className="image-logo-cls" />
                  )}
                </div>
                <div>
                <input
                  type="file"
                  onChange={fileChange}
                  id="file"
                  name="file"
                  accept="image/*"
                  style={{display:'none'}}
                  ref={fileInputRef}
                />
                </div>
                <div className='d-flex justify-content-center mt-3'>
                  <button
                    className="userForm-container-footer mt-10  btn btn-outline-secondary"
                    style={{color:'#25A9E0',fontSize:16}}
                  onClick={()=> handleFileUpload()}
                  >
                  {image?"Neue Datei wählen":"Datei hochladen"}
                  </button>
                </div>
                
              {/* <button type="button" class="btn btn-outline-secondary px-3 py-2 my-auto" style={{color:'#25A9E0',fontSize:16}}>Datei hochladen</button> */}
            </div>
          </div>
          <div className='col-lg-4 col-12 mt-4 mt-lg-0'>
            <div className='d-flex justify-content-center'>
              <img src={image1?ImageBlue:Circle} width={'64px'}/>
            </div>
            <div className='text-center mt-3' style={{fontSize:26,fontWeight:600}}>Laden Sie die Rückseite Ihres Gewerbenachweises hoch</div>
            <div className='img-sec'>
                <div className='d-flex justify-content-center'>
                  {image1 && (
                    <img src={image1} width="240" height="160" className="image-logo-cls" />
                  )}
                </div>
                <div>
                <input
                  type="file"
                  onChange={fileChange1}
                  id="file"
                  name="file"
                  accept="image/*"
                  style={{display:'none'}}
                  ref={fileInputRef1}
                />
                </div>
                <div className='d-flex justify-content-center mt-3'>
                  <button
                    className="userForm-container-footer mt-10  btn btn-outline-secondary"
                    style={{color:'#25A9E0',fontSize:16}}
                  onClick={()=> handleFileUpload1()}
                  >
                    {image1?"Neue Datei wählen":"Datei hochladen"}
                  </button>
                </div>
                
              {/* <button type="button" class="btn btn-outline-secondary px-3 py-2 my-auto" style={{color:'#25A9E0',fontSize:16}}>Datei hochladen</button> */}
            </div>
          </div>
          <div className='col-lg-4 col-12 mt-4 mt-lg-0'>
            <div className='d-flex justify-content-center'>
              <img src={image2?ImageBlue:Circle} width={'64px'}/>
            </div>
            <div className='text-center mt-3' style={{fontSize:26,fontWeight:600}}>Laden Sie Ihren Versicherungsnachweis hoch</div>
            <div className='img-sec'>
                <div className='d-flex justify-content-center'>
                  {image2 && (
                    <img src={image2} width="240" height="160" className="image-logo-cls" />
                  )}
                </div>
                <div>
                <input
                  type="file"
                  onChange={fileChange2}
                  id="file"
                  name="file"
                  accept="image/*"
                  style={{display:'none'}}
                  ref={fileInputRef2}
                />
                </div>
                <div className='d-flex justify-content-center mt-3'>
                  <button
                    className="userForm-container-footer mt-10  btn btn-outline-secondary"
                    style={{color:'#25A9E0',fontSize:16}}
                  onClick={()=> handleFileUpload2()}
                  >
                  {image2?"Neue Datei wählen":"Datei hochladen"}
                  </button>
                </div>
                
              
            </div>
          </div>
          {(image && image1 && image2)?
                <div className='d-flex justify-content-center mt-5'>
                  <button
                    className="userForm-container-footer mt-10  btn btn-secondary"
                    style={{color:'#ffffff',backgroundColor:'#25A9E0',fontSize:16,border:'none'}}
                    onClick={handleUpload}
                  >
                    Bestätigen und weiter
                  </button>
                </div>
              :""}
        </div>
      </div>
    </>
  )
}