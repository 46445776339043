import React, { useEffect, useState } from 'react'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import axios from "axios";
import toast from "react-hot-toast";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { AUTENTICATE_API } from '../../../services/Api/authenticationApi';
import { useNavigate } from 'react-router';
import './notification.css'
import SideBar from "../../../common/sidebar/side";
import Header from "../../../common/header";
import Footer from '../../../common/footer';


const Notification = (props) => {
  const [open, setOpen] = useState(false);
  const navi = useNavigate();

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [emailErrFlag, setemailErrFlag] = useState(false);
  const [phoneerrorFlag, setPhoneErrFlag] = useState(false);
  const [bothrrorEmailFlag, setBothEmailErrFlag] = useState(false);
  const [bothrrorPhoneFlag, setBothPhoneErrFlag] = useState(false);
  const [emailboth, setEmailBoth] = useState('');
  const [phoneboth, setPhoneBoth] = useState('');
  const [name, setName] = useState("")
  const [message, setMessage] = useState("")
  const [errr, setErrr] = useState("")
  const [showMenu, setShowMenu] = useState(true)
  const [tabss, setTabss] = useState("email")
  const [phoneErr, setPhoneErr] = useState(false)
  const [contactBack, setContactBack] = useState(0);
  const [phone1, setPhone1] = useState('');
  const [email1, setEmail1] = useState('');
  const onOpenModal = () => {
    setOpen(true)
  };
  const onCloseModal = () => setOpen(false);

  const onSumbit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!name || !email1 || !phone1 || !message) {
      setErrr("Bitte füllen Sie alle markierten Felder aus");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return;
    }

    if (!emailRegex.test(email1)) {
      setErrr("Bitte füllen Sie alle markierten Felder aus");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return;
    }

    if (phone1.length < 8 || phone1.length > 14) {
      setErrr("Telefonnummer muss zwischen 8 und 14 Zeichen lang sein");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return;
    }
    setOpen(false);


    https: axios
      .post("https://api.warumleer.de/api/api/v1/contect_us", {
        Name: name,
        email_id: email1,
        is_checked: contactBack,
        mobile_no: phone1,
        description: message,
      })
      .then((res) => {
        if (res?.status == 201) {
          toast.success("Ihre Anfrage wurde übermittelt", {
            autoClose: 2000,
          });

          setTimeout(() => {
            setEmail("");
            setName("");
            setMessage("");
            setPhone("");
            setContactBack("");
          }, 2500);
        }
      })
      .catch((err) => {
        
      });
  };

  const closeT = () => {
    document.getElementById("navbarSupportedContent")?.classList.remove("show");
  }

  useEffect(() => {
    //debugger
    let user = localStorage.getItem("user");
    let usr = JSON.parse(user);
    setEmail(usr?.userInfo?.email);
    setPhone(usr?.userInfo?.phone_no);
    setEmailBoth(usr?.userInfo?.email);
    setPhoneBoth(usr?.userInfo?.phone_no);
  }, [tabss])


  const onSubmit = async () => {
    if (phone === "" && tabss == "sms") {
      setPhoneErrFlag(true)
      return false
    }
    if (email === "" && tabss == "email") {
      setemailErrFlag(true)
      return false
    }
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (tabss == "email" && !re.test(email)) {
      setemailErrFlag(true)
      return false
    }
    if (emailboth === "" && phoneboth === "" && tabss == "both") {
      setBothEmailErrFlag(true);
      setBothPhoneErrFlag(true);
      return false
    }
    if (emailboth === "" && tabss == "both") {
      setBothEmailErrFlag(true);
      return false
    }

    if (tabss == "both" && !re.test(emailboth)) {
      setBothEmailErrFlag(true)
      return false
    }

    if (phoneboth === "" && tabss == "both") {
      setBothPhoneErrFlag(true);
      return false
    }

    let user = localStorage.getItem("user");
    let usr = JSON.parse(user);


    let obj = {};
    if (tabss === "email") {
      obj = {
        email: email,
        form: localStorage.getItem("from"),
        to: localStorage.getItem("to"),
        user_id: usr?.userInfo?.user_id,
        date: localStorage.getItem("date")
      }
    }
    if (tabss == "sms") {
      obj = {
        phone: phone,
        form: localStorage.getItem("from"),
        to: localStorage.getItem("to"),
        user_id: usr?.userInfo?.user_id,
        date: localStorage.getItem("date")
      }
    }
    if (tabss === "both") {
      obj = {
        email: emailboth,
        phone: phoneboth,
        form: localStorage.getItem("from"),
        to: localStorage.getItem("to"),
        user_id: usr?.userInfo?.user_id,
        date: localStorage.getItem("date")
      }
    }
    AUTENTICATE_API?.notificationSave(obj).then(res => {
      if (res && res?.status === 200) {
        toast.success("Informationen erfolgreich gespeichert", { autoClose: 1500 });
        setTimeout(() => {
          setEmail("");
          setPhone("");
          setEmailBoth("");
          setPhoneBoth("");
          navi('/SuccesfullNotificationSubmission');
        }, 2500);
      }
    });

  }
  return <React.Fragment>
    <Header />
    <div id="ab">
      <div className="container notification-sec mb-5 pb-5">
        <div className="row mt-lg-5 pt-3">
          <div className="col-lg-3 hamburger-sec">
            {/* <SideBar title="profile" /> */}
            <nav class="navbar navbar-expand-lg navbar-light bg-white">
              <div class="container-fluid justify-content-end px-lg-0">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse mt-3 mt-lg-0" id="navbarSupportedContent">
                  <SideBar title="notification" />
                </div>
              </div>
            </nav>
          </div>
          <div className="col-lg-9">
            <div className="container">
              <div style={{ fontSize: 24, fontWeight: 600 }} >Benachrichtigungen verwalten</div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-12 mt-3">
                  <label className="pro-label">Benachrichtigungs-Email</label>
                  <div className="form-wrap d-flex">
                    <input disabled type="text" style={{ background: "rgb(237, 240, 247)" }} value={(JSON.parse(localStorage.getItem("user"))?.userInfo?.email)} className="form-control shadow-none profile-imput" placeholder="Benachrichtigungs-Email" />
                    <div style={{ height: '24px', width: '24px', position: 'relative', top: 7, left: '-30px' }} />
                  </div>
                </div>
                <div className="col-lg-6 col-12 mt-3">
                  <label className="pro-label">Benachrichtigungs-Telefonnummer</label>
                  <div className="form-wrap d-flex">
                    <input disabled style={{ background: "rgb(237, 240, 247)" }} type="text" value={(JSON.parse(localStorage.getItem("user"))?.userInfo?.phone_no)}
                      className="form-control shadow-none profile-imput" placeholder="Benachrichtigungs-Telefonnummer" />
                    <div style={{ height: '24px', width: '24px', position: 'relative', top: 7, left: '-30px' }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="container mt-3">
              <div style={{ fontSize: 24, fontWeight: 600 }} >Email Benachrichtigungen</div>
            </div>
            <div className="container mt-2">
              <div className="d-flex p-4 justify-content-between" style={{ background: "#EDF0F7", borderRadius: 24, height: 82 }}>
                <div className="my-auto" style={{ color: "#000", fontSize: 18, fontWeight: 700 }}>
                  Neue Nachricht erhalten
                </div>
                <div className="p-0">
                  <input className='check' defaultChecked={true} type="checkbox" id="notification" name="vehicle1" value="Bike" />
                </div>
              </div>
              <div className="d-flex p-4 justify-content-between mt-4" style={{ background: "#EDF0F7", borderRadius: 24, height: 82 }}>
                <div className="my-auto" style={{ color: "#000", fontSize: 18, fontWeight: 700 }}>
                  Neue Buchungsanfrage erhalten
                </div>
                <div className="p-0">
                  <input className='check' defaultChecked={true} type="checkbox" id="notification1" name="vehicle2" value="Bike" />
                </div>
              </div>
            </div>
          
            <div className="container mt-5 pb-5 mb-5">
              <button style={{ fontSize: 16, color: "#FFFFFF", background: '#25A9E0', borderRadius: 8, border: "1px solid #25A9E0", paddingLeft: 25, paddingRight: 25, paddingTop: 8, paddingBottom: 8 }}>Änderungen speichern</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <Footer/> */}

  </React.Fragment>
}
export default Notification;