import React from 'react'
import Successful from '../../../assets/Forgot-Successful.png'
import { Link } from "react-router-dom";
import Header from '../../../common/zuruckheader';

export default function ForgotPasswordSuccessful() {
    
  return (
    <>
    <Header/>
    <div className='container'>
        <div className='row justify-content-center mt-md-5 pt-5'>
            <div className='col-lg-4 col-md-10 col-12'>
                <div className='d-flex justify-content-center mt-4  mx-5'>
                    <img className='px-5' src={Successful} width={'100%'}/>
                </div>
                <div className='text-center' style={{fontSize:40,fontWeight:600,lineHeight:'1.2'}}>Passwort erfolgreich geändert</div>
                <div className='text-center px-3 mt-4' style={{fontSize:22,color:'#717D96'}}>
                    Merken Sie sich immer Ihre Daten für Ihr WarumLeer-Account.
                </div>
                <div className='d-flex justify-content-center mt-4 mb-5'>
                    <button type="button" class="btn btn-primary px-3 py-2 my-auto w-100" style={{backgroundColor:'#25A9E0',border:'none',fontSize:16,marginRight:20}}><Link to='/Login' style={{textDecoration:'none',color:'#fff'}}> Weiter </Link></button>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
