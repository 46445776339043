import React from 'react'
import Successful from '../../../assets/Trip Publish.jpg'
import { Link, useNavigate } from "react-router-dom";
import Header from '../../../common/zuruckheader';
import Footer from '../../../common/footer';

export default function TripSuccessfullyPublished() {
    const navigate = useNavigate();
    const handlePublishedTrip=()=>{
        navigate('/publishedtrips')
    }
  return (
    <>
    {/* <Header/> */}
    <div className='container pb-5'>
        <div className='row justify-content-center mt-md-3 pt-3'>
            <div className='col-xl-6 col-lg-8 col-md-10 col-12'>
                <div className='text-center' style={{fontSize:32,fontWeight:600}}>Fahrt erfolgreich veröffentlich!</div>
                <div className='d-flex justify-content-center mt-4 px-5 mx-lg-5'>
                    <img src={Successful} width={'100%'}/>
                </div>
                <div className='text-center px-3 mt-4' style={{fontSize:22,color:'#717D96'}}>
                    Wu-Huu! Ihre Fahrt ist jetzt auf <span style={{color:'#25A9E0',fontWeight:'bold'}}>WarumLeer</span> verfügbar.<br className='d-none d-lg-block'/> Suchende können Sie nun finden und kontaktieren.
                </div>
                <div className='text-center px-3 mt-3' style={{fontSize:22,color:'#717D96'}}>
                    Viel Spaß und gute Fahrt!
                </div>
                <div className='d-flex justify-content-center my-4'>
                    <button type="button" class="btn btn-primary px-3 py-2 my-auto" style={{backgroundColor:'#25A9E0',border:'none',fontSize:16,marginLeft:20}}><Link to={'/'} style={{textDecoration:'none',color:'#fff'}}>Zurück zur Startseite</Link></button>
                    <button type="button" onClick={handlePublishedTrip} class="btn btn-outline-secondary mx-3 px-3 py-2 my-auto" style={{color:'#25A9E0',fontSize:16}} >Fahrt verwalten</button>
                </div>
            </div>
        </div>
    </div>
    {/* <Footer/> */}
    </>
  )
}
