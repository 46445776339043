import React, { useState, useEffect, useRef } from "react";
import "../HomePage/HomePage.css";
import Route from "../../../assets/routing.png";
import Calendar from "../../../assets/calendar-2.png";
import ContactImg from '../../../assets/contact-us-icon.png'
import LocationIcon from "../../../assets/location.png";
import User from "../../../assets/Avatar2.png";
import star from "../../../assets/images/star-blue.png";
import Header from "../../../common/header";
import CircleIcon from '../../../assets/delete-icon.png'
import spinImg from "../../../assets/images/loading.gif"
import { useLocation } from "react-router-dom";
import { AUTENTICATE_API } from "../../../services/Api/authenticationApi";
import { EDITPROFILE_API } from "../../../services/Api/editProfileApi";
import LocationRed from "../../../assets/images/locationRed.png";
import RouteRed from "../../../assets/images/routingRed.png";
import { useNavigate } from "react-router-dom";
import Footer from "../../../common/footer";
import axios from "axios"; // Import Axios
import CalendarReact from "react-calendar";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import toast from "react-hot-toast";
import moment from "moment";
export default function ListingPage({ rider }) {
  // const [data, set_data] = useState([])
  const location = useLocation();
  const [showCalendar, setShowCalendar] = useState(false);
  const [av, setAv] = useState(false); // State variable to toggle calendar visibility
  const [calendarDate, setCalendarDate] = useState("");
  const navigate = useNavigate();
  const { location: loc, arrivalLocation, date,data} = location.state || {};
  let [departureLocation, setDepartureLocation] = useState(loc || "");
  let [deptt, setDeppttt] = useState(loc || "");
  const [departurepointt, setDepartureponitt] = useState();
  const [arrivalPointt, setArrivalPonitt] = useState();
  let [arrivalLocationValue, setArrivalLocationValue] = useState(arrivalLocation || "");
  let [Arriii, setArriii] = useState(arrivalLocation || "");
  const [arrivalLocationSelectFlage, setarrivalLocationSelectFlage] = useState(false);
  const [departLocationSelectFlage, setdepartLocationSelectFlage] = useState(false);
  const [dateValue, setDateValue] = useState(date || "");
  const [uploadComplete, setUploadComplete] = useState(false);
  const [checkValue, setCheckValue] = useState(false);
  // debugger

  const [valueDate, setValueDate] = useState();
  const [departureLocationSelect, setDepartureLocationSelect] = useState("");
  const [arrivalLocationSelect, setArrivalLocationSelect] = useState(""); // State variable for Arrival location
  const [riderList, setRiderList] = useState("");
  const [userId, setUserId] = useState(0);
  const [profileUrl, setUrl] = useState("");
  const [subsciber, setSubscriber] = useState("");
  let [radius_50_km, set_radius_50_km] = useState("")
  let [arrival_50_km, set_arrival_50_km] = useState("")
  let [all_data, set_all_data] = useState([])
  let [stopss, setTotalStops] = useState([])
  const changeDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const finalDate = `${day}.${month}.${year}`;
    setDateValue(finalDate);
    // setDateValue(urlArrival);
    
    setCalendarDate(date);
  };
  const handleChange = (address) => {
    setdepartLocationSelectFlage(false)
    setDepartureLocation(address);
    setDeppttt(address)
    setDepartureLocationSelect("");
  };
  const handleSelect = async (address) => {
    const shortWithEllipsis = (text, maxLength) => {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
      }
      return text;
    };
    const shortValue = shortWithEllipsis(address, 20);
    setdepartLocationSelectFlage(false)
    setDepartureLocationSelect(address);
    setDepartureLocation(address);
    setDeppttt(shortValue)
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
    } catch (error) {
      console.error("Error fetching geolocation:", error);
    }

  };
  const handleArrivalSelect = async (address) => {
    setArrivalLocationSelect(address);
    const shortWithEllipsisArrival = (text, maxLength) => {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
      }
      return text;
    };
    const shortValue = shortWithEllipsisArrival(address, 20);
    

    setArriii(shortValue);
   
    setArrivalLocationValue(address);

    setarrivalLocationSelectFlage(false)
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
    } catch (error) {
      console.error("Error fetching geolocation:", error);
    }
  };
  const handleArrivalChange = (address) => {
    
  
    setArrivalLocationValue(address);
    setArriii(address)
    return;
    setarrivalLocationSelectFlage(false)
    setArrivalLocationSelect("")
  };
  const handleOutsideClick = (e) => {
    if (showCalendar && !e.target.closest('.calendar-component')) {
      setShowCalendar(false);
      setAv(false);
    }
  };

  const handleCalendarClick = () => {
    setAv(true);
    setShowCalendar(!showCalendar); 
  }; 
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errr, setErrr] = useState("");
  const [open, setOpen] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false)
  const [contactBack, setContactBack] = useState(0);
  const [urlDerture, setUrlDeparture] = useState("");
  const [urlArrival, setUrlArrival] = useState("");
  const [urlDate, setUrlDate] = useState("");
  const onSumbit = () => {
    if (name == "" && email == "" && message == "") {
      setErrr("Bitte alle Pflichtfelder ausfÃ¼llen!");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return false;
    }
    else if (name == "") {
      setErrr("Bitte geben Sie Ihren Namen ein")
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return false;
    }
    const emailReg =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email.match(emailReg)) {
      setErrr("Bitte eine gÃ¼ltige E-Mail Adresse eingeben");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return false;
    }
    else if (phone == "") {
      setErrr("Bitte Nummer eingeben")
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return false;
    }
    else if (phoneErr === true) {
      setErrr('Telefonnummer muss zwischen 8 und 14 Zeichen lang sein')
      setTimeout(() => {
        setErrr("");
        setPhoneErr(false)
      }, 3000);
      return false;
    }

    else if (message == "") {
      setErrr("Bitte geben Sie eine Nachricht ein")
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return false;
    }


    if (message?.length > 500) {
      setErrr("Die Nachricht sollte 500 Zeichen enthalten !!");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return false;
    }
    setOpen(false);


    https: axios
      .post("https://api.warumleer.de/api/api/v1/contect_us", {
        Name: name,
        email_id: email,
        is_checked: contactBack,
        mobile_no: phone,
        description: message,
      })
      .then((res) => {
        if (res?.status == 201) {
          toast.success("Ihre Anfrage wurde Ã¼bermittelt", {
            autoClose: 2000,
          });

          setTimeout(() => {
            setEmail("");
            setName("");
            setMessage("");
            setPhone("");
            setContactBack("");
          }, 2500);
        }
      })
      .catch((err) => {
       
      });
  };

  const getProfileInfo = async (userId) => {
    try {
      const response = await EDITPROFILE_API.getProfileInfo(userId);
      if (response?.status === 200) {
        const profiledata = response.data[0] || [];
    
        setUrl(profiledata.image_url);
      } else {
        console.error('Error fetching profile data. Status:', response?.status);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };
  const [userInfo, setUserInfo] = useState("");
  

  async function getDistance(dateValue11, depart_from,arrive_location) {
    let str = [];
    let str2 = [];
    let aaa = [];
    let tempp = [];
    let obj_date = new Date();
  
    let response = await EDITPROFILE_API.trip_list_by_date({
      "pickup_date": dateValue11,
      "currentTime": (obj_date.getHours() < 10 ? "0" + obj_date.getHours() : obj_date.getHours()) + ":" + (obj_date.getMinutes() < 10 ? "0" + obj_date.getMinutes() : obj_date.getMinutes())
    });
    if (response?.status == 200) {
      aaa = response.data.slice();
      aaa.map((val, i) => {
           {
          tempp.push(val);
          str.push(val.depart_to); 
          str2.push(val.depart_from)     
        }
      });
    }
  
    if (str.length === 0) {
      return [];
    }
    
    let distance_50=[]
    
    try {
      let str_len=str.length/23
      if(str.length%23!=0){
        str_len++
      }
      let aaa_tmp=[]
      for(let a_=1;a_<=str_len;a_++){
        aaa_tmp=aaa.slice((a_-1)*23,((a_-1)*23)+23)
  
        
       let tmx_str= str.slice((a_-1)*23,((a_-1)*23)+23)
      
      
      
      let distanceService = new window.google.maps.DistanceMatrixService();
      let distanceMatrixResponse = await new Promise((resolve, reject) => {
        distanceService.getDistanceMatrix({
          origins: [depart_from],
          destinations: tmx_str,
          travelMode: window.google.maps.TravelMode.WALKING,
          unitSystem: window.google.maps.UnitSystem.METRIC,
          durationInTraffic: true,
          avoidHighways: false,
          avoidTolls: false
        }, (response, status) => {
         
          if (status === "OK") {
           
            resolve(response);
            return;
          } else {
            // reject(`Error`:${status});
           
          }
        });
      });
      
      
     
 
  const distancesWithin50Km = distanceMatrixResponse.rows[0].elements
  .map((element, i) => {
  // Check if the distance is in kilometers
  if (element?.distance?.text?.includes("km")) {
    // Extract the distance value as a number
    const distanceValue = parseFloat(element.distance.text.replace(",", ".").split(" ")[0]);
    // Check if the distance is less than or equal to 50 km
    if (distanceValue <= 100) {
     return  aaa_tmp[i];
    }
  }
    else if(element?.distance?.text?.includes("m")){
    return aaa_tmp[i]
  }
  return null; // If distance is not in kilometers or exceeds 50 km, return null
  })
  .filter(Boolean); // Remove null values from the array
  distance_50=[...distance_50,...distancesWithin50Km]
  
  }
  
   
  sessionStorage.setItem("from_50",JSON.stringify({data:distance_50}))
  
  
  return distance_50;
  
    } catch (error) {
      console.error("Error in getDistance:", error);
      return [];
    }
  }
  
  async function getDistance1(dateValue11, deport_from, arrive_location) {
    let str = [];
    let str2 = [];
    let index = [];
    let tempp = [];
    let aaa=[]
  
    // Fetch trip data from API
    let obj_date = new Date();
    let response = await EDITPROFILE_API.trip_list_by_date({
      "pickup_date": dateValue11,
      "currentTime": (obj_date.getHours() < 10 ? "0" + obj_date.getHours() : obj_date.getHours()) + ":" + (obj_date.getMinutes() < 10 ? "0" + obj_date.getMinutes() : obj_date.getMinutes())
    });
   
    if (response?.status == 200) {
     aaa = response.data.slice();
  
      aaa.map((val) => {
     
           {
          tempp.push(val);
          str.push(val.depart_from);
         
        }
      });
    
    }
    
    if (str.length === 0) {
      return [];
    }
    
    let distance_50_1=[]
  
    // if (str.length === 0) {
    //   check_fun();
     
    //   return [];
    // }
  
  
  try {
  let str_len=str.length/23
  if(str.length%23!=0){
    str_len++
  }
  let aaa_tmp=[]
  for(let a_=1;a_<=str_len;a_++){
    aaa_tmp=aaa.slice((a_-1)*23,((a_-1)*23)+23)
    let tmx_str= str.slice((a_-1)*23,((a_-1)*23)+23)
  
  let distanceService = new window.google.maps.DistanceMatrixService();
  let distanceMatrixResponse = await new Promise((resolve, reject) => {
    distanceService.getDistanceMatrix({
      origins: [arrive_location],
      // destinations: str,
      destinations:tmx_str,
      travelMode: window.google.maps.TravelMode.WALKING,
      unitSystem: window.google.maps.UnitSystem.METRIC,
      durationInTraffic: true,
      avoidHighways: false,
      avoidTolls: false
    }, (response, status) => {
      if (status === "OK") {
        
        resolve(response);
      } else {
        // reject(`Error: ${status}`);
        
      }
    });
  });
  
  

  
  const addressesWithin50Km = distanceMatrixResponse.rows[0].elements.map((element, i) => {
  
    if (element?.distance?.text?.includes("km") ) {
      const distanceValue = parseFloat(element.distance.text.replace(",", ".").split(" ")[0]);
      if (distanceValue <= 100) {
       
        return aaa_tmp[i];
      }
    }
    else if(element?.distance?.text?.includes("m")){
     
  
      return aaa_tmp[i]
    }
  })
  .filter(Boolean); // Remove null values from the array
  
   distance_50_1=[...distance_50_1,...addressesWithin50Km]
  
  }
  
  sessionStorage.setItem("arr_50",JSON.stringify({data:distance_50_1}))
  
  
  // check_fun()
  
  return distance_50_1;
  
          } catch (error) {
        console.error("Error in getDistance:", error);
        return [];
      }
  
  
  
  }
  




  const formatDate = (date) => {
    let dt = date;
    return date;
  };
  const sort_fun = (temp____arra, type) => {
    let temp_store = temp____arra.slice();
    for (let aa = 0; aa < temp_store.length; aa++) {
      for (let bb = aa + 1; bb < temp_store.length; bb++) {
        if (temp_store[aa].pd_id == temp_store[bb].pd_id) {
          temp_store.splice(bb, 1)
        }
      }
    }
    switch (type) {
      case 1:
        for (let i = 0; i < temp_store.length; i++) {
          let datetmp = [...temp_store[i].pickup_date.split(".").slice(), ...temp_store[i].pickup_time.split(":").slice()]
          let tmppp = datetmp[0]
          datetmp[0] = datetmp[2]
          datetmp[2] = tmppp
          datetmp.push("00")
          datetmp = new Date(Number(datetmp[0]), Number(datetmp[1]) + 1, Number(datetmp[2]), Number(datetmp[3]), Number(datetmp[4]), Number(datetmp[5]))


          for (let j = i + 1; j < temp_store.length; j++) {
            let datetmp2 = [...temp_store[j].pickup_date.split(".").slice(), ...temp_store[j].pickup_time.split(":").slice()]
            let tmppp = datetmp2[0]
            datetmp2[0] = datetmp2[2]
            datetmp2[2] = tmppp
            datetmp2.push("00")
            datetmp2 = new Date(Number(datetmp2[0]), Number(datetmp2[1]) + 1, Number(datetmp2[2]), Number(datetmp2[3]), Number(datetmp2[4]), Number(datetmp2[5]))
            if (datetmp.getTime() >= datetmp2.getTime()) {
              let temp__________ = temp_store[i];
              temp_store[i] = temp_store[j];
              temp_store[j] = temp__________
            }
          }
        }
        break;
      case 2:
        for (let i = 0; i < temp_store.length; i++) {
          let datetmp = [...temp_store[i].pickup_date.split(".").slice(), ...temp_store[i].pickup_time.split(":").slice()]
          let tmppp = datetmp[0]
          datetmp[0] = datetmp[2]
          datetmp[2] = tmppp
          datetmp.push("00")
          datetmp = new Date(Number(datetmp[0]), Number(datetmp[1]) + 1, Number(datetmp[2]), Number(datetmp[3]), Number(datetmp[4]), Number(datetmp[5]))
          let datetmp22 = [...temp_store[i].arrival_date.split(".").slice(), ...temp_store[i].arrival_time.split(":").slice()]
          tmppp = datetmp22[0]
          datetmp22[0] = datetmp22[2]
          datetmp22[2] = tmppp
          datetmp22.push("00")
          datetmp22 = new Date(Number(datetmp22[0]), Number(datetmp22[1]) + 1, Number(datetmp22[2]), Number(datetmp22[3]), Number(datetmp22[4]), Number(datetmp22[5]))
          let temp1 = datetmp22.getTime() - datetmp.getTime()
          for (let j = i + 1; j < temp_store.length; j++) {
            let datetmp = [...temp_store[j].pickup_date.split(".").slice(), ...temp_store[j].pickup_time.split(":").slice()]
            let tmppp = datetmp[0]
            datetmp[0] = datetmp[2]
            datetmp[2] = tmppp
            datetmp.push("00")
            datetmp = new Date(Number(datetmp[0]), Number(datetmp[1]) + 1, Number(datetmp[2]), Number(datetmp[3]), Number(datetmp[4]), Number(datetmp[5]))
            let datetmp22 = [...temp_store[j].arrival_date.split(".").slice(), ...temp_store[j].arrival_time.split(":").slice()]
            tmppp = datetmp22[0]
            datetmp22[0] = datetmp22[2]
            datetmp22[2] = tmppp
            datetmp22.push("00")
            datetmp22 = new Date(Number(datetmp22[0]), Number(datetmp22[1]) + 1, Number(datetmp22[2]), Number(datetmp22[3]), Number(datetmp22[4]), Number(datetmp22[5]))
            let temp2 = datetmp22.getTime() - datetmp.getTime()
            if (temp2 <= temp1) {
              let temp__________ = temp_store[i];
              temp_store[i] = temp_store[j];
              temp_store[j] = temp__________
            }
          }
        }
        break;
      case 3:
        for (let i = 0; i < temp_store.length; i++) {
          if (temp_store[i].avarge_rating == null) {
            temp_store[i].avarge_rating = "5"
          }
          for (let j = i + 1; j < temp_store.length; j++) {
            if (temp_store[j].avarge_rating == null) {
              temp_store[j].avarge_rating = "5"
            }
            if (parseFloat(temp_store[i].avarge_rating) <= parseFloat(temp_store[j].avarge_rating)) {
              let temp__________ = temp_store[i];
              temp_store[i] = temp_store[j];
              temp_store[j] = temp__________
            }
          }
        }
        break;
      default:
    }
    return temp_store;
  }
  const fetchData = async () => {
    let user = localStorage.getItem("user");
    if (user) {
      let usr = JSON.parse(user);
      let id = usr.userInfo.user_id;
      let is_subscribe = usr.userInfo.is_subscribe;
      setSubscriber(is_subscribe);
      setUserId(id);
      await getProfileInfo(id);
    }
  };
  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      let usr = JSON.parse(user);
      setUserInfo(usr);
    } else {
      setUserInfo("");
    }
    fetchData();
    const { location: loc, arrivalLocation, date } = location.state || {};
    setArrivalLocationSelect(arrivalLocation || "");
    setDepartureLocationSelect(loc || "")
//  arrivalLocationValue=arrivalLocation
// departureLocation=loc
   
  }, []);
  useEffect(() => {
    if (all_data.length > 0) {
      setRiderList(sort_fun([...all_data], 1))
    }
  }, [all_data])


  const get_trip_def = async () => {
    setUploadComplete(true);
    setDepartureponitt(departureLocation);
    setArrivalPonitt(arrivalLocationValue);
    setValueDate(dateValue);
      try {
        
          const response = await AUTENTICATE_API.getRiderInformation({
              depart_from: arrivalLocationValue,
              depart_to: departureLocation,
              pickup_date: dateValue,
          });
    // console.log({
    //   depart_from: arrivalLocationValue,
    //   depart_to: departureLocation,
    //   pickup_date: dateValue,
    // },response)
    
    
    
         
    
          if (response?.status === 200 && response?.count>0) {
          //   const [departure50km, arrival50km] = await Promise.all([
          //     getDistance(dateValue, departureLocation, arrivalLocationValue).then(data => data || []),
          //     getDistance1(dateValue, departureLocation, arrivalLocationValue).then(data => data || [])
          // ]);
    
          
          // let res___=await AUTENTICATE_API.lat_lng([departureLocation,arrivalLocationValue])
          // let tmp_____=await AUTENTICATE_API.getDistance(formatDate(date),[res___[0].start_location,res___[0].end_location])
          // check_fun(res___________)
          //    let tmp_____=[]
          //    departure50km.map((val)=>{
          //     arrival50km.map((val1)=>{
          //       if(val.pd_id==val1.pd_id){
          //         tmp_____.push(val)
          //       }
    
          //     })
    
          //    })
    
    
              const selectedDateData = response.data.filter(item => item.pickup_date === dateValue) || [];
              const combinedData = [...selectedDateData];
    
              // Filter out undefined elements
              const filteredData = combinedData.filter(item => item !== undefined);
    
              // Remove duplicates based on the pd_id field
              const uniqueData = Array.from(new Set(filteredData.map(item => item.pd_id))).map(pd_id => {
                  return filteredData.find(item => item.pd_id === pd_id);
              });
    
              // Sort data if needed, e.g., by date
              const sortedData = sort_fun(uniqueData, 1);
          
    if(sortedData.length<=0){
      navigate("/ListingPageNoResults", {
        state: {
          location: departureLocationSelect,
          arrivalLocation: arrivalLocationSelect,
          date: formatDate(date),
        },
      });
    }
    else{
      setUploadComplete(false)
              set_all_data(sortedData);
              const stopsCounts = sortedData.map(item => {
                const stopsArray = JSON.parse(item.no_of_stop);
                setTotalStops(stopsArray.length)
                return stopsArray.length;
                
            });
          }
          } else {
            setUploadComplete(false)
            // let res___=await AUTENTICATE_API.lat_lng([departureLocation,arrivalLocationValue])
            // let tmp_____=await AUTENTICATE_API.getDistance(formatDate(date),[res___[0].start_location,res___[0].end_location])
    
    
              const selectedDateData = response.data.filter(item => item.pickup_date === dateValue) || [];
              const combinedData = [...selectedDateData];
    
              // Filter out undefined elements
              const filteredData = combinedData.filter(item => item !== undefined);
    
              // Remove duplicates based on the pd_id field
              const uniqueData = Array.from(new Set(filteredData.map(item => item.pd_id))).map(pd_id => {
                  return filteredData.find(item => item.pd_id === pd_id);
              });
    
              // Sort data if needed, e.g., by date
              const sortedData = sort_fun(uniqueData, 1);
          
    if(sortedData.length<=0){
      navigate("/ListingPageNoResults", {
        state: {
          location: departureLocationSelect,
          arrivalLocation: arrivalLocationSelect,
          date: formatDate(date),
        },
      });
    }
    else{
      setUploadComplete(false)
              set_all_data(sortedData);
              const stopsCounts = sortedData.map(item => {
                const stopsArray = JSON.parse(item.no_of_stop);
                setTotalStops(stopsArray.length)
                return stopsArray.length;
                
            });
          }
    
            // return 
            // navigate("/ListingPageNoResults", {
            //   state: {
            //     location: departureLocationSelect,
            //     arrivalLocation: arrivalLocationSelect,
            //     date: formatDate(date),
            //   },
            // });
              console.error("Failed to fetch rider information:", response);
          }
      } catch (error) {
          console.error("Error fetching trip data:", error);
      }
    };



// const get_trip_def = async () => {
// setDepartureponitt(departureLocation);
// setArrivalPonitt(arrivalLocationValue);
// setValueDate(dateValue);
//   try {
    
//       const response = await AUTENTICATE_API.getRiderInformation({
//           depart_from: arrivalLocationValue,
//           depart_to: departureLocation,
//           pickup_date: dateValue,
//       });



     

//       if (response?.status === 200 && response?.count>0) {
//         const [departure50km, arrival50km] = await Promise.all([
//           getDistance(dateValue, departureLocation, arrivalLocationValue).then(data => data || []),
//           getDistance1(dateValue, departureLocation, arrivalLocationValue).then(data => data || [])
//       ]);
//          let tmp_____=[]
//          departure50km.map((val)=>{
//           arrival50km.map((val1)=>{
//             if(val.pd_id==val1.pd_id){
//               tmp_____.push(val)
//             }

//           })

//          })


//           const selectedDateData = response.data.filter(item => item.pickup_date === dateValue) || [];
//           const combinedData = [...selectedDateData, ...tmp_____];

//           // Filter out undefined elements
//           const filteredData = combinedData.filter(item => item !== undefined);

//           // Remove duplicates based on the pd_id field
//           const uniqueData = Array.from(new Set(filteredData.map(item => item.pd_id))).map(pd_id => {
//               return filteredData.find(item => item.pd_id === pd_id);
//           });

//           // Sort data if needed, e.g., by date
//           const sortedData = sort_fun(uniqueData, 1);
      
// if(sortedData.length<=0){
//   navigate("/ListingPageNoResults", {
//     state: {
//       location: departureLocationSelect,
//       arrivalLocation: arrivalLocationSelect,
//       date: formatDate(date),
//     },
//   });
// }
// else{
//           set_all_data(sortedData);
//           const stopsCounts = sortedData.map(item => {
//             const stopsArray = JSON.parse(item.no_of_stop);
//             setTotalStops(stopsArray.length)
//             return stopsArray.length;
            
//         });
//       }
//       } else {
//         const [departure50km, arrival50km] = await Promise.all([
//           getDistance(dateValue, departureLocation, arrivalLocationValue).then(data => data || []),
//           getDistance1(dateValue, departureLocation, arrivalLocationValue).then(data => data || [])
//       ]);
//          let tmp_____=[]
//          departure50km.map((val)=>{
//           arrival50km.map((val1)=>{
//             if(val.pd_id==val1.pd_id){
//               tmp_____.push(val)
//             }

//           })

//          })


//           const selectedDateData = response.data.filter(item => item.pickup_date === dateValue) || [];
//           const combinedData = [...selectedDateData, ...tmp_____];

//           // Filter out undefined elements
//           const filteredData = combinedData.filter(item => item !== undefined);

//           // Remove duplicates based on the pd_id field
//           const uniqueData = Array.from(new Set(filteredData.map(item => item.pd_id))).map(pd_id => {
//               return filteredData.find(item => item.pd_id === pd_id);
//           });

//           // Sort data if needed, e.g., by date
//           const sortedData = sort_fun(uniqueData, 1);
      
// if(sortedData.length<=0){
//   navigate("/ListingPageNoResults", {
//     state: {
//       location: departureLocationSelect,
//       arrivalLocation: arrivalLocationSelect,
//       date: formatDate(date),
//     },
//   });
// }
// else{
//           set_all_data(sortedData);
//           const stopsCounts = sortedData.map(item => {
//             const stopsArray = JSON.parse(item.no_of_stop);
//             setTotalStops(stopsArray.length)
//             return stopsArray.length;
            
//         });
//       }

//         // return 
//         // navigate("/ListingPageNoResults", {
//         //   state: {
//         //     location: departureLocationSelect,
//         //     arrivalLocation: arrivalLocationSelect,
//         //     date: formatDate(date),
//         //   },
//         // });
//           console.error("Failed to fetch rider information:", response);
//       }
//   } catch (error) {
//       console.error("Error fetching trip data:", error);
//   }
// };

  
  useEffect(() => {
    const getQueryParamValue = (key) => {
      const urlParams = new URLSearchParams(window.location.search);
      
      return urlParams.get(key);
  };
  const fetchedDepartTo = getQueryParamValue('depart_to');
  const fetchedDepartFrom = getQueryParamValue('depart_from');
  const fetchedPickupDate = String( getQueryParamValue('pickup_date'));
  setUrlArrival(fetchedPickupDate)
  // Parse the date string manually
const parts = fetchedPickupDate.split('.');
const day = parseInt(parts[0], 10);
const month = parseInt(parts[1], 10) - 1; 
const year = parseInt(parts[2], 10);
const dateObject = new Date(year, month, day);
if (isNaN(dateObject.getTime())) {
    // console.error("Invalid date format:", fetchedPickupDate);
} else {
    const formattedDate = `${(dateObject.getMonth() + 1).toString().padStart(2, '0')}/` +
                          `${dateObject.getDate().toString().padStart(2, '0')}/` +
                          `${dateObject.getFullYear().toString().slice(-2)}`;
    setUrlDate(formattedDate)
    
}
  

  if(fetchedDepartTo && fetchedDepartFrom && fetchedPickupDate){
  
  setDepartureLocation(fetchedDepartTo);
  setDeppttt(fetchedDepartTo)
  setArrivalLocationValue(fetchedDepartFrom);
  setArriii(fetchedDepartFrom)
  setCalendarDate(urlDate);
  setDateValue(fetchedPickupDate)
 setCheckValue(true);
  }
  if(checkValue===true){
    
    // get_trip_def()
  }
  if(departureLocation && arrivalLocationValue && dateValue){
  // get_trip_def()
  }
  
  
  
  }, [urlDate,setArrivalLocationSelect])


  useEffect(()=>{

set_all_data(data)


  },[])



  return (
    <>
      <Header />
      {/* <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDDSeDxQt83B_nmvItImVbS8f2gt_yfL5E&libraries=geometry&language=en&callback=initMap" async defer></script> */}
      <div className="mb-5" onClick={handleOutsideClick}>
        <div
          className="py-5 home-page"
          style={{ backgroundImage: "radial-gradient(#145D7B,#10475E)" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div
                className="col-lg-10 col-12 search-sec bg-white py-2"
                style={{
                  borderRadius: 16,
                  boxShadow: "rgba(0, 0, 0, 0.15) -0.05px 4.95px 5.6px",
                }}
              >
                <div className="d-lg-flex justify-content-center">
                  <PlacesAutocomplete
                    placeholder="Abfahrt"
                    value={deptt}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    searchOptions={{
                      types: ["(cities)"],
                      componentRestrictions: {
                        country: "DE",
                      },
                    }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <>
                        <div
                          className="inputWithIcon input-bar my-auto"
                          style={{ width: 390, borderRight: '1px solid rgb(160,171,192)' }}
                        >
                          <input
                            readOnly={departureLocationSelect != ""}
                            {...getInputProps({
                              id: "abfahrtInput",
                              placeholder: "Abfahrt",
                              className: "py-0 text-center text-lg-start",
                              style: { fontSize: 16, color: "#717D96" }, 
                            })}
                          />
                          {departureLocationSelect && (
                            <img className="cross" src={CircleIcon} onClick={() => {
                              setDepartureLocation('');
                              setDeppttt('')
                              setDepartureLocationSelect("");
                            }} width={"20px"} style={{ position: 'absolute', left: '340px' }} />
                          )}
                          <img className="loc-icon" src={departLocationSelectFlage == true ? LocationRed : LocationIcon} width={"20px"} />
                        </div>
                        <div className="dropdown mt-2">
                          {" "}
                          <div
                            className="dropdown-menu"
                            style={{
                              display:
                                suggestions.length > 0 ? "block" : "none",
                              left: "-343px",
                              top: "40px",
                            }}
                            aria-labelledby="dropdownMenuButton"
                          >
                            {loading ? <div>Laden...</div> : null}
                            {suggestions.map((suggestion, index) => (
                              <div
                                className="dropdown-item"
                                {...getSuggestionItemProps(suggestion, {
                                  key: index,
                                  style: {
                                    backgroundColor: suggestion.active
                                      ? "#41b6e6"
                                      : "#fff",
                                    borderBottom: "1px solid #ddd",
                                    padding: "8px",
                                  },
                                })}
                              >
                                {suggestion.description}
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                  </PlacesAutocomplete>
                  <PlacesAutocomplete
                    onChange={handleArrivalChange}
                    onSelect={handleArrivalSelect}
                    value={Arriii}
                    searchOptions={{
                      types: ["(cities)"],
                      componentRestrictions: {
                        country: "DE",
                      },
                    }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <>
                        <div
                          className="inputWithIcon input-bar  my-auto"
                          style={{ width: 390, borderRight: '1px solid rgb(160,171,192)' }}
                        >
                          <input
                            readOnly={arrivalLocationSelect != ""}
                            {...getInputProps({
                              id: "ankunftInput",
                              placeholder: "Ankunft",
                              className: "py-0 text-center text-lg-start",
                              style: { fontSize: 16, color: "#717D96" },
                            })}
                          />
                          {arrivalLocationSelect && (
                            <img className="cross" src={CircleIcon} onClick={() => {
                              setArrivalLocationValue('');
                              setArriii('');
                              setArrivalLocationSelect("");
                            }} width={"20px"} style={{ position: 'absolute', left: '340px' }} />
                          )}
                          <img className="loc-icon" src={arrivalLocationSelectFlage == true ? RouteRed : Route} width={"20px"} />
                        </div>

                        <div className="dropdown mt-2">
                          <div
                            className="dropdown-menu"
                            style={{
                              display:
                                suggestions.length > 0 ? "block" : "none",
                              left: "-330px",
                              top: "40px",
                            }}
                            aria-labelledby="dropdownMenuButton"
                          >
                            {loading ? <div>Laden...</div> : null}
                            {suggestions.map((suggestion, index) => (
                              <div
                                className="dropdown-item"
                                {...getSuggestionItemProps(suggestion, {
                                  key: index,
                                })}
                              >
                                {suggestion.description}
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                  </PlacesAutocomplete>
                  <div class="inputWithIcon">
                    <input
                      className="py-0 text-center text-lg-start"
                      type="text"
                      placeholder="Heute"
                      style={{ fontSize: 16, color: "#717D96" }}
                      onClick={handleCalendarClick}
                      readOnly
                      value={formatDate(calendarDate == "" ? date : moment(calendarDate).format("DD.MM.YYYY"))}
                    />
                    <img className="loc-icon" src={Calendar} width={"20px"} />
                  </div>
                  {(uploadComplete=== false)?
                  <div className="my-auto  text-center text-lg-start">
                    <button
                      type="button"
                      class="btn btn-primary w-100 w-lg-50"
                      style={{
                        backgroundColor: "#25A9E0",
                        border: "none",
                        fontSize: 16,
                        borderRadius: 8,
                      }}
                      onClick={get_trip_def}
                    >
                      Suchen
                    </button>
                  </div>
                  : <div className='d-flex'>
                  <button  
                     type="button"
                     class="btn btn-primary w-100 w-lg-50 d-flex  justify-content-center"style={{
                      backgroundColor: "#25A9E0",
                      border: "none",
                      fontSize: 16,
                      borderRadius: 8,
                    }}>
                <img className='my-auto' src={spinImg} width={20}   /> <div className="my-auto">&nbsp;&nbsp;&nbsp;Suchen</div>
                </button>
              </div>
}

                </div>
                {showCalendar && (
                  <div
                    className="calendar-sec"
                    style={{ marginLeft: '820px', position: 'absolute', marginTop: 13 }}
                  >
                    <CalendarReact
                      onChange={changeDate}
                      locale="de"
                      minDate={new Date()}
                      value={calendarDate}
                      className="calendar-component home-calander"
                    />
                  </div>
                )}

              </div>

            </div>
          </div>
        </div>
        {((riderList))?.length !== 0 && (
          <div className="container">
            <div className="row">
              <div className="col-12 my-5">
                <div style={{ fontSize: 40, fontWeight: 600 }}>

                              {departurepointt && arrivalPointt ? (
  <>{departurepointt.split(",")[0]} nach {arrivalPointt.split(",")[0]}</>
) : (
  <>{loc.split(",")[0]} nach {arrivalLocation.split(",")[0]}</>
)}

                </div>
              </div>
              <div className="col-lg-9">
                <div
                  style={{ fontSize: 32, fontWeight: 600, color: "#A0ABC0" }}
                >
                  Verfügbare Fahrten für {valueDate?valueDate:date}
                </div>
                <div className="row mb-5">
                  {((riderList))?.map((rider) => (
                    <div className="col-lg-4 col-md-6 col-12 mt-4" key={rider?.pd_id}>
                      <div
                        onClick={() => {
                          if (userId==0) {
                          
                            navigate("/login");
                          }
                         else  if (subsciber == 1) {
                           
                            navigate(`/ListingDetailPage?pdId=${rider?.pd_id}`);
                          }
                        
                            else {
                          
                           navigate("/Subscription");
                          }
                        }}
                        className="p-4"
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          borderRadius: 24,
                        }}
                      >
                        <div className="row">
                          <div className="col-6">
                            <div
                              className="mt-1"
                              style={{
                                color: "#000",
                                fontSize: 16,
                                fontWeight: 700,
                              }}
                            >
                              Abfahrt
                            </div>
                            <div
                              className="mt-1"
                              style={{
                                color: "#4A5468",
                                fontSize: 14,
                              }}
                            >
                              {rider?.pickup_date}
                            </div>
                            <div
                              className="mt-1"
                              style={{
                                color: "#4A5468",
                                fontSize: 14,
                                fontWeight: 700,
                              }}
                            >
                              {rider?.pickup_time}
                            </div>
                            <div
                              className="mt-1"
                              style={{ color: "#4A5468", fontSize: 14 }}
                            >
                              {rider?.depart_to}
                            </div>
                            {/* */}
                          </div>
                          <div
                            className="col-6"
                            style={{ borderLeft: "0.1rem solid #A0ABC0" }}
                          >
                            <div
                              className="mt-1"
                              style={{
                                color: "#000",
                                fontSize: 16,
                                fontWeight: 700,
                              }}
                            >
                              Ankunft
                            </div>

                            <div
                              className="mt-1"
                              style={{
                                color: "#4A5468",
                                fontSize: 14,
                              }}
                            >
                              {rider?.arrival_date}
                              { }
                            </div>
                            <div
                              className="mt-1"
                              style={{
                                color: "#4A5468",
                                fontSize: 14,
                                fontWeight: 700,
                              }}
                            >
                              {rider?.arrival_time}
                            </div>

                            <div
                              className="mt-1"
                              style={{ color: "#4A5468", fontSize: 14 }}
                            >
                              {rider?.depart_from}
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="row mt-3">
                              <div className="col-lg-12">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex">
                                    <div className="my-auto">
                                      {rider?.image_url && subsciber==1 ? (
                                        <img
                                          src={rider.image_url}
                                          style={{
                                            width: 48,
                                            height: 48,
                                            borderRadius: 50,
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={User}
                                          style={{
                                            width: 48,
                                            height: 48,
                                            borderRadius: 50,
                                          }}
                                        />
                                      )}
                                    </div>

                                    <div style={{ marginLeft: 15 }} className="my-auto ">
                                      <div
                                        style={{
                                          color: "#000",
                                          fontSize: 16,
                                          fontWeight: 700,
                                          textTransform: 'capitalize',
                                          marginLeft:3,
                                        }}
                                      >
                                        {subsciber==1?rider?.Name ? rider?.Name : rider?.name ? rider?.name : "":"****** ******"}
                                      </div>
                                      <div className="d-flex">
                                        <img
                                          src={star}
                                          style={{ width: 24, height: 24 }}
                                          alt="Star"
                                        />
                                        <div style={{marginLeft:4,marginTop:4}}>{rider?.avarge_rating ? parseFloat(rider?.avarge_rating)?.toFixed(1) : "5.0"}/5.0</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="d-flex justify-content-center">
                                      <div className="text-center" style={{backgroundColor:'#25a9e0',color:'#fff',borderRadius:50, width:40,height:40}}><span style={{position:'relative',top:9}}>
                                      {JSON.parse(rider?.no_of_stop).length}</span>
                                      </div>
                                    </div>
                                      <div className="justify-content-center d-flex">
  {JSON.parse(rider?.no_of_stop).length === 0|| JSON.parse(rider?.no_of_stop).length >1 ? " Stopps" : JSON.parse(rider?.no_of_stop).length == 1 ? " Stopp" : ""}
                                      </div>
                                  </div>



                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>


              </div>

            </div>

          </div>

        )}

       
      </div>
      {/* <Footer/> */}
    </>
  );
}