import React, { useState, useEffect } from "react";
import './add-wehicle.css'
import SideBar from "../../../common/sidebar/side";
import back from "../../../assets/back.png";
import { useNavigate, useLocation } from "react-router-dom";
import { EDITPROFILE_API } from "../../../services/Api/editProfileApi";
import toast from "react-hot-toast";
import Header from "../../../common/header";
import { Modal } from 'react-responsive-modal';
import axios from "axios";
import Footer from "../../../common/footer";
const AddWehicle = (props) => {
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [payload, setPayload] = useState('');
    const [pitches, setPitches] = useState('');
    const [userId, setUserId] = useState('');
    const [makeError, setMakeError] = useState(false);
    const [modelError, setModelError] = useState(false);
    const [payloadError, setPayloadError] = useState(false);
    const [pitchesError, setPitchesError] = useState(false);
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")
    const [errr, setErrr] = useState("")
    const [errorMessage, setErrMessage] = useState(false)
    const [showMenu, setShowMenu] = useState(true)
      const onOpenModal = () => {
          setOpen(true)
          // document.getElementById("navbarSupportedContent")?.classList.remove("show");
        };
        const onCloseModal = () => setOpen(false);
      
        const onSumbit = () => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!name || !email || !phone || !message) {
              setErrr("Bitte füllen Sie alle markierten Felder aus");
              setTimeout(() => {
                  setErrr("");
              }, 3000);
              return;
          }
  
          if (!emailRegex.test(email)) {
              setErrr("Bitte füllen Sie alle markierten Felder aus");
              setTimeout(() => {
                  setErrr("");
              }, 3000);
              return;
          }
  
          if (phone.length < 8 || phone.length > 14) {
              setErrr("Telefonnummer muss zwischen 8 und 14 Zeichen lang sein");
              setTimeout(() => {
                  setErrr("");
              }, 3000);
              return;
          }
          setOpen(false);
  
          axios
              .post("https://smtp.taction.in/api/api/v2/send-mail", {
                  name: name,
                  email: email,
                  message: message,
              })
              .then((res) => {
                  if (res && res?.status == 200) {
                      toast.success("Ihre Anfrage wurde übermittelt ", {
                          autoClose: 2000,
                      });
  
                  }
              })
              .catch((err) => {
              });
      };
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const car_id = queryParams.get("carId");
    let usr = localStorage.getItem('user')
    let user_info = JSON.parse(usr)

    const navigate = useNavigate();
    const getSingleCarDetail = (carId) => {
        EDITPROFILE_API.getSingleCarDetail(carId)
            .then((response) => {
                if (response?.status == 200) {
                    const SelectedcarData = response.data[0] || {}; // Get the first car data object from the array
                    // Set state using semicolons to separate statements
                    setMake(SelectedcarData.car_name);
                    setModel(SelectedcarData.car_model);
                    setPayload(SelectedcarData.weight);
                    setPitches(SelectedcarData.no_of_cars);
                } else {
                    console.error('Error fetching car data. Response:', response);
                }
            })
            .catch((error) => {
                console.error('Error fetching car data:', error);
            });
    }



    const handleConfirmAdd = async () => {

        let isError = false;

        if (!make) {
            setMakeError(true);
            isError = true;
        } else {
            setMakeError(false);
        }

        if (!model) {
            setModelError(true);
            isError = true;
        } else {
            setModelError(false);
        }

        if (!payload) {
            setPayloadError(true);
            isError = true;
        } else {
            setPayloadError(false);
        }

        if (!pitches) {
            setPitchesError(true);
            isError = true;
        } else {
            setPitchesError(false);
        }

        if (!isError) {
            const obj1 = {
                user_id: user_info.userInfo.user_id,
                car_name: make,
                car_model: model,
                weight: payload,
                no_of_cars: parseInt(pitches),
            }

            const obj2 = {
                user_id: user_info.userInfo.user_id,
                car_name: make,
                car_id: car_id,
                car_model: model,
                weight: payload,
                no_of_cars: parseInt(pitches),
            }
            
            const response = car_id ? await EDITPROFILE_API.updateVehicle(obj2) : await EDITPROFILE_API.addVehicle(obj1);
            if (response?.status === 201 || response?.status === 200) {
                toast.success(car_id ? 'Informationen aktualisiert' : 'Fahrzeug erfolgreich hinzugefügt', {
                    autoClose: 1000,
                });
                navigate('/profile');
            } else {
                toast.error('Fehler beim Aktualisieren des Fahrzeugs. Bitte versuche es erneut.');
            }
        }
    }
    useEffect(() => {

        getSingleCarDetail(car_id)
    }, [car_id])
    let inputValue = "";
    const handleChange = (event) => {
        inputValue = event.target.value.replace(/\D/g, ""); 
        if (inputValue.length > 2) {
            inputValue = inputValue.slice(0, 2); 
        }

        
        if (inputValue.length === 2) {
            const number = parseFloat(inputValue) / 10;
            inputValue = number.toFixed(1);
        }

        setPayload(prevState => inputValue); 
    };
    
    const handleNoofcarsChange = (e) => {
        const input =  e.target.value.replace(/\D/g, "");
        if (/^[1-9]{0,1}$/.test(input)) {
            setPitches(input);
            setPitchesError(false);
        } else {
            setPitchesError(true); 
        }
    };

    return (
        <React.Fragment>
            <Header />
            <div className="pading-mobile">
                <div className="container mt-lg-5 pt-3 ">
                    <div className="row">
                        <div className="col-lg-3 hamburger-sec">
                            <nav class="navbar navbar-expand-lg navbar-light bg-white">
                                <div class="container-fluid justify-content-end px-lg-0">
                                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                <div class="collapse navbar-collapse mt-3 mt-lg-0" id="navbarSupportedContent">
                                    <SideBar title="profile" />
                                </div>
                                </div>
                            </nav>
                        </div>
                        <div className="col-lg-9">
                            <div className="d-flex">
                                <div className="row">
                                    <div className='col-lg-12 col-12 my-auto'>
                                        <div className='d-flex'>
                                            <div className='mt-1'>
                                                <img src={back} width={'7px'} />
                                            </div>
                                            <div className='text-start mx-3 ' style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>Zurück</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <h1 style={{ fontSize: 24, fontWeight: 700 }}>Neues Fahrzeug hinzufügen</h1>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-6">
                                    <label className="pro-label">Marke</label>
                                    <input type="text" value={make}
                                        onChange={(e) => setMake(e.target.value)} maxLength={25}
                                        className={`form-control shadow-none profile-imput mt-1 ${makeError ? 'error' : ''}`} placeholder="Marke" />
                                </div>
                                <div className="col-lg-6 mt-3 mt-lg-0">
                                    <label className="pro-label">Model</label>
                                    <input type="text" value={model}
                                        onChange={(e) => setModel(e.target.value)} maxLength={25}
                                        className={`form-control shadow-none profile-imput mt-1 ${modelError ? 'error' : ''}`} placeholder="Modell" />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-6">
                                    <label className="pro-label">Zuladungsgewicht (Tonnen)</label>
                                    {/* <input type="text" value={payload}
                                        onChange={(e) => setPayload(e.target.value)}
                                        className={`form-control shadow-none profile-imput mt-1 ${payloadError ? 'error' : ''}`} placeholder="Zuladungsgewicht" /> */}
                                    <input
                                        type="text"
                                        value={payload}
                                        onChange={handleChange}
                                        className={`form-control shadow-none profile-imput mt-1 ${payloadError ? 'error' : ''}`}
                                        placeholder="0.0 t"
                                    />
                                </div>
                                {/* <div className="col-lg-6 mt-3 mt-lg-0">
                                    <label className="pro-label">Verfügbare Stellplätze</label>

                                    <input
                                        type="text"
                                        value={pitches}
                                        onChange={(e) => {
                                            const input = e.target.value;
                                            // Check if the input matches the pattern (0-9)
                                            if (/^[1-9]{0,1}$/.test(input)) {
                                                setPitches(input); // Update state if the input is valid
                                                setErrMessage(true);
                                            }
                                        }}
                                        className={`form-control shadow-none profile-imput mt-1 ${pitchesError ? 'error' : ''}`}
                                        placeholder="Verfügbare Stellplätze"
                                        pattern="[0-9]{0,1}" // Use the pattern attribute to limit input to 0-9
                                    />
                                    {errorMessage===true && (
                                    <div className="error-msg">{"Bitte geben Sie einen Wert von 0 bis 9 ein"}</div>
                                )}
                                </div> */}
                                <div className="col-lg-6 mt-3 mt-lg-0">
                <label className="pro-label">Verfügbare Stellplätze</label>
                <input
                    type="text"
                    value={pitches}
                    onChange={handleNoofcarsChange}
                    className={`form-control shadow-none profile-imput mt-1 ${pitchesError ? 'error' : ''}`}
                    placeholder="Verfügbare Stellplätze"
                />
                {pitchesError && (
                    <div className="error-msg">Bitte geben Sie einen Wert von 1 bis 9 ein</div>
                )}
            </div>
                            
                            
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-4">
                                    <button style={{
                                        color: "#FFF", background: "#25A9E0", border: "1px solid #25A9E0", borderRadius: 8, paddingLeft: 25, paddingRight: 25,
                                        paddingBottom: 8,
                                        paddingTop: 8
                                    }} onClick={handleConfirmAdd}>Bestätigen und hinzufügen</button>
                                </div>
                            </div>
                            <div className="pb-5"></div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </React.Fragment>
    );
}

export default AddWehicle;
