import React, { useEffect, useState } from "react";
import "./OTP.css";
import OTPIMG from "../../../../assets/otp.png";
import { useLocation, useNavigate } from "react-router-dom";
import { EDITPROFILE_API } from "../../../../services/Api/editProfileApi";
import { AUTENTICATE_API } from "../../../../services/Api/authenticationApi";
import toast from "react-hot-toast";
import OTPInput from "otp-input-react";
import Header from "../../../../common/zuruckheader";

const OTP = (props) => {
  const location = useLocation();
  let [otp_timer,set_otp_timer] = useState(0)
  let [change_state,set_change_state]=useState(true)
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [emptyFields, setEmptyFields] = useState(false);
  const [payload,set_payload]=useState({})
  const check_type=useState(sessionStorage.getItem("new_user"))
  useEffect(() => {
    const otpInputs = document.querySelectorAll(".otp__digit");

    const handleNextInput = (event) => {
      const current = event.target;
      const index = parseInt(current.classList[1].split("__")[2], 10);

      if (event.keyCode === 8 && index > 1) {
        current.previousElementSibling.focus();
      }

      if (index < 6 && event.key >= "0" && event.key <= "9") {
        current.value = event.key;
        const next = current.nextElementSibling;
        next && next.focus();
      }

      const otpValues = Array.from(otpInputs).map((input) => input.value);
      const finalKey = otpValues.join("");

      setOtp(finalKey);

      if (finalKey.length === 6) {
        setEmptyFields(false);
        setInvalidOtp(false);
      } else {
        setEmptyFields(true);
        setInvalidOtp(false);
      }
    };

    otpInputs.forEach((input) => {
      input.addEventListener("keyup", handleNextInput);
    });

    return () => {
      otpInputs.forEach((input) => {
        input.removeEventListener("keyup", handleNextInput);
      });
    };
  }, []);

  const onLogin = async () => {
    if (otp.length !== 6) {
      setEmptyFields(true);
      setInvalidOtp(false);
      setErrorMessage("Bitte geben Sie ein gültiges 6-stelliges OTP ein.");
      return;
    }

    let resp=await EDITPROFILE_API.verify_otp_registration({email:payload.email,verify_code:otp});
    if(resp?.status==200 && resp?.token!=undefined){
      let datata=JSON.parse(atob(sessionStorage.getItem("data")))
       datata.token=resp.token
       let response=await AUTENTICATE_API.customerRegister(datata)
       if(response?.status==201)
       {
        sessionStorage.clear();
        toast.success(response.message, {
          autoClose: 1000,
        });
        setTimeout(()=>{
          window.location.href="/AccountCreatedSuccessful"
        },1500)
        
       }
      else if(response?.status==401){
        toast.error("Ungültiger Code.", {
          autoClose: 1000,
        });
       }

    }
    else
    {
      toast.error("Ungültiger Code.", {
        autoClose: 1000,
      });
    }
   

  




  };

    const send_otp=async()=>{
        let  response=await EDITPROFILE_API.request_otp_email(payload);
        set_change_state(!change_state)

     }

     useEffect(()=>{   

      if(otp_timer!=0){
        setTimeout(()=>{
          set_otp_timer(otp_timer-1)
        },1000)
      }
     },[otp_timer])
     useEffect(()=>{
      if(payload?.email!=undefined ){
        send_otp(); 
      }
     },[payload])
  useEffect(()=>{
            if(sessionStorage.getItem('data')!=null){
             let datata=JSON.parse(atob(sessionStorage.getItem('data')))
             set_payload(datata)
             set_otp_timer(30)
           }
  },[])

  return (
    <React.Fragment>
      <Header />
      <div className="container login-page">
        <div className="row mt-lg-5 pt-lg-5">
          <div className="col-lg-5 col-12 my-auto order-2 order-lg-1 " style={{ background: "" }}>
            <div className="text-center text-lg-start" style={{ color: "#000000", fontSize: 45, fontWeight: 800 }}>
              Identität bestätigen
            </div>
            <div className="mt-3 mb-4" style={{ fontSize: 22, color: "#717D96" }}>
            Wir haben Ihnen einen Bestätigungscode per Mail geschickt. Tragen Sie diesen bitte ein. <br/>
 
              <span style={{ color: "#25A9E0" }}>Falsche E-Mail?</span>
            </div>
            <div className="col-12 mt-4">
              <OTPInput
                className={`input-classname ${emptyFields ? "input-error" : ""}`}
                value={otp}
                onChange={setOtp}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
              />
            </div>
            <div className="col-12 input-classname text-end mt-2">
                    <button onClick={()=>{
                       send_otp()
                       set_otp_timer(30)
                    }} 

                    id="resend"
                    className="btn btn-round"
                style={{color:'rgb(37, 169, 224)',border:'none'}}

                      disabled={(otp_timer==0?false:true)}
                    >{(otp_timer==0?"erneut senden":otp_timer)}</button> 
             </div>
            
            <div className="col-12 mt-4">
              <button
                onClick={onLogin}
                style={{ color: "#FFFFFF", fontSize: 14, background: "#25A9E0", border: "1px solid #25A9E0", borderRadius: 8, width: "100%", padding: 8 }}
              >
                Weiter
              </button>
            </div>
            {/* <div className="col-12 mt-5">
              <div style={{ marginLeft: 5 }}>
                <span style={{ color: "#000000", fontSize: 14 }}>
                  <a href="" style={{ textDecoration: "none", color: "#25A9E0" }}>
                    Verwenden Sie einen anderen Weg
                  </a>
                </span>
              </div>
            </div> */}
          </div>
          <div className="col-lg-6 px-5 order-1 order-lg-2 my-auto offset-lg-1">
            <img className="px-4" src={OTPIMG} style={{ width: "100%" }} alt="OTP Image" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OTP;