import React, { useState,useEffect } from 'react'
import { Link } from "react-router-dom";
import Back from '../../../assets/back.png'
import star from "../../../assets/images/star-blue.png"
import sms from "../../../assets/images/sms.png";
import verify from "../../../assets/images/verify.png";
import User from '../../../assets/Avatar2.png';
import call from "../../../assets/images/call.png";
import RangeImg from '../../../assets/range.png'
import LocationGreen from '../../../assets/images/location-green.png'
import LocationYellow from '../../../assets/images/location-yellow.png'
import TruckBlue from '../../../assets/images/truck-b.png'
import CircleBlue from '../../../assets/images/stop-circle.png'
import WeightBlue from '../../../assets/images/weight.png'
import CarBlue from '../../../assets/images/car-b.png'
import Header from '../../../common/header';
import infoicon from "../../../assets/images/info.png"
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { EDITPROFILE_API } from '../../../services/Api/editProfileApi';
import toast from "react-hot-toast";
import './tripConfirm.css'
export default function ConfirmReservation() {
    const navigate = useNavigate();
    const[userId,setUserId] = useState('');
    const[departFrom,setDepartFrom] = useState('');
    const[departTo,setDepartTo] = useState('');
    const[driverName,setDriverName] = useState('');
    const[driverEmail,setDriverEmail] = useState('');
    const[driverPhone,setDriverPhone] = useState('');
    const[imageUrl,setImageUrl] = useState('');
    const[pickupDate,setPickupDate] = useState('')
    const[arrivalDate,setArrivalDate] = useState('')
    const[pickupDate1,setPickupDate1] = useState('')
    const[arrivalDate1,setArrivalDate1] = useState('')
    const[pickupTime,setPickupTime] = useState('')
    const[arrivalTime,setArrivalTime] = useState('')
    const[weight,setWeight] = useState('')
    const[noOfCars,setNoOfCars] = useState('')
    const[information,setInformation] = useState('')
    const[searcherPhone,setSearcherPhone] = useState('')
    const[searcherEmail,setSearcherEmail] = useState('')
    const[searcherName,setSearchername] = useState('')
    const[ratings,setRatings] = useState('')
    const[buttonClicked,setButtonClicked] = useState()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pdId = queryParams.get("book_trip_id");
    const seacherId = queryParams.get('searcheId');
   
    const getBokkedTripDetails = (pd_id) => {
        EDITPROFILE_API.getBokkedTripDetails(pd_id)
          .then((response) => {
            if (response && response.status === 200 && response.data) {
              const profiledata = response.data[0] || []
              setDepartFrom(profiledata.depart_from.split(',')[0])
              setDepartTo(profiledata.depart_to.split(',')[0])
              setDriverName(profiledata.name)
              setDriverEmail(profiledata.visible_email)
              setDriverPhone(profiledata.visible_phone_no)
              
              setPickupDate(profiledata.pickup_date)
              setArrivalDate(profiledata.arrival_date)
              setPickupDate1(profiledata.pickup_date)
              setArrivalDate1(profiledata.arrival_date)
              setPickupTime(profiledata.pickup_time)
              setArrivalTime(profiledata.arrival_time)
              setWeight(profiledata.weight)
              setNoOfCars(profiledata.no_of_cars)
              setInformation(profiledata.description)
            } else {
              console.error('Error fetching branch data. Response:', response)
            }
          })
          .catch((error) => {
            console.error('Error fetching branch data:', error)
          })
      }
      const getUserDetailsOnTrip = (pd_id,usserId) => {
        EDITPROFILE_API.getUserDetailsOnTrip(pd_id,usserId)
          .then((response) => {
            if (response && response.status === 200 && response.data) {
              const userInfodata = response.data[0] || []
              
              setSearcherPhone(userInfodata.phone_no)
              setSearcherEmail(userInfodata.email)
              setSearchername(userInfodata.name)
             
              setImageUrl(userInfodata.image_url);
              setRatings(userInfodata?.avarge_rating ?parseFloat(userInfodata?.avarge_rating)?.toFixed(1):"5")
            } else {
              console.error('Error fetching branch data. Response:', response)
            }
          })
          .catch((error) => {
            console.error('Error fetching branch data:', error)
          })
      }
      useEffect(() => {
        let usr = localStorage.getItem('user')
        let user_info = JSON.parse(usr)
        let user_id=user_info.userInfo.user_id;
        setUserId(user_id)
        
        getBokkedTripDetails(pdId)
        getUserDetailsOnTrip(pdId,seacherId)
      }, [pdId,seacherId])
      const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('.');
        const formattedDate = `${year}-${month}-${day}`;
        const date = new Date(Date.parse(formattedDate)); 
        if (isNaN(date.getTime())) {
          console.error('Invalid date:', dateString);
          return ''; 
        }
      const options = { weekday: 'long', month: 'short', day: '2-digit' };
        return new Intl.DateTimeFormat('de-DE', options).format(date);
      };
      const formatDate1 = (dateString) => {
        const [day, month, year] = dateString.split('.');
        const formattedDate = `${year}-${month}-${day}`;
        const date = new Date(Date.parse(formattedDate));
        if (isNaN(date.getTime())) {
          console.error('Invalid date:', dateString);
          return '';
        }
        const options = { month: 'short', day: '2-digit' };
        return new Intl.DateTimeFormat('de-DE', options).format(date);
      };
      // const formatDate1 = (dateString) => {
      //   const [day, month, year] = dateString.split('.');
      //   const formattedDate = `${year}-${month}-${day}`;
      //   const date = new Date(Date.parse(formattedDate)); 
      //   if (isNaN(date.getTime())) {
      //     // console.error('Invalid date:', dateString);
      //     return ''; 
      //   }
      // const options = {  month: 'short', day: '2-digit' };
      //   // return new Intl.DateTimeFormat('de-DE', options).format(date);
      // };

      const handleConfirmBooking = async(buttonNumber)=>{
        setButtonClicked(buttonNumber);
     
      let obj={
          user_id:userId,
          pd_id:parseInt(pdId),
          book_status:buttonNumber
      }
      const response = await EDITPROFILE_API.tripAccept(obj);

      if (response!=undefined) {
        if(response.status==200){
        
          toast.success('Buchung bestätigt', {
              autoClose: 2000,
          });
          navigate('/BookingSuccessful')
        }

        
        else if(response?.status==201) {
          navigate('/BookingReject')
        }
      }
    }
  return (
    <>
    
    <Header/>
   
    <div className='container'>
        <div className='row mt-5'>
            <div className='col-lg-2 col-12 justify-content-center d-flex mt-3'>
                {/* <div className='d-flex' onClick={() => navigate(-1)} style={{cursor:'pointer'}}> */}
                    {/* <img className='mx-2' src={Back} width={7} height={14}/> */}
                    {/* <div style={{fontSize:14,marginRight:100}}>Zurück</div> */}
                {/* </div> */}
            </div>
            <div className='col-lg-8 col-12'>
                <div className='text-center inform-text' style={{fontWeight:600,color:'#25A9E0'}}>Buchungsdetails bestätigen</div>
                <div className='text-center inform-text' style={{fontWeight:600,color:'#1A202C'}}>{departTo} nach {departFrom}</div>
                <div className='d-flex justify-content-between mt-4 px-5'>
                    <div className='booktrip' style={{fontWeight:600,color:'#4A5468'}}>{formatDate(pickupDate)}</div>
                    <div className='booktrip me-2' style={{fontWeight:600,color:'#A0ABC0'}}>&#8594;</div>
                    <div className='booktrip' style={{fontWeight:600,color:'#4A5468'}}>{formatDate(arrivalDate)}</div>
                </div>
                <div className='mt-4 px-5'><img src={RangeImg} width={'100%'}/></div>
                <div className='d-flex justify-content-between mt-4 px-5'>
                    <div>
                        {/* <div><img src={LocationGreen} width={18} /></div> */}
                        <div style={{fontSize:16,fontWeight:600}}>Abfahrt</div>
                        <div style={{fontSize:14,color:'#4A5468',fontWeight:600}}>{formatDate1(pickupDate1)}<br/>{pickupTime}</div>
                        <div style={{fontSize:14,color:'#A0ABC0'}}>{departTo}</div>
                    </div>
                    <div style={{textAlign:'end'}}>
                        {/* <div className='d-flex justify-content-end'><img src={LocationYellow} width={18} /></div> */}
                        <div style={{fontSize:16,fontWeight:600}}>Ankunft</div>
                        <div style={{fontSize:14,color:'#4A5468',fontWeight:600}}>{formatDate1(arrivalDate1)} <br/> {arrivalTime}</div>
                        <div style={{fontSize:14,color:'#A0ABC0'}}>{departFrom}</div>
                    </div>
                </div>
                <div className='text-center mt-4' style={{fontSize:24,fontWeight:600}}>
  {searcherName
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')} möchte Ihre Fahrt buchen!
</div>
                {/* <div className='text-center mt-4' style={{fontSize:24,fontWeight:600}}>{searcherName} möchte Ihre Fahrt buchen!</div> */}
               
               
                                        
                <div className='d-flex justify-content-center mt-3 mb-3'>
                {imageUrl ? (
                                            <img src={imageUrl} width={'48px'} style={{ borderRadius: "50%", height: 48 }} alt="User Image" />
                                        ) : (
                                            <img src={User} width={'48px'} style={{ borderRadius: "50%", height: 48 }} alt="Default User Image" />
                                        )}
                    {/* <img src={sms} width={24}/> */}
                    <div className=' my-auto'>
                      <div className='mx-3' style={{fontSize:18,textTransform: 'capitalize'}}>{searcherName}</div>
                      {/* <div className='d-flex mx-3'>
                          <img src={verify} width={18} alt="Verified" />
                          <div className='mx-1' style={{fontSize:14}}>Verifiziertes Profil</div>
                      </div> */}
                      
                              <div className="d-flex mx-3">
                                   <img src={star} style={{ width: 24, height: 24 }} />
                                   
                                         <div className="my-1" style={{ color: '#4A5468', fontSize: 16, marginLeft: 6 }}>
                                          {/* {ratings?ratings:'5.0'}/5.0 */}
                                          {ratings != null && !isNaN(ratings) ? Number(ratings).toFixed(1) : '5.0'}/5.0
                                          </div>
                                          </div>
                          
                        </div>
               
            
                </div>
                
                <div className='d-flex justify-content-center mt-3 mb-3'>
                    <img src={sms} width={24}/>
                    <div className='mx-3' style={{fontSize:16}}>{searcherEmail}</div>
                </div>
                <div className='d-flex justify-content-center mb-3'>
                    <img src={call} width={24}/>
                    <div className='mx-3' style={{fontSize:16}}>{searcherPhone}</div>
                </div>
               
                            <div className='row justify-content-center mb-5'>
                              
                <div className='col-lg-2 col-6'>
                <div className='d-flex justify-content-center mt-3'>
                    <button type="button" class="btn btn-primary px-3 py-2 my-auto" style={{backgroundColor:'#f5222d',border:'none',fontSize:16}}  onClick={() => handleConfirmBooking(2)}>Ablehnen</button>
                </div>
                </div>
                <div className='col-lg-2 col-6'>
                <div className='d-flex justify-content-center mt-3'>
                    <button type="button" class="btn btn-primary px-3 py-2 my-auto" style={{backgroundColor:'#25A9E0',border:'none',fontSize:16}}onClick={() => handleConfirmBooking(1)}>Annehmen</button>
                </div>
                </div>
                </div>
            </div>
            <div className='col-2 d-none d-lg-block'></div>
        </div>
    </div>
 
    </>
  )
}