import React, { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "./book-trip.css";
import User from "../../../assets/Avatar2.png";
import SideBar from "../../../common/sidebar/side";
import location1 from "../../../assets/images/location-green.png";
import location2 from "../../../assets/images/location-gray.png";
import location3 from "../../../assets/images/location-yellow.png";
import avatar from "../../../assets/images/Avatar.png";
import star from "../../../assets/images/star-blue.png";
import Header from "../../../common/header";
import { EDITPROFILE_API } from "../../../services/Api/editProfileApi";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import Footer from "../../../common/footer";
const BookTrip = (props) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [contactBack, setContactBack] = useState(0);
    const [message, setMessage] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [errr, setErrr] = useState("");
    const [showMenu, setShowMenu] = useState(true);
    const [userId, setUserId] = useState("");
    const [bookedList, setBookedList] = useState([]);
    const [pdId, setPdId] = useState("");
    const [bookedTripId, setBookedTripId] = useState("");

    const handleCloseModal = () => {
        setIsOpen(false);
    };
    
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };
    const handleOpenModal = (userriidd, pdIddd, id) => {
        setUserId(userriidd);
        setPdId(pdIddd);
        setBookedTripId(id);
        setIsOpen(true);
    };
    const getTripByUser = (userId) => {
        let obj_date = new Date();
        let obj={
            user_id:userId,
            currentTime:(obj_date.getHours() < 10 ? "0" + obj_date.getHours() : obj_date.getHours()) + ":" + (obj_date.getMinutes() < 10 ? "0" + obj_date.getMinutes() : obj_date.getMinutes()),
            pickup_date: formatDate(obj_date)

        }
        EDITPROFILE_API.getTripByUser(obj)
            .then((response) => {
                if (response.status === 200) {
                    setBookedList(response.data);
                } else {
                    console.error("Error fetching trip data. Response:", response);
                }
            })
            .catch((error) => {
                console.error("Error fetching trip data:", error);
            });
    };
    const handleCancelRide = async (userrid, pdidd, bookedtripid) => {
        await EDITPROFILE_API.cancel_tripbysearcher(userrid, pdidd, bookedtripid)
            .then((response) => {
                if (response?.status === 200) {
                    navigate("/BookedTripCancelled")
                    getTripByUser(userId);
                } else {
                    console.error("Error fetching trip data. Response:", response);
                }
            })
            .catch((error) => {
                console.error("Error fetching trip data:", error);
            });
    };
    const handleArchivedTrips = () => {
        navigate('/ArchiveTrips')

    }

    useEffect(() => {
        let usr = localStorage.getItem("user");
        let user_info = JSON.parse(usr);
        let user_id = user_info.userInfo.user_id;
        setUserId(user_id);
        getTripByUser(user_id);
    }, [userId]);
    const onOpenModal = () => {
        setOpen(true);
        // document.getElementById("navbarSupportedContent")?.classList.remove("show");
    };
    const onCloseModal = () => setOpen(false);

    const onSumbit = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!name || !email || !phone || !message) {
            setErrr("Bitte füllen Sie alle markierten Felder aus");
            setTimeout(() => {
                setErrr("");
            }, 3000);
            return;
        }

        if (!emailRegex.test(email)) {
            setErrr("Bitte füllen Sie alle markierten Felder aus");
            setTimeout(() => {
                setErrr("");
            }, 3000);
            return;
        }

        if (phone.length < 8 || phone.length > 14) {
            setErrr("Telefonnummer muss zwischen 8 und 14 Zeichen lang sein");
            setTimeout(() => {
                setErrr("");
            }, 3000);
            return;
        }
        setOpen(false);

        axios
            .post("https://smtp.taction.in/api/api/v2/send-mail", {
                name: name,
                email: email,
                message: message,
            })
            .then((res) => {
                if (res && res?.status == 200) {
                    toast.success("Ihre Anfrage wurde übermittelt ", {
                        autoClose: 2000,
                    });

                }
            })
            .catch((err) => {
            });
    };

    const closeT = () => {
        document.getElementById("navbarSupportedContent")?.classList.remove("show");
    };

    return (
        <React.Fragment>
            <Header />
            <div className="container pading-mobile">
                <div className="row mt-lg-5 pt-3">
                    <ReactModal
                        isOpen={isOpen}
                        onRequestClose={handleCloseModal}
                        ariaHideApp={true}
                    >
                        <span
                            className="close-btn p-3 py-2"
                            onClick={handleCloseModal}
                            style={{
                                float: "right",
                                backgroundColor: "#000",
                                color: "#fff",
                                borderRadius: 50,
                            }}
                        >
                            &times;
                        </span>
                        <div>
                            <div className="mt-5 pt-4">
                                <h2
                                    className="text-center"
                                    style={{ color: "#000", fontWeight: 600 }}
                                >
                                    Wollen Sie ihre Fahrt wirklich stornieren?
                                </h2>
                                <p className="text-center" style={{ color: "#717d96" }}>
                                    Wenn Sie Ihre Fahrt stornieren, informieren wir den Fahrer,
                                    und
                                    <br /> die Fahrt wird wieder in der Suche sichtbar sein.
                                </p>{" "}
                                <div className="text-center mt-3">
                                    <button
                                        className="btn btn-secondary me-3"
                                        style={{ backgroundColor: "#25A9E0", border: "#25A9E0" }}
                                        onClick={handleCloseModal}
                                    >
                                        Zurück
                                    </button>
                                    <button
                                        onClick={() => handleCancelRide(userId, pdId, bookedTripId)}
                                        className="btn btn-danger"
                                        style={{
                                            backgroundColor: "#fff",
                                            color: "red",
                                            border: "1px solid #000000",
                                        }}

                                    >
                                        Stornieren
                                    </button>
                                </div>
                            </div>
                            {/* Add more content or functionality for your modal */}
                        </div>
                    </ReactModal>
                    <div className="col-lg-3 hamburger-sec">
                        {/* <SideBar title="profile" /> */}
                        <nav class="navbar navbar-expand-lg navbar-light bg-white">
                            <div class="container-fluid justify-content-end px-lg-0">
                                <button
                                    class="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                <div
                                    class="collapse navbar-collapse mt-3 mt-lg-0"
                                    id="navbarSupportedContent"
                                >
                                    <SideBar title="booktrip" />
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div className="col-lg-9">
                        <div className="container">
                            <div style={{ fontSize: 24, fontWeight: 600 }}>
                                Gebuchte Fahrten
                            </div>
                        </div>
                        <div className="container mt-5 pb-5">
                            <div className="row">
                                {bookedList.length > 0 ? (
                                    bookedList.map((item) => (
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div
                                                key={item.pd_id}
                                                className=" p-4"
                                                style={{
                                                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                                    borderRadius: 24,
                                                    // height: 245,
                                                }}
                                            >
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div
                                                            className="mt-1"
                                                            style={{
                                                                color: "#000",
                                                                fontSize: 16,
                                                                fontWeight: 700,
                                                            }}
                                                        >
                                                            Abfahrt
                                                        </div>
                                                        <div
                                                            className="mt-1"
                                                            style={{
                                                                color: "#4A5468",
                                                                fontSize: 14,
                                                                fontWeight: 700,
                                                            }}
                                                        >
                                                            {item.pickup_date}
                                                        </div>
                                                        <div
                                                            className="mt-1"
                                                            style={{
                                                                color: "#4A5468",
                                                                fontSize: 14,
                                                                fontWeight: 700,
                                                            }}
                                                        >
                                                            {item.pickup_time}
                                                        </div>
                                                        <div
                                                            className="mt-1"
                                                            style={{ color: "#4A5468", fontSize: 14 }}
                                                        >
                                                            {item.depart_to}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-6"
                                                        style={{ borderLeft: "0.1rem solid #A0ABC0" }}
                                                    >
                                                        <div
                                                            className="mt-1"
                                                            style={{
                                                                color: "#000",
                                                                fontSize: 16,
                                                                fontWeight: 700,
                                                            }}
                                                        >
                                                            Ankunft
                                                        </div>
                                                        <div
                                                            className="mt-1"
                                                            style={{
                                                                color: "#4A5468",
                                                                fontSize: 14,
                                                                fontWeight: 700,
                                                            }}
                                                        >
                                                            {item.arrival_date}
                                                        </div>
                                                        <div
                                                            className="mt-1"
                                                            style={{
                                                                color: "#4A5468",
                                                                fontSize: 14,
                                                                fontWeight: 700,
                                                            }}
                                                        >
                                                            {item.arrival_time}
                                                        </div>
                                                        <div
                                                            className="mt-1"
                                                            style={{ color: "#4A5468", fontSize: 14 }}
                                                        >
                                                            {item.depart_from}
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-lg-12">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="d-flex">
                                                                    <div>
                                                                        <img
                                                                            src={item.image_url || User}
                                                                            style={{
                                                                                width: 48,
                                                                                height: 48,
                                                                                borderRadius: 50,
                                                                            }}
                                                                            alt="User Image"
                                                                        />
                                                                    </div>
                                                                    <div style={{ marginLeft: 15 }}>
                                                                        <div
                                                                            style={{
                                                                                color: "#000",
                                                                                fontSize: 16,
                                                                                fontWeight: 700,
                                                                                textTransform: 'capitalize'
                                                                            }}
                                                                        >
                                                                            {item.name}
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <img
                                                                                src={star}
                                                                                style={{ width: 24, height: 24 }}
                                                                            />
                                                                            <div
                                                                                className="my-1"
                                                                                style={{
                                                                                    color: "#4A5468",
                                                                                    fontSize: 16,
                                                                                    marginLeft: 6,
                                                                                }}
                                                                            >
                                                                                {item.avarge_rating != null && !isNaN(item.avarge_rating) ? Number(item.avarge_rating).toFixed(1) : '5.0'}/5.0
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                    <div className="d-flex justify-content-center">
                                      <div className="text-center" style={{backgroundColor:'#25a9e0',color:'#fff',borderRadius:50, width:40,height:40}}><span style={{position:'relative',top:9}}>
                                      {JSON.parse(item?.no_of_stop).length}</span>
                                      </div>
                                    </div>
                                      <div className="justify-content-center d-flex">
  {JSON.parse(item?.no_of_stop).length === 0|| JSON.parse(item?.no_of_stop).length >1 ? " Stopps" : JSON.parse(item?.no_of_stop).length == 1 ? " Stopp" : ""}
                                      </div>
                                  </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center mt-3 mb-5">
                                                <button
                                                    style={{
                                                        fontSize: 16,
                                                        color: "#FFFFFF",
                                                        background: "#F5222D",
                                                        borderRadius: 8,
                                                        border: "1px solid #F5222D",
                                                        paddingLeft: 25,
                                                        paddingRight: 25,
                                                        paddingTop: 8,
                                                        paddingBottom: 8,
                                                    }}

                                                    onClick={() =>
                                                        handleOpenModal(
                                                            item.user_id,
                                                            item.pd_id,
                                                            item.book_trip_id
                                                        )
                                                    }
                                                >
                                                    Fahrt stornieren
                                                </button>

                                                {/* */}
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div
                                        className="col-lg-12 text-center mt-3"
                                        style={{
                                            justifyContent: "center",
                                            fontSize: "22px",
                                            color: "grey",
                                            padding: "20px",
                                        }}
                                    >
                                        <b> Keine Aufzeichnungen gefunden!</b>
                                    </div>
                                )}
                            </div>
                            <div className="row mt-5">
                                <div style={{ color: "#25A9E0", fontSize: 16, cursor: 'pointer' }} onClick={handleArchivedTrips}>
                                    Archiv ansehen
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </React.Fragment>
    );
};
export default BookTrip;