import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { Urls } from "../Urls/apiUrl";
import toast from "react-hot-toast";
import { EDITPROFILE_API } from "./editProfileApi";

export const AUTENTICATE_API = {
  customerRegister,
  customerLogin,
  getRiderInformation,
  makePayment,
  getPaymentByUserId,
  customerLoginByToken,
  email_mob_chjeck,
  notificationSave,
  get_route_list,
  lat_lng,
  getDistance
};
// async function getDistance(dateValue11,lat_lng_,dep_arr) {
//   let aaa = [];
//   let tempp = [];
//   let tmp_1={
//     "origins": [

//     ],
//     "destinations": [
      
//     ]
//   }
//   let tmp_2={
//     "origins": [
//     ],
//     "destinations": [
      
//     ]
//   }


//   let obj_date = new Date();

//   let response = await EDITPROFILE_API.trip_list_by_date({
//     "pickup_date": dateValue11,
//     "currentTime": (obj_date.getHours() < 10 ? "0" + obj_date.getHours() : obj_date.getHours()) + ":" + (obj_date.getMinutes() < 10 ? "0" + obj_date.getMinutes() : obj_date.getMinutes())
//   });

//   tmp_1.origins.push({
//     "waypoint": {
//       "location": {
//         "latLng": {
//           "latitude": lat_lng_[0].lat,
//           "longitude": lat_lng_[0].lng
//         }
//       }
//     },
//     "routeModifiers": { "avoid_ferries": true}
//   })
//   tmp_2.origins.push({
//     "waypoint": {
//       "location": {
//         "latLng": {
//           "latitude": lat_lng_[1].lat,
//           "longitude": lat_lng_[1].lng
//         }
//       }
//     },
//     "routeModifiers": { "avoid_ferries": true}
//   })
//   // console.log(tmp_2,"pppppppppppppppppp")

//   // return


//   if (response?.status == 200) {
//     // console.log(aaa,"nitish chauhan ")
//     aaa = response.data.slice();
//     // console.log(aaa,"distance 1")
//     // aaa.map((val, i) => {
//       for(let i =0; i<aaa.length;i++){
//          {



//           // let res1=axios.post("https://maps.googleapis.com/maps/api/directions/json?destination="+aaa[i].depart_to+"dep&origin="+dep_arr[0]+"&key=AIzaSyDDSeDxQt83B_nmvItImVbS8f2gt_yfL5E")
//           let res1=axios.post("https://maps.googleapis.com/maps/api/directions/json?destination="+aaa[i].depart_to+"&origin="+dep_arr[0]+"&key=AIzaSyDDSeDxQt83B_nmvItImVbS8f2gt_yfL5E")

//           let res2=axios.post("https://maps.googleapis.com/maps/api/directions/json?destination="+aaa[i].depart_from+"&origin="+dep_arr[1]+"&key=AIzaSyDDSeDxQt83B_nmvItImVbS8f2gt_yfL5E")
         
//          aaa[i].distance=[res1.routes[0].legs[0].distance,res2.routes[0].legs[0].distance]
         
//           tmp_1.destinations.push({
//             "waypoint": {
//               "location": {
//                 "latLng": {
//                   "latitude": val.depto_latitude,
//                   "longitude": val.depto_longitude
//                 }
//               }
//             }
//           })
//           tmp_2.destinations.push({
//             "waypoint": {
//               "location": {
//                 "latLng": {
//                   "latitude": val.dep_latitude,
//                   "longitude": val.dep_longitude
//                 }
//               }
//             }
//           })


      
//       }
//     };
//     console.log(aaa,"divyaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
//     return 
//   let tmp_1_res=await get_route_list(tmp_1)
//   let tmp_2_res=await get_route_list(tmp_2)
//   // console.log(aaa,tmp_1_res,tmp_2_res)
  
//   tmp_1_res.map((val,i)=>{
//     tmp_2_res.map((val1,i1)=>{
// // debugger
//       if(val.destinationIndex==val1.destinationIndex ){
//         // debugger
//         if((val.distanceMeters<=100000 && (val1.duration=="0s" || val1.distanceMeters<=100000)) || (val1.distanceMeters<=100000 &&  (val.duration=="0s" || val.distanceMeters<=100000))){
//           // debugger 
//           tempp.push(aaa[val.destinationIndex])
//       }
//       }
     
  
      
//     })




//   })
//   return tempp
// }
// return tempp
// }
async function getDistance(dateValue11,lat_lng_) {
  let aaa = [];
  let tempp = [];
  let tmp_1={
    "origins": [

    ],
    "destinations": [
      
    ]
  }
  let tmp_2={
    "origins": [
    ],
    "destinations": [
      
    ]
  }


  let obj_date = new Date();

  let response = await EDITPROFILE_API.trip_list_by_date({
    "pickup_date": dateValue11,
    "currentTime": (obj_date.getHours() < 10 ? "0" + obj_date.getHours() : obj_date.getHours()) + ":" + (obj_date.getMinutes() < 10 ? "0" + obj_date.getMinutes() : obj_date.getMinutes())
  });

  tmp_1.origins.push({
    "waypoint": {
      "location": {
        "latLng": {
          "latitude": lat_lng_[0].lat,
          "longitude": lat_lng_[0].lng
        }
      }
    },
    "routeModifiers": { "avoid_ferries": true}
  })
  tmp_2.origins.push({
    "waypoint": {
      "location": {
        "latLng": {
          "latitude": lat_lng_[1].lat,
          "longitude": lat_lng_[1].lng
        }
      }
    },
    "routeModifiers": { "avoid_ferries": true}
  })
  // console.log(tmp_2,"pppppppppppppppppp")

  // return


  if (response?.status == 200) {
    if(response?.count==0){
      window.location.href="/ListingPageNoResults"
    }
    // console.log(aaa,"nitish chauhan ")
    aaa = response.data.slice();
    // console.log(aaa,"distance 1")
    aaa.map((val, i) => {
         {
          tmp_1.destinations.push({
            "waypoint": {
              "location": {
                "latLng": {
                  "latitude": val.depto_latitude,
                  "longitude": val.depto_longitude
                }
              }
            }
          })
          tmp_2.destinations.push({
            "waypoint": {
              "location": {
                "latLng": {
                  "latitude": val.dep_latitude,
                  "longitude": val.dep_longitude
                }
              }
            }
          })
      
      }
    });
  let tmp_1_res=await get_route_list(tmp_1)
  let tmp_2_res=await get_route_list(tmp_2)
  // console.log(aaa,tmp_1_res,tmp_2_res)
  
  tmp_1_res.map((val,i)=>{
    tmp_2_res.map((val1,i1)=>{
// debugger
      if(val.destinationIndex==val1.destinationIndex ){
        // debugger
        if((val.distanceMeters<=100000 && (val1.duration=="0s" || val1.distanceMeters<=100000)) || (val1.distanceMeters<=100000 &&  (val.duration=="0s" || val.distanceMeters<=100000))){
          // debugger 
          tempp.push(aaa[val.destinationIndex])
      }
      }
     
  
      
    })




  })
  return tempp
}
else{
 
    window.location.href="/ListingPageNoResults"
  
}
return tempp
}
function lat_lng(payload){
  return axios
  .get(Urls + "get_direction?from="+payload[0]+"&to="+payload[1])
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    toast.error(error?.response?.data?.message);
     return error.response
  })
}

// function getLatLon(location) {
//   const url = `https://maps.googleapis.com/maps/api/directions/json?origin=${encodeURIComponent(location[0])}&destination=${encodeURIComponent(location[1])}&key=AIzaSyDDSeDxQt83B_nmvItImVbS8f2gt_yfL5E`;
//   return axios
//   .get(url,{
//     headers: { 'Content-Type':'application/json',
//       'X-Goog-Api-Key':'AIzaSyDDSeDxQt83B_nmvItImVbS8f2gt_yfL5E',
//       'X-Goog-FieldMask':'originIndex,destinationIndex,duration,distanceMeters,status,condition'
//      } 
//   })
//   .then((response) => {
//     return response.data;
//   })
//   .catch((error) => {
//     toast.error(error?.response?.data?.message);
//      return error.response
//   })

// //   const apiKey = process.env.GOOGLE_MAP_KEY;
// // // console.log(url)
// //   try {
// //       const response = await axios.get(url);
// //       if (response.data.routes.length > 0) {
// //           const startLocation = response.data.routes[0].legs[0].start_location;
// //           const endLocation = response.data.routes[0].legs[0].end_location;
// //           // console.log(startLocation)
// //           return {
// //               dep_latitude: startLocation.lat,
// //               dep_longitude: startLocation.lng,
// //               depto_latitude: endLocation.lat,
// //               depto_longitude: endLocation.lng
// //           };
// //       }
// //       return null;
// //   } catch (error) {
// //       console.error('Error fetching coordinates:', error);
// //       return null;
// //   }
// }

function get_route_list(payload){
  // let payload={
  //   "origins": [
  //     {
  //       "waypoint": {
  //         "location": {
  //           "latLng": {
  //             "latitude": 37.420761,
  //             "longitude": -122.081356
  //           }
  //         }
  //       },
  //       "routeModifiers": { "avoid_ferries": true}
  //     },
  //     {
  //       "waypoint": {
  //         "location": {
  //           "latLng": {
  //             "latitude": 37.403184,
  //             "longitude": -122.097371
  //           }
  //         }
  //       },
  //       "routeModifiers": { "avoid_ferries": true}
  //     }
  //   ],
  //   "destinations": [
  //     {
  //       "waypoint": {
  //         "location": {
  //           "latLng": {
  //             "latitude": 37.420999,
  //             "longitude": -122.086894
  //           }
  //         }
  //       }
  //     },
  //     {
  //       "waypoint": {
  //         "location": {
  //           "latLng": {
  //             "latitude": 37.383047,
  //             "longitude": -122.044651
  //           }
  //         }
  //       }
  //     }
  //   ],
  //   "travelMode": "DRIVE",
  //   "routingPreference": "TRAFFIC_AWARE"
  // }
  return axios
  .post('https://routes.googleapis.com/distanceMatrix/v2:computeRouteMatrix',payload,{
    headers: { 'Content-Type':'application/json',
      'X-Goog-Api-Key':'AIzaSyDDSeDxQt83B_nmvItImVbS8f2gt_yfL5E',
      'X-Goog-FieldMask':'originIndex,destinationIndex,duration,distanceMeters,status,condition'
     } 
  })
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    toast.error(error?.response?.data?.message);
     return error.response
  })


}

function email_mob_chjeck(requestOptions){
  return axios
  .post(Urls + "email_mob_chjeck",requestOptions)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    toast.error(error?.response?.data?.message);
     return error.response
  })
};

function notificationSave(data){
  return axios
    .post(Urls + "notification_save", data,{ headers: { Authorization: getToken() } } )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message);
        }
    });

}

function getPaymentByUserId(requestOptions) {
  return axios
    .get(Urls + "payment_detailsById/"+requestOptions,{ headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message);
        }
    });
}

function makePayment(data){
  return axios
    .post(Urls + "payment", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message);
        }
    });

}


function customerLoginByToken(requestOptions) {
  return axios
    .post(Urls + "token_genrate_login", requestOptions)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error("Unauthorized user");
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message);
        }
    });
}

function customerLogin(requestOptions) {
  return axios
    .post(Urls + "warumleer_user_login", requestOptions)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error("User nicht gefunden ");
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message);
        }
    });
}

function customerRegister(requestOptions) {
  return axios
    .post(Urls + "warumleer_registration", requestOptions)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error("Ungültiger Code");
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message);
        }
    });
}

function getToken() {
  // Retrieve the user data from localStorage
  const storedUserString = localStorage.getItem("user");
  // Check if the user data is stored in localStorage
  if (storedUserString) {
    const storedUserObject = JSON.parse(storedUserString);
    // Retrieve the token from the user data
    const token = storedUserObject.token;

    if (token) {
      const decoded = jwtDecode(token);

      // Perform additional validation if needed
      if (decoded.email === storedUserObject.userInfo.email) {
        // If the token is valid, return it in the "Bearer" format
        return `Bearer ${token}`;
      } else {
        // Token is invalid
        return null;
      }
    } else {
      // Token not found in user data
      return null;
    }
  } else {
    window.location.href = "/";
    // setTimeout(() => {

    // })
  }
}

function getRiderInformation(requestOptions) {
  return axios
    .post(Urls + "get_rider_details", requestOptions)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error("User nicht gefunden ");
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message);
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message);
        }
    });
}
