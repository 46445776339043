import React, { useEffect, useState, useRef } from "react";
import '../HomePage/HomePage.css'
import toast from "react-hot-toast";
import Icon from '../../../assets/Gold Mitglied.png'
import Header from '../../../common/header'
import { useNavigate } from "react-router-dom";
import Footer from "../../../common/footer";
export default function Subscription() {
    const [isYearly, setIsYearly] = useState(false);
    const [type, setType] = useState(false);
    const [userId, setUserId] = useState("");
    const navigate = useNavigate();
    const handleJetztbeitreten = () => {
        if (!userId) {
          navigate("/login");
        }  else {
          if(userId.userInfo.is_subscribe===1){
            toast.error("Gold-Mitgliedschaft bereits vorhanden")
          }
          else{
          window.location.href = `/BillingCycle?${type}`
          // navigate("/BillingCycle?"+type);
          }
        }
      };
      useEffect(() => {
        let usr = localStorage.getItem("user");
        let user_info = JSON.parse(usr);
        // let user_id=user_info.userInfo.user_id;
        setUserId(user_info);
      }, []);
  return (
    <>
    <Header/>
    <div className=" back-img">
    <div className="container py-5">
            <div className="row py-2">
              <div className="col-12 text-center">
                <div style={{ fontSize: 48, fontWeight: 600 }}>
                  Mitgliedschaft
                </div>
                <div
                  className="mt-3"
                  style={{ fontSize: 22, color: "#4A5468" }}
                >
                  Ihre WarumLeer-Mitgliedschaft öffnet Türen zu exklusiven
                  Vorteilen. Treten Sie jetzt bei!
                </div>
                <div className="d-flex mt-4 justify-content-center">
    <div
        className="my-auto"
        style={{ fontSize: 16, fontWeight: 600, color: isYearly ? "#A0ABC0" : "#000" }}
    >
        Monatlich
    </div>
    <label className="switch mx-4">
        <input
            type="checkbox"
            checked={isYearly}
            onChange={(e) => {
                setType(e.target.checked)
                setIsYearly(!isYearly)
            }}
        />
        <span className="slider round"></span>
    </label>
    <div
        className="my-auto"
        style={{ fontSize: 16, fontWeight: 600, color: isYearly ? "#000" : "#A0ABC0" }}
    >
        Jährlich
    </div>
</div>

                {/* <div className="d-flex mt-4 justify-content-center">
                  <div
                    className="my-auto"
                    style={{ fontSize: 16, fontWeight: 600, color: "#A0ABC0" }}
                  >
                    Monatlich
                  </div>
                  <label className="switch mx-4">
                    <input
                      type="checkbox"
                      checked={isYearly}
                      onChange={(e) => {
                        setType(e.target.checked)
                        setIsYearly(!isYearly)}}
                    />
                    <span className="slider round"></span>
                  </label>
                  <div
                    className="my-auto"
                    style={{ fontSize: 16, fontWeight: 600 }}
                  >
                    Jährlich
                  </div>
                </div> */}

                <div className="d-flex justify-content-center mt-3 mb-5 pb-5 mb-lg-0 pb-lg-0">
                  <div
                    class="card text-center"
                    style={{ width: "400px", borderRadius: 20 }}
                  >
                    <div className="d-flex justify-content-center pb-4 pt-5">
                      <img src={Icon} class="card-img-top" alt="..." />
                    </div>
                    <div class="card-body py-0 px-4">
                      <h5 class="card-title">Gold Mitglied</h5>
                      <p class="card-text py-3">
                        Mitglied werden, Fahrten buchen, exklusiven
                        Kundenservice erleben und noch viel mehr.
                      </p>
                      <div className="d-flex justify-content-center">
                        <h5 class="card-tit">{isYearly ? "22€" : "36€"}</h5>
                        <p
                          class="card-text text-start mx-3 mt-2"
                          style={{ lineHeight: 1 }}
                        >
                          Pro
                          <br />
                          {isYearly ? "Monat" : "Monat"}
                        </p>
                      </div>
                      <div className="pb-5">
                        <button
                          type="button"
                          class="btn btn-primary px-3 py-2 mt-2 w-100"
                          style={{
                            backgroundColor: "#25A9E0",
                            border: "none",
                            fontSize: 16,
                          }}
                          onClick={handleJetztbeitreten}
                        >
                          Jetzt beitreten
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        {/* <div className='container '>
            <div className='row py-2'>
                <div className='col-12 text-center'>
                    <div style={{fontSize:48,fontWeight:600}}>Mitgliedschaft</div>
                    <div className='mt-2' style={{fontSize:22,color:'#4A5468'}}>Ihre WarumLeer-Mitgliedschaft öffnet Türen zu exklusiven Vorteilen. Treten Sie jetzt bei!</div>
                    <div className='d-flex mt-3 justify-content-center'>
                        <div className='my-auto' style={{fontSize:16,fontWeight:600,color:'#A0ABC0'}}>Monatlich</div>
                        <label className="switch mx-4">
                            <input type="checkbox"/>
                            <span className="slider round"></span>
                        </label>
                        <div className='my-auto' style={{fontSize:16,fontWeight:600}}>Jährlich</div>
                    </div>
       
                    
                    <div className='d-flex justify-content-center mt-3'>
                        <div class="card text-center" style={{width: '400px',borderRadius:20,backgroundImage:'radial-gradient(#145D7B,#10475E)'}}>
                            <div className='d-flex justify-content-center pb-4 pt-5'>
                                <img src={Icon} class="card-img-top" alt="..." style={{width:'48px'}}/>
                            </div>
                            <div class="card-body py-0 px-4">
                                <h5 class="card-title" style={{fontSize:32,color:'#fff',fontWeight:600}}>Gold Mitglied</h5>
                                <p class="card-text py-3" style={{fontSize:18,color:'#CBD2E0'}}>Mitglied werden, Fahrten buchen, exklusiven Kundenservice erleben und noch viel mehr.</p>
                                <div className='d-flex justify-content-center'>
                                    <h5 class="card-tit" style={{fontSize:48,fontWeight:600,color:'#fff'}}>32€</h5>
                                    <p class="card-text text-start mx-3 mt-2" style={{lineHeight:1,fontSize:18,color:'#CBD2E0'}}>Pro<br/>Monat</p>
                                </div>
                                <div className='pb-5'>
                                    <button type="button" class="btn btn-primary px-3 py-2 mt-2 w-100" style={{backgroundColor:'#25A9E0',border:'none',fontSize:16}}>Jetzt beitreten</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        {/* <Footer/> */}
    </>
  )
}
