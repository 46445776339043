import React, { useState, useEffect } from "react";
import eye from "../../../assets/eye.png"
import openEye from "../../../assets/open-eye.png"
import sms from "../../../assets/sms.png"
import call from "../../../assets/call.png"
import user from "../../../assets/user.png"
import key from "../../../assets/key.png"
import register from "../../../assets/Register.png"
import emailred from "../../../assets/email-red.png"
import KeyRed from '../../../assets/key-red.png'
import UserRed from '../../../assets/user-red.png'
import PhoneRed from '../../../assets/call-red.png'
import "./register.css"
import { AUTENTICATE_API } from "../../../services/Api/authenticationApi";
import toast from "react-hot-toast";
import { json, useNavigate } from "react-router-dom";
import Header from "../../../common/zuruckheader";

const Register = (props) => {
    const navigate = useNavigate();
    const [emailErrFlag, setemailErrFlag] = useState(false);
    const [email, setemail] = useState('');
   
    const [passwordErrFlag, setpasswordErrFlag] = useState(false);
    const [passwordErr1, setpasswordErr1] = useState(false);
    const [password, setpassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [conpasswordErrFlag, setconpasswordErrFlag] = useState(false);
    const [conpassword, setconpassword] = useState('');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [name, setName] = useState('');
    const [nameErrFlag, setnameErrFlag] = useState(false);
    const [vorname, setVorName] = useState('');
    const [vornameErrFlag, setVornameErrFlag] = useState(false);
    const [nachname, setNachName] = useState('');
    const [nachnameErrFlag, setNachnameErrFlag] = useState(false);
    const [number, setNumber] = useState('');
    const [numberErrFlag, setnumberErrFlag] = useState(false);
    const [isUnternehmen, setIsUnternehmen] = useState(false);
    const [ust, setUst] = useState('');
    const [ustErrFlag, setUstErrFlag] = useState(false);
    const [userType, setuserType] = useState(1);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessage23, setErrorMessage23] = useState('');
    const [errorMessage1, setErrorMessage1] = useState('');
    const NavigateLogin = () => {
        navigate("/login")
    }
    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setpassword(value);
      
        const alphanumericRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,15}$/;
        const isValidPassword = alphanumericRegex.test(value);
        if (!isValidPassword) {
          setErrorMessage("Das Passwort muss 8-15 Zeichen lang sein und Großbuchstaben, Kleinbuchstaben und Zahlen enthalten.");
          setpasswordErrFlag(true);
          setpasswordErr1(true);
        } else {
          setErrorMessage("");
          setpasswordErrFlag(false);
          setpasswordErr1(false);
        }
      };
    useEffect(() => {
        setuserType(isUnternehmen ? 0 : 1);
        if(isUnternehmen===true){
            setVorName("");
            setNachName("");

        }
        else {
            setName("");
        }
    }, [isUnternehmen]);
    function validatePassword(p) {

        let errors = [];
        if (p.length < 8) {
            return true

            errors.push("Your password must be at least 8 characters");
        }
        if (p.search(/[a-z]/i) < 0) {
            return true

            errors.push("Your password must contain at least one letter.");
        }
        if (p.search(/[0-9]/) < 0) {
            return true
            errors.push("Your password must contain at least one digit.");
        }
        return false
    }
    const validateEmail = (email11) => {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email11)
    };
    const onRegister = async (event) => {
        event.preventDefault();
        if(numberErrFlag===true||email===""||password===""||conpassword==="")
            {
                toast.error('Bitte überprüfen Sie Ihre eingegebenen Daten.');
            }
        let abc = {
            first_name: vorname != undefined ? vorname : '',
            last_name: nachname != undefined ? nachname : '',
            phone_no: number,
            email: email,
            password: password,
            user_type: userType,
            company_name: name != undefined ? name : '',
            ust_id: ust,
        }
       
        let return_value = true;
        if (vorname == "" && number == "" && email == "" && password == "" && conpassword == "") {
           
            setnumberErrFlag(true);
            setnameErrFlag(true);
            setpasswordErrFlag(true);
            setconpasswordErrFlag(true);
            setemailErrFlag(true);
            setVornameErrFlag(true);
            setUstErrFlag(true);
            return_value = false
        }
        if (!number||numberErrFlag=== true) {
           
            setnumberErrFlag(true);
            return_value = false;
        }
        if (vorname == "" && userType == 1) {
           
            setVornameErrFlag(true);
            return_value = false;
        }
        if (name == "" && userType == 0) {
         
            setnameErrFlag(true);
            return_value = false
        }
      
  
        if (!validateEmail(email)) {
            
            setemailErrFlag(true);
            return_value = false;
        }
        if (validatePassword(password)) {
           
            setpasswordErrFlag(true);
            return_value = false
        }
        if (validatePassword(conpassword)) {
           
            setconpasswordErrFlag(true);
            return_value = false
        }
        if(passwordErr1===true){
            setpasswordErrFlag(true);
            setErrorMessage("Das Passwort muss 8-15 Zeichen lang sein und Großbuchstaben, Kleinbuchstaben und Zahlen enthalten.")
            return_value= false;
        }
        if (password != conpassword) {
            
            setconpasswordErrFlag(true);
            setErrorMessage23("Passwort muss übereinstimmen")
            return_value = false
        }
        if (!return_value) {
            return return_value
        }
        
            else{
       let response=await AUTENTICATE_API.email_mob_chjeck({email:email,phone_no:number})
       if(response?.status==200){
       if(response?.duplicates?.email || response?.duplicates?.phone_no){
            if(response?.duplicates.email==true) {
                setemailErrFlag(true);
                toast.error('E-Mail existiert bereits.');
            }
            else if (response?.duplicates.phone_no==true) {
                setnumberErrFlag(true);
                toast.error('Telefonnummer existiert bereits.');
            }
       }
       else
       {
          if(!response?.duplicates?.email && !response?.duplicates?.phone_no){
                sessionStorage.setItem("data",window.btoa(JSON.stringify(abc)))
                toast.success('Verifizierungscode wurde per E-Mail gesendet');
                setTimeout(() => {
                 navigate("/OTP_created")   
           }, 1500);     
       }
    }}
}
        
    }
    useEffect(()=>{
         setemail('')

    },[])
    return <React.Fragment>
        <Header />
        <form onSubmit={onRegister} autoComplete="off">
        <div className="container register">
            <div className="row mt-4">
                <div className="col-lg-5 col-xl-5 col-12 order-2 order-lg-1 mt-4 mt-lg-0 mb-5 mb-lg-0" style={{ background: '' }}>
                    <div className="text-center text-lg-start" style={{ color: "#000000", fontSize: 45, fontWeight: 800 }}>
                        Registrieren
                    </div>
                    <div className="my-xl-3 text-center text-lg-start" style={{ fontSize: 22, color: "#717D96" }}>
                        Willkommen bei WarumLeer! Registrieren Sie sich jetzt und seien Sie dabei, wenn wir den Autotransport neu definieren – effizient, sozial und nachhaltig.
                    </div>
                    <div className="col-12">
                        <div className="d-flex justify-content-center justify-content-lg-start mt-4">
                            <div className={`my-auto ${(isUnternehmen ? '' : 'font-bold')}`}>
                                Privat
                            </div>
                            <label className="switch mx-4">
                                <input
                                    type="checkbox"
                                    checked={isUnternehmen}
                                    onChange={(e) => setIsUnternehmen(e.target.checked)}
                                />
                                <span className="slider round"></span>
                            </label>
                            <div className={`my-auto ${(isUnternehmen ? 'font-bold' : '')}`}>
                                Unternehmen
                            </div>
                        </div>
                    </div>
                    {!isUnternehmen && (
                        <div className="row">
                            <div className="col-6 mt-xl-4 mt-lg-2 mt-3">
                                <img src={(vornameErrFlag == true ? UserRed : user)} className="eye" />
                                <input
                                    style={{textTransform:'capitalize'}}
                                    value={vorname}
                                    onChange={(e) => {
                                        setVornameErrFlag(false)
                                        setVorName(e.target.value);
                                       
                                    }}
                                    className="form-input form-control shadow-none con-passwords" id={`${(vornameErrFlag == true ? "nameErr1" : "password")}`}
                                    type="text"
                                    name="vorname"
                                    placeholder='Vorname'
                                    autoComplete={"off"}
                                />
                            </div>
                            <div className="col-6 mt-xl-4 mt-lg-2 mt-3">
                                <img src={nachnameErrFlag == true ? UserRed : user} className="eye" />
                                <input
                                    style={{textTransform:'capitalize'}}
                                    value={nachname}
                                    onChange={(e) => {
                                        setNachnameErrFlag(false)
                                        setNachName(e.target.value);
                                    }}
                                    className="form-input form-control shadow-none con-passwords"
                                    type="text"
                                    name="nachname"
                                    placeholder='Nachname'
                                />
                            </div>
                        </div>
                    )}
                    {isUnternehmen && (
                        <div className="col-12 mt-xl-4 mt-lg-2 mt-3">
                            <div className="form-wrap d-flex">
                                <img src={(nameErrFlag == true ? UserRed : user)} className="eye" />
                                <input value={name} onChange={(e) => {
                                    setnameErrFlag(false)
                                    setName(e.target.value);
                                }} 
                                style={{textTransform:'capitalize'}}
                                className="form-input form-control shadow-none con-passwords"
                                    id={`${nameErrFlag == true ? "nameErr1" : "password"}`} type="text"
                                    name="name" placeholder='Firmenname' />
                            </div>
                        </div>
                    )}
                    
                    <div className="col-12 mt-xl-4 mt-lg-2 mt-3">
  <div className="form-wrap d-flex">
    <img src={numberErrFlag ? PhoneRed : call} className="eye" />
    <input
      value={number}
      maxLength={14}
      onChange={(e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) { // Check if the value contains only digits
          setNumber(value);
          if (value.length >= 8 && value.length <= 14) {
            setnumberErrFlag(false);
            
          } else {
            setnumberErrFlag(true);
            setErrorMessage1('Telefonnummer muss zwischen 8 und 14 Zeichen lang sein')
          }
        }
      }}
      className={`form-input form-control shadow-none con-passwords ${numberErrFlag ? "nameErr1" : ""}`}
      id={numberErrFlag ? "nameErr1" : "password"}
      type="text"
      name="Telefonnummer"
      placeholder='Telefonnummer'
      autoComplete={'off'}
    />
  </div>
</div>
{ numberErrFlag===true && <div className="error-message">{errorMessage1}</div>}
                    <div className="col-12 mt-xl-4 mt-lg-2 mt-3">
                        <div className="form-wrap d-flex">
                            <img src={(emailErrFlag == true ? emailred : sms)} className="eye" />
                            <input type="text"  value={email} onChange={(e) => {
                                setemailErrFlag(false)
                                setemail(e.target.value);
                            }} className="form-input form-control shadow-none con-passwords" 
                            id={`${emailErrFlag == true ? "nameErr1" : "password"}`} 
                            placeholder='E-Mail'  />

                        </div>
                    </div>
                    <div className="col-12 mt-xl-4 mt-lg-2 mt-3">
                    <div className="form-wrap d-flex">
    <img src={passwordErrFlag ? KeyRed : key} className="eye" />
    <img
        onClick={() => setShowPassword(!showPassword)} 
        src={showPassword ? openEye : eye} 
        className="eye1"
    />
    <input
        type={showPassword ? "text" : "password"} 
        name="Passwort"

        value={password}
        onChange={handlePasswordChange}
        onCopy={(e)=>{
            e.preventDefault()
            return false;
          }} 
        className="form-input form-control shadow-none con-passwords u-p"
        id={`${passwordErrFlag ? "nameErr1" : "password"}`}
        placeholder="Passwort"
        maxLength={15}
        autocomplete="off"
    />
    
</div>           
{password && <div className="error-message">{errorMessage}</div>}             
                    </div>
                    <div className="col-12 mt-xl-4 mt-lg-2 mt-3">
                    <div className="form-wrap d-flex">
    <img src={conpasswordErrFlag ? KeyRed : key} className="eye" />
    <input
        value={conpassword}
        onChange={(e) => {
            setconpasswordErrFlag(false);
            setconpassword(e.target.value);
            if (password !== e.target.value) {
                // setErrorMessage("Passwort muss übereinstimmen");
                setconpasswordErrFlag(true);
            } else {
                setErrorMessage("");
                setconpasswordErrFlag(false);
            }
        }}
        className="form-input form-control shadow-none con-passwords con-p"
        id={`${conpasswordErrFlag ? "nameErr1" : "password"}`}
        type={showConfirmPassword ? "text" : "password"} 
        name="Passwort bestätigen"
        placeholder="Passwort bestätigen"
        maxLength={15}
    />
    <img
        onClick={() => setShowConfirmPassword(!showConfirmPassword)} 
        src={showConfirmPassword ? openEye : eye} 
        className="eye1"
    />
</div>
{conpasswordErrFlag && <div className="error-message">{errorMessage23}</div>}
                    </div>
                    <div className="col-12 mt-xl-4 mt-lg-2 mt-3">
                        <button onClick={onRegister} style={{ color: "#FFFFFF", fontSize: 14, background: "#25A9E0", border: "1px solid #25A9E0", borderRadius: 8, width: '100%', padding: 8 }}>Registrieren</button>
                    </div>
                    <div className="col-12 mt-xl-4 mt-lg-2 mt-3">
                        <div style={{ marginLeft: 5 }}>
                            <span style={{ color: "#000000", fontSize: 14 }}>Bereits Konto vorhanden?</span>
                            <span onClick={NavigateLogin} style={{ color: "#25A9E0", fontSize: 14, marginLeft: 15, cursor: 'pointer' }}>Einloggen</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-xl-6 col-12 my-auto offset-xl-1 order-1 order-lg-2 d-none d-lg-block">
                    <img src={register} style={{ width: "100%" }} />
                </div>
            </div>
        </div>
        </form>
    </React.Fragment>
}
export default Register;