import React ,{useState} from 'react'
import { Modal } from 'react-responsive-modal';
import toast from "react-hot-toast";
import axios from "axios";
import RequestSent from '../../../assets/CancelledTrip.png'
import Header from '../../../common/header'
import Footer from '../../../common/footer'
import { Link } from 'react-router-dom'

export default function CancelRidePage() {

    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [name, setName] = useState("")
    const [message, setMessage] = useState("")
    const [errr, setErrr] = useState("")
    const [phoneErr, setPhoneErr] = useState(false)
    const [showMenu, setShowMenu] = useState(true)
    const [contactBack, setContactBack] = useState(0);
      const onOpenModal = () => {
          setOpen(true)
          // document.getElementById("navbarSupportedContent")?.classList.remove("show");
        };
        const onCloseModal = () => setOpen(false);
      
        const onSumbit=()=>{
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!name || !email || !phone || !message) {
            setErrr("Bitte füllen Sie alle markierten Felder aus");
            setTimeout(() => {
              setErrr("");
            }, 3000);
            return;
          }
      
          if (!emailRegex.test(email)) {
            setErrr("Bitte füllen Sie alle markierten Felder aus");
            setTimeout(() => {
              setErrr("");
            }, 3000);
            return;
          }
      
          if (phone.length < 8 || phone.length > 14) {
            setErrr("Telefonnummer muss zwischen 8 und 14 Zeichen lang sein");
            setTimeout(() => {
              setErrr("");
            }, 3000);
            return;
          }
          setOpen(false);
      
           
          https: axios
          .post("https://api.warumleer.de/api/api/v1/contect_us", {
            Name: name,
            email_id: email,
            is_checked: contactBack,
            mobile_no: phone,
            description: message,
          })
          .then((res) => {
            if ( res?.status == 201) {
              toast.success("Ihre Anfrage wurde übermittelt", {
                autoClose: 2000,
              });
    
              setTimeout(() => {
                setEmail("");
                setName("");
                setMessage("");
                setPhone("");
                setContactBack("");
              }, 2500);
            }
          })
          .catch((err) => {
            
          });
      };
    
      const closeT = () => {
        document.getElementById("navbarSupportedContent")?.classList.remove("show");
        }

  return (
    <>
    <Header/>
    <div className='pt-5 pading-mobile' >
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-12'>
                    <div className='text-center' style={{fontSize:40,fontWeight:600}}>Fahrt erfolgreich storniert</div>
                </div>
                <div className='col-md-6 col-12 px-5'>
                    <div className='mt-3 px-lg-5'>
                        <img className='px-lg-5' src={RequestSent} width={"100%"}/>
                    </div>
                    <div className='text-center mt-4' style={{ fontSize: 22, color: '#717D96' }}>Die Fahrt wurde erfolgreich storniert.</div>
              <div className='text-center mt-3 mb-4' style={{ fontSize: 22, color: '#717D96' }}>
Der Fahrer wurde informiert, und die Fahrt wird nun wieder in der Suche angezeigt.</div>
                    <div className='d-flex justify-content-center'>
                        <div className='text-center text-lg-start mb-5'>
                            <button type="button" class="btn btn-outline-secondary px-3 py-2 my-md-auto mt-3" style={{background:'#25A9E0',border:'#25A9E0',color:'#fff',fontSize:16}}><Link to="/" style={{textDecoration:'none',color:'#fff'}}>Zurück zur Startseite</Link></button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    <Footer/>
    </>
  )
}