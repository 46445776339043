import React from 'react'
import Header from '../../../common/header'
import Footer from '../../../common/footer'
export default function Datenschutz() {
  return (
    <>
    <Header/>
    <div style={{ backgroundImage: 'radial-gradient(#145d7b, #10475e)' }}>
        <div className='container'>
            <div className='row'>
                <div className='col-12'>
                    <div className='text-center text-white py-4' style={{ fontSize: 40, fontWeight: 'bold' }}>Datenschutz</div>
                </div>
            </div>
        </div>
    </div>

    <div className='container'>
        <div className='row mt-5 pb-5'>
            <div className='col-12 pb-4'>
                <div style={{fontSize:24,fontWeight:'bold'}}>Datenschutzerklärung</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>1. Datenschutz auf einen Blick</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Allgemeine Hinweise</div>

                <div className='mt-3 impressum-text'>Der Plattformbetreiber betreibt eine Plattform, auf der u. a. personenbezogene Daten verarbeitet werden. Die Plattform ermöglicht es, Leerfahrten von Kraftfahrzeugen zu minimieren, indem diese für Transporte genutzt werden können, die über Ihre Plattform ausgewiesen werden. Die Plattform stellt hierbei den Kontakt zwischen dem Fahrer, der eine Leerfahrt anbietet und dem Suchenden her.</div>

                <div className='mt-3 impressum-text'>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Datenerfassung auf dieser Website</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</div>

                <div className='mt-3 impressum-text'>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Wie erfassen wir Ihre Daten?</div>

                <div className='mt-3 impressum-text'>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben.</div>

                <div className='mt-3 impressum-text'>Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Wofür nutzen wir Ihre Daten?</div>

                <div className='mt-3 impressum-text'>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Welche Rechte haben Sie bezüglich Ihrer Daten?</div>

                <div className='mt-3 impressum-text'>Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.</div>

                <div className='mt-3 impressum-text'>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Analyse-Tools und Tools von Drittanbietern</div>

                <div className='mt-3 impressum-text'>Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit sogenannten Analyseprogrammen.</div>

                <div className='mt-3 impressum-text'>Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Verifizierung von Nutzern und Kommunikation</div>

                <div className='mt-3 impressum-text'>Im Rahmen der Plattform verarbeitet der Plattformbetreiber alle Informationen, die für die Verifizierung der Plattform und die Kontaktherstellung zwischen den Fahrern und den Suchenden erforderlich sind. Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. b DSGVO (Vertragserfüllung). </div>

                <div className='mt-1 impressum-text'>Im Einzelnen finden folgende Datenverarbeitungsvorgänge statt:</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Verifizierung</div>

                <div className='mt-3 impressum-text'>Zum Zwecke der Verifizierung werden von privaten Nutzern mindestens Ausweis und Selfie verarbeitet. Bei Unternehmern wird zusätzlich der Gewerbenachweis und der Versicherungsnachweis verarbeitet. Diese Daten werden ausschließlich zur Verifizierung verarbeitet.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Kontaktvermittlung</div>

                <div className='mt-3 impressum-text'>Für die Vermittlung der Kontakte zwischen Fahrer und Suchendem, werden berechtigten Suchenden Firmenamen, E-Mail-Adressen, Telefonnummern, Routen, Name des Fahrers und ggf. weitere relevante Fahrerinformationen angezeigt.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Verarbeitung von Telefonnummern, E-Mail-Adressen und sonstigen Kontaktdaten</div>

                <div className='mt-3 impressum-text'>Innerhalb der Plattform werden Telefonnummern verarbeitet, um Nutzer zu verifizieren (Verifizierung-SMS) und Nachrichten zu verschicken.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>2. Hosting</div>

                <div className='mt-3 impressum-text'>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Amazon Web Services (AWS)</div>

                <div className='mt-3 impressum-text'>Anbieter ist die Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, 1855 Luxemburg (nachfolgend AWS).</div>

                <div className='mt-3 impressum-text'>Wenn Sie unsere Website besuchen, werden Ihre personenbezogenen Daten auf den Servern von AWS verarbeitet. Hierbei können auch personenbezogene Daten an das Mutterunternehmen von AWS in die USA übermittelt werden. Die Datenübertragung in die USA wird auf die EU-Standardvertragsklauseln gestützt. Details finden Sie hier:<a className="link-url" href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/" target='_blank'> https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/.</a></div>

                <div className='mt-3 impressum-text'>Weitere Informationen entnehmen Sie der Datenschutzerklärung von AWS: <a className="link-url" href="https://aws.amazon.com/de/privacy/?nc1=f_pr" target='_blank'>https://aws.amazon.com/de/privacy/?nc1=f_pr.</a></div>

                <div className='mt-3 impressum-text'>Die Verwendung von AWS erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer möglichst zuverlässigen Darstellung unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TDDDG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TDDDG umfasst. Die Einwilligung ist jederzeit widerrufbar.</div>

                <div className='mt-3 impressum-text'>Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der Europäischen Union und den USA, der die Einhaltung europäischer Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem </div>
                <div className='mt-3 impressum-text'>Link: <a className="link-url" href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active" target="_blank"> https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active</a></div>

               

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Auftragsverarbeitung</div>

                <div className='mt-3 impressum-text'>Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>3. Allgemeine Hinweise und Pflichtinformationen</div>
                
                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Datenschutz</div>

                <div className='mt-3 impressum-text'>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</div>

                <div className='mt-3 impressum-text'>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.</div>

                <div className='mt-3 impressum-text'>Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Hinweis zur verantwortlichen Stelle</div>

                <div className='mt-3 impressum-text'>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</div>

                <div className='mt-3 impressum-text'>Tolga Bugdaci<br/>Am Stadthafen 19<br/>45356 Essen</div>

                <div className='mt-3 impressum-text'>Telefon: 0201 325865</div>

                <div className='mt-3 impressum-text'>E-Mail: tolga@warumleer.de</div>

                <div className='mt-3 impressum-text'>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Speicherdauer</div>

                <div className='mt-3 impressum-text'>Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</div>

                <div className='mt-3 impressum-text'>Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TDDDG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden Absätzen dieser Datenschutzerklärung informiert.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Empfänger von personenbezogenen Daten</div>

                <div className='mt-3 impressum-text'>Im Rahmen unserer Geschäftstätigkeit arbeiten wir mit verschiedenen externen Stellen zusammen. Dabei ist teilweise auch eine Übermittlung von personenbezogenen Daten an diese externen Stellen erforderlich. Wir geben personenbezogene Daten nur dann an externe Stellen weiter, wenn dies im Rahmen einer Vertragserfüllung erforderlich ist, wenn wir gesetzlich hierzu verpflichtet sind (z. B. Weitergabe von Daten an Steuerbehörden), wenn wir ein berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO an der Weitergabe haben oder wenn eine sonstige Rechtsgrundlage die Datenweitergabe erlaubt. Beim Einsatz von Auftragsverarbeitern geben wir personenbezogene Daten unserer Kunden nur auf Grundlage eines gültigen Vertrags über Auftragsverarbeitung weiter. Im Falle einer gemeinsamen Verarbeitung wird ein Vertrag über gemeinsame Verarbeitung geschlossen.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Widerruf Ihrer Einwilligung zur Datenverarbeitung</div>

                <div className='mt-3 impressum-text'>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)</div>

                <div className='mt-3 impressum-text'>WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).</div>

                <div className='mt-3 impressum-text'>WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Beschwerderecht bei der zuständigen Aufsichtsbehörde</div>

                <div className='mt-3 impressum-text'>Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Recht auf Datenübertragbarkeit</div>

                <div className='mt-3 impressum-text'>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Auskunft, Berichtigung und Löschung</div>

                <div className='mt-3 impressum-text'>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Recht auf Einschränkung der Verarbeitung</div>

                <div className='mt-3 impressum-text'>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:</div>

                <div className='mt-3'>
                    <ul>
                        <li style={{fontSize:24}}>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
                        <li className='mt-3 impressum-text'>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.</li>
                        <li className='mt-3 impressum-text'>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
                        <li className='mt-3 impressum-text'>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
                    </ul>
                </div>

                <div className='mt-3 impressum-text'>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>SSL- bzw. TLS-Verschlüsselung</div>

                <div className='mt-3 impressum-text'>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://xn--hvg auf „https://xn--hvg wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</div>

                <div className='mt-3 impressum-text'>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>4. Datenerfassung auf dieser Website</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Cookies</div>

                <div className='mt-3 impressum-text'>Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Datenpakete und richten auf Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.</div>

                <div className='mt-3 impressum-text'>Cookies können von uns (First-Party-Cookies) oder von Drittunternehmen stammen (sog. Third-Party-Cookies). Third-Party-Cookies ermöglichen die Einbindung bestimmter Dienstleistungen von Drittunternehmen innerhalb von Webseiten (z. B. Cookies zur Abwicklung von Zahlungsdienstleistungen).</div>

                <div className='mt-3 impressum-text'>Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte Webseitenfunktionen ohne diese nicht funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies können zur Auswertung des Nutzerverhaltens oder zu Werbezwecken verwendet werden.</div>

                <div className='mt-3 impressum-text'>Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur Optimierung der Website (z. B. Cookies zur Messung des Webpublikums) erforderlich sind (notwendige Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von notwendigen Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TDDDG); die Einwilligung ist jederzeit widerrufbar.</div>

                <div className='mt-3 impressum-text'>Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.</div>

                <div className='mt-3 impressum-text'>Welche Cookies und Dienste auf dieser Website eingesetzt werden, können Sie dieser Datenschutzerklärung entnehmen.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>CCM19</div>

                <div className='mt-3 impressum-text'>Unsere Website nutzt CCM19, um Ihre Einwilligung zur Speicherung bestimmter Cookies auf Ihrem Endgerät oder zum Einsatz bestimmter Technologien einzuholen und diese datenschutzkonform zu dokumentieren. Anbieter dieser Technologie ist Papoo Software & Media GmbH, Auguststr. 4, 53229 Bonn (im Folgenden „CCM19“).</div>

                <div className='mt-3 impressum-text'>Wenn Sie unsere Website betreten, wird eine Verbindung zu den Servern von CCM19 hergestellt, um Ihre Einwilligungen und sonstigen Erklärungen zur Cookie-Nutzung einzuholen. Anschließend speichert CCM19 einen Cookie in Ihrem Browser, um Ihnen die erteilten Einwilligungen bzw. deren Widerruf zuordnen zu können. Die so erfassten Daten werden gespeichert, bis Sie uns zur Löschung auffordern, den CCM19-Cookie selbst löschen oder der Zweck für die Datenspeicherung entfällt. Zwingende gesetzliche Aufbewahrungspflichten bleiben unberührt.</div>

                <div className='mt-3 impressum-text'>Der Einsatz von CCM19 erfolgt, um die gesetzlich vorgeschriebenen Einwilligungen für den Einsatz von Cookies einzuholen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 S. 1 lit. c DSGVO.</div>

                <div className='mt-3 impressum-text'>Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Server-Log-Dateien</div>

                <div className='mt-3 impressum-text'>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:</div>

                <div className='mt-3'>
                    <ul>
                        <li style={{fontSize:24}}>Browsertyp und Browserversion</li>
                        <li style={{fontSize:24}}>verwendetes Betriebssystem</li>
                        <li style={{fontSize:24}}>Referrer URL</li>
                        <li style={{fontSize:24}}>Hostname des zugreifenden Rechners</li>
                        <li style={{fontSize:24}}>Uhrzeit der Serveranfrage</li>
                        <li style={{fontSize:24}}>IP-Adresse</li>
                    </ul>
                </div>

                <div className='mt-3 impressum-text'>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</div>

                <div className='mt-3 impressum-text'>Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website – hierzu müssen die Server-Log-Files erfasst werden.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Kontaktformular</div>

                <div className='mt-3 impressum-text'>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</div>

                <div className='mt-3 impressum-text'>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.</div>

                <div className='mt-3 impressum-text'>Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Anfrage per E-Mail, Telefon oder Telefax</div>

                <div className='mt-3 impressum-text'>Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</div>

                <div className='mt-3 impressum-text'>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.</div>

                <div className='mt-3 impressum-text'>Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>5. Analyse-Tools und Werbung</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Google Analytics</div>

                <div className='mt-3 impressum-text'>Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.</div>

                <div className='mt-3 impressum-text'>Google Analytics ermöglicht es dem Websitebetreiber, das Verhalten der Websitebesucher zu analysieren. Hierbei erhält der Websitebetreiber verschiedene Nutzungsdaten, wie z. B. Seitenaufrufe, Verweildauer, verwendete Betriebssysteme und Herkunft des Nutzers. Diese Daten werden dem jeweiligen Endgerät des Users zugeordnet. Eine Zuordnung zu einer User-ID erfolgt nicht.</div>

                <div className='mt-3 impressum-text'>Des Weiteren können wir mit Google Analytics u. a. Ihre Maus- und Scrollbewegungen und Klicks aufzeichnen. Ferner verwendet Google Analytics verschiedene Modellierungsansätze, um die erfassten Datensätze zu ergänzen und setzt Machine-Learning-Technologien bei der Datenanalyse ein.</div>

                <div className='mt-3 impressum-text'>Google Analytics verwendet Technologien, die die Wiedererkennung des Nutzers zum Zwecke der Analyse des Nutzerverhaltens ermöglichen (z. B. Cookies oder Device-Fingerprinting). Die von Google erfassten Informationen über die Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.</div>

                <div className='mt-3 impressum-text'>Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TDDDG. Die Einwilligung ist jederzeit widerrufbar.</div>

                <div className='mt-3 impressum-text'>Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier:<a className="link-url" href ="https://privacy.google.com/businesses/controllerterms/mccs/" target='_blank'>https://privacy.google.com/businesses/controllerterms/mccs/.</a></div>

                <div className='mt-3 impressum-text'>Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der Europäischen Union und den USA, der die Einhaltung europäischer Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem </div>

                <div className='mt-3 impressum-text'>Link:<a className="link-url" href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active" target='_blank'>https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active</a></div>

           

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>IP Anonymisierung</div>

                <div className='mt-3 impressum-text'>Die Google Analytics IP-Anonymisierung ist aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Browser Plugin</div>

                <div className='mt-3 impressum-text'>Sie können die Erfassung und Verarbeitung Ihrer Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:<a className="link-url" href="https://tools.google.com/dlpage/gaoptout?hl=de" target='_blank'>https://tools.google.com/dlpage/gaoptout?hl=de.</a></div>

                <div className='mt-3 impressum-text'>Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google:<a className="link-url" href="https://support.google.com/analytics/answer/6004245?hl=de" target='_blank'>https://support.google.com/analytics/answer/6004245?hl=de.</a></div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Auftragsverarbeitung</div>

                <div className='mt-3 impressum-text'>Wir haben mit Google einen Vertrag zur Auftragsverarbeitung abgeschlossen und setzen die strengen Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>6. Plugins und Tools</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Google Maps</div>

                <div className='mt-3 impressum-text'>Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland. Mit Hilfe dieses Dienstes können wir Kartenmaterial auf unserer Website einbinden.</div>

                <div className='mt-3 impressum-text'>Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP-Adresse zu speichern. Diese Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung. Wenn Google Maps aktiviert ist, kann Google zum Zwecke der einheitlichen Darstellung der Schriftarten Google Fonts verwenden. Beim Aufruf von Google Maps lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.</div>

                <div className='mt-3 impressum-text'>Die Nutzung von Google Maps erfolgt zur Vertragserfüllung (Art. 6 Abs.1 lit. b DSGVO). Die Vermittlung von Leerfahrten kann ohne digitale Karten nicht angeboten werden, sodass der Vertragszweck nicht ohne Google Maps erfüllt werden kann.</div>

                <div className='mt-3 impressum-text'>Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier:<a className="link-url" href="https://privacy.google.com/businesses/gdprcontrollerterms" target='_blank'>https://privacy.google.com/businesses/gdprcontrollerterms/</a> und <a className="link-url" href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/" target='_blank'>https://privacy.google.com/businesses/gdprcontrollerterms/sccs/.</a></div>

                <div className='mt-3 impressum-text'>Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google:<a className="link-url" href="https://policies.google.com/privacy?hl=de" target='_blank'>https://policies.google.com/privacy?hl=de.</a></div>

                <div className='mt-3 impressum-text'>Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der Europäischen Union und den USA, der die Einhaltung europäischer Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:<a className="link-url" href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active" target='_blank'>https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active</a></div>
            </div>
        </div>
    </div>
    {/* <Footer/> */}
    </>
  )
}
