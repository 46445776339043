import React, { useEffect, useState } from 'react'
import Successful from '../../../assets/booking-approved.png'
import { Link, useNavigate } from "react-router-dom";
import Header from '../../../common/zuruckheader';
import Footer from '../../../common/footer';

export default function BookingSuccessful() {
    const navigate=useNavigate();
    

  return (
    <>
    {/* <Header/> */}
    <div className='container'>
        <div className='row justify-content-center pt-5'>
            <div className='col-xl-7 col-lg-8 col-md-10 col-12'>
                <div className='text-center' style={{fontSize:40,fontWeight:600}}>Buchungsanfrage bestätigt</div>
                <div className='d-flex justify-content-center mt-4 px-lg-5 mx-lg-5'>
                    <div className='px-lg-5'>
                        <img className='px-5' src={Successful} width={'100%'}/>
                    </div>
                </div>
                <div className='text-center px-3 mt-4' style={{fontSize:22,color:'#717D96'}}>
                Sie haben die Buchungsanfrage erfolgreich bestätigt!<br className='d-none d-lg-block'/> In Kürze erhalten Sie alle wichtigen Details noch einmal per E-Mail.
                </div>
                <div className='text-center px-3 mt-4' style={{fontSize:22,color:'#717D96'}}>
                Wir wünschen Ihnen eine angenehme Fahrt!
                </div>
                <div className='d-flex justify-content-center mt-4'>
                    <div className='d-flex justify-content-center text-center text-lg-start mb-5 mb-lg-0'>
                        <button type="button" class="btn btn-primary px-3 py-2 my-auto" style={{backgroundColor:'#25A9E0',border:'none',fontSize:16,marginRight:20}}>
                            <Link to={'/'} style={{textDecoration:'none', color:'#ffffff'}}>Zurück zur Startseite</Link>
                        </button>
                        <button type="button" class="btn btn-outline-secondary px-3 py-2 my-md-auto" style={{color:'#25A9E0',fontSize:16}}>
                            <Link to={'/publishedtrips'} style={{textDecoration:'none', color:'#25A9E0'}}>Buchung verwalten</Link>
                        </button>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <Footer/> */}
    </>
  )
}
