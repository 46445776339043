import React from 'react'
import Header from '../../../common/header'
import Footer from '../../../common/footer'
export default function Widerrufsbelehrung() {
  return (
    <>
    <Header/>
    <div style={{ backgroundImage: 'radial-gradient(#145d7b, #10475e)' }}>
        <div className='container'>
            <div className='row'>
                <div className='col-12'>
                    <div className='text-center text-white py-4' style={{ fontSize: 34, fontWeight: 'bold' }}>Widerrufsbelehrung</div>
                </div>
            </div>
        </div>
    </div>

    <div className='container'>
        <div className='row mt-5 pb-5'>
            <div className='col-12'>
                <div style={{fontSize:24,fontWeight:'bold'}}>Widerrufsbelehrung</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Widerrufsrecht</div>

                <div className='mt-3 impressum-text'>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.</div>

                <div className='mt-3 impressum-text'>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Tolga Bugdaci, Am Stadthafen 19, 45356 Essen, Telefon: 0201 325865, E-Mail: tolga@warumleer.de) mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief oder eine E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.</div>

                <div className='mt-3 impressum-text'>Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Folgen des Widerrufs</div>

                <div className='mt-3 impressum-text'>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.</div>

                <div className='mt-3 impressum-text'>Haben Sie verlangt, dass die Dienstleistung während der Widerrufsfrist beginnen soll, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.</div>

                <div className='mt-3' style={{fontSize:24,fontWeight:'bold'}}>Muster-Widerrufsformular</div>

                <div className='mt-3 impressum-text'>Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.</div>

                <div className='mt-3 impressum-text'>An</div>

                <div className='mt-3 impressum-text'>
                    Tolga Bugdaci<br/>
                    Am Stadthafen 19<br/>
                    45356 Essen<br/>
                    E-Mail: tolga@warumleer.de
                </div>

                <div className='mt-3 impressum-text'>Hiermit widerrufe(n) ich/wir den von mir/uns abgeschlossenen Vertrag über die Erbringung der folgenden Dienstleistung (Bezeichnung, ggf. Bestellnummer und Preis):</div>

                <div className='mt-3 impressum-text'>.......................................................</div>

                <div className='mt-3 impressum-text'>.......................................................</div>

                <div className='mt-3 impressum-text'>Bestellt am (Datum):</div>

                <div className='mt-3 impressum-text'>.............................</div>

                <div className='mt-3 impressum-text'>Erhalten am (Datum): </div>

                <div className='mt-1 impressum-text'>.............................</div>

                <div className='mt-3 impressum-text'>Name und Anschrift des Verbrauchers:</div>

                <div className='mt-3 impressum-text'>.............................</div>

                <div className='mt-3 impressum-text'>.............................</div>

                <div className='mt-3 impressum-text'>.............................</div>

                <div className='mt-3 impressum-text'>.............................</div>

                <div className='mt-3 impressum-text'>Datum:</div>

                <div className='mt-3 impressum-text'>....................................................</div>

                <div className='mt-3 impressum-text'>Unterschrift Kunde (nur bei schriftlichem Widerruf):</div>

                <div className='mt-3 mb-5' style={{fontSize:24}}>....................................................</div>
            </div>
        </div>
    </div>
    {/* <Footer/> */}
    </>
  )
}
