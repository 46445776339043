import React,{useState} from 'react'
import Timeline from '../../../assets/range-company.png'
import { useDispatch, useSelector } from "react-redux";
import { setDescription } from "../../redux/redux/actions/locationActions"; 
import { LOCATION_API } from "./../../../../src/services/Api/locationApi";
import Circle from '../../../assets/circle-shape.png'
import { useNavigate } from 'react-router-dom'
import Header from '../../../common/zuruckheader';
import toast from 'react-hot-toast';

export default function IdVerificationCompany() {
  const Navigate = useNavigate();
  const [info, setInfo] = useState("");
  const route = useSelector((state) => state.location.route);
  const stopLocation = useSelector((state) => state.location.stopLocation);
  const noOfFields = useSelector((state) => state.location.stops);
  const pickup_date = useSelector((state) => state.location.pickup_date);
  const pickup_time = useSelector((state) => state.location.pickup_time);
  const no_of_cars = useSelector((state) => state.location.no_of_cars);
  const weight = useSelector((state) => state.location.weight);
  const visibleInfo = useSelector((state) => state.location.information);
  const [documentDataExist, setDocumentDataExist] = useState("");
  const storedUserString = localStorage.getItem("user");
  const storedUserObject = JSON.parse(storedUserString);
  const userInfo = storedUserObject.userInfo;
  const dispatch = useDispatch(); // Initializing dispatch
  const departToLocation = useSelector(
    (state) => state.location.departToLocation
  );
  const departFromLocation = useSelector(
    (state) => state.location.departFromLocation
  );
  const nav=useNavigate();

  const id = userInfo.user_id;
  const handleContinue = async () => {
    try {
      let descriptionText = info !== undefined && info.trim() !== '' ? info : "Der Fahrer hat keine Information zu seiner Fahrt angegeben.";

    const rep=await LOCATION_API.saveLocationData({
        depart_to: departToLocation.departTo,
        depart_from: departFromLocation.departFrom,
        stopLocation: stopLocation.location,
        route: route.route,
        user_id: id,
        no_of_stop: JSON.stringify(noOfFields),
        pickup_date: pickup_date,
        pickup_time: pickup_time,
        weight: weight,
        no_of_cars: no_of_cars,
        description: descriptionText,
        distance: route.distance,
        location_time: route.time,
        visible_email: visibleInfo.email,
        visible_phone_no: visibleInfo.phone_no,
      });
      if(rep!=undefined && rep.status==201){
        toast.success("Ihre Daten wurden gespeichert",{autoClose:1500});
        setTimeout(() => {
          nav("/")
        }, 2000);
    }
      dispatch(setDescription(info)); 
      return true;
    } catch (error) {
      console.error("Error saving location:", error);
    }
  };
  const handleDocsUpload = async () => {
    try {
      let descriptionText = info !== undefined && info.trim() !== '' ? info : "Der Fahrer hat keine Information zu seiner Fahrt angegeben.";

    const rep=await LOCATION_API.saveLocationData({
        depart_to: departToLocation.departTo,
        depart_from: departFromLocation.departFrom,
        stopLocation: stopLocation.location,
        route: route.route,
        user_id: id,
        no_of_stop: JSON.stringify(noOfFields),
        pickup_date: pickup_date,
        pickup_time: pickup_time,
        weight: weight,
        no_of_cars: no_of_cars,
        description: descriptionText,
        distance: route.distance,
        location_time: route.time,
        visible_email: visibleInfo.email,
        visible_phone_no: visibleInfo.phone_no,
      });
      if(rep!=undefined && rep.status==201){
        
        setTimeout(() => {
          nav("/UploadPictureCompany")
        }, 2000);
    }
      dispatch(setDescription(info)); 
      return true;
    } catch (error) {
      console.error("Error saving location:", error);
    }
  };
  return (
    <>
    <Header/>
    <div className='container py-5'>
        <div className='row justify-content-md-center'>
            <div className='col-lg-10 col-12'>
                <div className='text-center' style={{fontSize:32,fontWeight:600,color:'#000'}}>Bitte bestätigen Sie Ihr Unternehmen</div>
                <div className='text-center' style={{fontSize:18,color:'#717D96'}}>Wir wissen, es ist lästig, aber um die Sicherheit unserer Nutzer zu gewährleisten, müssen wir vorerst jedes<br className='d-none d-lg-block'/> Unternehmen einmalig überprüfen und bestätigen. Bitte nehmen Sie sich kurz die Zeit dafür.</div>
                <div className='d-none d-md-block d-md-flex justify-content-center'>
                  <img className='mt-5' src={Timeline} width={"66%"}/>
                </div>
                <div className='row mt-4'>
                  <div className='col-md-5 col-12 text-center'>
                    <div className='d-flex justify-content-center'>
                      <img className='d-md-none' src={Circle} width={'32px'}/>
                    </div>
                    <div className='mt-2 mt-md-0' style={{fontSize:24,fontWeight:600}}>Schritt 1</div>
                    <div className='mt-2' style={{fontSize:21,color:'#4A5468'}}>Gewerbenachweis <br/>(Gewerbeanmeldung oder Gewerbeschein)</div>
                  </div>
                  <div className='col-md-2 col-12 text-center mt-4 mt-md-0'>
                    
                  </div>
                  <div className='col-md-5 col-12 text-center mt-4 mt-md-0'>
                    <div className='d-flex justify-content-center'>
                      <img className='d-md-none' src={Circle} width={'32px'}/>
                    </div>
                    <div className='mt-2 mt-md-0' style={{fontSize:24,fontWeight:600}}>Schritt 2</div>
                    <div className='mt-2' style={{fontSize:21,color:'#4A5468'}}>Versicherungsnachweis</div>
                  </div>
                </div>
                <div className='mx-4 mt-5 text-center' style={{fontSize:18,color:'#717D96'}}>Ihre Daten sind bei uns zu <span style={{color:'#25A9E0'}}>100% sicher</span> und werden ausschließlich zur Identitätsfeststellung verwendet. Wir nehmen den Datenschutz ernst und behandeln Ihre Informationen vertraulich.</div>
                <div className='d-md-flex justify-content-center mt-5 text-center'>
                  <button type="button" 
                   onClick={handleDocsUpload}
                  // onClick={()=>{Navigate("/UploadPictureCompany")}} 
                  class="btn btn-primary px-3 py-2 my-auto" style={{backgroundColor:'#25A9E0',border:'none',fontSize:16}}>Verifizierung starten</button>
                  <button type="button" class="btn btn-outline-secondary mt-3 mt-md-0 mx-3 px-3 py-2 my-auto" style={{color:'#25A9E0',fontSize:16}} onClick={handleContinue}>Speichern und später bestätigen</button>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}