import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setDepartFromLocation,
  setDepartToLocation,removeAllStop
} from "../../redux/redux/actions/locationActions";

import { LOCATION_API } from "./../../../services/Api/locationApi";
import Location from "../../../assets/Vector.svg";
import locationRed from "../../../assets/locationRed.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { StandaloneSearchBox } from "@react-google-maps/api";
import "./DepartFrom.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Header from "../../../common/zuruckheader";

export default function DepartFrom() {
  const [fromLocation, setFromLocation] = useState("");

  const dispatch = useDispatch();

  const departToLocation = useSelector((state) => state.location.departTo);

  const [locationFlag, setLocationFlag] = useState(false);
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedLat, setSelectedLat] = useState("");
  const [selectedLon, setSelectedLon] = useState("");
const[latlng,setLatLng] = useState("");
  const searchBoxRef = useRef(null); // Create a ref for StandaloneSearchBox

  const setValue = (val, lat, lon) => {
    setFromLocation(val);
    setShowOptions(false);
    setSelectedLat(lat);
    setSelectedLon(lon);
  };
  const handleChange = (address) => {
    setFromLocation(address);
  };

  // useEffect(() => {
  //   let depTo = localStorage.getItem("DepartFrom");
  //   if (depTo) {
  //     let dt = JSON.parse(depTo)
  //     setFromLocation(dt?.address);
  //   } else {
  //     let obj = { "address": "", "latLng": { "lat": "", "lng": "" } };
  //     localStorage.setItem("DepartFrom", JSON.stringify(obj));
  //   }

  // }, [])

  const handleSelect = async (address) => {
    setFromLocation(address);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setLatLng(latLng);
      dispatch(setDepartFromLocation(address, latLng.lat, latLng.lng));
      let obj = {
        address,
        latLng
      }
      localStorage.setItem("DepartFrom", JSON.stringify(obj));
      // localStorage.setItem("from", address);

    } catch (error) {
      console.error("Error fetching geolocation:", error);
    }
  };

  const handleContinue = () => {
    if(latlng){
    if (fromLocation.trim() === "") {
      setLocationFlag(true);
      return false;
    } else {
      navigate("/TakeRoute");
      return true;
    }
  }
  else{
    setLocationFlag(true);
    return false;
  }
  };

  const handleButtonClick = async () => {
    const continueClicked = await handleContinue();
    if (continueClicked) {
      navigate("/TakeRoute");
    }
  };
  useEffect(()=>{
    dispatch(removeAllStop("check"))
  },[])

 

  return (
    <>
      <Header />
      <div className="container mt-lg-5 depart-to">
        <div className="row justify-content-center mt-lg-5 pt-5">
          <div className="col-lg-8 col-12">
            <div
              className="text-center"
              style={{ fontSize: 40, fontWeight: 600 }}
            >
              Wohin fahren Sie?
            </div>
            <div>
              <div className="inputWithIcon mt-4">
                <PlacesAutocomplete
                  value={fromLocation}
                  onChange={handleChange}
                  onSelect={handleSelect}
                  searchOptions={{
                    types: ["(cities)"],
                    componentRestrictions: {
                      country: "DE",
                    },
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input style={{borderRadius:"15px",width:"100%"}}
                    id={`${locationFlag === true ? "error" : ""}`}
                        {...getInputProps({
                          placeholder: "Geben Sie die Stadt oder den Ort ein",
                          className: "py-3  depart-sec",
                          // style: { fontSize: 22 },
                        })}
                      />
                      <img
                        src={locationFlag === true ? locationRed : Location}
                        width={"20px"}
                      />
                      {suggestions?.length>0?
                      <div className="suggestions-container py-3">
                        {loading ? <div>Laden...</div> : null}

                        {suggestions.map((suggestion, index) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#41b6e6"
                              : "#fff",
                            borderBottom: "1px solid #ddd", // Add bottom border
                            padding: "8px", // Add padding
                          };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, { style })}
                              key={index}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                      :""}
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              {addresses.length > 0 && (
                <div className="dropdown mt-2">
                  <div
                    className="dropdown-menu"
                    style={{ display: showOptions ? "block" : "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    {addresses.map((address, index) => (
                      <a
                        key={index}
                        className="dropdown-item"
                        href="#"
                        onClick={() =>
                          setValue(
                            `${address.address_line1}, ${address.address_line2}`,
                            address.lat,
                            address.lon
                          )
                        }
                      >
                        {`${address.address_line1}, ${address.address_line2}`}
                      </a>
                    ))}
                  </div>
                </div>
              )}
               {locationFlag && (
          <div className="text-danger d-flex justify-content-center mt-2">Bitte wählen Sie ein Ort aus dem Dropdown-Menü aus.</div>
        )}
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  onClick={handleButtonClick}
                  className="btn btn-primary px-3 py-2 mt-4"
                  style={{
                    backgroundColor: "#25A9E0",
                    border: "none",
                    fontSize: 16,
                  }}
                >
                  Weiter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
