import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import './Depart.css'
import {
  setDepartFromLocation,
  setDepartToLocation,
} from "../../redux/redux/actions/locationActions";
import Location from "../../../assets/Vector.svg";
import locationRed from "../../../assets/locationRed.png";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../../common/zuruckheader";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

export default function DepartTo() {
  const [location, setLocation] = useState("");
  const dispatch = useDispatch();
  const [locationFlag, setLocationFlag] = useState(false);
  const navigate = useNavigate();
  const [latLng, setLatLng] = useState(null);
  const handleChange = (address) => {
    setLocation(address);

  };



  const handleSelect = async (address) => {
    setLocation(address);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setLatLng(latLng);
      let obj = {
        address,
        latLng
      }
      dispatch(setDepartToLocation(address, latLng.lat, latLng.lng));
      localStorage.setItem("DepartTo", JSON.stringify(obj));
    } catch (error) {
      console.error("Error fetching geolocation:", error);
    }
  };


  const handleContinue = () => {
    if (latLng) {
      if (location.trim() === "") {
        setLocationFlag(true);
        return false;
      } else {
        setLatLng("");
        navigate("/DepartFrom");
        return true;
      }
    }
    else {
      setLocationFlag(true);
      return false;
    }
  };


  return (
    <>
      <Header />
      <div className="container mt-lg-5 depart-to">
        <div className="row justify-content-center mt-lg-5 pt-5">
          <div className="col-lg-8 col-12">
            <div
              className="text-center"
              style={{ fontSize: 40, fontWeight: 600 }}
            >
              Wo fahren Sie los?
            </div>
            <div>
              <div className="inputWithIcon mt-4">
                <PlacesAutocomplete
                  value={location}
                  onChange={handleChange}
                  onSelect={handleSelect}
                  searchOptions={{
                    types: ["(cities)"],
                    componentRestrictions: {
                      country: "DE",
                    },
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div >
                      <input style={{ borderRadius: "15px", width: "100%" }}
                        id={`${locationFlag === true ? "error" : ""}`}
                        {...getInputProps({
                          placeholder: "Geben Sie die Stadt oder den Ort ein",
                          className: "py-3 depart-sec",
                          // style: { fontSize: 22 },
                        })}
                      />
                      <img
                        src={locationFlag === true ? locationRed : Location}
                        width={"20px"}
                      />
                      {suggestions?.length > 0 ?
                        <div className="suggestions-container py-3">
                          {loading ? <div>Laden...</div> : null}

                          {suggestions.map((suggestion, index) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#41b6e6"
                                : "#fff",
                              borderBottom: "1px solid #ddd", // Add bottom border
                              padding: "8px", // Add padding
                            };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, { style })}
                                key={index}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                        : ""}

                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              {locationFlag && (
                <div className="text-danger d-flex justify-content-center mt-2">Bitte wählen Sie ein Ort aus dem Dropdown-Menü aus.</div>
              )}
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  onClick={handleContinue}
                  className="btn btn-primary px-3 py-2 mt-4"
                  style={{
                    backgroundColor: "#25A9E0",
                    border: "none",
                    fontSize: 16,
                    marginLeft: 20,
                  }}
                >
                  Weiter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
