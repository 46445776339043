import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../HomePage/HomePage.css";
import CalendarReact from "react-calendar";
import "react-calendar/dist/Calendar.css";
import axios from "axios"; // Import Axios
import CircleIcon from '../../../assets/delete-icon.png'
import { StandaloneSearchBox } from "@react-google-maps/api";
import Route from "../../../assets/routing.png";
import Calendar from "../../../assets/calendar-2.png";
import Clock from "../../../assets/clock.png";
import LocationImg from "../../../assets/location.png";
import Star1 from "../../../assets/star-grey.png";
import Timer from "../../../assets/timer-grey.png";
import location1 from "../../../assets/images/location-green.png";
import location2 from "../../../assets/images/location-gray.png";
import location3 from "../../../assets/images/location-yellow.png";
import avatar from "../../../assets/images/Avatar.png";
import star from "../../../assets/images/star-blue.png";
import spinImg from "../../../assets/images/loading.gif"
import { Link } from "react-router-dom";
import Header from "../../../common/header";
import Footer from "../../../common/footer";
import toast from "react-hot-toast";
import { AUTENTICATE_API } from "../../../services/Api/authenticationApi";
import { EDITPROFILE_API } from "../../../services/Api/editProfileApi";
import LocationRed from "../../../assets/images/locationRed.png";
import RouteRed from "../../../assets/images/routingRed.png";
import loadingImg from "../../../assets/images/loading.gif"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

export default function SearchingRoute() {
  const [isYearly, setIsYearly] = useState(false);
  const [location, setLocation] = useState("");
  const [locationFlag, setLocationFlag] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedLat, setSelectedLat] = useState("");
  const [selectedLon, setSelectedLon] = useState("");
  const [ellipsisDeparture, setEllipsisDeparture] = useState("");
  const [ellipsisArrival, setEllipsisArrival] = useState("");
  const [uploadComplete, setUploadComplete] = useState(false);
  const [arrivalLocationSelectFlage, setarrivalLocationSelectFlage] = useState(false);
  const [departLocationSelectFlage, setdepartLocationSelectFlage] = useState(false);
  const [departureLocation, setDepartureLocation] = useState("");
  const [arrivalLocation, setArrivalLocation] = useState(""); // State variable for Arrival location
  const [departureLocationSelect, setDepartureLocationSelect] = useState("");
  const [arrivalLocationSelect, setArrivalLocationSelect] = useState("");
  const [arrivalAddresses, setArrivalAddresses] = useState([]);
  const [showArrivalOptions, setShowArrivalOptions] = useState(false);
  const [date, setDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  const [av, setAv] = useState(false); // State variable to toggle calendar visibility
  const calendarRef = useRef(null);
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const departureSearchBox = useRef(null);
  const arrivalSearchBox = useRef(null);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  let [main_data, set_main_data] = useState([])
  useEffect(() => {
    let usr = localStorage.getItem("user");
    let user_info = JSON.parse(usr);
    sessionStorage.removeItem("main_50")
    // let user_id=user_info.userInfo.user_id;
    setUserId(user_info);
  }, []);
  const handleFahrtveröffentlichen = () => {
    if (!userId) {
      navigate("/login");
    } else {
      navigate("/DepartTo");
    }
  };
  const handleJetztbeitreten = () => {
    if (!userId) {
      navigate("/login");
    } else {
      navigate("/BillingCycle");
    }
  };

  const handleFahrtSuchen = () => {
    navigate("/SearchingRoute");
  };


  const check_fun=(arrival)=>{
   
    let mainData = main_data.slice();
    
    // let arrival =[];
    // let departure50kmData =[];
    // JSON.parse(sessionStorage.getItem("from_50"))?.data?.map((val)=>{
    //   JSON.parse(sessionStorage.getItem("arr_50"))?.data?.map((val1)=>{
    //      if(val.pd_id==val1.pd_id){
    //       arrival.push(val)
    //      }
    //   })
    // })
    
    
    // let combinedData = [...mainData,...arrival];
    // combinedData = combinedData.reduce((acc, curr) => {
    //   const existingItem = acc.find(item => item.pd_id === curr.pd_id);
    //   if (!existingItem) {
    //     acc.push(curr);
    //   }
    //   return acc;
    // }, []);
    sessionStorage.setItem("main_50",JSON.stringify({data:main_data}))
    
    if (main_data.length > 0) {
      navigate("/ListingPage", {
        state: {
          location: departureLocationSelect,
          arrivalLocation: arrivalLocationSelect,
          date: formatDate(date),
          data: main_data, // Pass the combined data to the next page
        },
      });
    } else {
      navigate("/ListingPageNoResults", {
        state: {
          location: departureLocationSelect,
          arrivalLocation: arrivalLocationSelect,
          date: formatDate(date),
        },
      });
      // navigate("/ListingPageNoResults");
    }
  
    
  }
    const handleSuchenClick = async () => {
      
      
      const inputElement = document.getElementById("abfahrtInput");
      const inputElement1 = document.getElementById("ankunftInput");
  
      if (departureLocationSelect == "" && arrivalLocationSelect == "") {
        setdepartLocationSelectFlage(true);
        setarrivalLocationSelectFlage(true);
        toast.error("Abfahrt/Ankunft bitte wählen", {
          autoClose: 3000,
        });
        return false;
      }
  
      if (departureLocationSelect == "") {
        setdepartLocationSelectFlage(true);
        toast.error("Abfahrt bitte wählen", {
          autoClose: 3000,
        });
        return false;
      } else if (arrivalLocationSelect == "") {
        setarrivalLocationSelectFlage(true);
        toast.error("Ankunft bitte wählen", {
          autoClose: 3000,
        });
        return false;
      } else {
        setUploadComplete(true)
        try {
          let response = await AUTENTICATE_API.getRiderInformation({
            depart_to: departureLocationSelect,
            depart_from: arrivalLocationSelect,
            pickup_date: formatDate(date),
          });
         
          main_data=response?.data.slice()
        //   let res___=await AUTENTICATE_API.lat_lng([departureLocationSelect,arrivalLocationSelect])
        // let res___________=await AUTENTICATE_API.getDistance(formatDate(date),[res___[0].start_location,res___[0].end_location])
        check_fun()
  
        } catch (error) {
          // let res___=await AUTENTICATE_API.lat_lng([departureLocationSelect,arrivalLocationSelect])
          // let res___________=await AUTENTICATE_API.getDistance(formatDate(date),[res___[0].start_location,res___[0].end_location])
          check_fun()
          console.error("Error fetching trip data:", error);
          toast.error("Fehler beim Abrufen der Reisedaten", {
            autoClose: 3000,
          });
        }
      }
    };


  // const check_fun = () => {

  //   let mainData = main_data.slice();

  //   let arrival = [];
  //   let departure50kmData = [];
  //   JSON.parse(sessionStorage.getItem("from_50"))?.data?.map((val) => {
  //     JSON.parse(sessionStorage.getItem("arr_50"))?.data?.map((val1) => {
  //       if (val.pd_id == val1.pd_id) {
  //         arrival.push(val)
  //       }
  //     })
  //   })


  //   let combinedData = [...mainData, ...arrival];
  //   combinedData = combinedData.reduce((acc, curr) => {
  //     const existingItem = acc.find(item => item.pd_id === curr.pd_id);
  //     if (!existingItem) {
  //       acc.push(curr);
  //     }
  //     return acc;
  //   }, []);
  //   sessionStorage.setItem("main_50", JSON.stringify({ data: combinedData }))

  //   if (combinedData.length > 0) {
  //     navigate("/ListingPage", {
  //       state: {
  //         location: departureLocationSelect,
  //         arrivalLocation: arrivalLocationSelect,
  //         date: formatDate(date),
  //         data: combinedData, // Pass the combined data to the next page
  //       },
  //     });
  //   } else {
  //     navigate("/ListingPageNoResults", {
  //       state: {
  //         location: departureLocationSelect,
  //         arrivalLocation: arrivalLocationSelect,
  //         date: formatDate(date),
  //       },
  //     });
  //     // navigate("/ListingPageNoResults");
  //   }


  // }
  // const handleSuchenClick = async () => {

  //   const inputElement = document.getElementById("abfahrtInput");
  //   const inputElement1 = document.getElementById("ankunftInput");

  //   if (departureLocationSelect == "" && arrivalLocationSelect == "") {
  //     setdepartLocationSelectFlage(true);
  //     setarrivalLocationSelectFlage(true);
  //     toast.error("Abfahrt/Ankunft bitte wählen", {
  //       autoClose: 3000,
  //     });
  //     return false;
  //   }

  //   if (departureLocationSelect == "") {
  //     setdepartLocationSelectFlage(true);
  //     toast.error("Abfahrt bitte wählen", {
  //       autoClose: 3000,
  //     });
  //     return false;
  //   } else if (arrivalLocationSelect == "") {
  //     setarrivalLocationSelectFlage(true);
  //     toast.error("Ankunft bitte wählen", {
  //       autoClose: 3000,
  //     });
  //     return false;
  //   } else {
  //     try {
  //       let response = await AUTENTICATE_API.getRiderInformation({
  //         depart_to: departureLocationSelect,
  //         depart_from: arrivalLocationSelect,
  //         pickup_date: formatDate(date),
  //       });

  //       main_data = response?.data.slice()
  //       // set_main_data(response?.data|| [])
  //       await getDistance(formatDate(date), departureLocationSelect, arrivalLocationSelect)
  //       await getDistance1(formatDate(date), departureLocationSelect, arrivalLocationSelect)

  //     } catch (error) {
  //       console.error("Error fetching trip data:", error);
  //       toast.error("Fehler beim Abrufen der Reisedaten", {
  //         autoClose: 3000,
  //       });
  //     }
  //   }
  // };

  async function getDistance(dateValue11, depart_from, arrive_location) {
    let str = [];
    let str2 = [];
    let aaa = [];
    let tempp = [];
    let obj_date = new Date();

    let response = await EDITPROFILE_API.trip_list_by_date({
      "pickup_date": dateValue11,
      "currentTime": (obj_date.getHours() < 10 ? "0" + obj_date.getHours() : obj_date.getHours()) + ":" + (obj_date.getMinutes() < 10 ? "0" + obj_date.getMinutes() : obj_date.getMinutes())
    });
    if (response?.status == 200) {
      aaa = response.data.slice();
      aaa.map((val, i) => {
        {
          tempp.push(val);
          str.push(val.depart_to);
          str2.push(val.depart_from)
        }
      });
    }

    if (str.length === 0) {
      return [];
    }

    let distance_50 = []

    try {
      let str_len = str.length / 23
      if (str.length % 23 != 0) {
        str_len++
      }
      let aaa_tmp = []
      for (let a_ = 1; a_ <= str_len; a_++) {
        aaa_tmp = aaa.slice((a_ - 1) * 23, ((a_ - 1) * 23) + 23)


        let tmx_str = str.slice((a_ - 1) * 23, ((a_ - 1) * 23) + 23)



        let distanceService = new window.google.maps.DistanceMatrixService();
        let distanceMatrixResponse = await new Promise((resolve, reject) => {
          distanceService.getDistanceMatrix({
            origins: [depart_from],
            destinations: tmx_str,
            travelMode: window.google.maps.TravelMode.WALKING,
            unitSystem: window.google.maps.UnitSystem.METRIC,
            durationInTraffic: true,
            avoidHighways: false,
            avoidTolls: false
          }, (response, status) => {

            if (status === "OK") {
              resolve(response);
              return;
            } else {
              // reject(`Error`:${status});

            }
          });
        });



        const distancesWithin50Km = distanceMatrixResponse.rows[0].elements
          .map((element, i) => {
            // Check if the distance is in kilometers
            if (element?.distance?.text?.includes("km")) {
              // Extract the distance value as a number
              const distanceValue = parseFloat(element.distance.text.replace(",", ".").split(" ")[0]);
              // Check if the distance is less than or equal to 50 km
              if (distanceValue <= 100) {
                return aaa_tmp[i];
              }
            }
            else if (element?.distance?.text?.includes("m")) {
              return aaa_tmp[i]
            }
            return null; // If distance is not in kilometers or exceeds 50 km, return null
          })
          .filter(Boolean); // Remove null values from the array
        distance_50 = [...distance_50, ...distancesWithin50Km]

      }


      sessionStorage.setItem("from_50", JSON.stringify({ data: distance_50 }))


      return distance_50;

    } catch (error) {
      console.error("Error in getDistance:", error);
      return [];
    }
  }

  async function getDistance1(dateValue11, deport_from, arrive_location) {
    let str = [];
    let str2 = [];
    let index = [];
    let tempp = [];
    let aaa = []

    // Fetch trip data from API
    let obj_date = new Date();
    let response = await EDITPROFILE_API.trip_list_by_date({
      "pickup_date": dateValue11,
      "currentTime": (obj_date.getHours() < 10 ? "0" + obj_date.getHours() : obj_date.getHours()) + ":" + (obj_date.getMinutes() < 10 ? "0" + obj_date.getMinutes() : obj_date.getMinutes())
    });

    if (response?.status == 200) {
      aaa = response.data.slice();

      aaa.map((val) => {

        {
          tempp.push(val);
          str.push(val.depart_from);

        }
      });

    }

    if (str.length === 0) {
      return [];
    }

    let distance_50_1 = []

    // if (str.length === 0) {
    //   check_fun();

    //   return [];
    // }


    try {
      let str_len = str.length / 23
      if (str.length % 23 != 0) {
        str_len++
      }
      let aaa_tmp = []
      for (let a_ = 1; a_ <= str_len; a_++) {
        aaa_tmp = aaa.slice((a_ - 1) * 23, ((a_ - 1) * 23) + 23)
        let tmx_str = str.slice((a_ - 1) * 23, ((a_ - 1) * 23) + 23)

        let distanceService = new window.google.maps.DistanceMatrixService();
        let distanceMatrixResponse = await new Promise((resolve, reject) => {
          distanceService.getDistanceMatrix({
            origins: [arrive_location],
            // destinations: str,
            destinations: tmx_str,
            travelMode: window.google.maps.TravelMode.WALKING,
            unitSystem: window.google.maps.UnitSystem.METRIC,
            durationInTraffic: true,
            avoidHighways: false,
            avoidTolls: false
          }, (response, status) => {
            if (status === "OK") {

              resolve(response);
            } else {
              // reject(`Error: ${status}`);

            }
          });
        });

        const addressesWithin50Km = distanceMatrixResponse.rows[0].elements.map((element, i) => {

          if (element?.distance?.text?.includes("km")) {
            const distanceValue = parseFloat(element.distance.text.replace(",", ".").split(" ")[0]);
            if (distanceValue <= 100) {

              return aaa_tmp[i];
            }
          }
          else if (element?.distance?.text?.includes("m")) {


            return aaa_tmp[i]
          }
        })
          .filter(Boolean); // Remove null values from the array


        distance_50_1 = [...distance_50_1, ...addressesWithin50Km]

      }

      sessionStorage.setItem("arr_50", JSON.stringify({ data: distance_50_1 }))


      check_fun()

      return distance_50_1;

    } catch (error) {
      console.error("Error in getDistance:", error);
      return [];
    }



  }


  const handleChange = (address) => {
    setdepartLocationSelectFlage(false)
    setDepartureLocation(address);
  };
  const handleSelect = async (address) => {
    setDepartureLocationSelect(address);
    // setEllipsisDeparture(address)
    const shortWithEllipsis = (text, maxLength) => {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
      }
      return text;
    };
    const shortValue = shortWithEllipsis(address, 20);
    

    setEllipsisDeparture(shortValue);
   
    setDepartureLocation(shortValue);
   
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      // dispatch(setDepartToLocation(address, latLng.lat, latLng.lng));
    } catch (error) {
      console.error("Error fetching geolocation:", error);
    }

  };
  const handleArrivalSelect = async (address) => {
    setArrivalLocationSelect(address);
    const shortWithEllipsisArrival = (text, maxLength) => {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
      }
      return text;
    };
    const shortValue = shortWithEllipsisArrival(address, 20);
    

    setEllipsisArrival(shortValue);
    
    setArrivalLocation(shortValue);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      // dispatch(setDepartToLocation(address, latLng.lat, latLng.lng));
    } catch (error) {
      console.error("Error fetching geolocation:", error);
    }
  };
  const handleArrivalChange = (address) => {
    setArrivalLocation(address);
    setarrivalLocationSelectFlage(false)
  };

  const handleCalendarClick = () => {
    setShowOptions(false);
    setShowArrivalOptions(false);
    setAv(true);
    setShowCalendar(!showCalendar); // Toggle calendar visibility
  };



  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    localStorage.setItem("date", `${day}.${month}.${year}`)
    return `${day}.${month}.${year}`;
  };


  const reloadCount1112 =
    Number(sessionStorage.getItem("reloadCount1112")) || 0;
  useEffect(() => {
    if (reloadCount1112 < 1) {
      localStorage.setItem("guest", false);
      sessionStorage.setItem("reloadCount1112", String(reloadCount1112 + 1));
      setTimeout(() => {
        window.location.reload();
      }, 200);
    } else {
      // sessionStorage.removeItem('reloadCount1112');
    }
  }, []);

  useEffect(() => { }, [date]);
  // const handleOutsideClick = (e) => {
  //   if (showCalendar == true) {
  //     setShowCalendar(false);
  //     setAv(false);
  //   } else if (av == true && showCalendar == false) {
  //     setShowCalendar(true);
  //   }
  // };
  const handleOutsideClick = (e) => {
    if (showCalendar && !e.target.closest('.calendar-component')) {
      setShowCalendar(false);
      setAv(false);
    }
  };
  return (
    <>
      <Header />
      <div className="pading-mobile" onClick={handleOutsideClick}>
        <div
          className="py-5 home-page"
          style={{ backgroundImage: "radial-gradient(#145D7B,#10475E)" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div
                  className="text-center py-3 text-white"
                  style={{ fontSize: 40, fontWeight: 600 }}
                >
                  Welche Strecke suchen Sie?
                </div>
              </div>
              <div className="col-lg-8 col-12 py-2">
                <div className="row justify-content-center location-sec mt-0">
                  <div
                    className="search-sec bg-white py-2"
                    style={{
                      borderRadius: 16,
                      boxShadow: "rgba(0, 0, 0, 0.15) -0.05px 4.95px 5.6px",
                    }}
                  >
                    <div className="d-lg-flex justify-content-center">
                      <PlacesAutocomplete
                        placeholder="Abfahrt"
                        value={departureLocation}
                        onChange={handleChange}
                        onSelect={handleSelect}
                        searchOptions={{
                          types: ["(cities)"],
                          componentRestrictions: {
                            country: "DE",
                          },
                        }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <>

                            <div
                              className="inputWithIcon input-bar my-auto"
                              style={{ width: 390, borderRight: "1px solid #a0abc0" }}
                            >
                              <input
                                readOnly={departureLocationSelect != ""}
                                {...getInputProps({
                                  id: "abfahrtInput",
                                  type: "text",
                                  placeholder: "Abfahrt",
                                  className: "py-0 text-center text-lg-start",
                                  style: { fontSize: 16, color: "#717D96" },
                                  required: true,
                                })}
                              />
                              {departureLocationSelect && (
                                <img
                                  className="cross"
                                  src={CircleIcon}
                                  onClick={() => {
                                    setDepartureLocation("")
                                    setDepartureLocationSelect("")
                                  }}
                                  width={"20px"}
                                  style={{ position: "absolute", left: "230px", cursor: "pointer" }}
                                />
                              )}
                              {/* {departureLocationSelect &&(
                                  <img className="cross" src={CircleIcon} onClick={()=>{
                                    setDepartureLocation('')
                                    setDepartureLocationSelect("");
                                }} width={"20px"} style={{position:'absolute',left:'240px'}}/>
                                  )} */}
                              <img className="loc-icon" src={departLocationSelectFlage == true ? LocationRed : LocationImg} width={"20px"} />
                            </div>

                            <div className="dropdown mt-2">
                              {" "}

                              <div
                                className="dropdown-menu"
                                style={{
                                  display:
                                    suggestions.length > 0 ? "block" : "none", 
                                  left: "-283px",
                                  top: "46px",
                                }}
                                aria-labelledby="dropdownMenuButton"
                              >
                                {loading ? <div>Laden...</div> : null}

                                {suggestions.map((suggestion, index) => (
                                  <div
                                    className="dropdown-item" // Use the same class as the StandaloneSearchBox
                                    {...getSuggestionItemProps(suggestion, {
                                      key: index,
                                      style: {
                                        backgroundColor: suggestion.active
                                          ? "#41b6e6"
                                          : "#fff",
                                        borderBottom: "1px solid #ddd",
                                        padding: "8px",
                                      },
                                    })}
                                  >
                                    {suggestion.description}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </>
                        )}
                      </PlacesAutocomplete>

                      <PlacesAutocomplete
                        onChange={handleArrivalChange}
                        onSelect={handleArrivalSelect}
                        value={arrivalLocation}
                        searchOptions={{
                          types: ["(cities)"],
                          componentRestrictions: {
                            country: "DE",
                          },
                        }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <>

                            <div
                              className="inputWithIcon input-bar my-auto"
                              style={{ width: 390, borderRight: "1px solid #a0abc0" }}
                            >

                              <input
                                readOnly={arrivalLocationSelect != ""}
                                {...getInputProps({
                                  id: "ankunftInput",
                                  type: "text",
                                  placeholder: "Ankunft",
                                  className: "py-0 text-center text-lg-start",
                                  style: { fontSize: 16, color: "#717D96" },
                                  required: true,
                                })}
                              />
                              {arrivalLocationSelect && (
                                <img className="cross" src={CircleIcon} onClick={() => {
                                  setArrivalLocation('');
                                  setArrivalLocationSelect("");
                                }} width={"20px"} style={{ position: 'absolute', left: '230px' }} />
                              )}
                              <img className="loc-icon" src={arrivalLocationSelectFlage == true ? RouteRed : Route} width={"20px"} />
                            </div>

                            <div className="dropdown mt-2">
                              <div
                                className="dropdown-menu"
                                style={{
                                  display:
                                    suggestions.length > 0 ? "block" : "none",
                                  // display: "block",
                                  left: "-274px",
                                  top: "46px",
                                }}
                                aria-labelledby="dropdownMenuButton"
                              >
                                {loading ? <div>Laden...</div> : null}
                                {suggestions.map((suggestion, index) => (
                                  <div
                                    className="dropdown-item"
                                    {...getSuggestionItemProps(suggestion, {
                                      key: index,
                                    })}
                                  >
                                    {suggestion.description}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </>
                        )}
                      </PlacesAutocomplete>

                      <div class="inputWithIcon input-bar my-auto">
                        <input
                          className="py-0 text-center text-lg-start"
                          type="text"
                          placeholder="Heute"
                          style={{ fontSize: 16, color: "#717D96" }}
                          onClick={handleCalendarClick}
                          readOnly
                          value={formatDate(date)}
                        />
                        <img className="loc-icon" src={Calendar} width={"20px"} />
                      </div>
                      {(uploadComplete===false)?
                            <div className="my-auto  text-center text-lg-start">  
                                                                              
                              <button   
                                type="button"
                                class="btn btn-primary w-100 w-lg-50"
                                // disabled={uploadComplete}
                                style={{
                                  backgroundColor: "#25A9E0",
                                  border: "none",
                                  fontSize: 16,
                                  borderRadius: 8,
                                }}
                                onClick={handleSuchenClick}
                              >    
                              {/* {uploadComplete==true?<img src={spinImg}/>:""}Suchen  */}
                                Suchen     
                              </button>

                            </div>
                            : <div className='d-flex'>
                              <button  
                                 type="button"
                                 class="btn btn-primary w-100 w-lg-50 d-flex justify-content-center"style={{
                                  backgroundColor: "#25A9E0",
                                  border: "none",
                                  fontSize: 16,
                                  borderRadius: 8,
                                }}>
                            <img className='my-auto' src={spinImg} width={20}   /> <div className="my-auto">&nbsp;&nbsp;&nbsp;Suchen</div>
                            </button>
                          </div>}

                      {/* <div className="my-auto  text-center text-lg-start">
                        <button
                          type="button"
                          className="btn btn-primary w-100 w-lg-100"
                          style={{
                            backgroundColor: "#25A9E0",
                            border: "none",
                            fontSize: 16,
                            borderRadius: 8,
                            position: 'relative'
                          }}
                          onClick={handleSuchenClick}
                          disabled={buttonDisabled}
                        >
                        
                            "Suchen"
                          
                        </button>


                        
                      </div> */}
                    </div>
                    {showCalendar && (
                      <div
                        className="calendar-sec"
                        style={{ marginLeft: '540px', position: 'absolute', marginTop: 13 }}
                      >
                        <CalendarReact
                          onChange={setDate}
                          value={date}
                          locale="de"
                          minDate={new Date()}
                          className="react-calendar calendar-component home-calander"
                        />
                      </div>
                    )}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </>
  );
}
