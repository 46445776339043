import React, { useEffect, useState } from "react";
import { AUTENTICATE_API } from "../../../services/Api/authenticationApi";

function TokenLogin(){
    const paramsString = window.location.href.toString().split("?");
    const LoginToken=async (payload)=>{
       
        let res=await AUTENTICATE_API.customerLoginByToken(payload)
      
        if(res && res?.status===200){
            let obj={
                userInfo:res?.data,
                token:res?.token
            }
            localStorage.setItem("user",JSON.stringify(obj));
            setTimeout(() => {
                window.location.href="/profile"
            }, 1500);
        }
        else
        {
            window.history.back()
        }
        
    }
    useEffect(()=>{
        if(paramsString.length==2){
            const searchParams = new URLSearchParams(paramsString[1])
            
            LoginToken({token:searchParams.get("token"),user_id:searchParams.get("user_id")})
        }
        else{
              window.history.back()
        }
    },[])


    return <>

    </>
}
export default TokenLogin