import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ContactImg from '../../../assets/contact-us-icon.png'
import Location from "../../../assets/location.png";
import Route from "../../../assets/routing.png";
import { EDITPROFILE_API } from "../../../services/Api/editProfileApi";
import LocationRed from "../../../assets/images/locationRed.png";
import RouteRed from "../../../assets/images/routingRed.png";
import Calendar from "../../../assets/calendar-2.png";
import Note from "../../../assets/note.png";
import Coin from "../../../assets/coin.png";
import Sheild from "../../../assets/shield-tick.png";
import Truck from "../../../assets/truck-questionmark.png";
import spinImg from "../../../assets/images/loading.gif"
import Search from "../../../assets/search-normal.png";
import CircleIcon from '../../../assets/delete-icon.png'
import Ride from "../../../assets/ride-sec-img.png";
import { StandaloneSearchBox } from "@react-google-maps/api";
import Icon from "../../../assets/Gold Mitglied.png";
import TruckIcon from "../../../assets/truck.png";
import "./HomePage.css";
// import banner from "../../../assets/Home-Page-Banner-min.webp";
import banner from "./logo.js"
import CalendarReact from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {Helmet} from "react-helmet";
import Header from "../../../common/header";
import Footer from "../../../common/footer";
import toast from "react-hot-toast";
import axios from "axios"; // Import Axios
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { AUTENTICATE_API } from "../../../services/Api/authenticationApi";
import ReactGA from 'react-ga'

export default function HomePage() {

  const [showContactF, setShowContact] = useState(false);
  const [isYearly, setIsYearly] = useState(false);
  const [departureLocation, setDepartureLocation] = useState("");
  const [departureLocationSelect, setDepartureLocationSelect] = useState("");
  const [ellipsisDeparture, setEllipsisDeparture] = useState("");
  const [ellipsisArrival, setEllipsisArrival] = useState("");
  const [arrivalLocationSelect, setArrivalLocationSelect] = useState(""); // State variable for Arrival location
  const [location, setLocation] = useState("");
  const [locationFlag, setLocationFlag] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedLat, setSelectedLat] = useState("");
  const [selectedLon, setSelectedLon] = useState("");
  const [arrivalLocation, setArrivalLocation] = useState("");
  const [arrivalAddresses, setArrivalAddresses] = useState([]);
  const [showArrivalOptions, setShowArrivalOptions] = useState(false);
  const [date, setDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  const [type, setType] = useState(false);
  // const [spin, setSpin] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [arrivalLocationSelectFlage, setarrivalLocationSelectFlage] = useState(false);
  const [departLocationSelectFlage, setdepartLocationSelectFlage] = useState(false);
  const [av, setAv] = useState(false); //  State variable to toggle calendar visibility
  const calendarRef = useRef(null);
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const departureSearchBox = useRef(null);
  const arrivalSearchBox = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const excludedCity = 'Munich'; //
  let [main_data,set_main_data]=useState([])

  const filterSuggestions = (suggestions) => {
    return suggestions.filter(
      (suggestion) => suggestion.description.toLowerCase() !== excludedCity.toLowerCase()
    );
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    localStorage.removeItem('checkoutUrl');
    sessionStorage.removeItem("main_50")
    let usr = localStorage.getItem("user");
    let user_info = JSON.parse(usr);
    setUserId(user_info);
  }, []);
  const handleFahrtveröffentlichen = () => {
    if (!userId) {
      navigate("/login");
    } else {
      navigate("/DepartTo");
    }
  };
  const handleJetztbeitreten = () => {
    if (!userId) {
      navigate("/login");
    } else {
      if(userId.userInfo.is_subscribe===1){
        toast.error("Gold-Mitgliedschaft bereits vorhanden")
      }
      else{
      window.location.href = `/BillingCycle?${type}`
    
      }
    }
  };

  const handleFahrtSuchen = () => {
    navigate("/SearchingRoute");
  };

  const [abfahrtErrFlag, setabfahrtErrFlag] = useState(false);
  const [abfahrt, setabfahrt] = useState('');


  const check_fun=(arrival)=>{
    // let mainData = main_data.slice();
    // let combinedData = [...mainData,...arrival];
    // combinedData = combinedData.reduce((acc, curr) => {
    //   const existingItem = acc.find(item => item.pd_id === curr.pd_id);
    //   if (!existingItem) {
    //     acc.push(curr);
    //   }
    //   return acc;
    // }, []);
    //  console.log(combinedData,"fffff")
    // //  return 
    sessionStorage.setItem("main_50",JSON.stringify({data:main_data}))
    if (main_data.length > 0) {
      navigate("/ListingPage", {
        state: {
          location: departureLocationSelect,
          arrivalLocation: arrivalLocationSelect,
          date: formatDate(date),
          data: main_data, // Pass the combined data to the next page
        },
      });
    } else {
      navigate("/ListingPageNoResults", {
        state: {
          location: departureLocationSelect,
          arrivalLocation: arrivalLocationSelect,
          date: formatDate(date),
        },
      });
      // navigate("/ListingPageNoResults");
    }
  
    
  }
    const handleSuchenClick = async () => {
     

    // return;
    
  
    
      const inputElement = document.getElementById("abfahrtInput");
      const inputElement1 = document.getElementById("ankunftInput");
  
      if (departureLocationSelect == "" && arrivalLocationSelect == "") {
        setdepartLocationSelectFlage(true);
        setarrivalLocationSelectFlage(true);
        toast.error("Abfahrt/Ankunft bitte wählen", {
          autoClose: 3000,
        });
        return false;
      }
  
      if (departureLocationSelect == "") {
        setdepartLocationSelectFlage(true);
        toast.error("Abfahrt bitte wählen", {
          autoClose: 3000,
        });
        return false;
      } else if (arrivalLocationSelect == "") {
        setarrivalLocationSelectFlage(true);
        toast.error("Ankunft bitte wählen", {
          autoClose: 3000,
        });
        return false;
      } else {
        setUploadComplete(true)
        try {
          let response = await AUTENTICATE_API.getRiderInformation({
            depart_to: departureLocationSelect,
            depart_from: arrivalLocationSelect,
            pickup_date: formatDate(date),
          });
  
          main_data=response?.data.slice()
          // let res___=await AUTENTICATE_API.lat_lng([departureLocationSelect,arrivalLocationSelect])
          // console.log(departureLocationSelect,arrivalLocationSelect,"sadsadsad",[res___[0].start_location,res___[0].end_location])
         
          // let res___________=await AUTENTICATE_API.getDistance(formatDate(date),[res___[0].start_location,res___[0].end_location],[departureLocationSelect,arrivalLocationSelect])
          check_fun()
          // console.log(res___________,"ASDasd")
          // // set_main_data(response?.data|| [])
          // await getDistance(formatDate(date), departureLocationSelect, arrivalLocationSelect)
          // await getDistance1(formatDate(date), departureLocationSelect, arrivalLocationSelect)
  
        } catch (error) {
          
          // let res___=await AUTENTICATE_API.lat_lng([departureLocationSelect,arrivalLocationSelect])
          // let res___________=await AUTENTICATE_API.getDistance(formatDate(date),[res___[0].start_location,res___[0].end_location])
          check_fun()
  
  
  
          console.error("Error fetching trip data:", error);
          toast.error("Fehler beim Abrufen der Reisedaten", {
            autoClose: 3000,
          });
        }
      }
    };

  
// const check_fun=()=>{
//   let mainData = main_data.slice();
//   let arrival =[];
//   let departure50kmData =[];
//   JSON.parse(sessionStorage.getItem("from_50"))?.data?.map((val)=>{
//     JSON.parse(sessionStorage.getItem("arr_50"))?.data?.map((val1)=>{
//        if(val.pd_id==val1.pd_id){
//         arrival.push(val)
//        }
//     })
//   })
  
 
//   let combinedData = [...mainData,...arrival];
//   combinedData = combinedData.reduce((acc, curr) => {
//     const existingItem = acc.find(item => item.pd_id === curr.pd_id);
//     if (!existingItem) {
//       acc.push(curr);
//     }
//     return acc;
//   }, []);
//   sessionStorage.setItem("main_50",JSON.stringify({data:combinedData}))

//   if (combinedData.length > 0) {
//     navigate("/ListingPage", {
//       state: {
//         location: departureLocationSelect,
//         arrivalLocation: arrivalLocationSelect,
//         date: formatDate(date),
//         data: combinedData, // Pass the combined data to the next page
//       },
//     });
//   } else {
//     navigate("/ListingPageNoResults", {
//       state: {
//         location: departureLocationSelect,
//         arrivalLocation: arrivalLocationSelect,
//         date: formatDate(date),
//       },
//     });
//     // navigate("/ListingPageNoResults");
//   }

  
// }
//   const handleSuchenClick = async () => {
//     const inputElement = document.getElementById("abfahrtInput");
//     const inputElement1 = document.getElementById("ankunftInput");

//     if (departureLocationSelect == "" && arrivalLocationSelect == "") {
//       setdepartLocationSelectFlage(true);
//       setarrivalLocationSelectFlage(true);
//       toast.error("Abfahrt/Ankunft bitte wählen", {
//         autoClose: 3000,
//       });
//       return false;
//     }

//     if (departureLocationSelect == "") {
//       setdepartLocationSelectFlage(true);
//       toast.error("Abfahrt bitte wählen", {
//         autoClose: 3000,
//       });
//       return false;
//     } else if (arrivalLocationSelect == "") {
//       setarrivalLocationSelectFlage(true);
//       toast.error("Ankunft bitte wählen", {
//         autoClose: 3000,
//       });
//       return false;
//     } else {
//       try {
//         let response = await AUTENTICATE_API.getRiderInformation({
//           depart_to: departureLocationSelect,
//           depart_from: arrivalLocationSelect,
//           pickup_date: formatDate(date),
//         });
//         main_data=response?.data.slice()
//         // set_main_data(response?.data|| [])
//         await getDistance(formatDate(date), departureLocationSelect, arrivalLocationSelect)
//         await getDistance1(formatDate(date), departureLocationSelect, arrivalLocationSelect)

//       } catch (error) {
//         console.error("Error fetching trip data:", error);
//         toast.error("Fehler beim Abrufen der Reisedaten", {
//           autoClose: 3000,
//         });
//       }
//     }
//   };

async function getDistance(dateValue11, depart_from,arrive_location) {
  let str = [];
  let str2 = [];
  let aaa = [];
  let tempp = [];
  let obj_date = new Date();

  let response = await EDITPROFILE_API.trip_list_by_date({
    "pickup_date": dateValue11,
    "currentTime": (obj_date.getHours() < 10 ? "0" + obj_date.getHours() : obj_date.getHours()) + ":" + (obj_date.getMinutes() < 10 ? "0" + obj_date.getMinutes() : obj_date.getMinutes())
  });
  if (response?.status == 200) {
    aaa = response.data.slice();
    aaa.map((val, i) => {
         {
        tempp.push(val);
        str.push(val.depart_to); 
        str2.push(val.depart_from)     
      }
    });
  }

  if (str.length === 0) {
    return [];
  }
  
  let distance_50=[]
  
  try {
    let str_len=str.length/23
    if(str.length%23!=0){
      str_len++
    }
    let aaa_tmp=[]
    for(let a_=1;a_<=str_len;a_++){
      aaa_tmp=aaa.slice((a_-1)*23,((a_-1)*23)+23)

      
     let tmx_str= str.slice((a_-1)*23,((a_-1)*23)+23)
    
    
    
    let distanceService = new window.google.maps.DistanceMatrixService();
    let distanceMatrixResponse = await new Promise((resolve, reject) => {
      distanceService.getDistanceMatrix({
        origins: [depart_from],
        destinations: tmx_str,
        travelMode: window.google.maps.TravelMode.WALKING,
        unitSystem: window.google.maps.UnitSystem.METRIC,
        durationInTraffic: true,
        avoidHighways: false,
        avoidTolls: false
      }, (response, status) => {
       
        if (status === "OK") {
         
          resolve(response);
          return;
        } else {
          // reject(`Error`:${status});
          
        }
      });
    });
    
const distancesWithin50Km = distanceMatrixResponse.rows[0].elements
.map((element, i) => {
// Check if the distance is in kilometers
if (element?.distance?.text?.includes("km")) {
  // Extract the distance value as a number
  const distanceValue = parseFloat(element.distance.text.replace(",", ".").split(" ")[0]);
  // Check if the distance is less than or equal to 50 km
  if (distanceValue <= 100) {
   return  aaa_tmp[i];
  }
}
  else if(element?.distance?.text?.includes("m")){
  return aaa_tmp[i]
}
return null; // If distance is not in kilometers or exceeds 50 km, return null
})
.filter(Boolean); // Remove null values from the array
distance_50=[...distance_50,...distancesWithin50Km]

}

 
sessionStorage.setItem("from_50",JSON.stringify({data:distance_50}))


return distance_50;

  } catch (error) {
    console.error("Error in getDistance:", error);
    return [];
  }
}

async function getDistance1(dateValue11, deport_from, arrive_location) {
  let str = [];
  let str2 = [];
  let index = [];
  let tempp = [];
  let aaa=[]

  // Fetch trip data from API
  let obj_date = new Date();
  let response = await EDITPROFILE_API.trip_list_by_date({
    "pickup_date": dateValue11,
    "currentTime": (obj_date.getHours() < 10 ? "0" + obj_date.getHours() : obj_date.getHours()) + ":" + (obj_date.getMinutes() < 10 ? "0" + obj_date.getMinutes() : obj_date.getMinutes())
  });
 
  if (response?.status == 200) {
   aaa = response.data.slice();

    aaa.map((val) => {
   
         {
        tempp.push(val);
        str.push(val.depart_from);
       
      }
    });
  
  }
  
  if (str.length === 0) {
    return [];
  }
  
  let distance_50_1=[]

  // if (str.length === 0) {
  //   check_fun();
   
  //   return [];
  // }


try {
let str_len=str.length/23
if(str.length%23!=0){
  str_len++
}
let aaa_tmp=[]
for(let a_=1;a_<=str_len;a_++){
  aaa_tmp=aaa.slice((a_-1)*23,((a_-1)*23)+23)
  let tmx_str= str.slice((a_-1)*23,((a_-1)*23)+23)

let distanceService = new window.google.maps.DistanceMatrixService();
let distanceMatrixResponse = await new Promise((resolve, reject) => {
  distanceService.getDistanceMatrix({
    origins: [arrive_location],
    // destinations: str,
    destinations:tmx_str,
    travelMode: window.google.maps.TravelMode.WALKING,
    unitSystem: window.google.maps.UnitSystem.METRIC,
    durationInTraffic: true,
    avoidHighways: false,
    avoidTolls: false
  }, (response, status) => {
    if (status === "OK") {
      
      resolve(response);
    } else {
      // reject(`Error: ${status}`);
    
    }
  });
});




const addressesWithin50Km = distanceMatrixResponse.rows[0].elements.map((element, i) => {

  if (element?.distance?.text?.includes("km") ) {
    const distanceValue = parseFloat(element.distance.text.replace(",", ".").split(" ")[0]);
    if (distanceValue <= 100) {
      
      return aaa_tmp[i];
    }
  }
  else if(element?.distance?.text?.includes("m")){
   

    return aaa_tmp[i]
  }
})
.filter(Boolean); // Remove null values from the array

 distance_50_1=[...distance_50_1,...addressesWithin50Km]

}

sessionStorage.setItem("arr_50",JSON.stringify({data:distance_50_1}))


check_fun()

return distance_50_1;

        } catch (error) {
      console.error("Error in getDistance:", error);
      return [];
    }



}


  const handleCalendarClick = () => {
    setShowOptions(false);
    setShowArrivalOptions(false);
    setAv(true);
    setShowCalendar(!showCalendar); // Toggle calendar visibility
  };




  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const handleChange = (address) => {
    setdepartLocationSelectFlage(false)
    setDepartureLocation(address);
    
    setDepartureLocationSelect("")
  };
  const handleSelect = async (address) => {
    setDepartureLocationSelect(address);
    // setEllipsisDeparture(address)
    const shortWithEllipsis = (text, maxLength) => {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
      }
      return text;
    };
    const shortValue = shortWithEllipsis(address, 20);
    

    setEllipsisDeparture(shortValue);
   
    setDepartureLocation(shortValue);
   
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      // dispatch(setDepartToLocation(address, latLng.lat, latLng.lng));
    } catch (error) {
      console.error("Error fetching geolocation:", error);
    }

  };
  const handleArrivalSelect = async (address) => {
    setArrivalLocationSelect(address);
    const shortWithEllipsisArrival = (text, maxLength) => {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
      }
      return text;
    };
    const shortValue = shortWithEllipsisArrival(address, 20);
    

    setEllipsisArrival(shortValue);
   
    setArrivalLocation(shortValue);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      // dispatch(setDepartToLocation(address, latLng.lat, latLng.lng));
    } catch (error) {
      console.error("Error fetching geolocation:", error);
    }
  };
  const handleArrivalChange = (address) => {
    setArrivalLocation(address);
    setarrivalLocationSelectFlage(false)
    setArrivalLocationSelect("")
  };


  const reloadCount1112 =
    Number(sessionStorage.getItem("reloadCount1112")) || 0;
    useEffect(() => {
// console.log(window.google.maps,"nitish chauhna ")


    if (reloadCount1112 < 1) {
      localStorage.setItem("guest", false);
      sessionStorage.setItem("reloadCount1112", String(reloadCount1112 + 1));
      setTimeout(() => {
       // window.location.reload();
      }, 200);
    } else {
     
    }
  }, []);

  useEffect(() => { }, [date]);
  

  const handleOutsideClick = (e) => {
    if (showCalendar && !e.target.closest('.calendar-component')) {
      setShowCalendar(false);
      setAv(false);
    }
  };
//   useEffect(() => {
//     // Check if the consent cookie is set
//     if (!getCookie('cookieConsentAccepted')) {
//         // Load the script only if consent hasn't been given
//         const script = document.createElement('script');
//         script.src = 'https://cloud.ccm19.de/app.js?apiKey=81a998b44cb9498319babc9738ff999cfe1c0d4becfb61f6&amp;domain=666808a270ff1f6c45072782&amp;lang=de_DE';
//         script.referrerpolicy = 'origin';
//         document.head.appendChild(script);
//     }
// }, []);

// function getCookie(name) {
//     const cookies = document.cookie.split('; ');
//     for (let i = 0; i < cookies.length; i++) {
//         const cookie = cookies[i].split('=');
//         if (cookie[0] === name) {
//             return cookie[1];
//         }
//     }
//     return null;
// }

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errr, setErrr] = useState("");
  const [open, setOpen] = useState(false);
  const [contactBack, setContactBack] = useState("");

  const onSumbit = () => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!name || !email || !phone || !message) {
      setErrr("Bitte füllen Sie alle markierten Felder aus");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return;
    }

    if (!emailRegex.test(email)) {
      setErrr("Bitte füllen Sie alle markierten Felder aus");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return;
    }

    if (phone.length < 8 || phone.length > 14) {
      setErrr("Telefonnummer muss zwischen 8 und 14 Zeichen lang sein");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return;
    }
    setOpen(false);

    https: axios
      .post("https://api.warumleer.de/api/api/v1/contect_us", {
        Name: name,
        email_id: email,
        is_checked: contactBack,
        mobile_no: phone,
        description: message,
      })
      .then((res) => {
        if (res && (res?.status == 200 || res?.status == 201)) {
          toast.success("Ihre Anfrage wurde übermittelt", {
            autoClose: 2000,
          });

          setTimeout(() => {
            setEmail("");
            setName("");
            setMessage("");
            setPhone("");
            setContactBack("");
          }, 2500);
          setOpen(false);

        }
      })
      .catch((err) => {
        
      });
  };

  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      let usr = JSON.parse(user);
      setUserInfo(usr);
    } else {
      setUserInfo("");
    }
  }, []);
 
  
  const showContactBtn=()=>{
     setShowContact((showContactF==true?false:true))
  }

  return (
    <>



    <div onClick={()=>{
     setShowContact(false)
    }} onTouchStart={()=>{
     setShowContact(false)

    }} >
      <Header />
      <div className="pading-mobile">
        <div className="home-page pb-5" onClick={handleOutsideClick} style={{ overflowX: 'hidden' }}>
          <div
            className=" home-page"
          >
            <div className="">
              <div className="row justify-content-center">

                <div className="bmnop0">

                  <div className="bmnop3">
                    <img className="yu25fl0" loading="eager" decoding="sync" fetchpriority="high" src={banner} width={"100%"} />
                  </div>



                  <div className="bmnop4">
                    <div className="col-12">
                      <div
                        className="text-center banner-heading"
                      >
                        Leer fahren? Nicht mit uns!
                      </div>
                    </div>

                    <div className="">
                      <div className="row justify-content-center location-sec">
                        <div
                          className="search-sec bg-white py-2"
                          style={{
                            borderRadius: 16,
                            boxShadow: "rgba(0, 0, 0, 0.15) -0.05px 4.95px 5.6px",
                            width: 900
                          }}
                        >

                          <div className="d-lg-flex justify-content-center" id={"tmpmpm"} >
                            <PlacesAutocomplete
                              placeholder="Abfahrt"
                              value={departureLocation}
                              onChange={handleChange}
                              onSelect={(e)=>{
                                

                                handleSelect(e)
                              }}
                              searchOptions={{
                                types: ["(cities)"],
                                componentRestrictions: {
                                  country: "DE",
                                },
                              }}
                             
                              // style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <>
                                 
                                  <div className="inputWithIcon input-bar my-auto" title={departureLocationSelect} style={{ width: 390, borderRight: "1px solid #a0abc0",fontSize: 16, color: "#717D96", }}
                                  
                               
                                  
                                  
                                  >
                                 

                                    <input
                                     id={"abfahrtInput"}
                                      readOnly={departureLocationSelect != ""}
                                      {...getInputProps({
                                        id: "abfahrtInput",
                                        type: "text",
                                        placeholder: "Abfahrt",
                                        // className: "inputtext py-0 text-center text-lg-start",
                                        className:`inputtext py-0 text-center text-lg-start }`,
                                        autoFocus: true,
                                        required: true,
                                        // title: departureLocation,

                                      })}

                                      style={{ fontSize: 16, color: "#717D96"}}
                                      

                                    />
                                    

                                    {departureLocationSelect && (
                                      <img
                                        className="cross"
                                        src={CircleIcon}
                                        onClick={() => {
                                          setDepartureLocation("")
                                          setDepartureLocationSelect("")
                                          setEllipsisDeparture("")
                                        }}
                                        width={"20px"}
                                        style={{ position: "absolute", left: "240px", cursor: "pointer" }}
                                      />
                                    )}
                                    <img className="loc-icon" src={departLocationSelectFlage == true ? LocationRed : Location} width={"20px"} />
                                  </div>


                                
                                  <div className="dropdown mt-2">
                                    {" "}

                                    
                                    <div
                                      className="dropdown-menu"
                                      style={{
                                        display:
                                          suggestions.length > 0 ? "block" : "none", // Show dropdown when suggestions are available
                                        // display: "block",

                                        left: "-291px",
                                        top: "46px",
                                      }}
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      {loading ? <div>Laden...</div> : null}

                                      {suggestions.map((suggestion, index) => (
                                        <div
                                          className="dropdown-item" // Use the same class as the StandaloneSearchBox
                                          {...getSuggestionItemProps(suggestion, {
                                            key: index,
                                            style: {
                                              backgroundColor: suggestion.active
                                                ? "#41b6e6"
                                                : "#fff",
                                              borderBottom: "1px solid #ddd",
                                              padding: "8px",
                                            },
                                          })}
                                        >
                                          {suggestion.description}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </>
                              )}
                            </PlacesAutocomplete>













                            

                            <PlacesAutocomplete
                              onChange={handleArrivalChange}
                              onSelect={handleArrivalSelect}
                              value={arrivalLocation}
                              searchOptions={{
                                types: ["(cities)"],
                                componentRestrictions: {
                                  country: "DE",
                                },
                              }}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <>

                                  <div
                                    className="inputWithIcon input-bar my-auto" title={arrivalLocationSelect}
                                    style={{ width: 390, borderRight: "1px solid #a0abc0" }}
                                  >

                                    <input
                                      readOnly={arrivalLocationSelect != ""}
                                      {...getInputProps({
                                        id: "ankunftInput",
                                        type: "text",
                                        placeholder: "Ankunft",
                                        className: "py-0 text-center text-lg-start",
                                        style: { fontSize: 16, color: "#717D96" },
                                        required: true,
                                      })}
                                    />
                                    {arrivalLocationSelect && (
                                      <img className="cross" src={CircleIcon} onClick={() => {
                                        setArrivalLocation('')
                                        setArrivalLocationSelect("");
                                      }} width={"20px"} style={{ position: 'absolute', left: '240px', cursor: "pointer" }} />
                                    )}
                                    <img className="loc-icon" src={arrivalLocationSelectFlage == true ? RouteRed : Route} width={"20px"} />
                                  </div>

                                  <div className="dropdown mt-2">
                                    <div
                                      className="dropdown-menu"
                                      style={{
                                        display:
                                          suggestions.length > 0 ? "block" : "none",
                                        // display: "block",
                                        left: "-281px",
                                        top: "46px",
                                      }}
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      {loading ? <div>Laden...</div> : null}
                                      {suggestions.map((suggestion, index) => (
                                        <div
                                          className="dropdown-item"
                                          {...getSuggestionItemProps(suggestion, {
                                            key: index,
                                          })}
                                        >
                                          {suggestion.description}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </>
                              )}
                            </PlacesAutocomplete>

                            <div class="inputWithIcon my-auto">
                              <input
                                className="py-0 text-center text-lg-start"
                                type="text"
                                placeholder="Heute"
                                style={{ fontSize: 16, color: "#717D96" }}
                                onClick={handleCalendarClick}
                                readOnly
                                value={formatDate(date)}
                              />
                              <img className="loc-icon" src={Calendar} width={"20px"} />
                            </div>
                            {(uploadComplete===false)?
                            <div className="my-auto  text-center text-lg-start">  
                                                                              
                              <button   
                                type="button"
                                class="btn btn-primary w-100 w-lg-50"
                                // disabled={uploadComplete}
                                style={{
                                  backgroundColor: "#25A9E0",
                                  border: "none",
                                  fontSize: 16,
                                  borderRadius: 8,
                                }}
                                onClick={handleSuchenClick}
                              >    
                              {/* {uploadComplete==true?<img src={spinImg}/>:""}Suchen  */}
                                Suchen     
                              </button>

                            </div>
                            : <div className='d-flex'>
                              <button  
                                 type="button"
                                 class="btn btn-primary w-100 w-lg-50 d-flex justify-content-center"style={{
                                  backgroundColor: "#25A9E0",
                                  border: "none",
                                  fontSize: 16,
                                  borderRadius: 8,
                                }}>
                            <img className='my-auto' src={spinImg} width={20}   /> <div className="my-auto">&nbsp;&nbsp;&nbsp;Suchen</div>
                            </button>
                          </div>}
                          </div>

                          {showCalendar && (
                            <div
                              className="d-flex justify-content-end calendar-sec"
                              style={{ marginLeft: '550px', position: 'absolute', marginTop: 16 }}
                            >
                              <CalendarReact
                                onChange={setDate}
                                minDate={new Date()}
                                locale="de-DE"
                                value={date}
                                className="calendar-component home-calander"
                                onClick={(e) => e.stopPropagation()}
                              />
                            </div>
                          )}
                        </div>

                      </div>


                    </div>

                  </div>

                </div>
              </div>

            </div>
          </div>

          <div className="container mb-5 mt-5">
            <div className="row">
              <div className="col-lg-4 col-12 card-sec px-lg-4">
                <div class="card p-4" style={{ width: "100%" }}>
                  <img src={Note} class="card-img-top" alt="Note" />
                  <div class="card-body px-0">
                    <h5 class="card-title">
                      Bequemer Autotransport von  <br /> A nach B
                      {/* Bequemer Autotransport
                      <br /> von A nach B */}
                    </h5>
                    <p class="card-text" style={{ textAlign: 'justify' }}>
                      Überlassen Sie uns den <span style={{ fontWeight: 600 }}>Autotransport!</span> Geben Sie Ihre <span style={{ fontWeight: 600 }}>Route</span> ein und finden Sie eine <span style={{ fontWeight: 600 }}>große</span> Auswahl an Fahrern, die <span style={{ fontWeight: 600 }}>leer</span> fahren und Ihr Fahrzeug von A nach B <span style={{ fontWeight: 600 }}>transportieren.</span><br /><br />
                      Keine Leerfahrten mehr – <span style={{ fontWeight: 600 }}>einfach, bequem</span> und <span style={{ fontWeight: 600 }}>stressfrei!</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12 card-sec mt-3 mt-lg-0 px-lg-4">
                <div class="card p-4" style={{ width: "100%" }}>
                  <img src={Coin} class="card-img-top" alt="Note" />
                  <div class="card-body px-0">
                    <h5 class="card-title">
                      {/* Geld verdienen ohne
                      <br /> Umwege */}
                      Geld verdienen ohne  <br />  Umwege
                    </h5>
                    <p class="card-text" style={{ textAlign: 'justify' }}>
                      Verdienen Sie <span style={{ fontWeight: 600 }}>Geld</span>, ohne <span style={{ fontWeight: 600 }}>Umwege</span> zu machen! <span style={{ fontWeight: 600 }}>Inserieren</span> Sie <strong>kostenlos</strong> Ihre Leerfahrt und finden Sie <span style={{ fontWeight: 600 }}>Transportaufträge</span> in Ihre gewünschte Richtung. <br /><br />
                      Teilen Sie Ihre <span style={{ fontWeight: 600 }}>Routen, profitieren</span> Sie aus Ihren <span style={{ fontWeight: 600 }}>Leerfahrten</span> und machen Sie das Beste aus Ihren <span style={{ fontWeight: 600 }}>Aufträgen!</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12 card-sec mt-3 mt-lg-0 px-lg-4">
                <div class="card p-4" style={{ width: "100%" }}>
                  <img src={Sheild} class="card-img-top" alt="Note" />
                  <div class="card-body px-0">
                    <h5 class="card-title">
                      Vertrauenswürdiger <br />  Autotransport
                    </h5>
                    <p class="card-text" style={{ textAlign: 'justify' }}>
                      Bei WarumLeer hat die <strong>Sicherheit</strong> unserer Nutzer <strong>oberste</strong> Priorität. Durch unsere <strong>Dokumentenprüfung</strong> können wir sicherstellen, dass alle Benutzer <strong>vertrauenswürdig</strong> sind. <br /><br />
                      Verlassen Sie sich auf <strong>WarumLeer</strong>, um Ihre Transportbedürfnisse <strong>sicher</strong> und <strong>effizient</strong> zu erfüllen!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-5 gradiant">
            <div className="container py-5">
              <div className="row">
                <div className="col-lg-6 col-12 my-auto">
                  <img src={Truck} width={"100%"} />
                </div>
                <div className="col-lg-6 col-12 my-lg-auto px-lg-4 mt-5">
                  <div
                    className="sec-heading"
                    style={{ color: "#fff", fontWeight: 600, lineHeight: "1.2" }}
                  >
                    Mehr als 155 Millionen Leerfahrten pro Jahr
                  </div>
                  <div
                    className="mt-3 sections-sec"
                    style={{ color: "#E2E7F0" }}
                  >
                    Doch wir haben die <span style={{ fontWeight: 600 }}>Lösung!</span> Unsere Plattform vernetzt <span style={{ fontWeight: 600 }}>Suchende</span> und<br className="d-none d-lg-block" /> <span style={{ fontWeight: 600 }}>Fahrer</span>, um <span style={{ fontWeight: 600 }}>Leerfahrten</span> zu <span style={{ fontWeight: 600 }}>minimieren.</span> <br /><br />
                    Geben Sie Ihre gewünschte <span style={{ fontWeight: 600 }}>Transportstrecke</span> in die Suchleiste ein und<br className="d-none d-lg-block" /> <span style={{ fontWeight: 600 }}>entdecken</span> Sie Fahrer, die genau diese Route <span style={{ fontWeight: 600 }}></span>leer fahren.
                    Nehmen Sie<br className="d-none d-lg-block" /> <span style={{ fontWeight: 600 }}>Kontakt</span> mit ihnen auf und lassen Sie Ihr Fahrzeug <span style={{ fontWeight: 600 }}>transportieren.</span><br /><br />
                    Profitieren Sie von <span style={{ fontWeight: 600 }}>weniger Transportkosten, schnelleren</span><br className="d-none d-lg-block" /> Lieferungen und <span style={{ fontWeight: 600 }}>einfacher Kommunikation</span> – suchen Sie jetzt Ihre<br className="d-none d-lg-block" /> Fahrt!
                  </div>
                  <div>
                    <button
                      type="button"
                      class="btn btn-primary px-3 py-2 mt-4 d-flex justify-content-center"
                      style={{
                        backgroundColor: "#25A9E0",
                        border: "none",
                        fontSize: 16,
                      }}
                    >
                      <img src={Search} width={"20px"} />
                      <span style={{ marginLeft: 5 }} onClick={handleFahrtSuchen}>
                        Fahrt suchen
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" back-img">
            <div className="container py-5">
              <div className="row py-2">
                <div className="col-12 text-center">
                  <div style={{ fontSize: 48, fontWeight: 600 }}>
                    Mitgliedschaft
                  </div>
                  <div
                    className="mt-3 sections-sec"
                    style={{  color: "#4A5468" }}
                  >
                    Ihre WarumLeer-Mitgliedschaft öffnet Türen zu exklusiven
                    Vorteilen. Treten Sie jetzt bei!
                  </div>
                  <div className="d-flex mt-4 justify-content-center">
                    <div
                      className="my-auto"
                      style={{ fontSize: 16, fontWeight: 600, color: isYearly ? "#A0ABC0" : "#000" }}
                    >
                      Monatlich
                    </div>
                    <label className="switch mx-4">
                      <input
                        type="checkbox"
                        checked={isYearly}
                        onChange={(e) => {
                          setType(e.target.checked)
                          setIsYearly(!isYearly)
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                    <div
                      className="my-auto"
                      style={{ fontSize: 16, fontWeight: 600, color: isYearly ? "#000" : "#A0ABC0" }}
                    >
                      Jährlich
                    </div>
                  </div>

                  {/* <div className="d-flex mt-4 justify-content-center">
                    <div
                      className="my-auto"
                      style={{ fontSize: 16, fontWeight: 600, color: "#A0ABC0" }}
                    >
                      Monatlich
                    </div>
                    <label className="switch mx-4">
                      <input
                        type="checkbox"
                        checked={isYearly}
                        onChange={(e) => {
                          setType(e.target.checked)
                          setIsYearly(!isYearly)}}
                      />
                      <span className="slider round"></span>
                    </label>
                    <div
                      className="my-auto"
                      style={{ fontSize: 16, fontWeight: 600 }}
                    >
                      Jährlich
                    </div>
                  </div> */}

                  <div className="d-flex justify-content-center mt-3">
                    <div
                      class="card text-center"
                      style={{ width: "400px", borderRadius: 20 }}
                    >
                      <div className="d-flex justify-content-center pb-4 pt-5">
                        <img src={Icon} class="card-img-top" alt="..." />
                      </div>
                      <div class="card-body py-0 px-4">
                        <h5 class="card-title">Gold Mitglied</h5>
                        <p class="card-text py-3">
                          Mitglied werden, Fahrten buchen, exklusiven
                          Kundenservice erleben und noch viel mehr.
                        </p>
                        <div className="d-flex justify-content-center">
                          <h5 class="card-tit">{isYearly ? "22€" : "36€"}</h5>
                          <p
                            class="card-text text-start mx-3 mt-2"
                            style={{ lineHeight: 1 }}
                          >
                            Pro
                            <br />
                            {isYearly ? "Monat" : "Monat"}
                          </p>
                        </div>
                        <div className="pb-5">
                          <button
                            type="button"
                            class="btn btn-primary px-3 py-2 mt-2 w-100"
                            style={{
                              backgroundColor: "#25A9E0",
                              border: "none",
                              fontSize: 16,
                            }}
                            onClick={handleJetztbeitreten}
                          >
                            Jetzt beitreten
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-5 gradiant">
            <div className="container py-lg-5">
              <div className="row">
                <div className="col-lg-5 col-12 my-lg-auto px-lg-5 mt-5 order-2 order-lg-1">
                  <div
                    className="sec-heading"
                    style={{ color: "#fff", fontWeight: 600, lineHeight: "1.2" }}
                  >
                    Ride & Earn: Keine Umwege, Nur Profit!
                  </div>
                  <div
                    className="mt-3 sections-sec"
                    style={{ color: "#E2E7F0" }}
                  >
                    Teilen Sie Ihre Fahrt und <span style={{ fontWeight: 600 }}>maximieren</span> Sie Ihre<br  className="d-none d-lg-block"/> Vorteile! <span style={{ fontWeight: 600 }}>Keine Umwege</span>,
                    einfach <span style={{ fontWeight: 600 }}>Geld</span> verdienen.<br className="d-none d-lg-block" /> Veröffentlichen Sie jetzt <strong>kostenlos</strong> Ihre Route und
                    finden Sie passende <span style={{ fontWeight: 600 }}>Transportaufträge</span> in Ihre gewünschte<br  className="d-none d-lg-block"/> Richtung. <br /><br />
                    Seien Sie <span style={{ fontWeight: 600 }}>smart</span>, seien Sie <span style={{ fontWeight: 600 }}>profitabel!</span>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary px-3 py-2 mt-4 d-flex justify-content-center"
                      style={{
                        backgroundColor: "#25A9E0",
                        border: "none",
                        fontSize: 16,
                      }}
                    >
                      <img src={TruckIcon} width={"20px"} />
                      <span
                        style={{ marginLeft: 5 }}
                        onClick={handleFahrtveröffentlichen}
                      >
                        <Link style={{ textDecoration: "none", color: "#fff" }}>
                          Fahrt veröffentlichen
                        </Link>
                      </span>
                    </button>
                  </div>
                </div>
                <div className="col-lg-7 col-12 order-1 order-lg-2">
                  <img src={Ride} width={"100%"} />
                </div>
              </div>
            </div>
          </div>
          <div className="container pb-5 pb-lg-0">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-12 my-5 accordian-sec">
                <div
                  className="text-center mb-4"
                  style={{ fontSize: 40, fontWeight: 600 }}
                >
                  Häufig gestellte Fragen
                </div>
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button collapsed shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Wie funktioniert WarumLeer genau?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">


                        WarumLeer ermöglicht es Fahrern aller Art, seien es Spediteure oder
                        Abschleppfahrer, Leerfahrten zu minimieren, indem sie ihre Routen mit anderen
                        teilen. Suchende können passende Fahrten finden und Kontakt mit den Fahrern
                        aufnehmen. Eine genauere Erklärung finden Sie auch auf unserer <a href="/HowItWorks" style={{ color: "safed" == "safed" ? "white" : '' }}>Wie es
                          funktioniert</a> Seite.


                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed  shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Ist meine Mitgliedschaft verpflichtend, um Fahrten zu
                        suchen oder anzubieten?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">

                        Die Mitgliedschaft ist optional, aber sie bietet entscheidende Vorteile. Ohne
                        Mitgliedschaft können Sie Fahrten suchen, aber um die Kontaktdaten des Fahrers zu sehen und
                        eine Fahrt zu buchen, ist die Mitgliedschaft notwendig.Das Fahrten Anbieten ist auf unserer Website kostenlos.
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button collapsed shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Wie sicher sind die Zahlungen auf WarumLeer?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        Wir verwenden ein SSL-Secure-Payment System, um sichere Zahlungen zu
                        gewährleisten. Ihre Daten sind verschlüsselt, und wir setzen alles daran, einen
                        sicheren Zahlungsvorgang zu ermöglichen.
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button
                        class="accordion-button collapsed shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        Kann ich meine Fahrt stornieren oder ändern?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        Ja, Sie können Ihre Fahrt stornieren oder ändern. Gehen Sie dazu einfach als
                        Suchender in Ihrem Profil auf "Gebuchte Fahrten" und als Fahrer auf "Veröffentlichte
                        Fahrten" und stornieren Sie Ihre gewünschte Fahrt. Der Buchende oder der Fahrer
                        wird daraufhin per E-Mail über die Stornierung benachrichtigt.
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                      <button
                        class="accordion-button collapsed shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        Wie kann ich mein Profil bearbeiten?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        Gehen Sie zu Ihrem Profil, dort finden Sie Optionen zum Bearbeiten von
                        Informationen wie Profilbild, Kontaktangaben und Präferenzen. Klicken Sie auf
                        "Speichern", um die Änderungen zu bestätigen.
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingNine">
                      <button
                        class="accordion-button collapsed shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                      >
                        Welche Vorteile bietet mir WarumLeer?
                      </button>
                    </h2>
                    <div
                      id="collapseNine"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingNine"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        WarumLeer ermöglicht die effiziente Nutzung von Fahrzeugkapazitäten, trägt zur
                        Umweltentlastung bei, bietet Flexibilität bei Transportaufträgen und erleichtert die
                        Kommunikation. Durch dieses Win-Win-Prinzip profitieren sowohl Fahrer als auch
                        Suchende gleichermaßen von der Plattform.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
     
      
     

      <Helmet>
        <script src="https://cloud.ccm19.de/app.js?apiKey=81a998b44cb9498319babc9738ff999cfe1c0d4becfb61f6&amp;domain=666808a270ff1f6c45072782&amp;lang=de_DE" referrerpolicy="origin"></script>
      </Helmet>
      <Footer   />
    </div>
    <div id="floating-button"  onClick={showContactBtn}>
        <p class="plus"><img src={ContactImg} width={30} style={{ marginTop: 12, marginLeft: 12 }} /></p>
        <img
          class="edit"
          src="https://ssl.gstatic.com/bt/C3341AA7A1A076756462EE2E5CD71C11/1x/bt_compose2_1x.png"
        />
      </div>
    {showContactF?
      <div id="contectas">
      <div class="nd4 nds">
        <div
          style={{
            background: "#fff",
            width: "300px",
            borderRadius: 24,
            boxShadow: "rgba(0, 0, 0, 0.15) -0.05px 4.95px 5.6px",
          }}
        >
          <div className="container-modal">
            <div className="text-center" style={{ fontSize: 16, color: '#000' }}>
              Kontaktieren Sie uns!
            </div>
            <form action="/action_page.php">
              <label
                className="label-text"
                for="fname"
                style={{ fontSize: 12, color: '#000' }}
              >
                Name <sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                className="py-0 my-0"
                type="text"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                id="fname"
                name="firstname"
                placeholder=""
              />
              <br />

              <label
                className="label-text"
                for="lname"
                style={{ fontSize: 12, color: '#000' }}
              >
                E-Mail<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                className="py-0 my-0"
                type="text"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
                id="Fname"
                name="lastname"
                placeholder=""
              />
              <label
                className="label-text"
                for="Fname"
                style={{ fontSize: 12, color: '#000' }}
              >
                Telefonnummer<sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                className="py-0 my-0"
                type="text"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                value={phone}
                id="lname"
                name="lastname"
                placeholder=""
                onKeyPress={(e) => {
                  // Check if the entered key is not a number
                  if (isNaN(parseInt(e.key, 10))) {
                    e.preventDefault();
                  }
                }}
              />
              <label
                className="label-text"
                for="subject"
                style={{ fontSize: 12, color: '#000' }}
              >
                Ihre Nachricht<sup style={{ color: "red" }}>*</sup>
              </label>
              <textarea
                onChange={(e) => {
                  if (e.target.value?.length > 5000) {
                    setErrr("Messahe should be contain 500 charectors !!");
                    setTimeout(() => {
                      setErrr("");
                    }, 3000);
                    return false;
                  } else {
                    setMessage(e.target.value);
                  }
                }}
                className="my-0"
                id="subject"
                value={message}
                name="subject"
                placeholder=""
                style={{ height: "50px" }}
              ></textarea>

              <div className="d-flex">
                <div className="my-auto d-flex justify-content-center">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      const isChecked = e.target.checked ? 1 : 0; // Convert checked state to 1 or 0

                      setContactBack(isChecked);
                    }}
                    checked={contactBack === 1} // Check if contactBack is equal to 1 to determine checked state
                    id="contactBack"
                    name="contactBack"
                    value="1" // This value will be submitted with the form data if the checkbox is checked
                  />
                </div>
                <div className="my-auto">
                  <label
                    className="label-text "
                    for="Fname"
                    style={{ marginLeft: "10px", paddingTop: 3, fontSize: 12 }}
                  >
                    Bitte um Rückruf
                  </label>
                </div>
              </div>

              {/* {/ <input type="submit" value="Submit"/> /} */}
              <div className="text-center mt-3">
                <button
                  onClick={onSumbit}
                  type="button"
                  className="btn btn-primary"
                  style={{
                    width: "100%",
                    backgroundColor: "rgb(37, 169, 224)",
                    borderColor: "rgb(37, 169, 224)",
                    fontSize: 12,
                  }}
                >
                  Senden
                </button>
              </div>
              <div
                style={{
                  height: 20,
                  color: "red",
                  fontSize: 12,
                  marginTop: 5,
                }}
              >
                {errr ? errr : ""}
              </div>
            </form>
          </div>
        </div>
      </div>

      </div>
      :""}


      
      </>
  );
}