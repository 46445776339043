import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRoute,addStop } from "../../redux/redux/actions/locationActions";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../../common/zuruckheader";
import './TakeRoute.css'

export default function TakeRoute() {
  const [route, setRouteVal] = useState("0");
  const [time, setTime] = useState("");
  const [distance, setDistance] = useState("0");
  const [distanceUnits, setDistanceUnits] = useState("0");
  const [stops, setStops] = useState([]); // Array to store stops
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [to,set_to]=useState(JSON.parse(localStorage.getItem("DepartTo")))
  const [from,set_from]=useState(JSON.parse(localStorage.getItem("DepartFrom")))




  // useEffect(() => {
    
    
  // }, [latitude1, longitude1, latitude2, longitude2]);
  useEffect(()=>{
    
  // from
  const latitude1 = from?.latLng?.lat
  const longitude1 = from?.latLng?.lng
 // to 
  const latitude2 = to?.latLng?.lat
  const longitude2 = to?.latLng?.lng
  const directionsService = new window.google.maps.DirectionsService();
  const directionsRenderer = new window.google.maps.DirectionsRenderer();

  const map = new window.google.maps.Map(document.getElementById("my-map"), {
    center: { lat: from?.latLng?.lat, lng: from?.latLng?.lng },
    zoom: 14,
  });
  
  directionsRenderer.setMap(map);

  // const origin = new window.google.maps.LatLng(latitude1, longitude1);
  // const destination = new window.google.maps.LatLng(latitude2, longitude2);

  const origin = new window.google.maps.LatLng(latitude2, longitude2);
  const destination = new window.google.maps.LatLng(latitude1, longitude1);
  const request = {
    origin: origin,
    destination: destination,
    travelMode: window.google.maps.TravelMode.DRIVING,
  };
  

  directionsService.route(request, (response, status) => {
    if (status === window.google.maps.DirectionsStatus.OK) {
      directionsRenderer.setDirections(response);
      const route = response.routes[0];
      const routeDistance = route.legs.reduce(
        (acc, curr) => acc + curr.distance.value,
        0
      );
      const distanceInKm = routeDistance / 1000;
      setDistance(distanceInKm.toFixed(0));
      setDistanceUnits("Kilometer");
      const routeTime = route.legs.reduce(
        (acc, curr) => acc + curr.duration.value,
        0
      );
      const hours = Math.floor(routeTime / 3600);
      const minutes = Math.floor((routeTime % 3600) / 60);
      setTime(`${hours} Std. ${minutes} Min.`);
      const stops = extractStops(response);
      setStops(stops);
      dispatch(
        setRoute(
          `${hours} Std. ${minutes} Min.`,
          `${distanceInKm.toFixed(0)} km`,
          stops
        )
      );
    } else {
      console.error("Directions request failed due to " + status);
    }
  });


       
  },[to,from])
  useEffect(()=>{
set_to(JSON.parse(localStorage.getItem("DepartTo")))
set_from(JSON.parse(localStorage.getItem("DepartFrom")))
  },[])

  const handleAddCityClick = () => {
    // dispatch(addStop({}));
    navigate("/AddStop");
  };

  const extractStops = (response) => {
    const stops = [];
    response.routes[0].legs.forEach((leg) => {
      leg.steps.forEach((step) => {
        if (step.instructions) {
          const instruction = step.instructions.replace(/<\/?[^>]+(>|$)/g, "");
          if (instruction.toLowerCase().includes("onto")) {
            const stopName = instruction.split("onto")[1].trim();
            stops.push({ name: stopName });
          }
        }
      });
    });
    return stops;
  };

  const handleButtonClick = () => {
    navigate("/SelectDate");
  };

  return (
    <>
     
      <Header />
      <div className="container-fluid mt-lg-5 pt-5">
        <div className="row">
          <div
            className="col-lg-6 col-12 map-sec"
            style={{ }}
          >
            <div style={{ fontSize: 40, fontWeight: 600, lineHeight: 1 }}>
              Wählen Sie Ihre Strecke aus
            </div>
            <div
              className="d-flex justify-content-between p-4 mt-5"
              style={{ backgroundColor: "#EDF0F7", borderRadius: 24 }}
            >
              <div className="my-auto">
                <div style={{ fontSize: 18, fontWeight: 600 }}>{time} </div>
                <div
                  style={{ fontSize: 18, fontWeight: 500, color: "#717D96" }}
                >
                  {distance} {distanceUnits}
                </div>
              </div>
              <div className="my-auto">
                <input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="0"
                  style={{ height: 32, width: 32 }}
                  checked 
                />
              </div>
            </div>
            <div
              className="mx-3 mt-4"
              style={{ fontSize: 16, color: "#25A9E0", cursor: "pointer" }}
              onClick={handleAddCityClick}
            >
              Zwischenstopp hinzufügen
            </div>

            <button
              type="button"
              className="btn btn-primary px-3 py-2 mt-5"
              onClick={handleButtonClick}
              style={{
                backgroundColor: "#25A9E0",
                border: "none",
                fontSize: 16,
              }}
            >
              Weiter
            </button>
          </div>

          <div className="col-lg-6 px-0 col-12 mt-5 mt-lg-0">
            <div id="my-map" style={{ width: "100%", height: "680px",borderTopLeftRadius:114 }}></div>
          </div>
        </div>
      </div>
    </>
  );
}