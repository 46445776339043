import React, { useEffect,useState } from 'react'
import { Link } from "react-router-dom";
import banner from '../../../assets/Main Banner.webp'
import Header from '../../../common/header'
import ContactImg from '../../../assets/contact-us-icon.png'
import TruckIcon from "../../../assets/truck.png";
import Ride1 from "../../../assets/FurdenFahrer1.png";
import Truck1 from "../../../assets/FurdenSuchenden.png";
import Search from "../../../assets/search-normal.png";
import Fazit from '../../../assets/Fazit.png'
import Sicher from '../../../assets/SicherheitbeiWarumLeer.png'
import { useNavigate } from 'react-router-dom';
import Footer from '../../../common/footer';
import toast from "react-hot-toast";
import axios from "axios"; // Import Axios

export default function HowItWorks() {
  const [showContactF, setShowContact] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errr, setErrr] = useState("");
  const [open, setOpen] = useState(false);
  const [contactBack, setContactBack] = useState("");

  const onSumbit = () => {
    if (name == "" && email == "" && message == "") {
      setErrr("Alle Felder sind Pflichtfelder!!");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return false;
    }
    const emailReg =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email.match(emailReg)) {
      setErrr("Bitte eine gültige Email eingeben!!");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return false;
    }

    if (message?.length > 500) {
      setErrr("Die Nachricht sollte 500 Zeichen enthalten!!");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return false;
    }
    setOpen(false);

    https: axios
      .post("https://api.warumleer.de/api/api/v1/contect_us", {
        Name: name,
        email_id: email,
        is_checked: contactBack,
        mobile_no: phone,
        description: message,
      })
      .then((res) => {
        if (res && (res?.status == 200 || res?.status == 201)) {
          toast.success("Ihre Anfrage wurde übermittelt", {
            autoClose: 2000,
          });

          setTimeout(() => {
            setEmail("");
            setName("");
            setMessage("");
            setPhone("");
            setContactBack("");
          }, 2500);
        }
      })
      .catch((err) => {
     
      });
  };

  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      let usr = JSON.parse(user);
      setUserInfo(usr);
    } else {
      setUserInfo("");
    }
  }, []);

  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  const handleFahrtSuchen = () => {
    navigate("/SearchingRoute");
  };

  
  const handleFahrtveröffentlichen = () => {
    let user=localStorage.getItem("user");
    let usr=JSON.parse(user);

    if (!usr?.userInfo?.user_id) {
      navigate("/login");
    } else {
      navigate("/DepartTo");
    }
  };

  const showContactBtn=()=>{
    setShowContact(!showContactF)
  }
  return (
    <>
    <div onClick={()=>{
      setShowContact(false)
    }}
    onTouchStart={()=>{
      setShowContact(false)
    }}
    
    >
      <Header/>
      <div className='pading-mobile'>
        <div className='how pb-5 '>
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12 px-0">
                <img src={banner} width={"100%"} />
              </div>
              <div className="col-12" style={{position:'absolute'}}>
                <div className="text-center banner-heading mt-0 mt-lg-5 pt-lg-3" style={{color:'#25A9E0'}}>
                  Wie es funktioniert
                </div>
              </div>
              <div className='col-lg-7 col-12 my-5'>
                <div className='text-center how-heading' style={{color:'#4A5468'}}>Stellen Sie sich vor, Sie finden ein super günstiges Auto in München, wohnen aber in<br className="d-none d-lg-block"/> Berlin. Wie holen Sie es ab? Transportunternehmen für etliche Euros?<span style={{color:'#4A5468',fontWeight:600}}> Nein!</span><br/><br className='d-lg-none'/> <span style={{fontWeight:'600',color:'#25A9E0'}}>WarumLeer</span> bietet Ihnen eine innovative Lösung, um Fahrzeuge kostengünstig und<br className="d-none d-lg-block"/> effizient zu transportieren.</div>
              </div>
            </div>
          </div>
          <div className=" gradiant">
            <div className="container ">
              <div className="row py-4">
                <div className="col-lg-7 col-12 my-auto px-lg-5 order-2 order-lg-1">
                  <div
                    className="sec-heading"
                    style={{ color: "#fff", fontWeight: 600, lineHeight: "1.2" }}
                  >
                    Für den Suchenden:
                  </div>
                  <div
                    className="mt-3 sections-sec"
                    style={{ color: "#E2E7F0" }}
                  >
                    Wenn Sie ein Fahrzeug transportieren lassen möchten, geben Sie einfach den <span style={{fontWeight:600}}>Abfahrts-</span> und <span style={{fontWeight:600}}>Ankunftsort</span> sowie das <span style={{fontWeight:600}}>Datum</span> in unsere Suchleiste ein.<br className="d-none d-lg-block"/> Innerhalb von Sekunden erhalten Sie eine Liste von verfügbaren <span style={{fontWeight:600}}>Fahrern,</span> die<br className="d-none d-lg-block"/> Ihre Route leer fahren. Sie haben die Möglichkeit, eine <span style={{fontWeight:600}}>Buchungsanfrage</span><br className="d-none d-lg-block"/> direkt an den gewünschten Fahrer zu senden oder die Kontaktdaten zu nutzen,<br className="d-none d-lg-block"/> um direkt mit ihm zu kommunizieren.<br/><br/>
                    Auf diese Weise können Sie Details klären, Fragen stellen und den<br className="d-none d-lg-block"/> Transportauftrag <span style={{fontWeight:600}}>schnell</span> und <span style={{fontWeight:600}}>unkompliziert</span> organisieren. <br/><br/>
                    So einfach war Autotransport noch nie <span style={{fontWeight:600}}>– versprochen!</span>
                  </div>
                  <div>
                    <button
                      type="button"
                      class="btn btn-primary px-3 py-2 mt-4 d-flex justify-content-center"
                      style={{
                        backgroundColor: "#25A9E0",
                        border: "none",
                        fontSize: 16,
                      }}
                      onClick={handleFahrtSuchen}
                    >
                      <img src={Search} width={"20px"} />
                      <span style={{ marginLeft: 5 }}>
                        Fahrt suchen
                      </span>
                    </button>
                  </div>
                </div>
                <div className="col-lg-5 col-12 order-1 order-lg-2 my-auto">
                  <img src={Truck1} width={"100%"} />
                </div>
              </div>
            </div>
          </div>
          <div className="container py-4">
              <div className="row">
                
                <div className="col-lg-5 col-12 justify-content-center justify-content-lg-start d-flex">
                    <img src={Ride1} width={"100%"} />
                </div>
                <div className="col-lg-7 col-12 my-lg-auto px-lg-5 mt-5 d-flex justify-content-lg-end">
                  <div>
                    <div
                      className="sec-heading"
                      style={{ color: "#000", fontWeight: 600, lineHeight: "1.2" }}
                    >
                      Für den Fahrer:
                    </div>
                    <div
                      className="mt-3 sections-sec"
                      style={{ color: "#2D3648" }}
                    >
                      Als Fahrer können Sie Ihre <span style={{fontWeight:800}}>Leerfahrt</span> schnell und einfach auf unserer<br className="d-none d-lg-block"/> Plattform <strong>kostenlos</strong> inserieren. <span style={{fontWeight:800}}>Suchende</span> können diese Route dann sehen und bei<br className="d-none d-lg-block"/> Interesse eine <span style={{fontWeight:800}}>Buchungsanfrage</span> senden. Sobald eine Buchungsanfrage<br className="d-none d-lg-block"/> eingeht, werden Sie per E-Mail benachrichtigt. Sie haben dann die<br className="d-none d-lg-block"/> Möglichkeit, die Anfrage sofort <span style={{fontWeight:800}}>anzunehmen oder</span> <span style={{fontWeight:800}}>abzulehnen.</span><br/><br/>
                      Im besten Fall nehmen Sie die Anfrage an, klären alle Details untereinander<br className="d-none d-lg-block"/> und <span style={{fontWeight:800}}>profitieren</span> beide voneinander, indem Sie eine <span style={{fontWeight:800}}>Leerfahrt vermeiden.</span> <br/><br/>
                      Seien Sie <span style={{fontWeight:800}}>smart</span>, seien Sie <span style={{fontWeight:800}}>profitabel</span> – inserieren Sie <span style={{fontWeight:800}}>jetzt</span> Ihre Leerfahrt!
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary px-3 py-2 mt-4 d-flex justify-content-center"
                        style={{
                          backgroundColor: "#25A9E0",
                          border: "none",
                          fontSize: 16,
                        }}
                      >
                        <img src={TruckIcon} width={"20px"} />
                        <span
                          style={{ marginLeft: 5 }}
                          onClick={handleFahrtveröffentlichen}
                        >
                          
                            Fahrt veröffentlichen
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" gradiant">
            <div className="container py-3">
              <div className="row">
                <div className="col-lg-7 col-12 my-lg-auto px-lg-5 mt-5 order-2 order-lg-1">
                  <div
                    className="sec-heading"
                    style={{ color: "#fff", fontWeight: 600, lineHeight: "1.2" }}
                  >
                    Sicherheit bei WarumLeer:
                  </div>
                  <div
                    className="mt-3 sections-sec"
                    style={{ color: "#E2E7F0" }}
                  >
                    Bei WarumLeer hat die <span style={{fontWeight:600}}>Sicherheit</span> unserer Nutzer <span style={{fontWeight:600}}>oberste Priorität.</span><br className="d-none d-lg-block"/> Sowohl Fahrer als auch Suchende müssen sich vor der Nutzung der<br className="d-none d-lg-block"/> Plattform <span style={{fontWeight:600}}>verifizieren</span> lassen, um sicherzustellen, dass alle Benutzer<br className="d-none d-lg-block"/> <span style={{fontWeight:600}}>vertrauenswürdig</span> sind. Dies gewährleistet, dass alle <span style={{fontWeight:600}}>Interaktionen</span><br className="d-none d-lg-block"/> und <span style={{fontWeight:600}}>Transaktionen</span> sicher und zuverlässig ablaufen.<br/><br/>
                    Verlassen Sie sich auf <span style={{fontWeight:600}}>WarumLeer</span>, um Ihre Transportbedürfnisse<br className="d-none d-lg-block"/> <span style={{fontWeight:600}}>sicher</span> und <span style={{fontWeight:600}}>effizient</span> zu erfüllen!
                  </div>
                </div>
                <div className="col-lg-5 col-12 justify-content-center d-flex justify-content-lg-end order-1 order-lg-2">
                    <img src={Sicher} width={"90%"} />
                </div>
              </div>
            </div>
          </div>

          <div className="container py-5">
              <div className="row">
                <div className="col-lg-5 col-12 justify-content-center justify-content-lg-start d-flex">
                    <img src={Fazit} width={"90%"} />
                </div>
                <div className="col-lg-7 col-12 my-lg-auto px-lg-5 mt-5 order-2 order-lg-1 d-flex justify-content-lg-end">
                  <div>
                    <div
                      className="sec-heading"
                      style={{ color: "#000", fontWeight: 600, lineHeight: "1.2" }}
                    >
                      Fazit:
                    </div>
                    <div
                      className="mt-3 sections-sec"
                      style={{ color: "#2D3648" }}
                    >
                      WarumLeer bietet eine <span style={{fontWeight:800}}>Win-Win-Situation</span> für beide Parteien. Suchende<br className="d-none d-lg-block"/> profitieren von <span style={{fontWeight:800}}>kostengünstigen</span> Transportmöglichkeiten und direkter<br className="d-none d-lg-block"/> Kommunikation mit Fahrern, während Fahrer Leerfahrten minimieren und<br className="d-none d-lg-block"/> <span style={{fontWeight:800}}>zusätzliches Einkommen</span> generieren können. Entdecken Sie jetzt die<br className="d-none d-lg-block"/> <span style={{fontWeight:800}}>Vorteile</span> von <span style={{fontWeight:800}}>WarumLeer</span> und machen Sie Transportaufträge <span style={{fontWeight:800}}>einfacher</span>,<br className="d-none d-lg-block"/> <span style={{fontWeight:800}}>effizienter</span> und <span style={{fontWeight:800}}>sicherer</span> denn je zuvor.
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      
    
      


          <Footer/>
          </div>
      <div id="floating-button"  onClick={showContactBtn}>
        <p class="plus"><img src={ContactImg} width={30} style={{ marginTop: 12, marginLeft: 12 }} /></p>
        <img
          class="edit"
          src="https://ssl.gstatic.com/bt/C3341AA7A1A076756462EE2E5CD71C11/1x/bt_compose2_1x.png"
        />
      </div>
            {showContactF?
              <div>
              <div class="nd4 nds">
                <div
                  style={{
                    background: "#fff",
                    width: "300px",
                    borderRadius: 24,
                    boxShadow: "rgba(0, 0, 0, 0.15) -0.05px 4.95px 5.6px",
                  }}
                >
                  <div className="container-modal">
                    <div className="text-center" style={{ fontSize: 16, color: '#000' }}>
                      Kontaktieren Sie uns!
                    </div>
                    <form action="/action_page.php">
                      <label
                        className="label-text"
                        for="fname"
                        style={{ fontSize: 12, color: '#000' }}
                      >
                        Name <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <input
                        className="py-0 my-0"
                        type="text"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        value={name}
                        id="fname"
                        name="firstname"
                        placeholder=""
                      />
                      <br />
        
                      <label
                        className="label-text"
                        for="lname"
                        style={{ fontSize: 12, color: '#000' }}
                      >
                        E-Mail<sup style={{ color: "red" }}>*</sup>
                      </label>
                      <input
                        className="py-0 my-0"
                        type="text"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                        id="Fname"
                        name="lastname"
                        placeholder=""
                      />
                      <label
                        className="label-text"
                        for="Fname"
                        style={{ fontSize: 12, color: '#000' }}
                      >
                        Telefonnummer<sup style={{ color: "red" }}>*</sup>
                      </label>
                      <input
                        className="py-0 my-0"
                        type="text"
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                        value={phone}
                        id="lname"
                        name="lastname"
                        placeholder=""
                        onKeyPress={(e) => {
                          // Check if the entered key is not a number
                          if (isNaN(parseInt(e.key, 10))) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <label
                        className="label-text"
                        for="subject"
                        style={{ fontSize: 12, color: '#000' }}
                      >
                        Ihre Nachricht<sup style={{ color: "red" }}>*</sup>
                      </label>
                      <textarea
                        onChange={(e) => {
                          if (e.target.value?.length > 5000) {
                            setErrr("Messahe should be contain 500 charectors !!");
                            setTimeout(() => {
                              setErrr("");
                            }, 3000);
                            return false;
                          } else {
                            setMessage(e.target.value);
                          }
                        }}
                        className="my-0"
                        id="subject"
                        value={message}
                        name="subject"
                        placeholder=""
                        style={{ height: "50px" }}
                      ></textarea>
        
                      <div className="d-flex">
                        <div className="my-auto d-flex justify-content-center">
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              const isChecked = e.target.checked ? 1 : 0; // Convert checked state to 1 or 0
        
                              setContactBack(isChecked);
                            }}
                            checked={contactBack === 1} // Check if contactBack is equal to 1 to determine checked state
                            id="contactBack"
                            name="contactBack"
                            value="1" // This value will be submitted with the form data if the checkbox is checked
                          />
                        </div>
                        <div className="my-auto">
                          <label
                            className="label-text "
                            for="Fname"
                            style={{ marginLeft: "10px", paddingTop: 3, fontSize: 12 }}
                          >
                            Bitte um Rückruf
                          </label>
                        </div>
                      </div>
        
                      {/* {/ <input type="submit" value="Submit"/> /} */}
                      <div className="text-center mt-3">
                        <button
                          onClick={onSumbit}
                          type="button"
                          className="btn btn-primary"
                          style={{
                            width: "100%",
                            backgroundColor: "rgb(37, 169, 224)",
                            borderColor: "rgb(37, 169, 224)",
                            fontSize: 12,
                          }}
                        >
                          Senden
                        </button>
                      </div>
                      <div
                        style={{
                          height: 20,
                          color: "red",
                          fontSize: 12,
                          marginTop: 5,
                        }}
                      >
                        {errr ? errr : ""}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
        
            </div>
              :""}
    </>
  )
}
