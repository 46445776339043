import React, { useState, useEffect } from "react";
import './edit-profile.css';
import SideBar from "../../../common/sidebar/side";
import back from "../../../assets/back.png";
import { useNavigate } from "react-router-dom";
import { EDITPROFILE_API } from "../../../services/Api/editProfileApi";
import toast from "react-hot-toast";
import Header from '../../../common/header';
import Footer from "../../../common/footer";
const EditProfile = (props) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [userId, setUserId] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [aboutMe, setAboutMe] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    
    let usr = localStorage.getItem('user');
    let user_info = JSON.parse(usr);
    let userType = user_info.userInfo.user_type;

    const handleChange = (e) => {
        const value = e.target.value;
        if (userType === 1) {
            setName(value);
        } else if (userType === 0) {
            setCompanyName(value);
        }
    };

    const handleInputChange = (e) => {
        const inputValue = e.target.value.slice(0, 200); // Limit input to 200 characters
        setAboutMe(inputValue);
    };

    const getProfileInfo = async (userId1) => {
        await EDITPROFILE_API.getProfileInfo(userId1)
            .then((response) => {
                if (response?.status === 200) {
                    setEmail(response?.data[0].email);
                    setPhoneNo(response?.data[0].phone_no);
                    setName(response?.data[0].name);
                    setAboutMe(response?.data[0].about_me);
                    setCompanyName(response?.data[0].name);
                }
            })
            .catch((error) => {
                // Handle error
            });
    };

    const handleEditProfile = async () => {
     
        let obj = {
            email: email,
            user_id: user_info.userInfo.user_id,
            phone_no: phoneNo,
            about_me: aboutMe,
        };

        if (userType === 1) {
            
            obj.first_name = name;
            obj.company_name = ""; 
            obj.last_name="";
        } else if (userType === 0) {
            
            obj.company_name = companyName;
            obj.first_name = ""; 
            obj.last_name="";
        }
        const response = await EDITPROFILE_API.updateProfile(obj);
        
        if (response?.status === 200) {
            if (response?.status === 200 && response.duplicates === true) {
                const { duplicates } = response;
                if (duplicates.email === true) {
                    setEmailError(true);
                    toast.error('E-Mail existiert bereits.');
                } else if (duplicates.phone_no === true) {
                    setPhoneError(true);
                    toast.error('Telefonnummer existiert bereits.');
                }
            } else {
                toast.success('Profil erfolgreich aktualisiert', {
                    autoClose: 2000,
                });
                navigate('/profile');
            }
        } 
      
        else {
           
            toast.error('Fehler beim Aktualisieren des Profils. Bitte versuche es erneut.');
        }
    };

    useEffect(() => {
        setUserId(user_info.userInfo.user_id);
        getProfileInfo(user_info.userInfo.user_id);
    }, [userId]);

    return (
        <React.Fragment>
            <Header />
            <div className="pading-mobile pb-lg-5">
                <div className="container mt-lg-5 pt-3">
                    <div className="row">
                        <div className="col-lg-3 hamburger-sec">
                            <nav className="navbar navbar-expand-lg navbar-light bg-white">
                                <div className="container-fluid justify-content-end px-lg-0">
                                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse mt-3 mt-lg-0" id="navbarSupportedContent">
                                        <SideBar title="profile" />
                                    </div>
                                </div>
                            </nav>
                        </div>
                        <div className="col-lg-9">
                            <div className="d-flex">
                                <div className="row">
                                    <div className='col-lg-12 col-12 my-auto'>
                                        <div className='d-flex'>
                                            <div className='mt-1'>
                                                <img src={back} width={'7px'} alt="Back"/>
                                            </div>
                                            <div className='text-start mx-3 ' style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>Zurück</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <h1 style={{ fontSize: 24, fontWeight: 700 }}>Profil bearbeiten</h1>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-12">
                                    <label className="pro-label">Vollständiger Name / Firmenname</label>
                                    <input type="text" value={userType === 1 ? name : companyName}
                                        onChange={handleChange}
                                        disabled
                                        style={{textTransform:"capitalize"}}
                                        className="form-control shadow-none profile-input disabled_input mt-1" placeholder="Vollständiger Name" />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-12">
                                    <label className="pro-label">E-Mail</label>
                                    <input type="text" value={email}
                                        disabled
                                        onChange={(e) => setEmail(e.target.value)}
                                        style={{ background: "rgb(237, 240, 247)" }}
                                        className={`form-control shadow-none profile-input disabled_input mt-1 ${emailError ? 'error' : ''}`} placeholder="Kontakt E-Mail" />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-12">
                                    <label className="pro-label">Telefonnummer</label>
                                    <input type="text" value={phoneNo}
                                        onChange={(e) => setPhoneNo(e.target.value)}
                                        style={{ background: "rgb(237, 240, 247)" }}
                                        disabled
                                        className={`form-control shadow-none profile-input disabled_input mt-1 ${phoneError ? 'error' : ''}`} placeholder="Telefonnummer" />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-12" id="abc">
                                    <label className="pro-label">Über mich</label>
                                    <textarea
                                        value={aboutMe}
                                        onChange={handleInputChange}
                                        className="form-control shadow-none profile-input mt-1"
                                        placeholder="Ich liebe Autos"
                                    />
                                    <div style={{ fontSize: '12px', color: '#6c757d' }}>
                                        {aboutMe.length}/200 Zeichen
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-3">
                                    <button style={{
                                        color: "#FFF", background: "#25A9E0", border: "1px solid #25A9E0", borderRadius: 8, paddingLeft: 25, paddingRight: 25,
                                        paddingBottom: 8,
                                        paddingTop: 8
                                    }} onClick={handleEditProfile}>Änderungen speichern</button>
                                </div>
                            </div>
                            <div className="pb-5"></div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </React.Fragment>
    );
};

export default EditProfile;