import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import "./AddStop.css";
import Location from "../../../assets/Vector.svg";
import { Link, useNavigate } from "react-router-dom";
import locationRed from "../../../assets/locationRed.png";
import {
  setStopLocation,
  setNoOfStop,
  addStop,
} from "../../redux/redux/actions/locationActions";
import Header from "../../../common/zuruckheader";
import { StandaloneSearchBox } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

export default function AddStop() {
  const [location, setLocation] = useState("");
  const dispatch = useDispatch();
  const [locationFlag, setLocationFlag] = useState(false);
  const navigate = useNavigate();
  const [selectedLat, setSelectedLat] = useState("");
  const [selectedLon, setSelectedLon] = useState("");
const [latlng,setLatLng] = useState("")
  const searchBoxRef = useRef(null);

  const setValue = (val, lat, lon) => {
    setLocation(val);
    setSelectedLat(lat);
    setSelectedLon(lon);
  };

  const handleChange = (address) => {
    setLocation(address);
  };

  const handleSelect = async (address) => {
    setLocation(address);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setLatLng(latLng)
     
      setLocation(address);
      setSelectedLat(latLng.lat);
      setSelectedLon(latLng.lng);
    } catch (error) {
      console.error("Error fetching geolocation:", error);
    }
  };

  const handleContinue = () => {
    if(latlng){
     
      
         // dispatch(setStopLocation(location, selectedLat, selectedLon));
         dispatch(addStop({ name: location, lat: selectedLat, lon: selectedLon }));
         dispatch(setNoOfStop(location));
    // if (location.trim() === "") {
    //   setLocationFlag(true);
      return true;
    // } 
  }
 
    else {
      setLocationFlag(true);
        return false;
      };
    }

  const handleButtonClick = async() => {
    const continueClicked = await handleContinue();
    
    if (continueClicked) {
      navigate("/TakeRouteStop");
    }
  };

  return (
    <>
      <Header />
      <div className="container mt-lg-5 add-stop">
        <div className="row justify-content-center mt-lg-5 pt-5">
          <div className="col-lg-8 col-12">
            <div
              className="text-center"
              style={{ fontSize: 40, fontWeight: 600 }}
            >
              Zwischenstopp hinzufügen
            </div>
            <div>
              <div className="inputWithIcon mt-4">
                <PlacesAutocomplete
                  value={location}
                  onChange={handleChange}
                  onSelect={handleSelect}
                  searchOptions={{
                    types: ["(cities)"],
                    componentRestrictions: {
                      country: "DE",
                    },
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                       <input style={{ background: "#EDF0F7",borderRadius:"15px",width:"100%"}}
                    id={`${locationFlag === true ? "error" : ""}`}
                        {...getInputProps({
                          placeholder: "Geben Sie die Stadt oder den Ort ein",
                          className: "py-3",
                          style: { fontSize: 22 },
                        })}
                      />
                      <img
                       src={locationFlag === true ? locationRed : Location}
                        width={"20px"}
                      />
                      {suggestions?.length>0?
                      <div className="suggestions-container py-3">
                        {loading ? <div>Laden...</div> : null}

                        {suggestions.map((suggestion, index) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#41b6e6"
                              : "#fff",
                            borderBottom: "1px solid #ddd", // Add bottom border
                            padding: "8px", // Add padding
                          };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, { style })}
                              key={index}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                      :""}
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              {locationFlag && (
          <div className="text-danger d-flex justify-content-center mt-2">Bitte wählen Sie ein Ort aus dem Dropdown-Menü aus.</div>
        )}
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  onClick={handleButtonClick}
                  className="btn btn-primary px-3 py-2 mt-4"
                  style={{
                    backgroundColor: "#25A9E0",
                    border: "none",
                    fontSize: 16,
                    marginLeft: 20,
                  }}
                >
                  Weiter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}