import React from 'react'
import Account from '../../../assets/Account Created Successfully.png'
import { useNavigate } from 'react-router-dom'
import Header from '../../../common/zuruckheader';

export default function AccountCreatedSuccessful() {
    const Navigate = useNavigate();
  return (
    <>
    {/* <Header/> */}
    <div className='pt-2'>
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-12'>
                    <div className='text-center' style={{fontSize:40,fontWeight:600}}>Willkommen bei WarumLeer!</div>
                </div>
                <div className='col-lg-6 col-12'>
                    <div className='mt-5 px-lg-5'>
                        <img className='px-5' src={Account} width={"100%"}/>
                    </div>
                    <div className='text-center mt-4' style={{fontSize:22,color:'#717D96'}}>Herzlichen Glückwunsch!</div>
                    <div className='text-center mt-3' style={{fontSize:22,color:'#717D96'}}>Ihr <span style={{fontWeight:'bold',color:'#25A9E0'}}>WarumLeer</span>-Account wurde erfolgreich erstellt.<br className='d-none d-lg-block'/> Jetzt können Sie sich auf spannende Fahrten und neue<br className='d-none d-lg-block'/> Abenteuer freuen.</div>
                    <div className='text-center mt-3 mb-4' style={{fontSize:22,color:'#717D96'}}>Bei Fragen stehen wir Ihnen gerne zur Verfügung.</div>
                    <div className='d-flex justify-content-center'>
                        <div className='text-center text-lg-start mb-5'>
                            <button type="button" onClick={()=>{Navigate("/login")}} class="btn btn-secondary px-3 py-2 my-md-auto mt-3" style={{border:'none',color:'#fff',backgroundColor:'#25A9E0',fontSize:16}}>Jetzt loslegen</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
