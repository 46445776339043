import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import Header from "./common/header";
// import ProvideFrontPicture from "./views/pages/Provide front picture/ProvideFrontPicture";
import ProvideFrontPicture from "./views/pages/Providefrontpicture/ProvideFrontPicture"
import IdVerificationStart from "./views/pages/IdVerificationStart/IdVerificationStart";
import CaptureFrontId from "./views/pages/CaptureFrontId/CaptureFrontId";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Login from "./views/pages/login/login";
import CaptureBackId from "./views/pages/CaptureBackId/CaptureBackId";
import CaptureSelfie from "./views/pages/CaptureSelfie/CaptureSelfie";
import VerificationSuccessful from "./views/pages/VerificationSuccessful/VerificationSuccessful";
import Register from "./views/pages/register/register";
import UploadPicture from "./views/pages/UploadPicture/UploadPicture";
import DepartTo from "./views/pages/DepartTo/DepartTo";
import SelectDate from "./views/pages/SelectDate/SelectDate";
import TakeRoute from "./views/pages/TakeRoute/TakeRoute";
import Stops from "./views/pages/Stops/Stops";
import PickUpCars from "./views/pages/PickUpCars/PickUpCars";
import WeightCarry from "./views/pages/WeightCarry/WeightCarry";
import TakeCars from "./views/pages/TakeCars/TakeCars";
import InformationSeeker from "./views/pages/InformationSeeker/InformationSeeker";
import ExtraInformation from "./views/pages/ExtraInformation/ExtraInformation";
import TripSuccessfullyPublished from "./views/pages/TripSuccessfullyPublished/TripSuccessfullyPublished";
import AddStop from "./views/pages/AddStop/AddStop";
import Profile from "./views/components/profile/profile";
import Info from "./views/components/information/info";
import Money from "./views/components/money/money";
import BookTrip from "./views/components/book-trip/book-trip";
import PublishedTrips from "./views/components/published-trips/published-trips";
import HomePage from "./views/pages/HomePage/HomePage";
import ListingPageNoResults from "./views/pages/ListingPageNoResults/ListingPageNoResults";
import GetNotifications from "./views/pages/GetNotifications/GetNotifications";
import SuccesfullNotificationSubmission from "./views/pages/SuccesfullNotificationSubmission/SuccesfullNotificationSubmission";
import Footer from "./common/footer";
import Notification from "./views/components/notification/notification";
import Reviews from "./views/components/reviews/reviews";
import EditProfile from "./views/components/edit-profile/edit-profile";
import AddWehicle from "./views/components/add-new-wehicle/add-wehicle";
import BillingCycle from "./views/pages/BillingCycle/BillingCycle";
import PublishedTripCancelled from "./views/components/published-trips/cancelledTrip"
import BookedTripCancelled from "./views/components/book-trip/cancelledBookTrip"
import RequireAuth from "./services/helper/autRequired";
import "react-responsive-modal/styles.css";
import {Helmet} from "react-helmet";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import AccountCreatedSuccessful from "./views/pages/AccountCreatedSuccessful/AccountCreatedSuccessful";
import DepartFrom from "./views/pages/DepartFrom/DepartFrom";
import ListingPage from "./views/pages/ListingPage/ListingPage";
import ConfirmReservation from "./views/pages/ConfirmReservation/ConfirmReservation";
import TripConfirmation from "./views/pages/TripConfirmation/tripConfirmation";
import CompletePuchase from "./views/pages/CompletePuchase/CompletePuchase";
import Subscription from "./views/pages/Subscription/Subscription";
import TakeRouteStop from "./views/pages/TakeRouteStop/TakeRouteStop";
import SuccesfullNotificationSubmission1 from "./views/pages/SuccesfullNotificationSubmission1/SuccesfullNotificationSubmission1";
import PaymentMadeSuccessful from "./views/pages/PaymentMadeSuccessful/PaymentMadeSuccessful";
import ForgotPassword from "./views/pages/ForgotPassword/ForgotPassword";
import NewPassword from "./views/pages/NewPassword/NewPassword";
import OTP from "./views/pages/OTP/OTP/OTP";
import SearchingRoute from "./views/pages/SearchingRoute/SearchingRoute";
import ForgotPasswordSuccessful from "./views/pages/ForgotPasswordSuccessful/ForgotPasswordSuccessful";
import ListingDetailPage from "./views/pages/ListingDetailPage/ListingDetailPage";
import HowItWorks from "./views/pages/HowItWorks/HowItWorks";
import Invoice from "./views/pages/Invoice/Invoice";
import TokenLogin from "./views/pages/login/tokenLogin";
import ArchiveTrips from "./views/pages/ArchiveTrips/ArchiveTrips";
import BookingSuccessful from "./views/pages/BookingSuccessful/BookingSuccessful";
import BookingReject from "./views/pages/BookingReject/BookingReject";
import IdVerificationCompany from './views/pages/IdVerificationStartcompany/IdVerificationCompany';
import UploadPictureCompany from "./views/pages/UploadPictureCompany/UploadPictureCompany";
import OtpCreate from './views/pages/OTP/OTP/OTP_create_accout'
import CompanyVerificationSuccessful from "./views/pages/CompanyVerificationSuccessful/CompanyVerificationSuccessful";
import PaymentDecline from './views/pages/PaymentDecline/PaymentDecline';
import ReactGA from 'react-ga'
import Impressum from './views/pages/impressum/Impressum';
import Datenschutz from './views/pages/datenschutzs/Datenschutz';
import Widerrufsbelehrung from './views/pages/widerrufsbelehrung/Widerrufsbelehrung';
import AllgemeineGeschäftsbedingungen from "./views/pages/agp/AGP";
import { io } from "socket.io-client";
const socket = io("https://www.warumleer.de:5201");
socket.on('connect', () => {
  // console.log('Connected to server on port 5201');
});
// const socket = new WebSocket('https://www.warumleer.de:5201');
// socket.onopen = function(event) {
   
// };
// const socket = io('https://www.warumleer.de:5201'); // Update with your server URL

function App() {
useEffect(() => {
  ReactGA.initialize('G-M90VTQ0NRG');
  ReactGA.pageview(window.location.pathname);
}, []);
useEffect(()=>{
      
  socket.on('message', (event) => {
    
    try {
      // debugger
     let event1=event?.split("Received your message:")[1];
     let ev=JSON.parse(event1)
      if (ev.email == JSON.parse(localStorage.getItem('user')).userInfo.email && ev.type == "approved") {
        let storeData = localStorage.getItem('user');
        let parseData = JSON.parse(storeData);
        parseData['userInfo']["status"] = 1
        localStorage.setItem("user", JSON.stringify(parseData))
      }
      if (ev.email == JSON.parse(localStorage.getItem('user')).userInfo.email && ev.type == "ban") {
        // localStorage.clear();
        localStorage.removeItem('user');
        localStorage.removeItem('checkoutUrl');
        window.location.href = "/login";
      }

      if (ev.email == JSON.parse(localStorage.getItem('user')).userInfo.email && ev.type == "reload") {

        
      }
    }
    catch (e) {

    }
  });
  // return () => {
  //   socket.disconnect();
  // };

// const socket = new WebSocket('https://www.warumleer.de:5201');
// socket.onopen = function(event) {
   
// };
// socket.onmessage = function(event) {
// try{
//   if(JSON.parse(event.data).email==JSON.parse(localStorage.getItem('user')).userInfo.email && JSON.parse(event.data).type=="approved"){
//     let storeData = localStorage.getItem('user');
//     let parseData = JSON.parse(storeData);
//     parseData['userInfo']["status"] = 1
//     localStorage.setItem("user", JSON.stringify(parseData))
//   }
//   if(JSON.parse(event.data).email==JSON.parse(localStorage.getItem('user')).userInfo.email && JSON.parse(event.data).type=="ban"){
//     localStorage.clear();
//     window.location.href="/login";
//   }
// }
// catch(e){

// }
//   // Handle received message
// };


},[])




  return (
    <div className="App">
      <Toaster position="top-right" reverseOrder={false} />
      <BrowserRouter>
     
        <Routes>

        <Route path="/Impressum" element={<Impressum />} />

        <Route path="/Datenschutz" element={<Datenschutz />} />
        <Route path="/AllgemeineGeschäftsbedingungen" element={<AllgemeineGeschäftsbedingungen />} />

        <Route path="/Widerrufsbelehrung" element={<Widerrufsbelehrung />} />


          <Route path="/SearchingRoute" element={<SearchingRoute />} />
          <Route path="/PaymentDecline" element={<PaymentDecline />} />
          <Route path="/BookingSuccessful" element={<BookingSuccessful />} />
          <Route path="/IdVerificationCompany" element={<IdVerificationCompany />} />
          <Route path="/UploadPictureCompany" element={<UploadPictureCompany />} />
        <Route path="/BookingReject" element={<BookingReject />} />
          <Route path="/ArchiveTrips" element={<ArchiveTrips />} />
          <Route path="/token_login" element={<TokenLogin />} />

          <Route path="/login" element={<Login />} />
          <Route path="/HowItWorks" element={<HowItWorks />} />
          <Route path="/Invoice" element={<Invoice />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/OTP" element={<OTP />} />
          <Route path="/CompanyVerificationSuccessful" element={<CompanyVerificationSuccessful />} />
          <Route path="/OTP_created" element={<OtpCreate/>} />
          <Route path="/NewPassword" element={<NewPassword />} />
          <Route path="/SelectDate" element={<SelectDate />} />
          <Route path="/ListingDetailPage" element={<ListingDetailPage />} />
          <Route
            path="/AccountCreatedSuccessful"
            element={<AccountCreatedSuccessful />}
          />
          <Route path="/Register" element={<Register />} />
          <Route
            path="/IdVerificationStart"
            element={<IdVerificationStart />}
          />
          <Route
            path="/ProvideFrontPicture"
            element={<ProvideFrontPicture />}
          />
          <Route path="/CaptureFrontId" element={<CaptureFrontId />} />
          <Route path="/CaptureBackId" element={<CaptureBackId />} />
          <Route path="/CaptureSelfie" element={<CaptureSelfie />} />
          <Route
            path="/VerificationSuccessful"
            element={<VerificationSuccessful />}
          />
          <Route path="/UploadPicture" element={<UploadPicture />} />
          <Route path="/AccountCreatedSuccessful" element={<AccountCreatedSuccessful />} />
          <Route path="/DepartTo" element={<DepartTo />} />
          <Route path="/DepartFrom" element={<DepartFrom />} />
          <Route path="/TakeRoute" element={<TakeRoute />} />
          <Route path="/TakeRouteStop" element={<TakeRouteStop />} />
          <Route path="/Stops" element={<Stops />} />
          <Route path="/PickUpCars" element={<PickUpCars />} />
          <Route path="/WeightCarry" element={<WeightCarry />} />
          <Route path="/TakeCars" element={<TakeCars />} />
          <Route path="/InformationSeeker" element={<InformationSeeker />} />
          <Route path="/ExtraInformation" element={<ExtraInformation />} />
          <Route
            path="/TripSuccessfullyPublished"
            element={<TripSuccessfullyPublished />}
          />
          <Route path="/AddStop" element={<AddStop />} />
          <Route
            path="/ForgotPasswordSuccessful"
            element={<ForgotPasswordSuccessful />}
          />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            path="/edit-profile"
            element={
              <RequireAuth>
                <EditProfile />
              </RequireAuth>
            }
          />
          <Route
            path="/kontoinformationen"
            element={
              <RequireAuth>
                <Info />
              </RequireAuth>
            }
          />
          <Route
            path="/mitgliedschaft"
            element={
              <RequireAuth>
                <Money />
              </RequireAuth>
            }
          />
          <Route
            path="/book-trip"
            element={
              <RequireAuth>
                <BookTrip />
              </RequireAuth>
            }
          />
          <Route
            path="/publishedtrips"
            element={
              <RequireAuth>
                <PublishedTrips />
              </RequireAuth>
            }
          />
          <Route path="/" element={<HomePage />} />
          <Route
            path="/ListingPageNoResults"
            element={
              
                <ListingPageNoResults />
              
            }
          />
          <Route
            path="/GetNotifications"
            element={
              <RequireAuth>
                <GetNotifications />
              </RequireAuth>
            }
          />
          <Route path="/notification" element={<Notification />} />
          <Route
            path="/reviews"
            element={
              <RequireAuth>
                <Reviews />
              </RequireAuth>
            }
          />
          <Route
            path="/add-wehicle"
            element={
              <RequireAuth>
                <AddWehicle />
              </RequireAuth>
            }
          />
          <Route path="/BillingCycle" element={<BillingCycle />} />
          <Route path="/PublishedTripCancelled" element={<PublishedTripCancelled />} />
          <Route path="/BookedTripCancelled" element={<BookedTripCancelled />} />
          
          <Route path="/ListingPage" element={<ListingPage />} />
          <Route
            path="/SuccesfullNotificationSubmission"
            element={
              <RequireAuth>
                <SuccesfullNotificationSubmission />
              </RequireAuth>
            }
          />
          <Route
            path="/SuccesfullNotificationSubmission1"
            element={
              <RequireAuth>
                <SuccesfullNotificationSubmission1 />
              </RequireAuth>
            }
          />
          <Route
            path="/ConfirmReservation"
            element={
              <RequireAuth>
                <ConfirmReservation />
              </RequireAuth>
            }
          />
          <Route
            path="/TripConfirmation"
            element={
              <RequireAuth>
                <TripConfirmation />
              </RequireAuth>
            }
          />
          <Route path="/CompletePuchase" element={<CompletePuchase />} />
          <Route
            path="/Subscription"
            element={
              <RequireAuth>
                <Subscription />
              </RequireAuth>
            }
          />
          <Route
            path="/PaymentMadeSuccessful"
            element={<PaymentMadeSuccessful />}
          />
        </Routes>
      </BrowserRouter>
       
      {/* <Helmet>
     <script src="https://cloud.ccm19.de/app.js?apiKey=81a998b44cb9498319babc9738ff999cfe1c0d4becfb61f6&amp;domain=666808a270ff1f6c45072782&amp;lang=de_DE" referrerpolicy="origin"></script>
            </Helmet> */}
    </div>
  );
}

export default App;