import React, { useEffect, useState } from "react";
import eye from "../../../assets/eye.png";
import sms from "../../../assets/sms.png";
import call from "../../../assets/call.png";
import emailred from "../../../assets/email-red.png";
import key from "../../../assets/key.png";
import keyred from '../../../assets/key-red.png';
import Forgot from "../../../assets/forgotPassword.png"
import "./ForgotPassword.css";
import { EDITPROFILE_API } from "../../../services/Api/editProfileApi";
import toast from "react-hot-toast";
// import { toast, ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import Header from "../../../common/zuruckheader";

const ForgotPassword = (props) => {
    const [emailError, setEmailError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [emailErrFlag, setemailErrFlag] = useState(false);
    const [email, setemail] = useState('');
    const [isEmailMode, setIsEmailMode] = useState(true);
    const navigate = useNavigate();

    const onLogin = async (event) => {
        event.preventDefault();
        if (isEmailMode) {
            if (email === "") {
                setEmailError('Bitte geben Sie eine E-mail-Adresse ein.');
                setemailErrFlag(true);
                return false;
            }

            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (!email.match(validRegex)) {
                setEmailError('Bitte geben Sie eine gültige E-Mail-Adresse ein.');
                
                setemailErrFlag(true);
                return false;
            }

            const response = await EDITPROFILE_API.verifyEmail({ email });
if (response && (response.status === 201 || response.status === 200)) {
    toast.success('Verifizierungscode wurde per E-Mail gesendet', { autoClose: 1000 });
    navigate(`/OTP`);
} else {
    if (response) {
        
        setEmailError('Bitte geben Sie eine registrierte E-Mail-Adresse ein.');
        setemailErrFlag(true);
        toast.error("Bitte geben Sie eine registrierte E-Mail-Adresse ein.")
         return false;
    } else {
        setEmailError('Bitte geben Sie eine registrierte E-Mail-Adresse ein.');
        setemailErrFlag(true);
         return false;
    }
}
        } else {
            if (email === "") {
                setMobileError('Bitte geben Sie eine Handynummer ein.');
                setemailErrFlag(true);
                return false;
            }

            var validMobileRegex = /^[0-9]*$/;
            if (!email.match(validMobileRegex)) {
                setMobileError('Bitte geben Sie eine gültige Handynummer ein.');
                setemailErrFlag(true);
                return false;
            }

            const response = await EDITPROFILE_API.verifyPhone({ phone_no: email });
           
            if (response && (response.status === 201 || response.status === 200)) {
                toast.success('OTP an Mobiltelefonnummer gesendet', { autoClose: 1000 });
                navigate(`/OTP?email=${email}`);
            } else {
                if (response) {
                    setMobileError('Bitte geben Sie eine Mobiltelefonnummer ein.');
                    setemailErrFlag(true);
                    
                     return false;
                } else {
                    setMobileError('Bitte geben Sie eine gültige Handynummer ein.');
                    setemailErrFlag(true);
                    
                     return false;
                   
                }
            }
        }
        // navigate(`/OTP?email=${email}`);
    };

    const toggleMode = () => {
        setIsEmailMode(!isEmailMode);
        setemail('');
    };
    useEffect(() => {
        let obj={
            UserEmail:email
        }
        localStorage.setItem('myKey', JSON.stringify(obj));
      }, [email]);
    return (
        <React.Fragment>
            <Header />
            <form onSubmit={onLogin}>
            <div className="container login-page">
                <div className="row mt-lg-5 pt-lg-5">
                    <div className="col-lg-5 col-12 my-auto order-2 order-lg-1 " style={{ background: '' }}>
                        <div className="text-center text-lg-start" style={{ color: "#000000", fontSize: 45, fontWeight: 800 }}>
                            Passwort vergessen
                        </div>
                        <div className="mt-3 mb-4" style={{ fontSize: 22, color: "#717D96" }}>
                        Keine Sorge! Geben Sie Ihre E-Mail ein, damit wir Ihnen einen Code senden können, mit dem Sie zurück in Ihr Konto gelangen.
                        </div>
                        <div className="col-12 mt-4">
                            <div className="form-wrap d-flex">
                                <img
                                    src={emailErrFlag ? emailred : isEmailMode ? sms : call}
                                    className="eye"
                                />
                                <input
                                    value={email}
                                    onChange={(e) => {
                                        setEmailError('');
                                        setMobileError('');
                                        setemailErrFlag(false);
                                        setemail(e.target.value);
                                    }}
                                    className={`form-input form-control shadow-none con-passwords ${emailErrFlag ? 'error-border' : ''}`}
                                    id={`${emailErrFlag ? "nameErr" : "password"}`}
                                    type={isEmailMode ? 'text' : 'tel'}
                                    name={isEmailMode ? 'E-Mail' : 'Mobile No'}
                                    placeholder={isEmailMode ? 'E-Mail' : 'Mobile No'}
                                    pattern={isEmailMode ? null : '[0-9]*'}
                                />
                            </div>
                            {emailErrFlag && (
                                <div className="error-msg">{isEmailMode ? emailError : mobileError}</div>
                            )}
                        </div>
                        <div className="col-12 mt-4">
                            <button
                                onClick={onLogin}
                                style={{ color: "#FFFFFF", fontSize: 14, background: "#25A9E0", border: "1px solid #25A9E0", borderRadius: 8, width: '100%', padding: 8 }}
                            >
                                Weiter
                            </button>
                        </div>
                        {/* <div className="col-12 mt-5">
                            <div style={{ marginLeft: 5 }}>
                                <span style={{ color: "#000000", fontSize: 14 }}>
                                    <a href="#" onClick={toggleMode} style={{ textDecoration: 'none', color: '#25A9E0' }}>
                                        Verwenden Sie einen anderen Weg
                                    </a>
                                </span>
                            </div>
                        </div> */}
                    </div>
                    <div className="col-lg-6  order-1 order-lg-2 my-auto offset-lg-1">
                        <img src={Forgot} style={{ width: "100%" }} alt="Forgot password" />
                    </div>
                </div>
            </div>
            </form>
        </React.Fragment>
    );
};

export default ForgotPassword;