import React, { useState } from 'react'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import axios from "axios";
import toast from "react-hot-toast";
import Successful from '../../../assets/ID Verification.png'
import { Link } from "react-router-dom";
import Header from '../../../common/zuruckheader';
import Footer from '../../../common/footer';

export default function CompanyVerificationSuccessful() {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [errr, setErrr] = useState("")
  const [phoneErr, setPhoneErr] = useState(false)
  const [contactBack, setContactBack] = useState(0);
  const [phone, setPhone] = useState('');
  const onOpenModal = () => {
    setOpen(true)
    // document.getElementById("navbarSupportedContent")?.classList.remove("show");
  };
  const onCloseModal = () => setOpen(false);

  const onSumbit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!name || !email || !phone || !message) {
          setErrr("Bitte füllen Sie alle markierten Felder aus");
          setTimeout(() => {
            setErrr("");
          }, 3000);
          return;
        }
    
        if (!emailRegex.test(email)) {
          setErrr("Bitte füllen Sie alle markierten Felder aus");
          setTimeout(() => {
            setErrr("");
          }, 3000);
          return;
        }
    
        if (phone.length < 8 || phone.length > 14) {
          setErrr("Telefonnummer muss zwischen 8 und 14 Zeichen lang sein");
          setTimeout(() => {
            setErrr("");
          }, 3000);
          return;
        }
    setOpen(false);


    https: axios
      .post("https://api.warumleer.de/api/api/v1/contect_us", {
        Name: name,
        email_id: email,
        is_checked: contactBack,
        mobile_no: phone,
        description: message,
      })
      .then((res) => {
        if (res?.status == 201) {
          toast.success("Ihre Anfrage wurde übermittelt", {
            autoClose: 2000,
          });

          setTimeout(() => {
            setEmail("");
            setName("");
            setMessage("");
            setPhone("");
            setContactBack("");
          }, 2500);
        }
      })
      .catch((err) => {
     
      });
  };

  const closeT = () => {
    document.getElementById("navbarSupportedContent")?.classList.remove("show");
  }
  return (
    <>
      {/* <Header /> */}
      <div className='pb-5'>
        <div className='container'>
          <div className='row justify-content-center mt-md-3 pt-4'>
            <div className='col-xl-6 col-lg-8 col-md-10 col-12'>
              <div className='text-center' style={{ fontSize: 32, fontWeight: 600 }}>Wir haben Ihre Informationen erhalten</div>
              <div className='d-flex justify-content-center mt-4 px-lg-5 mx-5'>
                <img className='px-lg-5' src={Successful} width={'100%'} />
              </div>
              <div className='text-center px-3 mt-4' style={{ fontSize: 22, color: '#717D96' }}>
                Vielen Dank für Ihre Einreichung!
              </div>
              <div className='text-center px-3 mt-4' style={{ fontSize: 22, color: '#717D96' }}>
              Ihre Dokumente werden nun schnellstmöglich geprüft. Sobald dieser Vorgang
  abgeschlossen ist, benachrichtigen wir Sie und Ihre Fahrt wird veröffentlicht.
              </div>
              <div className='d-flex justify-content-center mt-4 pb-5'>
                <button type="button" class="btn btn-primary px-3 py-2 my-auto" style={{ backgroundColor: '#25A9E0', border: 'none', fontSize: 16, marginLeft: 20 }}><Link to={'/'} style={{ textDecoration: 'none', color: '#fff' }}>Zurück zur Startseite</Link></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </>
  )
}