import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { Urls } from '../Urls/apiUrl'
import toast from 'react-hot-toast'
import { ToastProvider } from 'react-hot-toast';
import GetNotifications from './../../views/pages/GetNotifications/GetNotifications';
export const PAYMENT_API = {
    getStripeCheckout,
    updatePaymentStatus,
    PaymentDetailbyId,
    CompletePayment,
    changePaymentMode
}
function updatePaymentStatus(requestOptions) {
  
  return axios
    .patch(Urls + 'change_payment_status', requestOptions, { headers: { Authorization: getToken() } })
    .then((response) => {     
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
          
          })
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
          
          })
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
          
          })
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
          
          })
        }
    })
}
function getStripeCheckout(requestOptions) {
  
  return axios
    .post(Urls + 'create-checkout-session', requestOptions, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
          
          })
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
          
          })
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
          
          })
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
          
          })
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
          
          })
        }
    })
}
function PaymentDetailbyId(user_id) {
  return axios
    .get(Urls + `payment_detailbyId/${user_id}`, {
      headers: { Authorization: getToken() },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response?.status === 401) {
          window.location.href = "/login";
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
          
          })
        } else if (error.response?.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
          
          })
        } else if (error.response?.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
          
          })
        } else if (error.response?.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
          
          })
        }
    })
}

function CompletePayment(session_id, user_id) {
  return axios
    .get(Urls + `complete?session_id=${session_id}&user_id=${user_id}`, {
      headers: { Authorization: getToken() },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return [];
      }
      if (error.response != undefined) {
        if (error.response?.status === 401) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
          
          })
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, { autoClose: 1000 });
        } else if (error.response?.status === 400) {
          toast.error(error?.response?.data?.message, { autoClose: 1000 });
        } else if (error.response?.status === 409) {
          toast.error(error?.response?.data?.message, { autoClose: 1000 });
        } else if (error.response?.status === 404) {
          toast.error(error?.response?.data?.message, { autoClose: 1000 });
        }
      }
    });
}
function changePaymentMode(requestOptions) {
  return axios
    .post(Urls + 'create-billing-portal-session', requestOptions, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error == 'Unauthorized') {
        return []
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
          
          })
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
          
          })
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
          
          })
        } else if (error.response.status === 404) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
          
          })
        } else if (error.response.status === 500) {
          toast.error(error?.response?.data?.message, {
            autoClose: 1000,
          
          })
        }
    })
}

function getToken() {
    // Retrieve the user data from localStorage
    const storedUserString = localStorage.getItem('user')
    // Check if the user data is stored in localStorage
    if (storedUserString) {
      const storedUserObject = JSON.parse(storedUserString)
      // Retrieve the token from the user data
      const token = storedUserObject.token
  
      if (token) {
        const decoded = jwtDecode(token)
  
        // Perform additional validation if needed
        if (decoded.email === storedUserObject.userInfo.email) {
          // If the token is valid, return it in the "Bearer" format
          return `Bearer ${token}`
        } else {
          // Token is invalid
          return null
        }
      } else {
        // Token not found in user data
        return null
      }
    }
  }