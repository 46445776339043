import React, { useEffect, useState } from 'react'
import Successful from '../../../assets/booking-reject.png'
import { Link, useNavigate } from "react-router-dom";
import Header from '../../../common/zuruckheader';
import Footer from '../../../common/footer';

export default function BookingReject() {
    const navigate=useNavigate();
    

  return (
    <>
    {/* <Header/> */}
    <div className='container'>
        <div className='row justify-content-center pt-5'>
            <div className='col-xl-7 col-lg-8 col-md-10 col-12'>
                <div className='text-center' style={{fontSize:40,fontWeight:600}}>Buchungsanfrage abgelehnt</div>
                <div className='d-flex justify-content-center mt-4 px-lg-5 mx-lg-5'>
                    <div className='px-lg-5'>
                        <img className='px-5' src={Successful} width={'100%'}/>
                    </div>
                </div>
                <div className='text-center px-3 mt-4' style={{fontSize:22,color:'#717D96'}}>
                Die Buchungsanfrage wurde abgelehnt.<br className='d-none d-lg-block'/> Vielen Dank für Ihre Rückmeldung. 
                </div>
                <div className='text-center px-3 mt-4' style={{fontSize:22,color:'#717D96'}}>
                    Wir hoffen, Sie finden eine passende Fahrmöglichkeit und<br className='d-none d-lg-block'/> wünschen Ihnen alles Gute.
                </div>
                <div className='d-flex justify-content-center mt-4'>
                    <div className='text-center text-lg-start mb-5'>
                        <button type="button" class="btn btn-primary px-3 py-2 my-auto" style={{backgroundColor:'#25A9E0',border:'none',fontSize:16}}>
                            <Link to={'/'} style={{textDecoration:'none', color:'#ffffff'}}>Zurück zur Startseite</Link>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <Footer/> */}
    </>
  )
}
