// Define action types
export const SET_DEPART_TO_LOCATION = "SET_DEPART_TO_LOCATION";
export const SET_DEPART_FROM_LOCATION = "SET_DEPART_FROM_LOCATION";
export const SET_ROUTE = "SET_ROUTE";
export const SET_INFORMATION = "SET_INFORMATION";
export const SET_NO_OF_STOP = "SET_NO_OF_STOP";
export const SET_PICKUP_DATE = "SET_PICKUP_DATE";
export const SET_PICKUP_TIME = "SET_PICKUP_TIME";
export const SET_WEIGHT = "SET_WEIGHT";
export const SET_NO_OF_CARS = "SET_NO_OF_CARS";
export const SET_DESCRIPTION = "SET_DESCRIPTION";
export const SET_FRONT_ID = "SET_FRONT_ID";
export const SET_BACK_ID = "SET_BACK_ID";
export const SET_SELFIE_ID = "SET_SELFIE_ID";
export const ADD_STOP = "ADD_STOP";
export const REMOVE_STOP="REMOVE_STOP";
export const REMOVE_ALL_STOP = "REMOVE_ALL_STOP"

// Define action creators

export const setDepartToLocation = (location, lat, lon) => {
  return {
    type: "SET_DEPART_TO_LOCATION",
    payload: {
      location: location,
      lat: lat,
      lon: lon,
    },
  };
};

export const setDepartFromLocation = (location, lat, lon) => {
  return {
    type: "SET_DEPART_FROM_LOCATION",
    payload: {
      location: location,
      lat: lat,
      lon: lon,
    },
  };
};

export const setStopLocation = (location, lat, lon) => {
  return {
    type: "SET_STOP_LOCATION",
    payload: {
      location: location,
      lat: lat,
      lon: lon,
    },
  };
};
export const setRoute = (time, distance, stops) => {
  return {
    type: "SET_ROUTE",
    payload: {
      time: time,
      distance: distance,
      stops: stops,
    },
  };
};
export const setInformation = (email, phone_no) => {
  return {
    type: "SET_INFORMATION",
    payload: {
      email: email,
      phone_no: phone_no,
    },
  };
};

export const setNoOfStop = (noOfStop) => {
  return {
    type: SET_NO_OF_STOP,
    payload: noOfStop,
  };
};

export const setPickupDate = (location) => {
  return {
    type: SET_PICKUP_DATE,
    payload: location,
  };
};

export const setPickupTime = (time) => {
  return {
    type: SET_PICKUP_TIME,
    payload: time,
  };
};
export const setWeight = (weight) => {
  return {
    type: SET_WEIGHT,
    payload: weight,
  };
};
export const setNoOfCars = (location) => {
  return {
    type: SET_NO_OF_CARS,
    payload: location,
  };
};
export const setDescription = (location) => {
  return {
    type: SET_DESCRIPTION,
    payload: location,
  };
};

export const setFrontId = (front_id) => {
  return {
    type: SET_FRONT_ID,
    payload: front_id,
  };
};

export const setBackId = (back_id) => {
  return {
    type: SET_BACK_ID,
    payload: back_id,
  };
};

export const setSelfieId = (selfie_id) => {
  return {
    type: SET_SELFIE_ID,
    payload: selfie_id,
  };
};

export const addStop = (stop) => ({
  type: ADD_STOP,
  payload: stop,
  
});

export const removeStop = (index) => ({
  type: REMOVE_STOP,
  payload: index,
});

export const removeAllStop=(tmp)=>({
  type:"REMOVE_ALL_STOP",payload:tmp,
});

