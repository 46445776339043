import React, { useEffect, useState } from "react";
import Logo from "../assets/Logo-for-verification.png";
import FrameIcon from "../assets/FrameIcon.png"
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./header.css";
import Search from "../assets/search-blue.png";
import Truck from "../assets/truck-blue.png";
import Contact from "../assets/contact-us.png";
import Email from '../assets/images/notification.png'
import User from "../assets/Avatar2.png";
import Bell from "../assets/bell.png";
import How from "../assets/how-we-work.png";
import CloseIcon from "../assets/images/close.png";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import LoginIcon from '../assets/images/user-blue.png'
import UserAdd from '../assets/images/user-add-blue.png'
import axios from "axios";
import toast from "react-hot-toast";
import Profil from "../assets/login-icon.png";
import Setting from "../assets/images/setting-g.png";
import Money from "../assets/images/money-changeg.png";
import Car from "../assets/images/car.png";
import TruckGrey from "../assets/images/truckg.png";
import BellIcon from "../assets/images/notification-g.png";
import Star from "../assets/images/starg.png";
// import { EDITPROFILE_API } from "../../../services/Api/editProfileApi";
import { EDITPROFILE_API } from "../services/Api/editProfileApi";
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { io } from "socket.io-client";
import { NullType } from "maplibre-gl";
const socket = io("https://www.warumleer.de:5201");
socket.on('connect', () => {
  // console.log('Connected to server on port1 5201');
});

export default function Header() {
  const [userInfo, setUserInfo] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [profileUrl, setUrl] = useState("");
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errr, setErrr] = useState("");
  const [showMenu, setShowMenu] = useState(true);
  const [contactBack, setContactBack] = useState(0);
  let [noti_count, set_noti_count] = useState(0)
  const [noti_, set_noti_] = useState([])
const [docs_status,setDocs_Status]= useState();

  const [isOpen, setIsOpen] = React.useState(false)

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }

  const activities = [
    { id: 1, user: 'Madhulika Chauhan', action: 'raised a new ticket', details: 'Test Email: Need account statement (#3)', time: '5 hours ago' },
    { id: 2, user: 'Freshdesk', action: 'raised a new ticket', details: 'Received a broken TV (#2)', time: '3 days ago' },
    { id: 3, user: 'Customer Service', action: 'has sent a response to the ticket', details: 'Email address change (#1)', time: '3 days ago' },
    { id: 4, user: 'Freshdesk', action: 'raised a new ticket', details: 'Email address change (#1)', time: '3 days ago' },
    { id: 5, user: 'Madhulika Chauhan', action: 'created a solution folder', details: 'Account Settings', time: '3 days ago' },
    { id: 6, user: 'Madhulika Chauhan', action: 'created a solution folder', details: 'Payments', time: '3 days ago' },
    { id: 7, user: 'Madhulika Chauhan', action: 'created a solution folder', details: 'Getting Started', time: '3 days ago' },
    { id: 8, user: 'Madhulika Chauhan', action: 'created a solution folder', details: 'FAQ', time: '3 days ago' },
    { id: 9, user: 'Madhulika Chauhan', action: 'created a solution category', details: 'General', time: '3 days ago' },
    { id: 10, user: 'Madhulika Chauhan', action: 'created a solution folder', details: 'Drafts', time: '3 days ago' },
    { id: 11, user: 'Madhulika Chauhan', action: 'created a solution category', details: 'Default Category', time: '3 days ago' },
    { id: 1, user: 'Madhulika Chauhan', action: 'raised a new ticket', details: 'Test Email: Need account statement (#3)', time: '5 hours ago' },
    { id: 2, user: 'Freshdesk', action: 'raised a new ticket', details: 'Received a broken TV (#2)', time: '3 days ago' },
    { id: 3, user: 'Customer Service', action: 'has sent a response to the ticket', details: 'Email address change (#1)', time: '3 days ago' },
    { id: 4, user: 'Freshdesk', action: 'raised a new ticket', details: 'Email address change (#1)', time: '3 days ago' },
    { id: 5, user: 'Madhulika Chauhan', action: 'created a solution folder', details: 'Account Settings', time: '3 days ago' },
    { id: 6, user: 'Madhulika Chauhan', action: 'created a solution folder', details: 'Payments', time: '3 days ago' },
    { id: 7, user: 'Madhulika Chauhan', action: 'created a solution folder', details: 'Getting Started', time: '3 days ago' },
    { id: 8, user: 'Madhulika Chauhan', action: 'created a solution folder', details: 'FAQ', time: '3 days ago' },
    { id: 9, user: 'Madhulika Chauhan', action: 'created a solution category', details: 'General', time: '3 days ago' },
    { id: 10, user: 'Madhulika Chauhan', action: 'created a solution folder', details: 'Drafts', time: '3 days ago' },
    { id: 11, user: 'Madhulika Chauhan', action: 'created a solution category', details: 'Default Category', time: '3 days ago' },
  ];

  const onOpenModal = () => {
    setOpen(true);
    // document.getElementById("navbarSupportedContent")?.classList.remove("show");
  };
  const onCloseModal = () => setOpen(false);

  const onSumbit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!name || !email || !phone || !message) {
      setErrr("Bitte füllen Sie alle markierten Felder aus");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return;
    }

    if (!emailRegex.test(email)) {
      setErrr("Bitte füllen Sie alle markierten Felder aus");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return;
    }

    if (phone.length < 8 || phone.length > 14) {
      setErrr("Telefonnummer muss zwischen 8 und 14 Zeichen lang sein");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return;
    }

   

    setOpen(false);

    https: axios
      .post("https://api.warumleer.de/api/api/v1/contect_us", {
        Name: name,
        email_id: email,
        is_checked: contactBack,
        mobile_no: phone,
        description: message,
      })
      .then((res) => {
        if (res && (res?.status == 200 || res?.status == 201)) {
          toast.success("Ihre Anfrage wurde übermittelt ", {
            autoClose: 2000,
          });
          setTimeout(() => {
            setEmail("");
            setName("");
            setMessage("");
            setPhone("");
            setContactBack("");
          }, 2500);
        }
      })
      .catch((err) => {
        
      });
  };

 
let docStatus="";
  useEffect(() => {
    let usr = localStorage.getItem("user");
    let user_info = JSON.parse(usr);
    // let user_id=user_info.userInfo.user_id;
    setUserId(user_info);
    if(user_info){
    docStatus=user_info?.userInfo?.document_status;
    setDocs_Status(docStatus);
    }
  }, []);
 
  const getProfileInfo = async (userId) => {
    try {
      const response = await EDITPROFILE_API.getProfileInfo(userId);
      if (response?.status === 200) {
        const profiledata = response?.data[0] || [];
        setUrl(profiledata?.image_url);
      } else {
        console.error("Error fetching branch data. Response:", response);
      }
    } catch (error) {
      console.error("Error fetching branch data:", error);
    }
  };
  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      let usr = JSON.parse(user);
      let id = usr?.userInfo?.user_id;
      setUserId(id);
      setUserInfo(usr);
      getProfileInfo(id); // Call getProfileInfo with the correct user ID
    } else {
      setUserInfo("");
    }
  }, [userId]); // Depend on userId to trigger the effect when it changes


  
  const handleSuchen = () => {
    if (!userId) {
      navigate("/register");
    } else {
      navigate("/SearchingRoute");
    }
  };
  const handledepartTo = () => {
    if (!userId) {
      navigate("/register");
    } else {
      navigate("/DepartTo");
    }
  };

  const changeNotiState = async (id_noti) => {
    let payload = {
      not_id: id_noti,
      status: 2
    }
    const response = await EDITPROFILE_API.notification_status_chnage(payload);

    notification()
  }


  const notification = async () => {
    let user = localStorage.getItem("user");
    if (user) {
        let usr = JSON.parse(user);
        const response = await EDITPROFILE_API.GetNotifications_by_id(usr?.userInfo?.user_id);
        if (response?.status === 200) {
            let count__ = 0;
            if (response?.count > 0) {
                const filteredNotifications = response.data.filter(notification => notification.created_dt !== null);

                filteredNotifications.forEach(notification => {
                    if (notification.isNotify === 1){
                      count__++;

                    }
                });
                
                set_noti_count(count__);
                set_noti_(filteredNotifications);



            }
            else{
              set_noti_count(0);
              set_noti_([]); 
            }

        }
        else{
          set_noti_count(0);
          set_noti_([]); 
        }
    }
}

  useEffect(() => {

    notification();

    socket.on('message', (event) => {
     
      try {
        let event1=event?.split("Received your message:")[1];
        let ev=JSON.parse(event1)
        if (ev.email == JSON.parse(localStorage.getItem('user')).userInfo.email && ev.type == "reload") {
          let storeData = localStorage.getItem('user');
          let parseData = JSON.parse(storeData);
        
          
         if(parseData['userInfo']["document_status"] ==1 || parseData['userInfo']["document_status"] == 0){
          parseData['userInfo']["document_status"] = null
          setDocs_Status(null);

         }
          localStorage.setItem("user", JSON.stringify(parseData))
          window.location.reload();
          notification();
        }
        if (ev.email == JSON.parse(localStorage.getItem('user')).userInfo.email && ev.type == "approved") {
         
          let storeData = localStorage.getItem('user');
          let parseData = JSON.parse(storeData);
          parseData['userInfo']["status"] = 1
          localStorage.setItem("user", JSON.stringify(parseData))
          notification();
        }
     
        if ((ev.email == JSON.parse(localStorage.getItem('user')).userInfo.email  ) && ev.type == "ban") {
         
          localStorage.removeItem('user');
          localStorage.removeItem('checkoutUrl');
          window.location.href = "/login";
        }

       
      }
      catch (e) {
  
      }
    });
  }, [noti_count])
 

  return (
    <>
      <div className="container py-3 d-none d-md-block px-0" style={{ backgroundColor: '#fff', position: 'relative', zIndex: 11 }}>
        <div className="row">
          <div className="col-lg-4 col-md-1 col-6 my-auto px-0">
            <Link to="/">
              <img src={Logo} width={"100px"} alt="Logo" />
            </Link>
          </div>

          <div className="col-lg-8 col-md-11 drop-sec">
            <div className="d-flex justify-content-end">
              {location.pathname !== "/Homepage" && (
                <>
                  <div className="d-flex my-auto mx-2">
                    <img src={How} width={20} height={20} alt="Search" />
                    <span
                      className="mx-2"
                      style={{
                        fontSize: 16,
                        color: "#25A9E0",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.location.href = "/HowItWorks";
                      }}
                    >
                      Wie funktioniert es?
                    </span>
                  </div>
                  <div className="d-flex my-auto">
                    <img src={Search} width={20} height={20} alt="Search" />
                    <span
                      className="mx-2"
                      style={{
                        fontSize: 16,
                        color: "#25A9E0",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.location.href = "/SearchingRoute";
                      }}
                    >
                      Suchen
                    </span>
                  </div>
                  <div
                    className="d-flex mx-2 my-auto"
                    style={{ cursor: "pointer" }}
                  >
                    <img src={Truck} width={20} height={20} alt="Truck" />
                    <span
                      className=""
                      style={{ fontSize: 16, color: "#25A9E0", marginLeft: 5 }}
                      onClick={handledepartTo}
                    >
                      Fahrt veröffentlichen
                    </span>
                  </div>
                  <div
                    className="d-flex mx-2 my-auto"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      onClick={onOpenModal}
                      src={Contact}
                      width={30}
                      height={30}
                      alt="Coontact us"
                    />
                  </div>
                  <div className="dropdown user-drop">
                    <button
                      className="btn dropdown-toggle d-flex"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={profileUrl ? profileUrl : User}
                        width={40}
                        height={40}
                        style={{ borderRadius: 50 }}
                        alt="User"
                      />
                    </button>
                    <ul
                      className="dropdown-menu py-0"
                      aria-labelledby="dropdownMenuButton1"
                      style={{ borderRadius: 0 }}
                    >
                      {userInfo ? (
                        <>
                          <li>
                            <Link className="dropdown-item px-0 py-0" to="#">
                              <div
                                className="px-3 py-2"
                                style={{
                                  backgroundColor: "#25A9E0",
                                  color: "#fff",
                                  textTransform: "capitalize",
                                }}
                              >
                                Hi, {userInfo?.userInfo?.name}
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item px-0 py-0"
                              to="/profile"
                            >
                              <div
                                className="d-flex px-3 py-2"
                              // style={{ backgroundColor: "#E2E7F0" }}
                              >
                                <img src={Profil} width={20} height={20} />
                                <div className="mx-2">Profil</div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item px-0 py-0"
                              to="/kontoinformationen"
                            >
                              <div
                                className="d-flex px-3 py-2"
                              // style={{ backgroundColor: "#E2E7F0" }}
                              >
                                <img src={Setting} width={20} height={20} />
                                <div className="mx-2">Kontoinformationen</div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item px-0 py-0"
                              to="/Mitgliedschaft"
                            >
                              <div
                                className="d-flex px-3 py-2"
                              // style={{ backgroundColor: "#E2E7F0" }}
                              >
                                <img src={Money} width={20} height={20} />
                                <div className="mx-2">Mitgliedschaft</div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item px-0 py-0"
                              to="/book-trip"
                            >
                              <div
                                className="d-flex px-3 py-2"
                              // style={{ backgroundColor: "#E2E7F0" }}
                              >
                                <img src={Car} width={20} height={20} />
                                <div className="mx-2">Gebuchte Fahrten</div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item px-0 py-0"
                              to="/publishedtrips"
                            >
                              <div
                                className="d-flex px-3 py-2"
                              // style={{ backgroundColor: "#E2E7F0" }}
                              >
                                <img src={TruckGrey} width={20} height={20} />
                                <div className="mx-2">
                                  Veröffentlichte Fahrten
                                </div>
                              </div>
                            </Link>
                          </li>
                          {/* <li>
                            <Link
                              className="dropdown-item px-0 py-0"
                              to="/notification"
                            >
                              <div
                                className="d-flex px-3 py-2"
                              // style={{ backgroundColor: "#E2E7F0" }}
                              >
                                <img src={BellIcon} width={20} height={20} />
                                <div className="mx-2">Benachrichtigungen</div>
                              </div>
                            </Link>
                          </li> */}

                          <li>
                            <Link
                              className="dropdown-item px-0 py-0"
                              to="/reviews"
                            >
                              <div
                                className="d-flex px-3 py-2"
                              // style={{ backgroundColor: "#E2E7F0" }}
                              >
                                <img src={Star} width={20} height={20} />
                                <div className="mx-2">Bewertungen</div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item px-0 py-0"
                              to="/login"
                            >
                              <div
                                className="px-3 py-2"
                                style={{
                                  backgroundColor: "#25A9E0",
                                  color: "#fff",
                                }}
                              >
                                Ausloggen
                              </div>
                            </Link>
                          </li>
                        </>
                      ) : (
                        <>
                          <li className="px-0">
                            <Link className="dropdown-item px-2" to="/login">
                              <div className="d-flex">
                                <img src={LoginIcon} width={20} height={20} /><div className="mx-2" style={{ fontSize: 16, fontWeight: 600, color: 'rgb(37, 169, 224)' }}>Einloggen</div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item px-2" to="/register">
                              <div className="d-flex">
                                <img src={UserAdd} width={20} height={20} /><div className="mx-2" style={{ fontSize: 16, fontWeight: 600, color: 'rgb(37, 169, 224)' }}>Registrieren</div>
                              </div>
                            </Link>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>

                  <div className="dropdown user-drop1 my-auto">
                    <button
                      onClick={toggleDrawer}

                      className="btn dropdown-toggle d-flex px-0"
                      type="button"

                    >
                      {noti_count != 0  ?
                        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                          {noti_count}
                          <span class="visually-hidden">unread messages</span>
                        </span>
                        : ''}
                      <img src={Bell} width={30} height={30} alt="User" />
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="container my-3 d-md-none">
        <div className="row">
          <div className="col-12 d-flex justify-content-between">
            <div className="dropdown user-drop">
              <button
                className="btn dropdown-toggle d-flex"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src={profileUrl ? profileUrl : User}
                  width={40}
                  height={40}
                  style={{ borderRadius: 50 }}
                  alt="User"
                />
                {/* <img src={profileUrl} width={40} height={40} style={{borderRadius:50}} alt='User' /> */}
              </button>
              <ul
                className="dropdown-menu py-0"
                aria-labelledby="dropdownMenuButton1"
              >
                {userInfo ? (
                  <>
                    <li>
                      <Link className="dropdown-item px-0 py-0" to="#">
                        <div
                          className="px-3 py-2"
                          style={{
                            backgroundColor: "#25A9E0",
                            color: "#fff",
                            textTransform: "capitalize",
                          }}
                        >
                          Hi, {userInfo?.userInfo?.name}
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item px-0 py-0"
                        to="/profile"
                      >
                        <div
                          className="d-flex px-3 py-2"
                        // style={{ backgroundColor: "#E2E7F0" }}
                        >
                          <img src={Profil} width={20} height={20} />
                          <div className="mx-2">Profil</div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item px-0 py-0"
                        to="/kontoinformationen"
                      >
                        <div
                          className="d-flex px-3 py-2"
                        // style={{ backgroundColor: "#E2E7F0" }}
                        >
                          <img src={Setting} width={20} height={20} />
                          <div className="mx-2">Kontoinformationen</div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item px-0 py-0"
                        to="/Mitgliedschaft"
                      >
                        <div
                          className="d-flex px-3 py-2"
                        // style={{ backgroundColor: "#E2E7F0" }}
                        >
                          <img src={Money} width={20} height={20} />
                          <div className="mx-2">Mitgliedschaft</div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item px-0 py-0"
                        to="/book-trip"
                      >
                        <div
                          className="d-flex px-3 py-2"
                        // style={{ backgroundColor: "#E2E7F0" }}
                        >
                          <img src={Car} width={20} height={20} />
                          <div className="mx-2">Gebuchte Fahrten</div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item px-0 py-0"
                        to="/publishedtrips"
                      >
                        <div
                          className="d-flex px-3 py-2"
                        // style={{ backgroundColor: "#E2E7F0" }}
                        >
                          <img src={TruckGrey} width={20} height={20} />
                          <div className="mx-2">
                            Veröffentlichte Fahrten
                          </div>
                        </div>
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        className="dropdown-item px-0 py-0"
                        to="/notification"
                      >
                        <div
                          className="d-flex px-3 py-2"
                        // style={{ backgroundColor: "#E2E7F0" }}
                        >
                          <img src={BellIcon} width={20} height={20} />
                          <div className="mx-2">Benachrichtigungen</div>
                        </div>
                      </Link>
                    </li> */}

                    <li>
                      <Link
                        className="dropdown-item px-0 py-0"
                        to="/reviews"
                      >
                        <div
                          className="d-flex px-3 py-2"
                        // style={{ backgroundColor: "#E2E7F0" }}
                        >
                          <img src={Star} width={20} height={20} />
                          <div className="mx-2">Bewertungen</div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item px-0 py-0"
                        to="/login"
                      >
                        <div
                          className="px-3 py-2"
                          style={{
                            backgroundColor: "#25A9E0",
                            color: "#fff",
                          }}
                        >
                          Ausloggen
                        </div>
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="px-0">
                      <Link className="dropdown-item px-2" to="/login">
                        <div className="d-flex">
                          <img src={LoginIcon} width={20} height={20} /><div className="mx-2" style={{ fontSize: 16, fontWeight: 600, color: '#4A5468' }}>Einloggen</div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item px-2" to="/register">
                        <div className="d-flex">
                          <img src={UserAdd} width={20} height={20} /><div className="mx-2" style={{ fontSize: 16, fontWeight: 600, color: '#4A5468' }}>Registrieren</div>
                        </div>
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div>
              <Link to="/">
                <img src={Logo} width={"100px"} alt="Logo" />
              </Link>
            </div>
            <div className="d-flex justify-content-end">
              {location.pathname !== "/Homepage" && (
                <>
                  <div
                    className="d-flex mx-2 my-auto"
                    onClick={() => {
                      window.location.href = "/HowItWorks";
                    }}
                  >
                    <img src={How} width={20} height={20} alt="Search" />
                  </div>
                  <div
                    className="d-flex mx-2 my-auto"
                    onClick={() => {
                      window.location.href = "/SearchingRoute";
                    }}
                  >
                    <img src={Search} width={20} height={20} alt="Search" />
                  </div>
                  <div
                    className="d-flex mx-2 my-auto"
                    style={{ cursor: "pointer" }}
                    onClick={handledepartTo}
                  >
                    <img src={Truck} width={20} height={20} alt="Truck" />
                  </div>
                  <div className="dropdown user-drop1 my-auto">
                    <button
                      onClick={toggleDrawer}

                      className="btn dropdown-toggle d-flex px-0"
                      type="button"

                    >
                      {noti_count != 0 ?
                        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                          {noti_count}
                          <span class="visually-hidden">unread messages</span>
                        </span>
                        : ''}
                      <img src={Bell} width={30} height={30} alt="User" />
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        style={{ background: "#f2f2f2" }}
      >
        <div className="container-modal">
          <div className="text-center contact">Kontaktieren Sie uns!</div>
          <form action="/action_page.php">
            <label className="label-text" for="fname">
              Name <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              type="text"
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              id="fname"
              name="firstname"
              placeholder=""
            />
            <br />

            <label className="label-text" for="lname">
              E-Mail<sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              type="text"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
              id="Fname"
              name="lastname"
              placeholder=""
            />
            <label className="label-text" htmlFor="phone">
              Telefonnummer<sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              type="text"
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              value={phone}
              maxLength={14}
              id="phone"
              name="phone"
              
              placeholder=""
              onKeyPress={(e) => {
                // Check if the entered key is not a number
                if (isNaN(parseInt(e.key, 10))) {
                  e.preventDefault();
                }
              }}
            />

            <label className="label-text" for="subject">
              Ihre Nachricht<sup style={{ color: "red" }}>*</sup>
            </label>
            <textarea
              onChange={(e) => {
                if (e.target.value?.length > 5000) {
                  setErrr("Messahe should be contain 500 charectors !!");
                  setTimeout(() => {
                    setErrr("");
                  }, 3000);
                  return false;
                } else {
                  setMessage(e.target.value);
                }
              }}
              className="mb-0"
              id="subject"
              value={message}
              name="subject"
              placeholder=""
              style={{ height: "100px" }}
            ></textarea>
            {/* <div style={{  fontSize: '12px', color: '#6c757d' }}>
        {message.length}/200 characters
      </div> */}
            <div className="d-flex">
              <div className="my-auto d-flex justify-content-center">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    const isChecked = e.target.checked ? 1 : 0; // Convert checked state to 1 or 0

                    setContactBack(isChecked);
                  }}
                  checked={contactBack === 1} // Check if contactBack is equal to 1 to determine checked state
                  id="contactBack"
                  name="contactBack"
                  value="1" // This value will be submitted with the form data if the checkbox is checked
                />
              </div>
              <div className="my-auto">
                <label
                  className="label-text "
                  for="Fname"
                  style={{ marginLeft: "10px" }}
                >
                  Bitte um Rückruf
                </label>
              </div>
            </div>

            {/* <input type="submit" value="Submit"/> */}
            <div className="text-center mt-3">
              <button
                onClick={onSumbit}
                type="button"
                className="btn btn-primary"
                style={{
                  width: "100%",
                  backgroundColor: "rgb(37, 169, 224)",
                  borderColor: "rgb(37, 169, 224)",
                }}
              >
                Senden
              </button>
            </div>
            <div
              style={{ height: 20, color: "red", fontSize: 12, marginTop: 5 }}
            >
              {errr ? errr : ""}
            </div>
          </form>
        </div>
      </Modal>

      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction='right'
        className='bla bla bla'
      >
        {/* {
            noti_.map((val) =>
              <div className="d-flex " id="notification-sec" style={{
                width: "350px", cursor: 'pointer',
                backgroundColor: (val.isNotify == 1 ? "rgb(224 246 255)" : "white"), padding: "14px 14px", borderBottom: '1px solid rgb(223 223 223)'
              }}
                dangerouslySetInnerHTML={{
                  __html: `<div class="email-sec"><img src='${Email}' class="email-icon"/></div> ` + val?.json?.Message
                }}
                onClick={() => {
                  if (val.isNotify == 1) {
                    changeNotiState(val.not_id)
                  }
                }}></div>
            )
          } */}

{
  1 ?
  
  
  
  (
    <div className="recent-activities">
      <div className="" style={{ borderBottom: '1px solid #ccc' }}>
        <div>
          <img className="my-2" id="close-icon" onClick={() => { setIsOpen(false) }} src={CloseIcon} width={30} style={{ cursor: "pointer" }} />
        </div>
        <div>
          <h5>Benachrichtigungen</h5>
        </div>
      </div>
      <ul>
        {/* {noti_?.map((val) => {
          const isDocUploadNotification =
            (docs_status === 0 || docs_status === 1) &&
            (val?.json?.Message?.includes("IdVerificationCompany") ||
              val?.json?.Message?.includes("idverificationstart"));
          if (isDocUploadNotification) { 
            return ""
          }  else {
            return (
              
                <div
                    onClick={() => {
                        if (val?.json?.Message.search(`<a href=`) !== -1) {
                            window.open(val?.json?.Message.substr(val?.json?.Message?.search(`<a href=`)).replace("<a href=", '').replace(">Click here</a>", ''), '_blank');
                        }
                    }}
                    id="notification-sec"
                    style={{
                        backgroundColor: val.isNotify === 1 ? "rgb(224 246 255)" : "white",
                        padding: "14px 14px",
                        borderBottom: '1px solid rgb(223 223 223)',
                        // width: "382px",
                        cursor: 'pointer',
                    }}
                    key={val.not_id}
                >
                    <div className="d-flex" style={{ width: "360px", cursor: 'pointer' }}>
                        <div className="email-sec">
                            <img src={Email} className="email-icon" alt="Email Icon" />
                        </div>
                        <div
                            className="my-auto"
                            dangerouslySetInnerHTML={{
                                __html: `<div class="email-sec"></div>` + (val?.json?.Message ? val.json.Message.replace("Click here", '') : '')
                            }}
                            onClick={() => {
                                if (val.isNotify === 1) {
                                    changeNotiState(val.not_id)
                                }
                            }}
                        />
                    </div>
                </div>
            );
        }
        })} */}
        {noti_?.map((val) => {
    const isDocUploadNotification =
       
        (val?.json?.Message?.includes("notify=uploaddocs") ||
            val?.json?.Message?.includes("notify=uploaddocs"));
    
    if (val.created_dt === null  || val.isNotify=== 2) {
      
        return null; 
        
    }
    else {
        return (
            <div
                onClick={() => {
                    if (val?.json?.Message.search(`<a href=`) !== -1) {
                        window.open(val?.json?.Message.substr(val?.json?.Message?.search(`<a href=`)).replace("<a href=", '').replace(">Click here</a>", ''), '_self');
                    }
                }}
                id="notification-sec"
                style={{
                    backgroundColor: val.isNotify === 1 ? "rgb(224 246 255)" : "white",
                    padding: "14px 14px",
                    borderBottom: '1px solid rgb(223 223 223)',
                    cursor: 'pointer',
                }}
                key={val.not_id}
            >
                <div className="d-flex" style={{ width: "360px", cursor: 'pointer' }}>
                    <div className="email-sec">
                        <img src={Email} className="email-icon" alt="Email Icon" />
                    </div>
                    <div
                        className="my-auto notification-text"
                        dangerouslySetInnerHTML={{
                            __html: `<div class="email-sec"></div>` + (val?.json?.Message ? val.json.Message.replace("Click here", '') : '')
                        }}
                        onClick={() => {
                            if (val.isNotify === 1) {
                                changeNotiState(val.not_id)
                            }
                        }}
                    />
                </div>
            </div>
        );
    }
})}

      </ul>
    </div>
  ) : (
    <div className="recent-activities">
      <div className="d-flex justify-content-between" style={{ borderBottom: '1px solid #ccc' }}>
        <div>
          <h5>Benachrichtigungen</h5>
        </div>
        <div>
          <img className="my-2" id="close-icon" onClick={() => { setIsOpen(false) }} src={CloseIcon} width={30} />
        </div>
      </div>
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        Keine Benachrichtigungen
      </div>
    </div>
  )
}



       
      </Drawer>

    </>
  );
}