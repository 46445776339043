import React, { useState, useEffect } from "react";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
import './profile.css'
import SideBar from "../../../common/sidebar/side";
import verify from "../../../assets/images/verify.png";
import sms from "../../../assets/images/sms.png";
import User from '../../../assets/Avatar2.png';
import call from "../../../assets/images/call.png";
import pending from "../../../assets/images/pending.png";
import { useNavigate } from "react-router-dom";
import truck from "../../../assets/images/truck-b.png";
import circle from "../../../assets/images/stop-circle.png";
import weigt from "../../../assets/images/weight.png";
import mycar from "../../../assets/images/car-b.png";
import { EDITPROFILE_API } from "../../../services/Api/editProfileApi";
import pencilIcon from "../../../assets/pencil-ic.png";
import pencil from "../../../assets/images/pencil.png";
import toast from "react-hot-toast";
import Header from '../../../common/header'
import Footer from "../../../common/footer";
import AWS from 'aws-sdk';
const Profile = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('')
  const [phoneNo, setPhoneNo] = useState('')
  const [userId, setUserId] = useState('')
  const [documentVerified, setStatus] = useState('')
  const [name, setName] = useState('');
  const [aboutMe, setAboutMe] = useState('');
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');
  const [payload, setPayload] = useState('');
  const [pitches, SetPitches] = useState('');
  const [carsData, setCarsData] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showPencil, setShowPencil] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [progressFlag, setProgressFlag] = useState(false);
  const [profileUrl, setUrl] = useState('');
  const [subscibed, setSubscribed] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("")
  const [errr, setErrr] = useState("")
  const [showMenu, setShowMenu] = useState(true)
  let usr = localStorage.getItem('user')
  let user_info = JSON.parse(usr)
  const onOpenModal = () => {
    setOpen(true)
  };
  const onCloseModal = () => setOpen(false);

  const onSumbit = () => {
    if (name == '' && email == "" && message == "") {
      setErrr("Alle Felder sind Pflichtfelder!!");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return false
    }
    const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!email.match(emailReg)) {
      setErrr("Bitte eine gültige E-Mail-Adresse eintragen!!");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return false
    }

    if (message?.length > 500) {
      setErrr("Die Nachricht sollte 500 Zeichen enthalten !!");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return false
    }
    setOpen(false);

    axios.post("https://smtp.taction.in/api/api/v2/send-mail", {
      name: name,
      email: email,
      message: message
    }).then(res => {
      if (res && res?.status == 200) {

        toast.success('Ihre Anfrage wurde übermittelt', {
          autoClose: 2000,
        }
        );
      }

    }).catch((err) => {
    
    })
  }

  const closeT = () => {
    document.getElementById("navbarSupportedContent")?.classList.remove("show");
  }
  let temp_var = '';
  let oldUrl='';
  const handleFileUpload = async (event) => {
    
    const file = event.target.files[0];
    const fileType = file.type.split('/')[0];
    const fileSize = file.size / 1024 / 1024;
    if (fileSize > 1) {
      toast.error("Die Bildgröße sollte nicht größer als 1 MB sein", {
        autoClose: 3000,
      });

      return;
    }
     
     if (fileType !== 'image') {
      toast.error('Bitte laden Sie eine Bilddatei hoch.');
         return;
     }
 
    
     if (file.type === 'image/gif') {
      toast.error('Bitte laden Sie keine GIF-Dateien hoch.');
         return;
     }

    const uniqueId = Date.now().toString();
    if (fileType !== 'image') {

      alert('Bitte laden Sie eine Bilddatei hoch.');
      return;
    }
    try {
      
      const s3 = new AWS.S3({
        region: 'us-east-2',
        // region: 'US East (Ohio) us-east-2',
        credentials: {
          accessKeyId: "AKIA6GBMFLZRFNFL64ZA",
          secretAccessKey: "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",
        },
      });
      
      const params = {
        Bucket: 'warumleer-bucket-i-0e9a1494985796c7d',
        Key: `warumleer/Profile/${uniqueId}-${file.name}`,
        Body: file,
        ACL: 'public-read',
        ContentType: file.type,
      };

      const upload = s3.upload(params);
      upload.on('httpUploadProgress', function (progress) {
        const percentage = Math.round((progress.loaded / progress.total) * 100);
      });
      const data = await upload.promise();
      const liveUrl = data.Location;
       oldUrl= profileUrl;
      setUrl(liveUrl);
      temp_var = liveUrl;
    } catch (error) {
      // Handle errors
      toast.error('Fehler beim Hochladen der Datei auf S3');
    } finally {
      setProgressFlag(false); // Reset progress flag after file upload
    }
    const fileName = oldUrl ? oldUrl.split('/').pop() : '';
    let saveUrl = {
      image_url: temp_var,
      user_id: userId,
      fileId: fileName
    }
   
    const response = await EDITPROFILE_API.uploadProfileImg(saveUrl)
    if (response && (response.status === 201 || response.status === 200)) {
      toast.success('Bild erfolgreich aktualisiert', {
        autoClose: 2000,

      })
      window.location.reload(false);
    }
  };


  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };


  const handleDelete = (carid) => {

    // Handle delete action
    EDITPROFILE_API.deleteCarDetail({
      car_id: carid,
    })
      .then((response) => {
        if (response?.status == 200) {

          toast.success('Fahrzeug gelöscht', {
            autoClose: 1000,
          })

        }
        getCarDetail(userId)
      })
      .catch((error) => {
        console.error('Error deleting Branch:', error)
      })

  };

  const getProfileInfo = (userId1) => {
    EDITPROFILE_API.getProfileInfo(userId1)
      .then((response) => {
        if (response?.status === 200) {
          const profiledata = response.data[0] || [];
          setEmail(profiledata.email)
          setPhoneNo(profiledata.phone_no)
          setName(profiledata.name)
          setAboutMe(profiledata.about_me)
          setUrl(profiledata.image_url);
        } else {
          console.error('Error fetching branch data. Response:', response)
        }
      })
      .catch((error) => {
        console.error('Error fetching branch data:', error)
      })
  }
  const getCarDetail = (userId) => {
    EDITPROFILE_API.getCarDetail(userId)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          const carData = response.data || []; // Update to get carData array
          setCarsData(carData); // Set car data to state
        } else {
          console.error('Error fetching car data. Response:', response);
        }
      })
      .catch((error) => {
        console.error('Error fetching car data:', error);
      });
  }
  useEffect(() => {

    let user_id = user_info.userInfo.user_id;
    let status = user_info.userInfo.status;
    setStatus(status);
    setUserId(user_id)
    setSubscribed(user_info.userInfo.is_subscribe);
    getProfileInfo(user_id)
    getCarDetail(user_id)
  }, [userId])
  return <React.Fragment>
    <Header />
    <div className="pading-mobile">
      <div className="container">
        <div className="row mt-lg-5 pt-3">
          <div className="col-lg-3 hamburger-sec">
            {/* <SideBar title="profile" /> */}
            <nav class="navbar navbar-expand-lg navbar-light bg-white">
              <div class="container-fluid justify-content-end px-lg-0">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse mt-3 mt-lg-0" id="navbarSupportedContent">
                  <SideBar title="profile" />
                </div>
              </div>
            </nav>
          </div>
          <div className="col-lg-9 profile-sec">
          <div class="container">
              <div className="row">
                <div className="col-lg-9 col-12">
              <div className="row profile-container" onMouseEnter={() => setShowPencil(true)} onMouseLeave={() => setShowPencil(false)}>
                <div className="col-2 px-0 d-flex justify-content-center my-auto" style={{ width: 96, height: 96 ,borderRadius:50}}>
                  <div className="my-auto" style={{width:'100%'}}>
                  
                  {profileUrl ? (
                    <div className="ratio img-responsive img-circle" style={{ backgroundImage: `url(${profileUrl})` }}>
                    <img className="" src={profileUrl} style={{borderRadius:50}} />
                  </div>
                  ) : (
                    <div className="ratio img-responsive img-circle" style={{ backgroundImage: `url(${User})` }}>
                        <img className="" src={User} style={{borderRadius:50}}/>
                      </div>
                  )}


                  {showPencil && (
                    <label htmlFor="fileInput" style={{display:'block',marginTop:0}}>
                      <img src={pencilIcon} className="pencil-icon" style={{ width: 36, cursor: 'pointer' }} alt="Edit Profile" />
                    </label>
                  )}
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                  />
                  </div>
                </div>

                <div className="col-8 my-auto">
                  <div style={{ color: "#000000", fontSize: 35, fontWeight: 700, textTransform: 'capitalize' }}>{name}</div>
                  {(subscibed === 1 || documentVerified === 1) ? (
                    <div style={{ color: "#000000", fontSize: 18 }}>
                      <div className="d-flex">
                        <div>
                          <img src={verify} width={24} alt="Verified" />
                        </div>
                        <div>Verifiziertes Profil</div>
                      </div>
                    </div>

                  ) : (
                    <div style={{ color: "#000000", fontSize: 18 }}>

                      <div className="d-flex">
                        <div>
                          <img src={pending} width={22} alt="pending" />
                        </div>
                        <div className="mx-2">Verifizierung steht aus</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
                </div>
                <div className="col-lg-3 col-12 mt-3 mt-lg-0">
                <div onClick={(e) => {
                navigate("/edit-profile")
              }} className="my-auto">
                <button style={{
                  color: "#FFF", background: "#25A9E0", border: "1px solid #25A9E0", borderRadius: 8, paddingLeft: 25, paddingRight: 25,
                  paddingBottom: 8,
                  paddingTop: 8
                }}>Profil bearbeiten</button>
              </div>
                </div>
              </div>
              
            </div>
            <div className="row container mt-4">
              <div className="col-lg-6">
                <div className="" style={{ color: "#000000", fontSize: 20, fontWeight: 700 }} >
                  E-Mail
                </div>
                <div className="d-flex mt-2"  >
                  <div>
                    <img src={sms} style={{ width: 32, height: 32 }} />
                  </div>
                  <div className="mx-3 my-auto" style={{ color: "#000000", fontSize: 18 }}>{email}</div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="" style={{ color: "#000000", fontSize: 20, fontWeight: 700 }} >
                  Telefonnummer
                </div>
                <div className="d-flex mt-2"  >
                  <div>
                    <img src={call} style={{ width: 32, height: 32 }} />
                  </div>
                  <div className="mx-3 my-auto" style={{ color: "#000000", fontSize: 18 }}>{phoneNo}</div>
                </div>
              </div>
            </div>
            <div className="row container mt-3">
              <div className="col-lg-12">
                <div className="" style={{ color: "#000000", fontSize: 20, fontWeight: 700 }}>
                  Erzählen Sie uns ein wenig über sich
                </div>
                <div>
                  {aboutMe ? (
                    <div style={{ color: "#4A5468", fontSize: 18 }}>{aboutMe}</div>
                  ) : (
                    <div style={{ color: "#4A5468", fontSize: 18 }}>Ich liebe Autos &#128578;</div>
                  )}
                </div>


              </div>
            </div>
            <div className="row container mt-3">
              <div className="col-lg-12">
                <div className="" style={{ color: "#000000", fontSize: 20, fontWeight: 700 }} >
                  Meine Fahrzeuge
                </div>
              </div>
            </div>
            <div className="row container mt-3  mb-lg-5 pb-lg-5">

              {carsData.map((car, index) => (
                <div className="col-lg-5 col-12 mt-3" key={index}>
                  <div className="card p-3" style={{ border: "0.1rem solid #E2E7F0", borderRadius: 20 }}>


                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <div className="tooltip-container" data-tooltip="Marke "><img src={truck} width={"22"} /></div>
                        <div className="mx-2 my-auto" style={{ color: "#000", fontSize: 16 }}>{car.car_name}</div>
                      </div>
                      <div class="dropdown">
                        <button
                          class="btn btn-secondary dropdown-toggle bg-white b-none"
                          style={{ display: 'flex', border: 'none' }}
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={pencil} width="18" />
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li><a class="dropdown-item" href="#" onClick={() => navigate(`/add-wehicle?carId=${car.car_id}`)}>Edit</a></li>
                          <li><a class="dropdown-item" href="#" onClick={() => handleDelete(car.car_id)}>Delete</a></li>
                        </ul>
                      </div>
                    </div>

                    <div className="d-flex mt-3">
                      <div className="tooltip-container" data-tooltip="Model"><img src={circle} width={"22"} /></div>
                      <div className="mx-2 my-auto" style={{ color: "#000", fontSize: 16 }}>{car.car_model}</div>
                    </div>

                    <div className="d-flex mt-3">
                      <div className="tooltip-container" data-tooltip="Zuladungsgewicht"><img src={weigt} width={"22"} /></div>
                      <div className="mx-2 my-auto" style={{ color: "#000", fontSize: 16 }}>{car.weight} t</div>
                    </div>
                    <div className="d-flex mt-3">
                      <div className="tooltip-container" data-tooltip="Verfügbare Stellplätze"><img src={mycar} width={"22"} /></div>
                      <div className="mx-2 my-auto" style={{ color: "#000", fontSize: 16 }}>{car.no_of_cars > 1 ? `${car.no_of_cars} Stellplätze` : `${car.no_of_cars} Stellplatz`}</div>
                    </div>
                  </div>

                </div>
              ))}
              <div onClick={() => navigate("/add-wehicle")} className="mt-4  mb-5 pb-5" style={{ color: "#25A9E0", fontSize: 16, cursor: "pointer" }}>
                Fahrzeug hinzufügen
              </div>
              <div className="pb-lg-4" >

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </React.Fragment>
}
export default Profile;