import React, { useEffect, useState } from "react";
import axios from 'axios'
import eye from "../../../assets/eye.png"
import openEye from "../../../assets/open-eye.png"
import sms from "../../../assets/sms.png"
import call from "../../../assets/call.png"
import emailred from "../../../assets/email-red.png"
import key from "../../../assets/key.png"
import keyred from '../../../assets/key-red.png'
import login from "../../../assets/Login_w.png"
import "./login.css"
import { AUTENTICATE_API } from "../../../services/Api/authenticationApi";
import toast from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import { Modal,Form } from 'react-bootstrap';
import { EDITPROFILE_API } from "../../../services/Api/editProfileApi";
import Header from "../../../common/zuruckheader";

const Login = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const [emailErrFlag, setemailErrFlag] = useState(false);
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    const [passwordErrFlag, setpasswordErrFlag] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [otp, setOtp] = useState('');
    const [isGetOtpClicked, setIsGetOtpClicked] = useState(false);
    const reloadCountLogin = Number(sessionStorage.getItem('reloadCountLogin')) || 0;
    const [emailExists, setEmailExists] = useState(false);
    useEffect(() => {
        if (reloadCountLogin < 1) {
            // localStorage.clear();
            localStorage.removeItem('user');
            localStorage.removeItem('checkoutUrl');
            sessionStorage.setItem('reloadCountLogin', String(reloadCountLogin + 1));
            setTimeout(() => {
                //window.location.reload();
            }, 200);
        } else {
            // localStorage.clear();
            localStorage.removeItem('user');
            localStorage.removeItem('checkoutUrl');
            sessionStorage.clear()
        }
    }, [])

    let navigate=useNavigate();
    const onLogin=async(event)=>{      
        event.preventDefault();
        let abc={
            email:email,
            password:password
        }

        if(email==="" && password===""){
            setpasswordErrFlag(true);
            setemailErrFlag(true);
            return false
        }

        if(email==="" ){
            setemailErrFlag(true);
            return false
        }
        if( password===""){
            setpasswordErrFlag(true);
            return false
        }

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!email.match(validRegex)){
            setemailErrFlag(true);
            return false
        }

        let res=await AUTENTICATE_API.customerLogin(abc)
        if(res && res?.status==200){
            toast.success(res?.message);
            let obj={
                userInfo:res?.data,
                token:res?.token
            }
            localStorage.setItem("user",JSON.stringify(obj));
            setTimeout(() => {
                navigate("/")
            }, 1500);

        }
        
    }
    const handleForgetPassword = () => {
       
        window.location.href = `/ForgotPassword`
    };

    const handleCloseModal = () => {
    };
    let obj={
        email:email,
    }
    
    const handleGetLink = async () => {
        try {
            
            setIsGetOtpClicked(true);
            
            
           
            const response = await EDITPROFILE_API.verifyEmail({ email });
           
            
            const { data } = response;
            if (data.success) {
                
             
                
            } else {
                
               
               
                setEmailExists(false);
            }
        } catch (error) {
            console.error('Error verifying email:', error);
            // Handle API error or display error message
        }
    };
    
    const handleVerifyEmail = () => {
        
    };
   
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    return <React.Fragment>
        <Header/>
        <form onSubmit={onLogin}>
        <div className="container login-page">
            <div className="row mt-lg-5 pt-lg-5">
                <div className="col-lg-5 col-12 order-2 order-lg-1 mt-5 mt-xl-4 mt-lg-0 mb-5 mb-lg-0" style={{ background: '' }}>
                    <div className="text-center text-lg-start" style={{ color: "#000000", fontSize: 45, fontWeight: 800 }}>
                        Einloggen
                    </div>
                    <div className="mt-3 mb-4" style={{ fontSize: 22, color: "#717D96" }}>
                        Willkommen zurück! Bitte loggen Sie sich ein und setzen Sie Ihre Reise mit WarumLeer fort.
                    </div>
                    <div className="col-12 mt-4">
                        <div className="form-wrap d-flex">
                            <img
                                src={emailErrFlag===true?emailred:sms} className="eye" />
                            <input value={email} onChange={(e) => {
                                setemailErrFlag(false)
                                setemail(e.target.value);
                            }} autoComplete={"off"} className="form-input form-control shadow-none con-passwords " id={`${emailErrFlag === true ? "nameErr" : "password"}`} type="text" name="E-Mail" placeholder='E-Mail' />
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        <div className="form-wrap d-flex">
                            <img
                                src={passwordErrFlag===true?keyred:key} className="eye" />
                                <input
    value={password}
    onChange={(e) => {
        setpasswordErrFlag(false);
        setpassword(e.target.value);
    }}
    autoComplete={"off"}
    className="form-input form-control shadow-none con-passwords show-pass"
    id={`${passwordErrFlag === true ? "nameErr" : "password"}`}
    type={showPassword ? "text" : "password"} // Show password if `showPassword` is true
    name="Passwort"
    placeholder='Passwort'
/>
<img
    src={showPassword ? openEye : eye} // Assuming `openEye` is your open eye image
    alt="show password"
    onClick={() => togglePasswordVisibility()}
    className="eye1"
/>
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        <button onClick={onLogin} style={{ color: "#FFFFFF", fontSize: 14, background: "#25A9E0", border: "1px solid #25A9E0", borderRadius: 8, width: '100%', padding: 8 }}>Einloggen</button>
                    </div>
                    <div className="col-12 mt-5">
                        <div style={{ marginLeft: 5 }}>
                            <span style={{ color: "#25A9E0", fontSize: 14,cursor:"pointer" }} onClick={handleForgetPassword}>Passwort vergessen?</span>
                        </div>
                      
              {/* Modal */}
              <Modal style={{marginTop:50}} show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton style={{ background: '#25A9E0', color: 'white' }}>
                    <Modal.Title>{isGetOtpClicked ? 'E-Mail-Verifizierung' : 'Passwort vergessen'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isGetOtpClicked ? (
                        <Form.Group controlId="formBasicOTP">
                            <Form.Label>Geben Sie Otp ein</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                            />
                        </Form.Group>
                    ) : (
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>E-Mail-Adresse</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={email}
                                onChange={(e) => setemail(e.target.value)}
                            />
                        </Form.Group>
                    )}
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                        <button  style={{ color: "#FFFFFF", fontSize: 14, background: "#25A9E0", border: "1px solid #25A9E0", borderRadius: 8, padding: 8 }} onClick={isGetOtpClicked ? handleVerifyEmail : handleGetLink}>
                            {isGetOtpClicked ? 'OTP überprüfen' : 'Holen Sie sich Otp'}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
                    </div>
                    <div className="col-12 mt-4">
                        <div style={{ marginLeft: 5 }}>
                            <span style={{ color: "#000000", fontSize: 14 }}>Kein Konto vorhanden?</span>
                            <span onClick={()=>{
                                navigate("/register")
                            }} style={{ color: "#25A9E0", fontSize: 14, marginLeft: 15,cursor:"pointer" }}>Jetzt registrieren</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 my-auto offset-lg-1 d-none d-lg-block">
                    <img src={login} style={{ width: "100%" }} />
                </div>
            </div>
        </div>
        </form>
    </React.Fragment>
}
export default Login;