import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setNoOfCars } from "../../redux/redux/actions/locationActions";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate hook
import "./TakeCars.css";
import Header from "../../../common/zuruckheader";
import toast from "react-hot-toast";
import Minus from '../../../assets/minus.png'
import Plus from '../../../assets/plus.png'

export default function TakeCars() {
  const [number, setNumber] = useState(1); //number of item
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate hook

  const updateQuantity = (value, label) => {
    if (label == "minus" && number !== 0) {
      let num = number - 1;
      setNumber(num);
      return false;
    }
    if (label === "plus" && number < 9) {
      // Add condition to limit number to 9
      let num = number + 1;
      setNumber(num);
      return false;
    } else {
      return false;
    }
  };

  const handleContinue = () => {
    //debugger
    if(number==0){
      toast.error("Wählen Sie Ihre verfügbaren Stellplätze aus")
      return false
    }else{
      dispatch(setNoOfCars(number));
      return true; // Return true if validation passes
    }
    
  };

  const handleButtonClick = () => {
    const continueClicked = handleContinue(); // Check validation before redirection
    if (continueClicked) {
      navigate("/InformationSeeker"); // Redirect only if validation passes
    }
  };

  return (
    <>
    <Header/>
      <div className="container mt-lg-5 ">
        <div className="row justify-content-center  pt-5">
          <div className="col-lg-8 col-12">
            <div
              className="text-center"
              style={{ fontSize: 40, fontWeight: 600 }}
            >
              Verfügbare Stellplätze wählen
            </div>
            <div className="mt-4">
              <div className="counter">
                <div className="row justify-content-center">
                  <div className="col-md-1 col-2 d-flex justify-content-center mt-4">
                    <div
                      className=""
                      style={{cursor:'pointer'}}
                      onClick={() => updateQuantity(-1, "minus")}
                    >
                      <img src={Minus} width={"100%"}/>
                    </div>
                  </div>
                  <div className="col-md-2 col-3 d-flex justify-content-center">
                    <p className="my-auto add-num">{number}</p>
                  </div>
                  <div className="col-md-1 col-2 d-flex justify-content-center mt-4">
                    <div
                      className=""
                      onClick={() => updateQuantity(1, "plus")}
                    >
                      <img src={Plus} style={{cursor:"pointer"}} width={"100%"}/>
                    </div>
                  </div>
                </div>

                {/* {/ <button onClick={() => remove(id)} className='remove-item'>Remove Form Cart</button> } */}
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <button
                type="button"
                onClick={handleButtonClick}
                class="btn btn-primary px-3 py-2 my-auto"
                style={{
                  backgroundColor: "#25A9E0",
                  border: "none",
                  fontSize: 16,
                }}
              >
                Weiter
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
